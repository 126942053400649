import { alpha, Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import theme from "../../../../theme";
import {
  Campaign,
  Fund,
  FUND_STATUS,
  FUND_TYPE,
} from "../../../../types/fund_types";
import { VC } from "../../../../types/vc-platform/news_types";
import IvBox from "../../../data-display/standard-card/iv-box";
import PrimaryButton, {
  SecondaryButton,
} from "../../../input-elements/buttons";

type HeaderSectionProps = {
  fund: Fund;
  campaign: Campaign | null;
  targetInvestment?: VC | null;
};

export function HeaderSectionNew({
  fund,
  campaign,
  targetInvestment,
}: HeaderSectionProps) {
  return (
    <Box
      sx={{
        justifyContent: {
          xs: "flex-start",
          md: "center",
        },
      }}
      style={{
        backgroundColor: theme.palette.background.default,
        display: "flex",
      }}
    >
      <Box
        sx={{ minHeight: { xs: "0", md: "600px" } }}
        style={{ width: "100vw", maxWidth: "1200px" }}
      >
        <Grid
          container
          sx={{
            padding: {
              xs: "0px",
              md: "30px 30px 0 30px",
              lg: "30px 00px 0 0px",
            },
          }}
          style={{ height: "100%" }}
        >
          <Grid
            item
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
            }}
            xs={12}
            md={6}
            style={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h1">{fund.headerText}</Typography>
            <Typography
              variant="body1"
              style={{ color: theme.palette.grey[900], marginTop: "40px" }}
            >
              {fund.description}
            </Typography>
            <div style={{ display: "flex", marginTop: "40px" }}>
              {fund.fundStatus != FUND_STATUS.CLOSED ? (
                <Link
                  to={`/portal/investment-process?campaign=${campaign?.cashlinkId}&fund=${fund.cashlinkId}`}
                  style={{ textDecoration: "none" }}
                >
                  {/**<PrimaryButton>Jetzt investieren</PrimaryButton>**/}
                </Link>
              ) : null}
              <a
                href="https://calendly.com/inventuredatle/30min"
                target="blank"
                style={{ color: "rgb(19, 59, 67)", textDecoration: "none" }}
              >
                <SecondaryButton
                  style={{
                    marginLeft:
                      fund.fundStatus !== FUND_STATUS.CLOSED ? "0px" : "0px",
                  }}
                >
                  Termin vereinbaren
                </SecondaryButton>
              </a>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              justifyContent: {
                xs: "flex-start",
                md: "center",
              },
            }}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "right",
              WebkitJustifyContent: "flex-end",
            }}
          >
            <Box
              sx={{ display: { xs: "none", md: "block" } }}
              style={{
                position: "absolute",
                width: "300px",
                left: "100px",
                bottom: "0px",
                height: "100px",
                filter: "blur(130px)",
                backgroundColor: theme.palette.background.default,
              }}
            />

            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: "80%",
                },
                borderRadius: {
                  xs: "0",
                  md: "5px",
                },
              }}
              style={{
                height: "500px",
                backgroundImage: `url(${fund.backgroundImgUrl})`,
                backgroundSize: "cover",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  borderRadius: {
                    xs: "5px",
                    md: "5px",
                  },
                  top: {
                    xs: "30px",
                    md: "70px",
                  },
                  height: {
                    xs: "400px",
                    md: "600px",
                  },
                }}
                style={{
                  width: "600px",

                  backgroundColor: theme.palette.background.paper,
                  position: "absolute",

                  left: "-300px",
                }}
              >
                <Box
                  sx={{
                    marginTop: {
                      xs: "50px",
                      md: "50px",
                    },
                  }}
                  style={{ marginLeft: "56%" }}
                >
                  <img src={fund.tokenLogo} style={{ maxWidth: "170px" }} />
                  <div
                    style={{
                      width: "225px",
                      height: "0.4px",
                      backgroundColor: "black",
                      marginTop: "30px",
                    }}
                  />
                  <div style={{ marginTop: "30px" }}>
                    <Typography variant="body1">Zielfonds</Typography>
                    <img
                      src={targetInvestment?.logo?.url}
                      style={{ height: "25px", marginTop: "-2px" }}
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Typography variant="body1">Fokus</Typography>
                    <Typography
                      variant="h5"
                      style={{ marginTop: "-5px", fontWeight: 700 }}
                    >
                      {fund.fokus}
                    </Typography>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Typography variant="body1">Renditeprofil</Typography>
                    <Typography
                      variant="h5"
                      style={{ marginTop: "-5px", fontWeight: 700 }}
                    >
                      {fund.nettoMoneyMultiplier}
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
