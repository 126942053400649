import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import React, { ReactNode, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../pages/direct/direct-router";

interface DrawerProps {
  children?: ReactNode;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  className?: string;
}

export default function Drawer({ children, className }: DrawerProps) {
  const [is_open, setIsOpen] = useState(false);
  const app_context = useContext(AppContext);
  const { isAuthenticated, isLoading, user, logout } = useAuth0();
  return (
    <div
      className={`${className} h-screen sticky top-0 bg-primary ${
        !isAuthenticated && !isLoading ? "blur-[2px]" : ""
      }`}
    >
      <div className="w-full h-full  bg-contain">
        <div
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          className="flex flex-col w-24 border-r hover:w-64 transition-all duration-350  hover:transition-all group overflow-hidden h-full top-0 sticky"
        >
          {/* <div className="flex justify-start pl-5 transition-all duration-300 ease-out group-hover:pl-8 pt-8 w-full h-48 bg-black bg-center bg-cover bg-[url('https://images.unsplash.com/photo-1682687220499-d9c06b872eee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80')]">
          <div className="w-12 h-12">
            <img src="https://ik.imagekit.io/inventure/Brand/mini-logo_AkG1RL5TU.svg?updatedAt=1686928074123" />
          </div>
        </div> */}

          <div className=" pt-8 pb-8 pl-4 pr-4 grid gap-4">
            <div className="mb-8">
              {!isLoading && isAuthenticated ? (
                <div className="w-full ml-4 grid gap-2">
                  {app_context.user &&
                  app_context.user.natural_person &&
                  app_context.user.natural_person.forename ? (
                    <div className="w-10 h-10 rounded-full bg-primary_light flex justify-center items-center">
                      <p className="text-1xl text-white">
                        {app_context.user?.natural_person.forename.substring(
                          0,
                          1
                        )}
                      </p>
                    </div>
                  ) : null}
                  <Typography
                    variant="body2"
                    className={`transition-opacity duration-1000 ease-in-out opacity-50 group-hover:opacity-100 text-xs text-gray-500`}
                  >
                    {is_open
                      ? app_context.userEmail
                      : app_context.userEmail.substring(0, 3) + "..."}
                  </Typography>
                </div>
              ) : null}
            </div>
            <MenuPoint
              active={window.location.pathname.includes("dashboard")}
              label="Dashboard"
              icon_dark="https://ik.imagekit.io/inventure/App/Icons/icons8-cashbook-96%20(2)_w9icj6D-I.png?updatedAt=1718797287797"
              to="/direct/dashboard"
            />
            {app_context.user?.natural_person ? (
              <MenuPoint
                active={window.location.pathname.includes("profil")}
                label="Profil"
                icon_dark="https://ik.imagekit.io/inventure/App/Icons/icons8-person-96%20(1)_3WqoqtQyP2.png?updatedAt=1718797556794"
                to="/direct/profile"
              />
            ) : null}
            <MenuPoint
              active={window.location.pathname.includes("bank-and-tax")}
              label="Bank & Steuern"
              icon_dark="https://ik.imagekit.io/inventure/App/Icons/icons8-euro-96_wrGuQn8sL.png?updatedAt=1718797744911"
              to="/direct/bank-and-tax"
            />
            {app_context.user?.legal_person ? (
              <MenuPoint
                active={window.location.pathname.includes("legal-person")}
                label="Juristische Person"
                icon_dark="https://ik.imagekit.io/inventure/App/Icons/icons8-museum-96_ZyHigJkc5.png?updatedAt=1718797784085"
                to="/direct/legal-person"
              />
            ) : null}
            <MenuPoint
              active={window.location.pathname.includes("security")}
              label="Sicherheit"
              icon_dark="https://ik.imagekit.io/inventure/App/Icons/icons8-password-96_EVyaUl7Hc.png?updatedAt=1718797831861"
              to="/direct/security"
            />
          </div>
          <div className="flex-grow" />
          {isAuthenticated ? (
            <div
              className="text-sm w-full flex justify-center pb-4 underline text-gray-500 cursor-pointer"
              onClick={() =>
                logout({
                  returnTo: window.location.origin + "/direct/dashboard",
                })
              }
            >
              <p>Logout</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

interface MenuPointProps {
  label: string;
  icon_dark: string;
  to: string;
  active: boolean;
}

function MenuPoint({ label, icon_dark, to, active }: MenuPointProps) {
  const [is_open, setIsOpen] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Link to={to} className="flex h-full relative">
        {active ? (
          <div className="absolute w-[2px] h-full bg-gradient-to-b from-yellow-300 to-yellow-700 rounded-full -translate-x-2"></div>
        ) : null}
        <div
          className={`w-full duration-200 transition flex items-center gap-4 text-white text-opacity-80 hover:text-primary hover:bg-gray-200 rounded-l-lg rounded-r-lg px-4 py-3 cursor-pointer`}
        >
          {is_open ? (
            <img
              className={`h-5 w-5 
`}
              src={icon_dark}
            />
          ) : (
            <img
              className={`h-5 w-5 saturate-200 grayscale invert
`}
              src={icon_dark}
            />
          )}

          <Typography
            variant="body1"
            className="font-semibold opacity-0  transition-none duration-0 group-hover:opacity-100 whitespace-nowrap"
          >
            {label}
          </Typography>
        </div>
      </Link>
    </div>
  );
}

// export default function Drawer({ children, className }: DrawerProps) {
//   const [is_open, setIsOpen] = useState(false);
//   const app_context = useContext(AppContext);
//   const { isAuthenticated, isLoading, user, logout } = useAuth0();
//   return (
//     <div
//       className={`${className} h-screen sticky top-0 bg-gray-50 ${
//         !isAuthenticated && !isLoading ? "blur-[2px]" : ""
//       }`}
//     >
//       <div
//         onMouseEnter={() => setIsOpen(true)}
//         onMouseLeave={() => setIsOpen(false)}
//         className="flex flex-col w-24 border-r hover:w-64 transition-all duration-350  hover:transition-all group overflow-hidden h-full top-0 sticky"
//       >
//         {/* <div className="flex justify-start pl-5 transition-all duration-300 ease-out group-hover:pl-8 pt-8 w-full h-48 bg-black bg-center bg-cover bg-[url('https://images.unsplash.com/photo-1682687220499-d9c06b872eee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80')]">
//           <div className="w-12 h-12">
//             <img src="https://ik.imagekit.io/inventure/Brand/mini-logo_AkG1RL5TU.svg?updatedAt=1686928074123" />
//           </div>
//         </div> */}

//         <div className=" pt-8 pb-8 pl-4 pr-4 grid gap-4">
//           <div className="mb-8">
//             {!isLoading && isAuthenticated ? (
//               <div className="w-full ml-4 grid gap-2">
//                 {app_context.user &&
//                 app_context.user.natural_person &&
//                 app_context.user.natural_person.forename ? (
//                   <div className="w-10 h-10 rounded-full bg-primary flex justify-center items-center">
//                     <p className="text-1xl text-white">
//                       {app_context.user?.natural_person.forename.substring(
//                         0,
//                         1
//                       )}
//                     </p>
//                   </div>
//                 ) : null}
//                 <Typography
//                   variant="body2"
//                   className={`transition-opacity duration-1000 ease-in-out opacity-50 group-hover:opacity-100 text-xs text-gray-500`}
//                 >
//                   {is_open
//                     ? app_context.userEmail
//                     : app_context.userEmail.substring(0, 3) + "..."}
//                 </Typography>
//               </div>
//             ) : null}
//           </div>
//           <MenuPoint
//             label="Dashboard"
//             icon_dark="https://ik.imagekit.io/inventure/App/Icons/icons8-dashboard-100__1__pEOjEmztk.png?updatedAt=1688990206070"
//             icon_light="https://ik.imagekit.io/inventure/App/Icons/icons8-dashboard-100__1__pEOjEmztk.png?updatedAt=1688990206070"
//             to="/direct/dashboard"
//           />
//           {app_context.user?.natural_person ? (
//             <MenuPoint
//               label="Profil"
//               icon_dark="https://ik.imagekit.io/inventure/App/Icons/icons8-user-100_q9s35ZzBjV.png?updatedAt=1688990001789"
//               icon_light="https://ik.imagekit.io/inventure/App/Icons/icons8-user-100_q9s35ZzBjV.png?updatedAt=1688990001789"
//               to="/direct/profile"
//             />
//           ) : null}
//           <MenuPoint
//             label="Bank & Steuern"
//             icon_dark="https://ik.imagekit.io/inventure/App/Icons/icons8-bank-euro-100__1__lF75gkEXi.png?updatedAt=1688990322440"
//             icon_light="https://ik.imagekit.io/inventure/App/Icons/icons8-bank-euro-100__1__lF75gkEXi.png?updatedAt=1688990322440"
//             to="/direct/bank-and-tax"
//           />
//           {app_context.user?.legal_person ? (
//             <MenuPoint
//               label="Juristische Person"
//               icon_dark="https://ik.imagekit.io/inventure/App/Icons/icons8-company-90_bYYLe4dqP.png?updatedAt=1689490935894"
//               icon_light="https://ik.imagekit.io/inventure/App/Icons/icons8-company-90_bYYLe4dqP.png?updatedAt=1689490935894"
//               to="/direct/legal-person"
//             />
//           ) : null}
//           <MenuPoint
//             label="Sicherheit"
//             icon_dark="https://ik.imagekit.io/inventure/App/Icons/icons8-password-100_jj1ieeVaN.png?updatedAt=1688989657457"
//             icon_light="https://ik.imagekit.io/inventure/App/Icons/icons8-password-100_jj1ieeVaN.png?updatedAt=1688989657457"
//             to="/direct/security"
//           />
//         </div>
//         <div className="flex-grow" />
//         {isAuthenticated ? (
//           <div
//             className="text-sm w-full flex justify-center pb-4 underline text-gray-500 cursor-pointer"
//             onClick={() =>
//               logout({ returnTo: "http://localhost:3000/direct/dashboard" })
//             }
//           >
//             <p>Logout</p>
//           </div>
//         ) : null}
//       </div>
//     </div>
//   );
// }

// interface MenuPointProps {
//   label: string;
//   icon_dark: string;
//   icon_light: string;
//   to: string;
// }

// function MenuPoint({ label, icon_dark, icon_light, to }: MenuPointProps) {
//   const [is_open, setIsOpen] = useState(false);
//   return (
//     <div
//       onMouseEnter={() => setIsOpen(true)}
//       onMouseLeave={() => setIsOpen(false)}
//     >
//       <Link to={to}>
//         <div className="duration-0 transition-none flex items-center gap-4 text-black hover:bg-gray-200 rounded-lg px-4 py-3 cursor-pointer">
//           {is_open ? (
//             <img
//               className={`h-5 w-5
// `}
//               src={icon_light}
//             />
//           ) : (
//             <img
//               className={`h-5 w-5
// `}
//               src={icon_light}
//             />
//           )}

//           <Typography
//             variant="body1"
//             className="font-semibold opacity-0  transition-none duration-0 group-hover:opacity-100 whitespace-nowrap"
//           >
//             {label}
//           </Typography>
//         </div>
//       </Link>
//     </div>
//   );
// }
