import React, { useContext } from "react";
import { Link } from "react-router-dom";
import IVButton from "../input-elements/iv-button";
import ReactGA from "react-ga";
import PrimaryButton from "../input-elements/buttons";
import { Box, Typography } from "@mui/material";
import { AppContext } from "../../pages/portal/auth-router";
import { INVESTOR_TYPE } from "../../types/onboarding_types";
import { USER_STATE } from "../../types/generic_types";

type LegalPersonOnboardingBannerProps = {
  style?: React.CSSProperties;
};

export default function LegalPersonOnboardingBanner({
  style,
}: LegalPersonOnboardingBannerProps) {
  const appContext = useContext(AppContext);
  var user = appContext.user;
  const noLegalPerson =
    user?.investor_type == INVESTOR_TYPE.LEGAL_PERSON &&
    user?.legal_person == null;
  const noBenefitingPerson =
    user?.investor_type == INVESTOR_TYPE.LEGAL_PERSON &&
    user?.benefiting_persons.length == 0;
  const show =
    (noLegalPerson || noBenefitingPerson) &&
    appContext.userState == USER_STATE.ONBOARDED_2;
  //tells if the user already uploaded documents and just needs to add benefitial persons
  return (
    <>
      {/** Desktop */}
      <Box
        sx={{ display: { xs: "none", md: show ? "flex" : "none" } }}
        style={{
          backgroundSize: "cover",

          marginLeft: "20px",

          backgroundColor: "#FFF9F1",
          borderRadius: "5px",
          boxShadow: "black 0 0 10px -5px",
          width: "calc(100% - 40px)",
          padding: "20px",
          ...style,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1">juristische Person</Typography>

          <Typography
            variant="h5"
            style={{ fontWeight: 600, marginBottom: "20px" }}
          >
            Onboarding der juristischen Person
          </Typography>

          <div style={{ flexGrow: 1 }} />
          <Link
            to={"/portal/company-onboarding"}
            style={{ textDecoration: "none" }}
            onClick={() => {
              ReactGA.event({
                category: "Onboarding",
                action: "user klicked onboarding banner",
              });
            }}
          >
            <PrimaryButton
              style={{ margin: "0px", padding: "0px 30px", height: "50px" }}
            >
              Zum Formular
            </PrimaryButton>
          </Link>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "50px",
            }}
          >
            <Dot disabled text="Onboarding" />
            <Stripe />
            <Dot active={true} text="jur. Person" />
            <Stripe />
            <Dot text="Investition" />
          </div>
        </div>
      </Box>
      {/** Mobile */}
      <Box
        sx={{ display: { xs: show ? "flex" : "none", md: "none" } }}
        style={{
          marginLeft: "0px",
          width: "100vw",
          padding: "0px",
        }}
      >
        <Link
          to={"/portal/company-onboarding"}
          style={{ textDecoration: "none", width: "inherit" }}
          onClick={() => {
            ReactGA.event({
              category: "Onboarding",
              action: "user klicked onboarding banner",
            });
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              background:
                "linear-gradient(90deg, rgba(74,120,128,1) 0%, rgba(30,58,66,1) 100%)",
              height: "40px",
            }}
          >
            <Typography variant="body1" style={{ color: "white" }}>
              Ergänze Informationen zur jur. Person.
            </Typography>
            <img
              style={{ height: "20px", marginLeft: "10px" }}
              src="https://ik.imagekit.io/inventure/App/static/arrow-right_MzuYJUlGk6x.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642411207178"
            />
          </div>
        </Link>
      </Box>
    </>
  );
}

interface DotProps {
  active?: boolean;
  text: string;
  disabled?: boolean;
}

function Dot({ text, disabled, active }: DotProps) {
  if (active) {
    return (
      <div style={{ display: "flex", height: "25px" }}>
        <div
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: "#5B8E9B",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "17px",
              height: "17px",
              borderRadius: "50%",
              backgroundColor: "#285C66",
            }}
          ></div>
        </div>
        <Typography
          variant="h6"
          style={{ marginLeft: "10px", marginTop: "-3px" }}
        >
          {text}
        </Typography>
      </div>
    );
  }
  return (
    <div style={{ display: "flex", alignItems: "center", height: "17px" }}>
      <div
        style={{
          width: "25px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "17px",
            height: "17px",
            borderRadius: "50%",
            backgroundColor: disabled ? "gray" : "#5B8E9B",
          }}
        ></div>
      </div>
      <Typography
        variant="body1"
        style={{ marginLeft: "10px", color: disabled ? "gray" : "black" }}
      >
        {text}
      </Typography>
    </div>
  );
}

function Stripe() {
  return (
    <div
      style={{
        width: "25px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "20px",
          width: "1px",
          backgroundColor: "#5B8E9B",
        }}
      />
    </div>
  );
}
