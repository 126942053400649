import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import HookFormTextField, {
  HookFormTextFieldV2,
} from "../../../components/hook-form-inputs";
import { PrimaryButtonV2 } from "../../../components/input-elements/buttons";
import LoadingScreen from "../../../components/loading-screen";
import {
  BankAccount,
  BankAndTax as BankAndTaxType,
  InventureUser,
  TaxInformation,
} from "../../../types/inventure-user_types";
import API_CLIENT from "../../../utility/api-client";
import { AppContext } from "../direct-router";
import { ProfileSection, ProfileSectionLeftSide } from "../profile/profile";

export default function BankAndTax() {
  const app_context = useContext(AppContext);
  const tax_data = app_context.user?.taxInformation;
  const bank_data = app_context.user?.bank_account;

  return (
    <div className="flex flex-col items-center pb-32">
      <div className="flex bg-cover justify-center items-center w-full h-32">
        <div className=" rounded-xl max-w-6xl w-full px-6 md:px-12 grid gap-2">
          <h1 className=" font-serif">Bank und Steuern</h1>
        </div>
      </div>
      <div className="h-[1px] w-full bg-gray-200" />
      <div className="w-full max-w-6xl px-6 md:px-12 py-16 grid grid-cols-1 gap-16 rounded-xl  text-primary">
        <div className="w-full grid gap-8">
          <ProfileSection>
            <ProfileSectionLeftSide>
              <div className="flex gap-4 items-center">
                <h2 className="text-2xl font-medium font-serif">
                  Auszahlungskonto
                </h2>
                <img
                  className="h-6 w-6"
                  src="https://ik.imagekit.io/inventure/App/Icons/icons8-merchant-account-48__Y4dSVJ2H.png?updatedAt=1704468389059"
                ></img>
              </div>

              <p className="text-sm text-gray-600">
                Hier kannst du dein Bankkonto hinterlegen. Das Bankkonto
                fungiert ausschließlich als Auszahlungskonto.
              </p>
            </ProfileSectionLeftSide>
            <div>
              {app_context.user?.natural_person ? (
                <BankForm bank_data={bank_data} />
              ) : (
                <p className="text-sm text-gray-500">
                  Sobald du deine persönliche Daten im Rahmen einer Investition
                  angegeben hast, kannst du hier deine Kontodaten angeben.
                </p>
              )}
            </div>
          </ProfileSection>
          <div className="h-[1px] bg-gray-200" />
          <ProfileSection>
            <ProfileSectionLeftSide>
              <div className="flex gap-4 items-center">
                <h2 className="text-2xl font-medium font-serif">Steuern</h2>
                <img
                  className="h-7 w-7"
                  src="https://ik.imagekit.io/inventure/App/Icons/icons8-bank-draft-48_uWHy94-jZ.png?updatedAt=1704467674132"
                ></img>
              </div>

              <p className="text-sm text-gray-600">
                Hier kannst du deine Steuerdaten hinterlegen, überprüfen und
                aktualisieren.
              </p>
            </ProfileSectionLeftSide>
            <div>
              <TaxForm className="" tax_data={tax_data} />
            </div>
          </ProfileSection>
        </div>
      </div>
    </div>
  );
}

interface TaxFormProps {
  tax_data?: TaxInformation;
  className?: string;
}

export function TaxForm(props: TaxFormProps) {
  const app_context = useContext(AppContext);
  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm<any>({ mode: "onChange" });
  const [is_loading, setIsLoading] = useState(false);

  const submit = (data: any) => {
    setIsLoading(true);
    const tax_data = {
      tax_identification_number: data.identification_number,
      tax_number: "",
      tax_office: data.tax_office,
    } as TaxInformation;
    API_CLIENT.putTax(
      app_context.token,
      tax_data,
      (user) => {
        app_context.updateUser(user);
        reset();
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div>
      {is_loading ? (
        <LoadingScreen className="w-full h-64" />
      ) : (
        <form
          onSubmit={handleSubmit(submit)}
          className={`${props.className} grid gap-8`}
        >
          <HookFormTextFieldV2
            name="identification_number"
            control={control}
            error={false}
            label="Steueridentifikationsnummer"
            required={true}
            defaultValue={props.tax_data?.tax_identification_number}
          />

          <HookFormTextFieldV2
            name="tax_office"
            control={control}
            error={false}
            label="Wohnsitzfinanzamt"
            required={true}
            defaultValue={props.tax_data?.tax_office}
          />
          <PrimaryButtonV2
            disabled={!formState.isDirty || !formState.isValid}
            type="submit"
          >
            Aktualisieren
          </PrimaryButtonV2>
        </form>
      )}
    </div>
  );
}

interface BankFormProps {
  bank_data?: BankAccount;
  className?: string;
}

export function BankForm(props: BankFormProps) {
  const app_context = useContext(AppContext);
  const {
    register,
    reset,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<any>({ mode: "onChange" });
  const [is_loading, setIsLoading] = useState(false);

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const bank = {
      bic: data.bic,
      iban: data.iban,
      bank: data.bank,
      account_holder:
        app_context.user?.natural_person.forename +
        " " +
        app_context.user?.natural_person.surname,
    } as BankAccount;
    API_CLIENT.putBankAccount(
      app_context.token,
      bank,
      (user: InventureUser) => {
        app_context.updateUser(user);
        reset();
        setIsLoading(false);
      },
      (error) => console.log(error)
    );
  };

  return (
    <div>
      {is_loading ? (
        <LoadingScreen className="w-full h-64" />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${props.className} grid gap-8`}
        >
          <div className="grid gap-2">
            <HookFormTextFieldV2
              name="bank"
              control={control}
              error={false}
              label="Bank"
              required={true}
              defaultValue={props.bank_data?.bank}
            />
            <HookFormTextFieldV2
              name="account_holder"
              control={control}
              error={false}
              disabled
              label="Kontoinhaber"
              required={false}
              defaultValue={props.bank_data?.account_holder}
            />
            <HookFormTextFieldV2
              name="iban"
              control={control}
              error={false}
              label="IBAN"
              required={true}
              defaultValue={props.bank_data?.iban}
            />
            <HookFormTextFieldV2
              name="bic"
              control={control}
              error={false}
              label="BIC"
              required={true}
              defaultValue={props.bank_data?.bic}
            />
          </div>
          <PrimaryButtonV2
            type="submit"
            disabled={!formState.isDirty || !formState.isValid}
          >
            Aktualisieren
          </PrimaryButtonV2>
        </form>
      )}
    </div>
  );
}
