import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { WhatToKnowSectionType } from "../../../../types/fund_types";

type WhatToKnowSectionProps = {
  whatToKnowSection: WhatToKnowSectionType;
};

export default function WhatToKnowSection({ whatToKnowSection }: WhatToKnowSectionProps) {
  const mainCard = whatToKnowSection.mainCard;
  return (
    <Grid container>
      <Grid item xs={12} style={{ marginTop: "70px" }}>
        <Typography variant="h5" style={{ color: "#494949", fontWeight: 600, marginBottom: "10px" }}>
          {whatToKnowSection.headline}
        </Typography>
      </Grid>
      {whatToKnowSection.simpleCards.slice(0, 2).map((card, i) => (
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              margin: {
                xs: "10px 0px 10px 0",
                md: i % 2 == 0 ? "10px 10px 10px 0px" : "10px 0 10px 10px",
              },
            }}
            style={{
              height: "160px",
              backgroundColor: "#fefaf4",
              borderRadius: "5px",

              boxShadow: "black 0 0 30px -25px",
              padding: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={card.icon.url} style={{ height: "30px" }} />
              <Typography variant="h5" style={{ marginLeft: "10px" }}>
                {card.headline}
              </Typography>
            </div>
            <Typography style={{ marginTop: "20px" }} variant="body1">
              {card.content}
            </Typography>
          </Box>
        </Grid>
      ))}

      <Grid item xs={12}>
        <div
          style={{
            minHeight: "200px",
            backgroundColor: "#fefaf4",
            borderRadius: "5px",
            padding: "20px",

            margin: "10px 00px 10px 0px",
            boxShadow: "black 0 0 30px -25px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img src={mainCard.icon.url} style={{ height: "30px" }} />
            <Typography variant="h5" style={{ marginLeft: "10px" }}>
              {mainCard.headline}
            </Typography>
          </div>
          <Typography style={{ marginTop: "20px" }} variant="body1">
            {mainCard.content}
          </Typography>
        </div>
      </Grid>
      {whatToKnowSection.simpleCards.slice(2, 4).map((card, i) => (
        <Grid item xs={12} md={6}>
          <Box
           sx={{
            margin: {
              xs: "10px 0px 10px 0",
              md: i % 2 == 0 ? "10px 10px 10px 0px" : "10px 0 10px 10px",
            },
          }}
            style={{
              height: "160px",
              backgroundColor: "#fefaf4",
              borderRadius: "5px",
             
              boxShadow: "black 0 0 30px -25px",
              padding: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={card.icon.url} style={{ height: "30px" }} />
              <Typography variant="h5" style={{ marginLeft: "10px" }}>
                {card.headline}
              </Typography>
            </div>
            <Typography style={{ marginTop: "20px" }} variant="body1">
              {card.content}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
