import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import VerticalStepper from "../../../components/vertical-multistep-form/vertical-stepper";
import PrimaryButton from "../../../components/input-elements/buttons";
import { PROFESSION, EDUCATION } from "../../../types/investment_types";
import {
  DOCUMENT_TYPE,
  OnboardingAddress,
  OnboardingLegitimation,
  OnboardingPerson,
  OnboardingData,
  OnboardingCheckboxes,
  INVESTOR_TYPE,
} from "../../../types/onboarding_types";
import deLocale from "date-fns/locale/de";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CountryUtils from "../../../utility/country-utils";
import API_CLIENT from "../../../utility/api-client";
import { InventureUser } from "../../../types/inventure-user_types";
import { AppContext } from "../auth-router";
import PostIdentButton from "../../../components/page-components/onboarding/postident-button";
import LoadingScreen from "../../../components/loading-screen";
import MultistepFormStep from "../../../components/vertical-multistep-form/multistep-form-step";
import MultistepForm from "../../../components/vertical-multistep-form/multistep-form";
import MultistepFormNavigation from "../../../components/vertical-multistep-form/multistep-form-navigation";
import MultistepFormBody from "../../../components/vertical-multistep-form/multistep-form-body";
import MultistepFormButtons from "../../../components/vertical-multistep-form/multistep-form-buttons";
import { USER_STATE } from "../../../types/generic_types";
import { HookFormCountrySelect } from "../../../components/hook-form-inputs";

interface OnboardingContextProps {
  investorType: INVESTOR_TYPE | null;
  address: OnboardingAddress | null;
  person: OnboardingPerson | null;
  checkboxes: OnboardingCheckboxes | null;
  legitimation: OnboardingLegitimation | null;
}

export const OnboardingContext = React.createContext<OnboardingContextProps>({
  investorType: null,
  address: null,
  person: null,
  checkboxes: null,
  legitimation: null,
});

const spring = {
  type: "spring",
  stiffness: 300,
  damping: 30,
};

const DateToString = (date: Date | null) => {
  const pad = (number: number) => {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  };
  if (date == null) {
    return "Invalid Date";
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate())
  );
};

export default function OnboardingNewLegal() {
  const [update, setUpdate] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [frameHeight, setFrameHeight] = useState(0);
  const [highestStep, setHighestStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [blockTo, setBlockTo] = useState(0);
  const [user, setUser] = useState<InventureUser | null>(null);
  const appContext = useContext(AppContext);

  const INVESTOR_TYPE_STEP = 0;
  const ADDRESS_STEP = 1;
  const PERSONAL_DATA_STEP = 2;
  const STATUS_STEP = 3;
  const DOCUMENT_STEP = 4;
  const VERIFICATION_STEP = 5;

  var investorType: INVESTOR_TYPE | null = null;
  var address: OnboardingAddress | null = null;
  var person: OnboardingPerson | null = null;
  var checkboxes: OnboardingCheckboxes | null = null;
  var legitimation: OnboardingLegitimation | null = null;

  var investorTypeString = localStorage.getItem("investor-type");
  var addressString = localStorage.getItem("onboarding-address");
  var personString = localStorage.getItem("onboarding-person");
  var checkboxesString = localStorage.getItem("onboarding-checkboxes");
  var legitimationString = localStorage.getItem("onboarding-legitimation");

  if (investorTypeString != null) {
    investorType =
      investorTypeString == "NATURAL_PERSON"
        ? INVESTOR_TYPE.NATURAL_PERSON
        : INVESTOR_TYPE.LEGAL_PERSON;
  }
  if (addressString != null) {
    address = JSON.parse(addressString);
  }
  if (personString != null) {
    person = JSON.parse(personString);
  }
  if (checkboxesString != null) {
    checkboxes = JSON.parse(checkboxesString);
  }
  if (legitimationString != null) {
    legitimation = JSON.parse(legitimationString);
  }

  var labels = [
    "Investorentyp",
    "Wohnort",
    "Persönliche Daten",
    "Statusabfragen",
    "Ausweisdaten",
    "Verifikation",
  ];

  const sendOnboardingData = () => {
    var investorTypeString = localStorage.getItem("investor-type");
    var addressString = localStorage.getItem("onboarding-address");
    var personString = localStorage.getItem("onboarding-person");
    var checkboxesString = localStorage.getItem("onboarding-checkboxes");
    var legitimationString = localStorage.getItem("onboarding-legitimation");
    localStorage.removeItem("investor-type");
    localStorage.removeItem("onboarding-address");
    localStorage.removeItem("onboarding-person");
    localStorage.removeItem("onboarding-checkboxes");
    localStorage.removeItem("onboarding-legitimation");

    if (investorTypeString != null) {
      investorType =
        investorTypeString == "NATURAL_PERSON"
          ? INVESTOR_TYPE.NATURAL_PERSON
          : INVESTOR_TYPE.LEGAL_PERSON;
    }

    if (addressString != null) {
      address = JSON.parse(addressString);
    }
    if (personString != null) {
      person = JSON.parse(personString);
    }
    if (checkboxesString != null) {
      checkboxes = JSON.parse(checkboxesString);
    }
    if (legitimationString != null) {
      legitimation = JSON.parse(legitimationString);
    }

    if (address == undefined) return;
    if (person == undefined) return;
    if (checkboxes == undefined) return;
    if (legitimation == undefined) return;

    setLoading(true);
    var oData: OnboardingData = {
      investor_type: investorType ? investorType : INVESTOR_TYPE.NATURAL_PERSON,

      country: address.country,
      address_addition: address.address_addition,
      city: address.city,
      zip: address.zip,
      street: address.street,

      occupation: person.occupation,
      educational_qualification: person.educational_qualification,
      citizenship: person.citizenship,
      birthdate: person.birthdate,
      birthplace: person.birthplace,
      birthcountry: person.birthcountry,
      pep_status: checkboxes.pep_status,
      is_beneficiary: checkboxes.is_beneficiary,

      document_type: legitimation.document_type,
      document_issuer: legitimation.document_issuer,
      document_id: legitimation.document_id,
      document_valid_from: legitimation.document_valid_from,
      document_valid_to: legitimation.document_valid_to,
    };

    API_CLIENT.putOnboarding(
      appContext.token,
      oData,
      (user: InventureUser) => {
        setUser(user);
        (window as any).dataLayer.push({
          event: "onboarding-form-sent",
        });
        updateStep(VERIFICATION_STEP);
        appContext.setUserState(USER_STATE.ONBOARDED_1);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  const chooseStep = () => {
    if (
      appContext.userState === USER_STATE.ONBOARDED_1 ||
      appContext.userState == USER_STATE.ONBOARDED_2
    ) {
      updateStep(VERIFICATION_STEP);
      setLoading(false);
    } else {
      var highestStep = INVESTOR_TYPE_STEP;
      if (investorType) {
        highestStep = ADDRESS_STEP;
        if (address) {
          highestStep = PERSONAL_DATA_STEP;
          if (person) {
            highestStep = STATUS_STEP;
            if (checkboxes) {
              highestStep = DOCUMENT_STEP;
            }
          }
        }
      }
      updateStep(highestStep);
      setLoading(false);
    }
  };

  useEffect(() => {
    setFrameHeight(window.innerHeight - 140);

    API_CLIENT.getUser(
      appContext.token,
      (user) => {
        setUser(user);
      },
      (error) => console.log(error)
    );
    chooseStep();
  }, []);

  const updateStep = (step: number) => {
    setActiveStep(step);
    console.log(step);
    if (step > highestStep) {
      setHighestStep(step);
    }
    if (step > DOCUMENT_STEP) {
      setBlockTo(VERIFICATION_STEP);
    }
  };
  return (
    <>
      {loading ? (
        <LoadingScreen style={{ minHeight: "calc(100vh - 65px)" }} />
      ) : (
        <OnboardingContext.Provider
          value={{
            investorType: investorType,
            address: address,
            person: person,
            checkboxes: checkboxes,
            legitimation: legitimation,
          }}
        >
          {/**<WelcomeScreen
            hide={investorType != null}
            onSuccess={(isLegal) => setUpdate(!update)}
        />*/}

          <MultistepForm>
            <MultistepFormNavigation>
              <VerticalStepper
                totalSteps={5}
                blockTo={blockTo}
                highestStep={highestStep}
                onStepClick={(step) => {
                  if (step < 0) {
                    localStorage.removeItem("investor-type");
                    setUpdate(!update);
                  } else if (step <= highestStep) updateStep(step);
                }}
                style={{ width: "100%", minHeight: "100%" }}
                activeStep={activeStep}
                returnOnFirstStep
                labels={labels}
              />
            </MultistepFormNavigation>
            <MultistepFormBody>
              {/**Investor Type STEP --------------------------------------------------------------------------*/}
              <MultistepFormStep
                step={INVESTOR_TYPE_STEP}
                activeStep={activeStep}
              >
                <InvestorTypeStep
                  height={frameHeight}
                  stepNumber={INVESTOR_TYPE_STEP}
                  activeStep={activeStep}
                  onSuccess={() => updateStep(ADDRESS_STEP)}
                />
              </MultistepFormStep>

              {/**ADDRESS STEP --------------------------------------------------------------------------*/}
              <MultistepFormStep step={ADDRESS_STEP} activeStep={activeStep}>
                <AddressStep
                  height={frameHeight}
                  stepNumber={ADDRESS_STEP}
                  activeStep={activeStep}
                  onSuccess={() => updateStep(PERSONAL_DATA_STEP)}
                  back={() => updateStep(INVESTOR_TYPE_STEP)}
                />
              </MultistepFormStep>

              {/**PERSONAL DATA STEP --------------------------------------------------------------------------*/}
              <MultistepFormStep
                step={PERSONAL_DATA_STEP}
                activeStep={activeStep}
              >
                <PersonalDataStep
                  height={frameHeight}
                  stepNumber={PERSONAL_DATA_STEP}
                  activeStep={activeStep}
                  onSuccess={() => updateStep(STATUS_STEP)}
                  back={() => updateStep(ADDRESS_STEP)}
                />
              </MultistepFormStep>

              {/**CHECKBOX STEP ----------------------------------------------------------------- */}
              <MultistepFormStep step={STATUS_STEP} activeStep={activeStep}>
                <CheckboxStep
                  height={frameHeight}
                  stepNumber={STATUS_STEP}
                  activeStep={activeStep}
                  onSuccess={() => updateStep(DOCUMENT_STEP)}
                  back={() => updateStep(PERSONAL_DATA_STEP)}
                />
              </MultistepFormStep>

              {/**LEGAL PERSON STEP -------------------------------------------------------------- */}

              {/**LEGITIMATION DATA STEP ---------------------------------------------------------- */}
              <MultistepFormStep step={DOCUMENT_STEP} activeStep={activeStep}>
                <LegitimationDataStep
                  height={frameHeight}
                  stepNumber={DOCUMENT_STEP}
                  activeStep={activeStep}
                  onSuccess={() => sendOnboardingData()}
                  back={() => updateStep(STATUS_STEP)}
                />
              </MultistepFormStep>

              {/**IDENT STEP ------------------------------------------------------------------------- */}
              {user ? (
                <MultistepFormStep
                  step={VERIFICATION_STEP}
                  activeStep={activeStep}
                >
                  <Identstep
                    stepNumber={VERIFICATION_STEP}
                    activeStep={activeStep}
                    user={user}
                  />
                </MultistepFormStep>
              ) : null}
            </MultistepFormBody>
          </MultistepForm>
        </OnboardingContext.Provider>
      )}
    </>
  );
}

interface OnboardingStepProps {
  height?: number;
  stepNumber: number;
  activeStep: number;
  onSuccess: () => void;
  back?: () => void;
}

function InvestorTypeStep({
  height,
  stepNumber,
  activeStep,
  onSuccess,
  back,
}: OnboardingStepProps) {
  const onboardingContext = useContext(OnboardingContext);
  const { handleSubmit } = useForm({ mode: "onChange" });

  const [isLegalPerson, setIsLegalPerson] = useState(
    onboardingContext.investorType === INVESTOR_TYPE.LEGAL_PERSON
  );

  const onSubmit = () => {
    var lsItem = isLegalPerson ? "LEGAL_PERSON" : "NATURAL_PERSON";
    localStorage.setItem("investor-type", lsItem);
    onSuccess();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h3">Wie möchtest du investieren?</Typography>
        <Typography variant="subtitle1">
          Hier entscheidest du, ob du als Privatperson oder als eine
          Gesellschaft investieren möchtest. Derzeit unterstützen wir bei
          juristischen Personen die Unternehmensformen GmbH und UG.
        </Typography>
        <Grid
          container
          spacing={2}
          style={{ display: "flex", marginTop: "20px" }}
        >
          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      if (isLegalPerson) {
                        setIsLegalPerson(false);
                      }
                    }}
                    checked={!isLegalPerson}
                    defaultChecked={!isLegalPerson}
                  />
                }
                label={
                  <div>
                    <Typography variant="body1">Natürliche Person</Typography>
                    <Typography variant="subtitle2">
                      Wähle diese Option, falls du als Privatperson investieren
                      möchtest
                    </Typography>
                  </div>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      if (!isLegalPerson) {
                        setIsLegalPerson(true);
                      }
                    }}
                    checked={isLegalPerson}
                    defaultChecked={isLegalPerson}
                  />
                }
                label={
                  <div>
                    <Typography variant="body1">Juristische Person</Typography>
                    <Typography variant="subtitle2">
                      Wähle diese Option, falls du über eine Gesellschaft (GmbH
                      oder UG) investieren möchtest
                    </Typography>
                  </div>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
        <MultistepFormButtons
          back={back != undefined}
          backFunction={() => (back ? back() : null)}
          disabled={false}
        />
      </form>
    </div>
  );
}

function AddressStep({
  height,
  stepNumber,
  activeStep,
  onSuccess,
  back,
}: OnboardingStepProps) {
  var onboardingContext = useContext(OnboardingContext);
  const isActive = activeStep == stepNumber;
  const {
    control,
    formState: { errors, isValid, touchedFields },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const onSubmit = (data: any) => {
    console.log(data);
    onboardingContext.address = data;
    localStorage.setItem("onboarding-address", JSON.stringify(data));
    onSuccess();
  };

  var address: OnboardingAddress | null = null;
  const addressString = localStorage.getItem("onboarding-address");
  if (addressString) {
    address = JSON.parse(addressString);
  }
  console.log(touchedFields);
  return (
    <Box sx={{ height: { xs: `calc(100vh - 70px)`, md: "" } }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", height: "100%", flexDirection: "column" }}
      >
        <Typography variant="h4">
          {onboardingContext.investorType == INVESTOR_TYPE.LEGAL_PERSON
            ? "Gib den Wohnsitz der handelnden Person ein"
            : "Wo ist dein derzeitiger Wohnsitz?"}
        </Typography>
        <Typography variant="body1">
          Die Angaben müssen mit deinem Ausweisdokument übereinstimmen.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: "40px" }}>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel>Land*</InputLabel>
              <Controller
                name="country"
                rules={{ required: true }}
                control={control}
                defaultValue={address?.country ? address.country : "DEU"}
                render={({ field: { onChange, value } }) => (
                  <Select
                    defaultValue={value}
                    onChange={onChange}
                    native
                    error={errors.country != null}
                    label="Land*"
                    variant="outlined"
                    id="demo-simple-select-filled"
                  >
                    {CountryUtils.getCountries().map((country) => (
                      <option value={country.alpha3}>{country.name}</option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <Controller
              name="street"
              defaultValue={address?.street ? address.street : ""}
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  error={errors.street != null}
                  fullWidth={true}
                  label="Straße und Hausnummer*"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="address_addition"
              defaultValue={
                address?.address_addition ? address.address_addition : ""
              }
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  fullWidth={true}
                  label="Adresszusatz"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Controller
              defaultValue={address?.zip ? address.zip : ""}
              name="zip"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={errors.zip != null}
                  fullWidth={true}
                  label={"Postleitzahl*"}
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Controller
              name="city"
              defaultValue={address?.city ? address.city : ""}
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={errors.city != null}
                  fullWidth={true}
                  label={"Ort*"}
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </Grid>

        <MultistepFormButtons
          back={back != undefined}
          backFunction={() => (back ? back() : null)}
          disabled={!isValid}
        />
      </form>
    </Box>
  );
}

function PersonalDataStep({
  height,
  stepNumber,
  activeStep,
  onSuccess,
  back,
}: OnboardingStepProps) {
  const onboardingContext = useContext(OnboardingContext);
  const isActive = activeStep == stepNumber;
  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm<any>({ mode: "onChange" });
  const [mobilestep, setMobileStep] = useState(0);
  const [birthdate, setBirthdate] = useState<Date | null>(null);

  const onSubmit = (data: OnboardingPerson) => {
    localStorage.setItem("onboarding-person", JSON.stringify(data));
    onSuccess();
  };

  var person: OnboardingPerson | null = null;
  const personString = localStorage.getItem("onboarding-person");
  if (personString) {
    person = JSON.parse(personString);
  }

  useEffect(() => {
    if (person) {
      setBirthdate(new Date(person.birthdate));
      reset();
    }
  }, []);

  return (
    <Box sx={{ height: { xs: `calc(100vh - 140px)`, md: "" } }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", height: "100%", flexDirection: "column" }}
      >
        <Typography variant="h4">
          {onboardingContext.investorType == INVESTOR_TYPE.LEGAL_PERSON
            ? "Persönliche Daten zur handelnden Person"
            : "Persönliche Daten"}
        </Typography>
        <Grid container spacing={2} style={{ marginTop: "40px" }}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel>Beruf bzw. Tätigkeit*</InputLabel>
              <Controller
                name="occupation"
                rules={{ required: true }}
                control={control}
                defaultValue={
                  person?.occupation ? person.occupation : PROFESSION.NO_ANSWER
                }
                render={({ field: { onChange, value } }) => (
                  <Select
                    defaultValue={value}
                    onChange={onChange}
                    native
                    error={errors.citizenship != null}
                    label="Beruf bzw. Tätigkeit"
                    variant="outlined"
                    id="demo-simple-select-filled"
                  >
                    <option value={PROFESSION.NO_ANSWER}>Keine Angabe</option>
                    <option value={PROFESSION.ANGESTELLTER}>
                      Angestellte:r
                    </option>
                    <option value={PROFESSION.RENTNER}>
                      Rentner:in / Pensionär:in
                    </option>
                    <option value={PROFESSION.SELBSTSTÄNDIG}>
                      Selbstständig:e
                    </option>
                    <option value={PROFESSION.STUDENT}>Studierende:r</option>{" "}
                    <option value={PROFESSION.APPRENTICE}>
                      Auszubildende:r
                    </option>
                    <option value={PROFESSION.MARGINAL_EMPLOYMENT}>
                      Geringfügige Beschäftigung
                    </option>
                    <option value={PROFESSION.WITHOUT}>Arbeitslos</option>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} style={{}}>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel>Höchster Bildungsabschluss*</InputLabel>
              <Controller
                name="educational_qualification"
                rules={{ required: true }}
                control={control}
                defaultValue={
                  person?.educational_qualification
                    ? person.educational_qualification
                    : EDUCATION.NO_ANSWER
                }
                render={({ field: { onChange, value } }) => (
                  <Select
                    defaultValue={value}
                    onChange={onChange}
                    native
                    error={errors.citizenship != null}
                    label="Höchster Bildungsabschluss"
                    variant="outlined"
                    id="demo-simple-select-filled"
                  >
                    <option value={EDUCATION.NO_ANSWER}>Keine Angabe</option>
                    <option value={EDUCATION.SECONDARY}>
                      Sekundarschulabschluss
                    </option>
                    <option value={EDUCATION.HIGHSCHOOL}>Abitur</option>
                    <option value={EDUCATION.STUDIUM}>
                      Bachelor- und/oder Master-Abschluss
                    </option>
                    <option value={EDUCATION.DOKTOR}>Doktorgrad</option>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} style={{}}>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel>Staatbürgerschaft*</InputLabel>
              <Controller
                name="citizenship"
                rules={{ required: true }}
                control={control}
                defaultValue={person?.citizenship ? person.citizenship : "DEU"}
                render={({ field: { onChange, value } }) => (
                  <Select
                    defaultValue={value}
                    onChange={onChange}
                    native
                    error={errors.citizenship != null}
                    label="Staatsbürgerschaft"
                    variant="outlined"
                    id="demo-simple-select-filled"
                  >
                    {CountryUtils.getCountries().map((country) => (
                      <option value={country.alpha3}>{country.name}</option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={deLocale}
            >
              <Controller
                name="birthdate"
                rules={{
                  required: true,
                  pattern: /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/,
                }}
                control={control}
                defaultValue={person?.birthdate}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    mask="__.__.____"
                    label="Geburtsdatum*"
                    value={birthdate}
                    onChange={(newValue: Date | null) => {
                      setBirthdate(newValue);
                      if (newValue?.toString() == "Invalid Date") {
                        onChange("");
                        return;
                      }
                      var newVal = DateToString(newValue);
                      onChange(newVal);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.birthdate != null}
                        fullWidth={true}
                        defaultValue={person?.birthdate}
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="birthplace"
              defaultValue={person?.birthplace}
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={errors.birthplace != null}
                  fullWidth={true}
                  label={"Geburtsort*"}
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HookFormCountrySelect
              name={"birthcountry"}
              error={false}
              control={control}
              label={"Geburtsland*"}
            />
          </Grid>
        </Grid>
        <Box
          sx={{ display: { xs: "block", md: "none" } }}
          style={{ flexGrow: 1 }}
        />

        <MultistepFormButtons
          back={back != undefined}
          backFunction={() => (back ? back() : null)}
          disabled={!isValid}
        />
      </form>
    </Box>
  );
}

function CheckboxStep({
  height,
  stepNumber,
  activeStep,
  onSuccess,
  back,
}: OnboardingStepProps) {
  var onboardingContext = useContext(OnboardingContext);
  const isActive = activeStep == stepNumber;
  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm<any>({ mode: "onChange" });

  const onSubmit = (data: OnboardingCheckboxes) => {
    data.pep_status = !data.pep_status;
    localStorage.setItem("onboarding-checkboxes", JSON.stringify(data));
    onSuccess();
  };

  var checkboxes: OnboardingCheckboxes | null = null;
  const checkboxesString = localStorage.getItem("onboarding-checkboxes");
  if (checkboxesString) {
    checkboxes = JSON.parse(checkboxesString);
  }
  return (
    <Box sx={{ height: { xs: `calc(100vh - 70px)`, md: "" } }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", height: "100%", flexDirection: "column" }}
      >
        <Typography variant="h4">Statusabfrage</Typography>
        <Grid container spacing={2} style={{ marginTop: "0px" }}>
          <Grid item xs={12}>
            <Controller
              name="pep_status"
              control={control}
              defaultValue={checkboxes ? !checkboxes?.pep_status : false}
              rules={{
                required:
                  "Bitte bestätige uns, dass du keine politisch exponierte Person bist.",
              }}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  style={{ marginTop: "20px", textAlign: "left" }}
                  control={
                    <Checkbox
                      style={{ marginRight: "30px" }}
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                    />
                  }
                  label={
                    <Typography variant="body1">
                      Ich bestätige, dass ich keine politisch exponierte Person
                      (PEP
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Eine politisch exponierte Person (PEP) ist eine
                            Person, die selbst ein hochrangiges wichtiges
                            öffentliches Amt (z.B. Politiker, Botschafter,
                            Richter oberster Gerichte) ausübt, ausgeübt hat oder
                            eine Person aus deren unmittelbaren persönlichen
                            Umfeld (z.B Familienmitglieder oder nahestehende
                            Personen).{" "}
                          </Typography>
                        }
                        style={{ fontSize: "18px", verticalAlign: "middle" }}
                      >
                        <InfoRoundedIcon />
                      </Tooltip>
                      ), kein unmittelbares Familienmitglied einer politisch
                      exponierten Person und keine einer politisch exponierten
                      Person bekanntermaßen nahestende Person bin.
                    </Typography>
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="is_beneficiary"
              control={control}
              defaultValue={checkboxes?.is_beneficiary}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  style={{ marginTop: "20px", textAlign: "left" }}
                  control={
                    <Checkbox
                      style={{ marginRight: "30px" }}
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                    />
                  }
                  label={
                    onboardingContext.investorType == INVESTOR_TYPE.LEGAL_PERSON
                      ? "Ich versichere, auf eigene Rechnung zu handeln."
                      : "Ich versichere, auf eigene Rechnung und im eigenen wirtschaftlichen Interesse zu handeln."
                  }
                />
              )}
            />
          </Grid>
        </Grid>

        <MultistepFormButtons
          back={back != undefined}
          backFunction={() => (back ? back() : null)}
          disabled={!isValid}
        />
      </form>
    </Box>
  );
}

function LegitimationDataStep({
  height,
  stepNumber,
  activeStep,
  onSuccess,
  back,
}: OnboardingStepProps) {
  const isActive = activeStep == stepNumber;
  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm<any>({ mode: "onChange" });
  const [documentValidFrom, setDocumentValidFrom] = useState<Date | null>(null);
  const [documentValidTo, setDocumentValidTo] = useState<Date | null>(null);

  const onSubmit = (data: OnboardingLegitimation) => {
    localStorage.setItem("onboarding-legitimation", JSON.stringify(data));
    onSuccess();
  };

  var legitimation: OnboardingLegitimation | null = null;
  const legitimationString = localStorage.getItem("onboarding-legitimation");
  if (legitimationString) {
    legitimation = JSON.parse(legitimationString);
  }

  useEffect(() => {
    if (legitimation) {
      setDocumentValidFrom(new Date(legitimation.document_valid_from));
      setDocumentValidTo(new Date(legitimation.document_valid_to));
      reset();
    }
  }, []);

  return (
    <Box sx={{ height: { xs: `calc(100vh - 140px)`, md: "" } }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", height: "100%", flexDirection: "column" }}
      >
        <Typography variant="h4">
          Mit welchem Ausweisdokument möchtest du dich legitimieren?
        </Typography>
        <Grid container spacing={2} style={{ marginTop: "40px" }}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel>Ausweisart*</InputLabel>
              <Controller
                name="document_type"
                rules={{ required: true }}
                control={control}
                defaultValue={
                  legitimation?.document_type
                    ? legitimation.document_type
                    : DOCUMENT_TYPE.IDCARD
                }
                render={({ field: { onChange, value } }) => (
                  <Select
                    defaultValue={value}
                    onChange={onChange}
                    native
                    error={errors.country != null}
                    label="Dokumententyp*"
                    variant="outlined"
                    id="demo-simple-select-filled"
                  >
                    <option value={DOCUMENT_TYPE.IDCARD}>
                      Personalausweis
                    </option>
                    <option value={DOCUMENT_TYPE.PASSPORT}>Reisepass</option>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth={true}>
              <Controller
                name="document_id"
                rules={{ required: true }}
                control={control}
                defaultValue={
                  legitimation?.document_id ? legitimation.document_id : ""
                }
                render={({ field: { onChange, value } }) => (
                  <TextField
                    variant="outlined"
                    error={errors.document_id != null}
                    fullWidth={true}
                    label="Ausweisnummer*"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth={true}>
              <Controller
                name="document_issuer"
                rules={{ required: true }}
                control={control}
                defaultValue={
                  legitimation?.document_issuer
                    ? legitimation.document_issuer
                    : ""
                }
                render={({ field: { onChange, value } }) => (
                  <TextField
                    variant="outlined"
                    error={errors.document_issuer != null}
                    fullWidth={true}
                    label="Ausstellende Behörde*"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth={true}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={deLocale}
              >
                <Controller
                  name="document_valid_from"
                  defaultValue={legitimation?.document_valid_from}
                  rules={{
                    required: true,
                    pattern: /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/,
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      mask="__.__.____"
                      label="Ausweis augestellt am*"
                      value={documentValidFrom}
                      onChange={(newValue: Date | null) => {
                        setDocumentValidFrom(newValue);
                        if (newValue?.toString() == "Invalid Date") {
                          onChange("");
                          return;
                        }
                        var newVal = DateToString(newValue);
                        onChange(newVal);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.document_valid_from != null}
                          fullWidth={true}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth={true}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={deLocale}
              >
                <Controller
                  name="document_valid_to"
                  defaultValue={legitimation?.document_valid_to}
                  rules={{
                    required: true,
                    pattern: /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/,
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      mask="__.__.____"
                      label="Ausweis gültig bis*"
                      value={documentValidTo}
                      onChange={(newValue: Date | null) => {
                        setDocumentValidTo(newValue);
                        if (newValue?.toString() == "Invalid Date") {
                          onChange("");
                          return;
                        }
                        var newVal = DateToString(newValue);
                        onChange(newVal);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.document_valid_to != null}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>

        <MultistepFormButtons
          back={back != undefined}
          backFunction={() => (back ? back() : null)}
          disabled={!isValid}
        />
      </form>
    </Box>
  );
}

type IdentStepProps = {
  user: InventureUser;
  activeStep: number;
  stepNumber: number;
};

function Identstep({ user, activeStep, stepNumber }: IdentStepProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginBottom: "20px" }}>
        <Typography variant="h4">Verifikation</Typography>
        <Typography variant="body1">
          Bevor du bei uns investieren kannst, möchten wir noch deine Identität
          verifizieren. Du gelangst über den <b>gelben Button</b> zur Seite der
          Deutschen Post, wo du über einen Video Chat Deine Daten verifizierst.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <img
          src="https://ik.imagekit.io/inventure/App/static/id-card_pChN23oFA.png?updatedAt=1641657833337"
          style={{ height: "40px" }}
        />
        <Typography variant="body1" style={{ marginLeft: "10px" }}>
          Du benötigst das Ausweisdokument, welches du in{" "}
          <b>Schritt 4 (Ausweisdaten)</b> angegeben hast
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <img
          src="https://ik.imagekit.io/inventure/App/static/sand-clock_gq3tmGSJpy.png?updatedAt=1641657842431"
          style={{ height: "40px" }}
        />
        <Typography variant="body1" style={{ marginLeft: "10px" }}>
          Der Vorgang dauert etwa 5 bis 10 Minuten. Du erhältst eine <b>Mail</b>
          , sobald du erfolgreich bei uns verifiziert bist.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <img
          src="https://ik.imagekit.io/inventure/App/static/iphone__1__Y-TwzHs8MJ.png?updatedAt=1641659166416"
          style={{ height: "40px" }}
        />
        <Typography variant="body1" style={{ marginLeft: "10px" }}>
          Wir empfehlen dir, die <b>PostIdent App</b> der Deutschen Post für die
          Legitimation zu nutzen.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          width: "calc(100% - 40px)",
          marginTop: "40px",
          display: "flex",
        }}
      >
        <PostIdentButton
          firstName={user?.natural_person.forename}
          lastName={user?.natural_person.surname}
          email={user?.communication.email}
          uid={user?.uid}
          street={user?.natural_person.street}
          city={user?.natural_person.city}
          zip={user?.natural_person.zip}
          title={user?.natural_person.title}
          phone={user?.natural_person.phone}
          country={user?.natural_person.country}
        />
      </Grid>
    </Grid>
  );
}
