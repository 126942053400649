import React from "react";
import { styled } from "@mui/material/styles";
import { Fund, FUND_STAGE, FUND_STATUS } from "../../../types/fund_types";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import theme from "../../../theme";
import { Divider, Typography } from "@mui/material";
import { Fund as TargetFund } from "../../../types/vc-platform/news_types";

const PREFIX = "FundCard";

const classes = {
  root: `${PREFIX}-root`,
  top: `${PREFIX}-top`,
  statusChip: `${PREFIX}-statusChip`,
  titleDescriptionBox: `${PREFIX}-titleDescriptionBox`,
  keyData: `${PREFIX}-keyData`,
  dataChip: `${PREFIX}-dataChip`,
  investButton: `${PREFIX}-investButton`,
  infoButton: `${PREFIX}-infoButton`,
  infoButtonFullWidth: `${PREFIX}-infoButtonFullWidth`,
};

const Root = styled("div")({
  [`& .${classes.root}`]: {
    width: "calc(100%)",
    height: "calc(100%)",
  },
  [`& .${classes.top}`]: {
    backgroundSize: "cover !important",
    backgroundPosition: "center !important",
  },
  [`& .${classes.statusChip}`]: {
    width: "150px",
    float: "right",
    margin: "10px",
    backgroundColor: "#4d9657",
    color: "white",
    fontSize: "20px",
  },
  [`& .${classes.titleDescriptionBox}`]: {
    marginTop: "140px",
    color: "white",
    margin: "10px",
    height: "150px",
    overflow: "revert",
  },
  [`& .${classes.keyData}`]: {
    padding: "0px",
  },
  [`& .${classes.dataChip}`]: {
    backgroundColor: "#4A7886 !important",
    color: "white",
    fontSize: "16px",
    margin: "10px",
  },
  [`& .${classes.investButton}`]: {
    color: "white !important",
    textAlign: "center",
    height: "40px",
    textDecoration: "none !important",
    backgroundColor: "#4A7886 !important",
    borderRadius: "0 !important",
    width: "50%",
    "&:hover": {
      backgroundColor: "#81b9c9 !important",
    },
  },
  [`& .${classes.infoButton}`]: {
    marginTop: "5px !important",
    color: "white !important",
    height: "40px",
    width: "100%",
    textAlign: "center",
    backgroundColor: "#4A7886 !important",
    "&:hover": {
      backgroundColor: "#315a66 !important",
    },
  },
  [`& .${classes.infoButtonFullWidth}`]: {
    color: "white !important",
    height: "50px",
    borderRadius: "0px 0px 20px 20px !important",
    width: "100%",
    textAlign: "center",
    backgroundColor: "#1E3A42 !important",
    "&:hover": {
      backgroundColor: "#315a66 !important",
    },
  },
});

type FundCardProps = {
  fund: Fund;
  targetFund?: TargetFund;
};

export function FundCardNew({ fund, targetFund }: FundCardProps) {
  const fundStatus = fund.fundStatus;
  const isActive =
    fundStatus == FUND_STATUS.OPEN || fundStatus == FUND_STATUS.SPECIAL1;
  const isClosed = fundStatus == FUND_STATUS.CLOSED;
  const isSpecial = fundStatus == FUND_STATUS.SPECIAL1;

  var today = new Date();
  var closingdate = new Date(fund.closingDate);

  // To calculate the time difference of two dates
  var timediff = closingdate.getTime() - today.getTime() + 1000 * 3600 * 12;

  // To calculate the no. of days between two dates
  var daysUntilClosing = Math.max(Math.round(timediff / (1000 * 3600 * 24)), 0);
  var fomoOn = daysUntilClosing < 30;

  const card = (
    <motion.div
      style={{
        position: "relative",
        borderRadius: "5px",
        borderStyle: "solid",
        borderWidth: "0.4px",
        borderColor: "rgba(150,150,150,0.3)",
        height: "500px",
        padding: "0px",
        backgroundColor: theme.palette.background.paper,
        cursor: isActive ? "pointer" : "",
      }}
      whileHover={
        isActive ? { scale: 1.01, boxShadow: "rgb(0,0,0) 0 0 20px -10px" } : {}
      }
      transition={{ ease: "easeOut" }}
    >
      {fund.fundStatus == FUND_STATUS.OPEN ? null : /**
        <div
          style={{
            boxShadow: "0px 0px 10px -3px black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            height: "25px",
            position: "absolute",
            top: "20px",
            left: "-7px",
            backgroundColor: fomoOn ? "#EC635E" : theme.palette.primary.light,
            zIndex: 50,
            padding: "0 20px",
          }}
        >
          <Typography variant="body2" style={{ color: "white" }}>
            {fomoOn ? `Nur noch ${daysUntilClosing} Tage` : "Offen"}
          </Typography>
        </div>
        **/
      fund.fundStatus == FUND_STATUS.COMING_SOON ? (
        <div
          style={{
            boxShadow: "0px 0px 10px -3px black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            height: "25px",
            position: "absolute",
            top: "20px",
            left: "-7px",
            backgroundColor: "#ECAB5E",
            zIndex: 50,
            padding: "0 20px",
          }}
        >
          <Typography variant="body2" style={{ color: "white" }}>
            Bald verfügbar
          </Typography>
        </div>
      ) : isSpecial ? (
        <div
          style={{
            boxShadow: "0px 0px 10px -3px black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            height: "25px",
            position: "absolute",
            top: "20px",
            left: "-7px",
            backgroundColor: "black",
            zIndex: 50,
            padding: "0 20px",
          }}
        >
          <Typography variant="body2" style={{ color: "white" }}>
            Limitiertes Kontingent
          </Typography>
        </div>
      ) : (
        <div
          style={{
            boxShadow: "0px 0px 10px -3px black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            height: "25px",
            position: "absolute",
            top: "20px",
            left: "-7px",
            backgroundColor: "black",
            zIndex: 50,
            padding: "0 20px",
          }}
        >
          <Typography variant="body2" style={{ color: "white" }}>
            Geschlossen
          </Typography>
        </div>
      )}

      <div
        style={{
          overflow: "hidden",
          borderRadius: "5px 5px 0 0",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
          background: `url(${fund.backgroundImgUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <img
          src={
            fund.invertedThumbnailLogo == true
              ? targetFund?.logoInverted?.url
              : targetFund?.logo?.url
          }
          style={{ width: "50%", zIndex: 50 }}
        />
        {fund.invertedThumbnailLogo == true ? null : (
          <div
            style={{
              width: "50%",
              height: "20px",
              backgroundColor: "white",
              position: "absolute",
              filter: "blur(30px)",
            }}
          />
        )}
      </div>
      <div style={{ padding: "20px" }}>
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          {fund.name}
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginBottom: "20px",
            height: "80px",
            overflow: "hidden",
            WebkitLineClamp: "4",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {fund.description}
        </Typography>
        <Attribute point1="Zielfonds" point2={fund.fundName} />
        <Divider />
        <Attribute point1="Fokus" point2={fund.fokus} />
        <Divider />
        <Attribute point1="Renditeprofil" point2={fund.nettoMoneyMultiplier} />
        <Divider />
        <Attribute
          point1="Stage"
          point2={
            FUND_STAGE[
              fund.investmentStage as string as keyof typeof FUND_STAGE
            ]
          }
        />
        <Divider />
        <Attribute point1="Laufzeit" point2={`${fund.duration} Jahre`} />
      </div>
    </motion.div>
  );

  if (isActive) {
    return (
      <Link
        to={"/portal/product?id=" + fund.cashlinkId}
        style={{
          color: "black",
          textDecoration: "none",
          cursor: isActive ? "pointer" : "default",
        }}
      >
        {card}
      </Link>
    );
  } else {
    return card;
  }
}

interface TextTuple {
  point1: string;
  point2: string;
}

function Attribute({ point1, point2 }: TextTuple) {
  return (
    <div style={{ display: "flex", marginTop: "5px" }}>
      <div style={{ minWidth: "100px" }}>
        <Typography
          variant="body2"
          style={{
            fontWeight: 700,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {point1}
        </Typography>
      </div>
      <div style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
        <Typography variant="body2">{point2}</Typography>
      </div>
    </div>
  );
}
