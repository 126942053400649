import React, { useContext, useEffect, useState } from "react";
import { InvestmentContextFull } from "../investment-process-full";
import { MultistepFormButtonsV2 } from "../../../../components/vertical-multistep-form/multistep-form-buttons";
import { AppContext } from "../../direct-router";
import API_CLIENT from "../../../../utility/api-client";
import {
  Amount,
  CURRENCY,
  Investment,
  Voucher,
} from "../../../../types/investment_types";
import { useHistory } from "react-router-dom";
import AmountUtils from "../../../../utility/amount-utils";
import HookFormTextField from "../../../../components/hook-form-inputs";
import { DocumentBoxWide } from "../../../../components/data-display/document-box";
import PdfPreviewer from "../../dashboard/pdf-preview-document";
import { IvSwitchV2 } from "../../../../components/input-elements/iv-switch";

type DataProtectionProps = {
  back: () => void;
  next: () => void;
};

export default function DataProtection({ back, next }: DataProtectionProps) {
  const investment_context = useContext(InvestmentContextFull);
  const app_context = useContext(AppContext);
  const investment = investment_context.investment;

  const [data_sharing, setDataSharing] = useState(false);
  const [data_no_sharing, setDataNoSharing] = useState(false);

  const submit = () => {
    window.scrollTo(0, 0);
    if (investment === null) return;
    investment_context.setLoading(true);
    API_CLIENT.putInvestmenDataProtection(
      app_context.token,
      investment.id,
      (investment: Investment) => {
        investment_context.setInvestment(investment);
      },
      (error: string) => {
        console.log(error);
      },
      !data_sharing
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submit();
  };

  if (!investment) return null;

  return (
    <div className="flex flex-col gap-8">
      <div className="grid gap-4">
        <p className="text-3xl font-headline font-medium">
          Weitergabe persönlicher Daten
        </p>
      </div>

      <div className="grid gap-4">
        <div className="flex gap-4">
          <div className="min-w-[150px] flex items-center">
            <input
              id="custom-checkbox"
              checked={data_sharing}
              onClick={(e) =>
                setDataSharing((e.target as HTMLInputElement).checked)
              }
              type="checkbox"
              className="hidden"
            />
            <label
              htmlFor="custom-checkbox"
              className="relative flex items-center cursor-pointer gap-4"
            >
              <span
                className={`block p-1 min-w-[32px] w-8 h-8 rounded-xl ${
                  data_sharing ? "bg-slate-300" : "bg-slate-200"
                } border border-gray-500`}
              >
                {data_sharing ? (
                  <img src="https://ik.imagekit.io/inventure/App/Icons/icons8-check-48_N6b56OTVB.png?updatedAt=1724830923273" />
                ) : (
                  ""
                )}
              </span>
              <span className="ml-2 text-gray-700">
                Ich bin mit der Weitergabe meiner persönlichen Daten,
                insbesondere Name, Anschrift und Beteiligungshöhe, an andere
                Gesellschafter/Treugeber, falls diese angefordert werden,
                einverstanden.
              </span>
            </label>
          </div>
        </div>
        <p>oder</p>
        <div className="flex gap-4">
          <div className="min-w-[150px] flex items-center">
            <input
              id="no-checkbox"
              checked={data_no_sharing}
              onClick={(e) => {
                setDataNoSharing((e.target as HTMLInputElement).checked);
              }}
              type="checkbox"
              className="hidden"
            />
            <label
              htmlFor="no-checkbox"
              className="relative flex items-center cursor-pointer gap-4"
            >
              <span
                className={`block p-1 min-w-[32px] w-8 h-8 rounded-xl ${
                  data_no_sharing ? "bg-slate-300" : "bg-slate-200"
                } border border-gray-500`}
              >
                {data_no_sharing ? (
                  <img src="https://ik.imagekit.io/inventure/App/Icons/icons8-check-48_N6b56OTVB.png?updatedAt=1724830923273" />
                ) : (
                  ""
                )}
              </span>
              <span className="ml-2 text-gray-700">
                Ich untersage hiermit der iVC Venture Innovation Fund GmbH & Co.
                geschlossene Investment KG, der Treuhandkommanditistin, der
                Kapitalverwaltungsgesellschaft, ihren Gesellschaftern und
                Dienstleistern, meine persönlichen Daten, insbesondere Name,
                Anschrift und Beteiligungshöhe, an andere
                Gesellschafter/Treugeber oder deren Vertreter weiterzugeben.
              </span>
            </label>
          </div>
        </div>
        <span className="text-sm">
          Hinweis der Gesellschaft: Sie können diese Entscheidung jederzeit
          schriftlich gegenüber der iVC Venture Innovation Fund GmbH & Co.
          geschlossene Investment KG, Rudower Chaussee 29, 12489 Berlin,
          widerrufen.
        </span>
      </div>

      <form onSubmit={handleSubmit}>
        <MultistepFormButtonsV2
          backFunction={() => back()}
          back={true}
          disabled={data_no_sharing == data_sharing}
          nextButtonText="Weiter"
        />
      </form>
    </div>
  );
}
