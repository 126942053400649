import { TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import BankAndTaxAIF from "../../../components/page-components/investment-process-aif/bank-and-tax-aif";
import InvestmentAmountAIF from "../../../components/page-components/investment-process-aif/investment-amount-aif";
import { InvestmentSurveyAIF } from "../../../components/page-components/investment-process-aif/investment-survey-aif";
import MultistepForm from "../../../components/vertical-multistep-form/multistep-form";
import MultistepFormBody from "../../../components/vertical-multistep-form/multistep-form-body";
import MultistepFormNavigation from "../../../components/vertical-multistep-form/multistep-form-navigation";
import MultistepFormStep from "../../../components/vertical-multistep-form/multistep-form-step";
import VerticalStepper from "../../../components/vertical-multistep-form/vertical-stepper";
import { Campaign, Fund } from "../../../types/fund_types";
import {
  CreateInvestmentDTO,
  Investment,
  INVESTMENT_COUNT,
  INVESTMENT_SIZE,
  INVESTMENT_STATE,
  SelfDisclosure,
} from "../../../types/investment_types";
import API_CLIENT from "../../../utility/api-client";
import { AppContext } from "../auth-router";
import queryString from "query-string";
import { MatchingDTO, USER_STATE } from "../../../types/generic_types";
import { INVESTOR_TYPE } from "../../../types/onboarding_types";
import TagManager from "react-gtm-module";
import { sha256 } from "js-sha256";
import LoadingScreen from "../../../components/loading-screen";

interface InvestmentProcessAIFContext {
  fund: Fund | null;
  campaign: Campaign | null;
  investment: Investment | null;
  setInvestment: (investment: Investment | null) => void;
  last_investment: Investment | null | undefined;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export enum INVESTMENTPROCESS_STEP {
  ERROR = -1,
  AMOUNT = 0,
  SURVEY = 1,
  BANK = 2,
  SUMMARY = 3,
  SUCCESS = 4,
}

export const InvestmentContextAIF =
  React.createContext<InvestmentProcessAIFContext>({
    fund: null,
    campaign: null,
    investment: null,
    setInvestment: () => null,
    last_investment: null,
    loading: true,
    setLoading: () => null,
    nextStep: () => null,
    prevStep: () => null,
  });

export default function InvestmentProcessAIF() {
  const app_context = useContext(AppContext);
  const user = app_context.user;

  const [loading, setLoading] = useState(true);
  const [investment, setInvestment] = useState<Investment | null>(null);
  const [active_step, setActiveStep] = useState(INVESTMENTPROCESS_STEP.AMOUNT);
  const [blockTo, setBlockTo] = useState(0);

  const [highestStep, setHighestStep] = useState(0);
  const [fund_loading, setFundLoading] = useState(true);
  const [fund, setFund] = useState<Fund | null>(null);
  const [commulated_investment_amount, setCommulatedInvestmentAmount] =
    useState(0);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [last_investment, setLastInvestment] = useState<Investment | null>();

  //queries
  const queries = queryString.parse(window.location.search);
  const campaign_id = queries.campaign ? queries.campaign.toString() : "";
  const fund_id = queries.fund ? queries.fund.toString() : "";
  const [investment_loaded, setInvestmentLoaded] = useState(false);

  useEffect(() => {
    if (app_context.user?.uid == null) return;
    setLoading(true);
    API_CLIENT.getFund(
      app_context.token,
      fund_id,
      (fund) => {
        setFund(fund);
        var campaign = fund.cashlinkCampaigns.find(
          (campaign) => campaign.cashlinkId == campaign_id
        );
        if (campaign) {
          setCampaign(campaign);
        }
      },
      (error) => console.log(error)
    );
    API_CLIENT.getLastIncomingInvestment(
      app_context.token,
      app_context.user.uid,
      (investment) => {
        setLastInvestment(investment);
      },
      () => {
        setLastInvestment(null);
        console.error("Error: Fetching investment failed");
      }
    );
    API_CLIENT.getInvestmentsByCampaign(
      app_context.token,
      campaign_id,
      (investments: Investment[]) => {
        var activeInvestment = null;
        var commulatedInvestmentSize = 0;
        investments.forEach((investment) => {
          if (investment.state === INVESTMENT_STATE.PENDING) {
            activeInvestment = investment;
          }
          if (
            investment.state === INVESTMENT_STATE.ACCEPTED ||
            investment.state === INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE ||
            investment.state === INVESTMENT_STATE.PAID ||
            investment.state === INVESTMENT_STATE.DELIVERED ||
            investment.state === INVESTMENT_STATE.KYC_PENDING ||
            investment.state === INVESTMENT_STATE.KYC_INVALID ||
            investment.state === INVESTMENT_STATE.KYC_INSUFFICIENT
          ) {
            commulatedInvestmentSize += investment.amount;
          }
        });
        setCommulatedInvestmentAmount(commulatedInvestmentSize);
        setInvestment(activeInvestment);
        setInvestmentLoaded(true);
      },
      () => {
        setInvestment(null);
      },
      (error) => console.log(error)
    );
    const checkUserState = () => {
      const state = app_context.userState;
      const user = app_context.user;
      if (
        state !=
        USER_STATE.ONBOARDED_2 /** 2 is ONBOARDED_2 => see auth router */
      ) {
        window.location.replace("/portal/onboarding");
      } else if (
        user?.investor_type == INVESTOR_TYPE.LEGAL_PERSON &&
        (user?.legal_person == null || user?.benefiting_persons.length == 0)
      ) {
        window.location.replace("/portal/company-onboarding");
      }
    };

    checkUserState();
  }, []);

  useEffect(() => {
    if (!investment_loaded || !campaign || last_investment === undefined) {
      return;
    }
    chooseStep();
    setLoading(false);
  }, [investment_loaded, campaign, investment, last_investment]);

  const setStep = (step: INVESTMENTPROCESS_STEP) => {
    setActiveStep(step);
    setHighestStep(step);
  };

  const chooseStep = () => {
    const survey = investment?.survey;
    if (investment == null || campaign == null) return;
    const surveyInvestmentScore = survey?.scoreKnowledgeExperience;
    const surveyServiceScore = survey?.scoreService;
    const surveyTransactionValue = survey
      ? translateTransactionValue(survey.avgtransactionvalue)
      : 0;
    const surveyYearlyTransactions = survey
      ? translateYearlyTransaction(survey.avgyearlytransactions)
      : 0;
    const surveyTransactionScore =
      (surveyTransactionValue * surveyYearlyTransactions) / 2;
    const campaignInvestmentScore = campaign?.experienceAndKnowledgeScore;
    const campaignServiceScore = campaign?.serviceScore;

    var surveyValid = false;
    if (
      surveyInvestmentScore &&
      surveyServiceScore &&
      campaignInvestmentScore &&
      campaignServiceScore
    ) {
      surveyValid =
        surveyInvestmentScore >= campaignInvestmentScore &&
        surveyServiceScore >= campaignServiceScore &&
        surveyTransactionScore >= investment.amount;
    }

    //if no investment has been created yet, start at the beginning
    if (investment == null) {
      setStep(INVESTMENTPROCESS_STEP.AMOUNT);
      return;
    }

    const investmentAmountStepDone =
      investment.amount > 0 &&
      (investment.self_disclosure_monthly_income !== false ||
        investment.self_disclosure_net_worth_100k !== false);

    const surveyStepDone =
      investmentAmountStepDone &&
      (surveyValid || investment.survey_waiver != null);
    const bank_and_tax_step_done =
      surveyStepDone && user?.bank_account && user.taxInformation;
    const CostStepDone = bank_and_tax_step_done && investment.cost_accepted;
    const confirmationStepDone =
      investment.state === INVESTMENT_STATE.ACCEPTED ||
      investment.state === INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE ||
      investment.state === INVESTMENT_STATE.KYC_PENDING;
    if (confirmationStepDone) {
      setBlockTo(5);
      setStep(INVESTMENTPROCESS_STEP.SUCCESS);
    } else if (
      bank_and_tax_step_done &&
      active_step != INVESTMENTPROCESS_STEP.SURVEY
    ) {
      setStep(INVESTMENTPROCESS_STEP.SUMMARY);
    } else if (surveyStepDone) {
      setStep(INVESTMENTPROCESS_STEP.BANK);
    } else if (investmentAmountStepDone) {
      setStep(INVESTMENTPROCESS_STEP.SURVEY);
    } else {
      setStep(INVESTMENTPROCESS_STEP.AMOUNT);
    }
  };

  const translateTransactionValue = (size: INVESTMENT_SIZE): number => {
    switch (size) {
      case INVESTMENT_SIZE.NO_ANSWER:
        return 0;
      case INVESTMENT_SIZE.LOWER_3000:
        return 3000;
      case INVESTMENT_SIZE.BETWEEN_3000_AND_5000:
        return 5000;
      case INVESTMENT_SIZE.BETWEEN_5000_AND_10000:
        return 10000;
      case INVESTMENT_SIZE.OVER_10000:
        return 100000;
    }
  };

  const translateYearlyTransaction = (
    transactions: INVESTMENT_COUNT
  ): number => {
    switch (transactions) {
      case INVESTMENT_COUNT.NO_ANSWER:
        return 0;
      case INVESTMENT_COUNT.NO_TRANS:
        return 0;
      case INVESTMENT_COUNT.LOWER_5_TRANS:
        return 5;
      case INVESTMENT_COUNT.LOWER_10_TRANS:
        return 10;
      case INVESTMENT_COUNT.MORE_THAN_10_TRANS:
        return 100;
    }
  };

  const nextStep = () => {
    let next_step = INVESTMENTPROCESS_STEP.ERROR;
    switch (active_step) {
      case INVESTMENTPROCESS_STEP.ERROR:
        next_step = INVESTMENTPROCESS_STEP.AMOUNT;
        break;
      case INVESTMENTPROCESS_STEP.AMOUNT:
        next_step = INVESTMENTPROCESS_STEP.SURVEY;
        break;
      case INVESTMENTPROCESS_STEP.SURVEY:
        next_step = INVESTMENTPROCESS_STEP.BANK;
        break;
      case INVESTMENTPROCESS_STEP.BANK:
        next_step = INVESTMENTPROCESS_STEP.SUMMARY;
        break;

      case INVESTMENTPROCESS_STEP.SUMMARY:
        next_step = INVESTMENTPROCESS_STEP.SUCCESS;
        break;
      case INVESTMENTPROCESS_STEP.SUCCESS:
        // Handle the logic for the final step
        break;
      default:
        break;
    }
    setActiveStep(next_step);
  };
  const prevStep = () => {
    let prev_step = INVESTMENTPROCESS_STEP.ERROR;
    switch (active_step) {
      case INVESTMENTPROCESS_STEP.ERROR:
        prev_step = INVESTMENTPROCESS_STEP.ERROR;
        break;
      case INVESTMENTPROCESS_STEP.AMOUNT:
        prev_step = INVESTMENTPROCESS_STEP.ERROR;
        break;
      case INVESTMENTPROCESS_STEP.SURVEY:
        prev_step = INVESTMENTPROCESS_STEP.AMOUNT;
        break;
      case INVESTMENTPROCESS_STEP.BANK:
        prev_step = INVESTMENTPROCESS_STEP.SURVEY;
        break;

      case INVESTMENTPROCESS_STEP.SUMMARY:
        prev_step = INVESTMENTPROCESS_STEP.BANK;
        break;
      case INVESTMENTPROCESS_STEP.SUCCESS:
        prev_step = INVESTMENTPROCESS_STEP.SUMMARY;
        break;
      default:
        break;
    }
    setActiveStep(prev_step);
  };

  if (loading) {
    return <LoadingScreen className="w-full min-h-screen" />;
  }

  return (
    <InvestmentContextAIF.Provider
      value={{
        fund: fund,
        campaign: campaign,
        investment: investment,
        setInvestment: (new_investment: Investment | null) => {
          setInvestment(new_investment);
        },
        last_investment: last_investment,
        loading: loading,
        setLoading: (loading: boolean) => setLoading(loading),
        nextStep: nextStep,
        prevStep: prevStep,
      }}
    >
      <MultistepForm style={{ height: "100%" }}>
        <MultistepFormNavigation>
          <VerticalStepper
            blockTo={blockTo}
            alignTop
            totalSteps={5}
            highestStep={highestStep}
            onStepClick={(step) => {
              if (step <= highestStep) setActiveStep(step);
            }}
            style={{ width: "100%", minHeight: "100%" }}
            activeStep={active_step}
            returnOnFirstStep={false}
            labels={[
              "Anlagebetrag",
              "Kenntnisse & Erfahrungen",
              "Bank & Steuern",

              "Zusammenfassung",
            ]}
          />
        </MultistepFormNavigation>
        <MultistepFormBody alignTop>
          <MultistepFormStep
            disableAnimation
            activeStep={active_step}
            step={INVESTMENTPROCESS_STEP.AMOUNT}
          >
            <InvestmentAmountAIF />
          </MultistepFormStep>

          <MultistepFormStep
            disableAnimation
            activeStep={active_step}
            step={INVESTMENTPROCESS_STEP.SURVEY}
          >
            <InvestmentSurveyAIF
              back={() => {
                setActiveStep(INVESTMENTPROCESS_STEP.AMOUNT);
              }}
              next={() => null}
            />
          </MultistepFormStep>
          <MultistepFormStep
            disableAnimation
            activeStep={active_step}
            step={INVESTMENTPROCESS_STEP.BANK}
          >
            <BankAndTaxAIF
              back={() => {
                setActiveStep(INVESTMENTPROCESS_STEP.SURVEY);
              }}
              next={() => null}
            />
          </MultistepFormStep>
        </MultistepFormBody>
      </MultistepForm>
    </InvestmentContextAIF.Provider>
  );
}
/**
 *
 */
