
import { Paper, Typography } from "@mui/material";
import React from "react";

type StandardBoxProps = {
  children: React.ReactNode;
  header: String;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
};

export default function StandardCard({ children, header, style, contentStyle }: StandardBoxProps) {
  return (
    <div style={{ ...style }}>
      <Typography variant="h5" style={{ color: "#494949", fontWeight: 600, marginBottom: "10px" }}>
        {header}
      </Typography>
      <Paper style={{ padding: "10px" }} elevation={3}>
        {children}
      </Paper>
    </div>
    /*** 
    <Paper
      elevation={0}
      style={{
        background: "white",
        overflow: "hidden",
        borderRadius: "10px",
        boxShadow: "rgb(0 0 0) 0 0 13px -5px",
        ...style,
      }}
    >
      <Grid container>
        <Grid item xs={12} style={{background: "linear-gradient(90deg, rgba(32,70,82,1) 0%, rgba(52,106,122,1) 100%)"}}>
          <Typography
            variant="body1"
            style={{ color: "white", fontWeight: 500, padding: "10px 0px 0 0px", margin: "0px 10px 5px 10px", borderRadius: "10px 10px 10px 10px" }}
          >
            {header}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: "0px 10px 10px 10px", color: "rgb(30, 58, 66)", ...contentStyle, marginTop: "10px" }}>
          {children}
        </Grid>
      </Grid>
    </Paper>**/
  );
}
