export type CreateInvestmentDTO = {
  couponCode: string | null;
  selfDisclosure: SelfDisclosure;
  investmentKey: string | null;
};

export type SelfDisclosure = {
  self_disclosure_monthly_income: boolean;
  self_disclosure_net_worth_100k: boolean;
};

export type Amount = {
  amount: string;
  decimals: number;
  currency: CURRENCY;
};

export interface InvestmentDocument {
  fileName: string;
  description: string;
}

export type Investment = {
  id: string;
  fundId: string;
  amount: number;
  tokenPrice: number;
  fundName: string;
  campaignName: string;
  campaignId: string;
  state: INVESTMENT_STATE;
  paymentCode: string;
  survey: Survey | null;
  survey_waiver: WAIVER_TYPE | null;
  survey_submitted_at: string;
  self_disclosure_monthly_income: boolean;
  self_disclosure_net_worth_100k: boolean;
  agio: Amount;
  disagio: Amount;
  investmentAmount: Amount;
  totalPayment: Amount;
  offerCreatedAt: string;
  signedAt: string;
  acceptedAt: string;
  paidAt: string;
  deliveredAt: string;
  rejectedAt: string;
  abortedAt: string;
  paymentDetails: PaymentDetails;
  costDocumentId: string;
  investmentKey: string;
  cost_accepted: boolean;
  contract_unsigned: string;
  tangany_wallet_accepted: boolean;
  yousign_signature_link: string;
  yousign_signature_link_expiration: string;
  documents: string;
  investor_type: INVESTOR_TYPE | null;
  data_protection_no_sharing: boolean;
  legitimation_document: string;
};

export type PaymentDetails = {
  paymentCode: string;
  bankAccount: BankAccount;
};

export type BankAccount = {
  accountHolder: string;
  bank: string;
  bic: string;
  country: string;
  currency: string;
  iban: string;
};

export type Survey = {
  investmentexperience_investment_fund: EXPERIENCE;
  investmentknowledge_investment_fund: KNOWLEDGE;
  investmentexperience_money_market_funds: EXPERIENCE;
  investmentknowledge_money_market_funds: KNOWLEDGE;

  investmentexperience_bond: EXPERIENCE;
  investmentknowledge_bond: KNOWLEDGE;
  investmentexperience_share: EXPERIENCE;
  investmentknowledge_share: KNOWLEDGE;

  investmentexperience_participation_right: EXPERIENCE;
  investmentknowledge_participation_right: KNOWLEDGE;
  investmentexperience_unsecured_junior_debt: EXPERIENCE;
  investmentknowledge_unsecured_junior_debt: KNOWLEDGE;
  investmentexperience_crypto_currency: EXPERIENCE;
  investmentknowledge_crypto_currency: KNOWLEDGE;
  investmentexperience_pension_funds: EXPERIENCE;
  investmentknowledge_pension_funds: KNOWLEDGE;
  investmentexperience_fund_of_funds: EXPERIENCE;
  investmentknowledge_fund_of_funds: KNOWLEDGE;
  investmentexperience_stock_funds: EXPERIENCE;
  investmentknowledge_stock_funds: KNOWLEDGE;
  investmentexperience_participation: EXPERIENCE;
  investmentknowledge_participation: KNOWLEDGE;

  serviceexperience_independent: SERVICE_KNOWLEDGE_OR_EXPERIENCE;
  serviceexperience_investment_consultancy: SERVICE_KNOWLEDGE_OR_EXPERIENCE;
  serviceexperience_investment_management: SERVICE_KNOWLEDGE_OR_EXPERIENCE;
  serviceexperience_investment_brokerage: SERVICE_KNOWLEDGE_OR_EXPERIENCE;

  avgyearlytransactions: INVESTMENT_COUNT;
  avgtransactionvalue: INVESTMENT_SIZE;

  scoreKnowledgeExperience: number;
  scoreService: number;
  scoreTransactions: number;
};

export type SignedDocument = {
  id: string;
  category: string;
  subject: string;
  document: {
    id: string;
    path: string;
    created_at: string;
    name: string;
  };
};

export type Discount = {
  type: string;
  amount_off: string;
  percent_off: string;
};

export type Voucher = {
  code: string;
  valid: boolean;
  discount: Discount;
};

export enum EDUCATION {
  NO_ANSWER = "Not specified",
  HIGHSCHOOL = "High school diploma / A-levels",
  SECONDARY = "Secondary school diploma",
  STUDIUM = "Bachelor and/or Master degree",
  DOKTOR = "Doctoral degree",
}

export enum PROFESSION {
  NO_ANSWER = "Not specified",
  RENTNER = "Retiree",
  ANGESTELLTER = "Employee",
  SELBSTSTÄNDIG = "Self-employed",
  STUDENT = "Student",
  APPRENTICE = "Apprentice",
  MARGINAL_EMPLOYMENT = "Marginal employment (Mini job)",
  WITHOUT = "Without employment",
}

export enum INVESTMENT_STATE {
  CREATED_CLIENT = "CREATED_CLIENT",
  CREATED_LOCAL = "CREATED_LOCAL",
  ACCEPTED = "ACCEPTED",
  PAID = "PAID",
  PENDING = "PENDING",
  KYC_PENDING = "KYC_PENDING",
  WAITING_FOR_ACCEPTANCE = "WAITING_FOR_ACCEPTANCE",
  DELIVERED = "DELIVERED",
  ABORTION = "ABORTION",
  REJECTION = "REJECTION",
  CANCELLATION = "CANCELLATION",
  PAYMENT_MISSING = "PAYMENT_MISSING",
  KYC_INSUFFICIENT = "KYC_INSUFFICIENT",
  KYC_INVALID = "KYC_INVALID",
  SIGNATURE_PENDING = "SIGNATURE_PENDING",
  SIGNED = "SIGNED",
  WAITING_FOR_YOUSIGN = "WAITING_FOR_YOUSIGN",
}

export enum KNOWLEDGE {
  NO_ANSWER = "NO_ANSWER",
  NO_KNOWLEDGE = "NO_KNOWLEDGE",
  KNOWLEDGE = "KNOWLEDGE",
}

export enum EXPERIENCE {
  NO_ANSWER = "NO_ANSWER",
  ZERO_TO_THREE_YEARS = "ZERO_TO_THREE_YEARS",
  THREE_TO_FIVE_YEARS = "THREE_TO_FIVE_YEARS",
  GREATER_THAN_FIVE_YEARS = "GREATER_THAN_FIVE_YEARS",
}

export enum SERVICE_KNOWLEDGE_OR_EXPERIENCE {
  NO_ANSWER = "NO_ANSWER",
  NO_KNOWLEDGE_OR_EXPERIENCE = "NO_KNOWLEDGE_OR_EXPERIENCE",
  ZERO_TO_THREE_YEARS = "ZERO_TO_THREE_YEARS",
  THREE_TO_FIVE_YEARS = "THREE_TO_FIVE_YEARS",
  GREATER_THAN_FIVE_YEARS = "GREATER_THAN_FIVE_YEARS",
}

export enum INVESTMENT_COUNT {
  NO_ANSWER = "NO_ANSWER",
  NO_TRANS = "NO_TRANS",
  LOWER_5_TRANS = "LOWER_5_TRANS",
  LOWER_10_TRANS = "LOWER_10_TRANS",
  MORE_THAN_10_TRANS = "MORE_THAN_10_TRANS",
}

export enum INVESTMENT_SIZE {
  NO_ANSWER = "NO_ANSWER",
  LOWER_3000 = "LOWER_3000",
  BETWEEN_3000_AND_5000 = "BETWEEN_3000_AND_5000",
  BETWEEN_5000_AND_10000 = "BETWEEN_5000_AND_10000",
  OVER_10000 = "OVER_10000",
}

export enum WAIVER_TYPE {
  UNSUITABLE = "UNSUITABLE",
  NO_ANSWERS = "NO_ANSWERS",
}

export enum CURRENCY {
  EUR = "EUR",
}

export enum INVESTOR_TYPE {
  LEGAL_PERSON = "LEGAL_PERSON",
  PRIVATE_PERSON = "PRIVATE_PERSON",
}
