import React, { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import API_CLIENT from "../../../utility/api-client";
import {
  SignedDocument,
  Investment,
  INVESTMENT_STATE,
} from "../../../types/investment_types";
import AmountUtils from "../../../utility/amount-utils";
import InvestmentStatusChip from "../../../components/data-display/investment-status-chip";
import { AppContext } from "../auth-router";
import SubHeader from "../../../template/sub-header";
import StandardCard from "../../../components/data-display/standard-card/standard-card";
import InvestmentStatusStepper from "../../../components/data-display/investment-status-stepper";
import { DocumentBoxWideDirect } from "../../../components/data-display/document-box";
import CopyText from "../../../components/data-display/copy-text";
import IBAN from "iban";
import LoadingScreen from "../../../components/loading-screen";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { Document } from "../../../types/generic_types";

export default function InvestmentDetails() {
  const queries = queryString.parse(window.location.search);
  const investmentId = queries.id ? queries.id.toString() : "";
  const appContext = useContext(AppContext);

  const [documents, setDocuments] = useState<SignedDocument[]>([]);
  const [investment, setInvestment] = useState<Investment>();
  const [documentsv1, setDocumentsv1] = useState<Document[]>([]);

  const beautifyDoc = (name: string) => {
    name = name.replaceAll("inVenture_Capital_", "");
    name = name.replaceAll("_", " ");
    name = name.replaceAll("-", " ");
    name = name.replaceAll(" (ex post)", "");
    name = name.replaceAll(".pdf", "");
    return name;
  };

  const translateDoc = (name: string) => {
    switch (name) {
      case "effecta_bib":
        return "Basisinformationsblatt";
      case "effecta_loan_conditions":
        return "Anleihebedingungen";
      case "effecta_customer_info_effecta":
        return "Effecta AGB";
      case "effecta_consumer_info":
        return "Verbraucherinformationen";
      case "effecta_customer_info":
        return "Tangany AGB";
      case "effecta_vib":
        return "Tangany Wallet";
      case "effecta_ausfuehrungsgrundsätze":
        return "Effecta Verbraucherinformation";
      case "effecta_investor_brochure":
        return "Risikohinweise";
      case "effecta_waiver":
        return "Bestätigung Keine Angaben";
      case "effecta_self_disclosure":
        return "Selbstauskunft";
      case "effecta_zeichnungsschein":
        return "Zeichnungsschein";
      case "effecta_costs_effecta_ex_post":
        return "Kosten und Zuwendungen";
      case "effecta_unsuitability":
        return "Bestätigung Unangemessenheit";
      case "effecta_ausfuehrungsgrundsaetze":
        return "Effecta Verbraucherinformationen";
      default:
        return name;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    API_CLIENT.getSignedDocuments(
      appContext.token,
      investmentId,
      (documents) => setDocuments(documents),
      (error) => console.log(error)
    );
    API_CLIENT.getInvestment(
      appContext.token,
      investmentId,
      (investment) => setInvestment(investment),
      () => console.log("no investment found"),
      (error) => console.log(error)
    );
    API_CLIENT.getInvestmentDocsV1(appContext.token, investmentId, (docs) =>
      setDocumentsv1(docs)
    );
  }, [appContext.token, investmentId]);

  const isRejected =
    investment?.state === INVESTMENT_STATE.REJECTION ||
    investment?.state === INVESTMENT_STATE.KYC_INSUFFICIENT;
  return (
    <Grid container justifyContent="center" style={{ overflow: "hidden" }}>
      {investment ? (
        <>
          <SubHeader
            titleMobile={`Investment: ${investment.campaignName}`}
            titleDesktop=""
          />

          <Grid
            item
            xs={12}
            style={{
              padding: "0 0px 100px 0px",
              marginTop: "50px",
              maxWidth: "1200px",
              margin: "50px 20px 0 20px",
            }}
          >
            <StandardCard header="Eckdaten">
              <Grid container spacing={2} style={{ padding: "10px" }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="body1">
                    {investment?.campaignName}
                  </Typography>
                  <Typography variant="body2">Kampagne</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="body1">
                    {AmountUtils.toString(investment?.investmentAmount, true)}
                  </Typography>
                  <Typography variant="body2">Investierter Betrag</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <InvestmentStatusChip state={investment.state} />
                  <Typography variant="body2">Status</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="body1">
                    {new Date(investment.signedAt).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2">investiert am</Typography>
                </Grid>
              </Grid>
            </StandardCard>
            <StandardCard header="Status" style={{ marginTop: "20px" }}>
              <Grid container style={{ padding: "10px" }}>
                <Grid item xs={12}>
                  <Typography variant="body1">Übersicht</Typography>
                  <Typography variant="body2">
                    Hier eine Übersicht über den Status deiner Investition
                  </Typography>
                  <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <InvestmentStatusStepper investment={investment} />
                  </Box>
                  <Box sx={{ display: { xs: "block", md: "none" } }}>
                    <InvestmentStatusStepper investment={investment} mobile />
                  </Box>
                </Grid>

                {investment.state == INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE ||
                investment.state == INVESTMENT_STATE.ACCEPTED ? (
                  <Grid item xs={12}>
                    <Grid container>
                      {" "}
                      <Grid item xs={12}>
                        <Typography
                          style={{ marginBottom: "30px", marginTop: "50px" }}
                          variant="h5"
                        >
                          Zahlungsdetails
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          Investitionsbetrag
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography variant="subtitle2">
                          {AmountUtils.toString(investment.investmentAmount)}
                        </Typography>
                      </Grid>
                      {AmountUtils.toString(investment.disagio) != "" ? (
                        <>
                          <Grid item xs={6}>
                            <Typography variant="subtitle2">
                              Gutschein
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "right" }}>
                            <Typography variant="subtitle2">
                              {AmountUtils.toString(investment.disagio)}
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">
                          Zu bezahlender Betrag
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography variant="subtitle1">
                          {AmountUtils.toString(investment.totalPayment)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {investment.state == INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE ? (
                  <Grid item xs={12} style={{ marginTop: "30px" }}>
                    <Paper
                      square
                      elevation={0}
                      style={{
                        borderColor: "rgb(96 154 109)",
                        borderStyle: "solid",
                        borderWidth: "2px",
                        borderRadius: "5px",
                        color: "black",
                        padding: "20px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            style={{
                              verticalAlign: "middle",
                              fontWeight: 600,
                              textAlign: "left",
                            }}
                          >
                            <p style={{ fontWeight: 500 }}>
                              Vielen Dank für dein Vertrauen. Wir prüfen aktuell
                              deine Investition.
                            </p>
                            <p>
                              Innerhalb der nächsten Tage erhältst du eine
                              E-Mail mit einem Link zu den Zahlungsdetails von
                              uns.
                            </p>{" "}
                            <p style={{ fontWeight: 500 }}>
                              Den Status deiner Investition kannst du jederzeit
                              bequem unter Investitionen in deinem{" "}
                              <a href="/portal/dashboard">Dashboard</a>{" "}
                              einsehen.
                            </p>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ) : null}
                {investment.state == INVESTMENT_STATE.ACCEPTED ? (
                  <Grid item xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} style={{ marginTop: "30px" }}>
                        <Typography
                          variant="body2"
                          style={{ textAlign: "left" }}
                        >
                          Wir haben deine Investition angenommen. Überweise
                          bitte den oben stehenden Betrag unter Angabe des
                          Referenztextes innerhalb von 14 Tagen auf das
                          Bankkonto der Emittentin, um die Investition
                          abzuschließen.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                        <Typography>Kontoinhaberin</Typography>
                        <CopyText
                          text={
                            investment.paymentDetails.bankAccount.accountHolder
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                        <Typography variant="body2">IBAN</Typography>
                        <CopyText
                          text={IBAN.printFormat(
                            investment.paymentDetails.bankAccount.iban
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                        <Typography variant="body2">BIC</Typography>
                        <CopyText
                          text={
                            investment.paymentDetails.bankAccount.bic
                              ? investment.paymentDetails.bankAccount.bic
                              : "SOBKDEB2XXX"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                        <Typography variant="body2">Referenztext</Typography>
                        <CopyText
                          text={investment.paymentDetails.paymentCode}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "30px" }}>
                        <Paper
                          elevation={0}
                          style={{
                            borderColor: "rgb(96 154 109)",
                            borderStyle: "solid",
                            borderWidth: "2px",
                            borderRadius: "5px",
                            color: "black",
                            padding: "20px",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                style={{ fontWeight: 600, textAlign: "left" }}
                              >
                                Sobald deine Zahlung bei uns eingetroffen ist,
                                werden wir dich per E-Mail benachrichtigen. Den
                                aktuellen Status deiner Investition kannst du
                                auch immer in deinem{" "}
                                <a href="/portal/dashboard">Dashboard</a>{" "}
                                einsehen.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </StandardCard>
            {!isRejected ? (
              <StandardCard header="Dokumente" style={{ marginTop: "20px" }}>
                <div
                  style={{
                    display: "inline-flex",
                    flexWrap: "wrap",
                    justifyContent: "left",
                  }}
                >
                  {documentsv1.length > 0
                    ? documentsv1.map((doc) => (
                        <DocumentBoxWideDirect
                          style={{ minWidth: "300px", maxWidth: "300px" }}
                          location={doc.location}
                          type={"pdf"}
                          name={
                            beautifyDoc(doc.name) /**translateDoc(doc.subject)*/
                          }
                        />
                      ))
                    : null}
                </div>
              </StandardCard>
            ) : null}
          </Grid>
        </>
      ) : (
        <LoadingScreen style={{ height: "100vh" }} />
      )}
    </Grid>
  );
}
