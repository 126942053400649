import React, { useContext, useState } from "react";
import {
  PrimaryButtonV2,
  SecondaryButtonV2,
} from "../../../components/input-elements/buttons";
import API_CLIENT from "../../../utility/api-client";
import { AppContext } from "../direct-router";

export default function Security() {
  const [enrolled, setEnrolled] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [loading, setLoading] = useState(true);
  const app_context = useContext(AppContext);
  return (
    <div className="w-full flex flex-col text-primary items-center">
      <div className="flex bg-cover justify-center items-center w-full h-32">
        <div className="max-w-6xl w-full px-6 md:px-12">
          <h1>Sicherheit</h1>
        </div>
      </div>
      <div className="max-w-6xl w-full px-6 md:px-12 py-8 flex flex-col gap-4 bg-[#E2EAF2] rounded-xl">
        <div className="flex gap-4 items-center">
          <h2>Zwei-Faktor Authentifizierung</h2>
        </div>
        <div className="flex flex-col gap-4">
          <p>
            Um deine Daten noch besser zu schützen kannst du hier einen zweiten
            Faktor einrichten. Wir unterstützen alle gängigen Apps, wie Google
            Authenticator oder iCloud Keychain.
          </p>
          <div className="flex flex-col gap-4">
            {isActivated ? (
              <>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <img
                    src="https://ik.imagekit.io/inventure/App/Icons/checked_KG2vbnfSJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1643014956108"
                    style={{ height: "25px", marginRight: "10px" }}
                  />
                  <p>Du hast einen zweiten Faktor bereits eingerichtet.</p>
                </div>
                <p>Melde Dich gerne bei uns, falls Du weiter Fragen hast.</p>
              </>
            ) : (
              <div className="flex flex-col items-start gap-2">
                {!enrolled ? (
                  <SecondaryButtonV2
                    onClick={() => {
                      API_CLIENT.enrollMFA(
                        app_context.token,
                        () => setEnrolled(true),
                        (error) => console.log(error)
                      );
                    }}
                    style={{
                      padding: "0 30px",
                      margin: "10px 0 0 0",
                      height: "50px",
                    }}
                  >
                    Aktivieren
                  </SecondaryButtonV2>
                ) : (
                  <p>Dir wurde eine Aktivierungsmail zugesandt</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
