export var Yousign = (function () {
  "use strict";

  var EventType;
  (function (EventType) {
    EventType["STARTED"] = "started";
    EventType["SUCCESS"] = "success";
    EventType["ERROR"] = "error";
    EventType["PING"] = "ping";
  })(EventType || (EventType = {}));

  class InvalidSignatureLink extends Error {
    constructor() {
      super();
      this.name = "InvalidSignatureLink";
      this.message = "The signature link is invalid.";
    }
  }
  class IframeContainerNotFound extends Error {
    constructor(iframeContainerId) {
      super();
      this.name = "IframeContainerNotFound";
      this.message = `The iFrame container with the id "${iframeContainerId}" is not found.`;
    }
  }
  class InvalidCallbackFunction extends Error {
    constructor(eventType) {
      super();
      this.name = "InvalidCallbackFunction";
      this.message = `Callback on ${eventType} event is not a function.`;
    }
  }

  class Yousign {
    constructor({ signatureLink, iframeContainerId, isSandbox }) {
      this.origin = /^https:\/\/yousign.app$/;
      this.eventCallbacks = {};
      let sLink;
      try {
        sLink = new URL(signatureLink);
      } catch (_a) {
        throw new InvalidSignatureLink();
      }
      const iframeContainer = document.getElementById(iframeContainerId);
      if (!iframeContainer) {
        throw new IframeContainerNotFound(iframeContainerId);
      }
      if (isSandbox) {
        sLink.searchParams.append("disable_domain_validation", "true");
      }
      const urlParams = new Proxy(sLink.searchParams, {
        get: (searchParams, property) => searchParams.get(property),
      });
      this.iframe = document.getElementById("yousign-iframe");
      if (!this.iframe) {
        this.iframe = document.createElement("iframe");
        this.iframe.id = "yousign-iframe";
        iframeContainer.appendChild(this.iframe);
      }
      this.iframe.src = sLink.href;
      window.addEventListener(
        "message",
        (event) => {
          this.receiveMessage(event, urlParams);
        },
        false
      );
    }
    receiveMessage(event, urlParams) {
      const { origin, data } = event;
      if (origin.match(this.origin)) {
        if (
          data.type === "yousign" &&
          this.eventCallbacks[data.event] &&
          typeof this.eventCallbacks[data.event] === "function"
        ) {
          this.eventCallbacks[data.event](data);
        }
      }
      if (data.type === "__ubble" && data.payload.redirectUrl) {
        this.iframe.src = `${data.payload.redirectUrl}&k=${urlParams.k}`;
      }
    }
    onStarted(fn) {
      if (typeof fn !== "function") {
        throw new InvalidCallbackFunction(EventType.STARTED);
      }
      this.eventCallbacks.started = fn;
    }
    onSuccess(fn) {
      if (typeof fn !== "function") {
        throw new InvalidCallbackFunction(EventType.SUCCESS);
      }
      this.eventCallbacks.success = fn;
    }
    onError(fn) {
      if (typeof fn !== "function") {
        throw new InvalidCallbackFunction(EventType.ERROR);
      }
      this.eventCallbacks.error = fn;
    }
    onPing(fn) {
      if (typeof fn !== "function") {
        throw new InvalidCallbackFunction(EventType.PING);
      }
      this.eventCallbacks.ping = fn;
    }
  }

  return Yousign;
})();
