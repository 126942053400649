import { SALUTATION, TITLE } from "../types/inventure-user_types";

export default abstract class EnumConverter {
    static salutationToString(salutation: SALUTATION): string {
        if (salutation == SALUTATION.D) return ""
        if (salutation == SALUTATION.MR) return "Herr"
        if (salutation == SALUTATION.MS) return "Frau"
        return ""
    }
    
    static titleToString(title: TITLE): string {
        if (title == TITLE.DR) return "Dr."
        return ""
    }
}
