import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import CategoryIcon from "@mui/icons-material/Category";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  BottomNavigation,
  BottomNavigationAction,
  createStyles,
  Theme,
} from "@mui/material";

export default function MobileBottomNavigation() {
  const [value, setValue] = useState(0);
  const { logout } = useAuth0();

  return (
    <BottomNavigation
      style={{
        padding: "5px 5px 0 5px",
        position: "fixed",
        bottom: 0,
        width: "100vw",
        zIndex: 1000,
        color: "rgb(100,100,100)",
        boxShadow: "rgb(0 0 0 / 20%) 0px 0px 8px",
      }}
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction
        label="Produkte"
        value={0}
        icon={<CategoryIcon />}
        component={Link}
        to="/portal/products"
      />
      <BottomNavigationAction
        label="Dashboard"
        value={1}
        icon={<DonutSmallIcon />}
        component={Link}
        to="/portal/dashboard"
      />
      <BottomNavigationAction
        label="Einstellungen"
        value={2}
        icon={<SettingsOutlinedIcon />}
        component={Link}
        to="/portal/profile"
      />
      <BottomNavigationAction
        label="Logout"
        onClick={() => logout()}
        value={3}
        icon={<ExitToAppIcon />}
      />
    </BottomNavigation>
  );
}

export function MobileBottomNavigationV2() {
  const [value, setValue] = useState(0);
  const { logout } = useAuth0();

  return (
    <BottomNavigation
      className="bg-slate-200 border-top border-primary border-t-[1px]"
      style={{
        padding: "5px 5px 0 5px",
        position: "fixed",
        bottom: 0,
        width: "100vw",
        zIndex: 40,
        color: "rgb(100,100,100)",
      }}
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction
        label="Dashboard"
        value={1}
        icon={
          <img
            src="https://ik.imagekit.io/inventure/App/Icons/icons8-cashbook-96%20(2)_w9icj6D-I.png?updatedAt=1718797287797"
            className="w-[25px] h-[25px]"
          />
        }
        component={Link}
        to="/direct/dashboard"
      />
      <BottomNavigationAction
        label="Profil"
        value={1}
        icon={
          <img
            src="https://ik.imagekit.io/inventure/App/Icons/icons8-person-96%20(1)_3WqoqtQyP2.png?updatedAt=1718797556794"
            className="w-[25px] h-[25px]"
          />
        }
        component={Link}
        to="/direct/profile"
      />
      <BottomNavigationAction
        label="Bankdaten"
        value={1}
        icon={
          <img
            src="https://ik.imagekit.io/inventure/App/Icons/icons8-euro-96_wrGuQn8sL.png?updatedAt=1718797744911"
            className="w-[25px] h-[25px]"
          />
        }
        component={Link}
        to="/direct/bank-and-tax"
      />
      <BottomNavigationAction
        label="Sicherheit"
        value={1}
        icon={
          <img
            src="https://ik.imagekit.io/inventure/App/Icons/icons8-password-96_EVyaUl7Hc.png?updatedAt=1718797831861"
            className="w-[25px] h-[25px]"
          />
        }
        component={Link}
        to="/direct/security"
      />
    </BottomNavigation>
  );
}
