
import React from "react";
import { InvestmentStrategySectionType } from "../../../../types/fund_types";
import StandardCard from "../../../data-display/standard-card/standard-card";
import ContentBlocks from "../content-blocks";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import OverlayText from "../../../data-display/overlay-text";
import IvBox from "../../../data-display/standard-card/iv-box";
import Header2 from "../../../template-components/texts/headers";
import { Box, Grid, Paper, Typography } from "@mui/material";

type InvestmentStrategySectionProps = {
  data: InvestmentStrategySectionType;
};

export function InvestmentStrategySectionNew({
  data,
}: InvestmentStrategySectionProps) {
  return (
    <div style={{ marginTop: "70px" }}>
      <Grid container>
        <Grid item xs={12}>
          <Header2>Welche Investmentstrategie wird verfolgt?</Header2>
        </Grid>
        <Grid item xs={12}>
          <ContentBlocks
            blocks={data.contentBlocks}
            style={{ padding: "0px" }}
          />
        </Grid>
        <Grid item xs={12} style={{margin: "30px 0"}}>
          <Grid container justifyContent="space-around">
            {data.investmentCriterias.map((criteria, i) => (
              <Grid item xs={12} md={4} style={{ minHeight: "100%" }} key={i}>
                <Box
                  sx={{
                    padding: {
                      sx: "0",
                      md:  i % 3 == 0
                      ? "10px 10px 10px 0"
                      : i % 3 == 1
                      ? "10px 5px 10px 5px"
                      : "10px 0 10px 10px",
                    }
                  }}
                  style={{
                    height: "calc(100% - 20px)",
                  }}
                >
                  <IvBox style={{ height: "calc(100% - 40px)" }}>
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "18px",
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: "15px",
                      }}
                    >
                      {criteria.header}
                    </Typography>
                    {criteria.criterias.map((crit, i) => (
                      <div
                        style={{ display: "flex", margin: "5px 0 8px 0" }}
                        key={i}
                      >
                        <div
                          style={{
                            padding: "0px",
                            display: "flex",
                            flexDirection: "column",
                            color: "#1e401e",
                            minWidth: "15px",
                            fontWeight: 500,
                          }}
                        >
                          <div
                            style={{
                              marginTop: "8px",
                              backgroundColor: "black",
                              borderRadius: "50%",
                              height: "5px",
                              width: "5px",
                            }}
                          />
                        </div>
                        <div>
                          <Typography variant="body2">{crit}</Typography>
                        </div>
                      </div>
                    ))}
                    <Box sx={{height: {xs: "15px", md: "0"}}}/>
                  </IvBox>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default function InvestmentStrategySection({
  data,
}: InvestmentStrategySectionProps) {
  return (
    <div style={{ marginTop: "70px" }}>
      <OverlayText header="Anlagestrategie" contentBlocks={data.contentBlocks}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="space-around">
              {data.investmentCriterias.map((criteria, i) => (
                <Grid item xs={12} md={4} style={{ minHeight: "100%" }} key={i}>
                  <div style={{ padding: "10px", height: "calc(100% - 20px)" }}>
                    <Paper
                      style={{
                        padding: "10px",
                        height: "calc(100% - 20px)",
                        borderRadius: "10px",
                        backgroundColor: "rgb(245 239 230)",
                        boxShadow: "black 0px 0px 10px -4px",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          fontWeight: 700,
                          marginBottom: "10px",
                        }}
                      >
                        {criteria.header}
                      </Typography>
                      {criteria.criterias.map((crit, i) => (
                        <div
                          style={{ display: "flex", margin: "5px 0 5px 0" }}
                          key={i}
                        >
                          <div
                            style={{
                              padding: "5px",
                              display: "flex",
                              flexDirection: "column",
                              color: "#1e401e",
                              minWidth: "25px",
                              fontWeight: 700,
                            }}
                          >
                            <CheckRoundedIcon style={{ fontSize: "20px" }} />
                          </div>
                          <div>
                            <Typography variant="body1">{crit}</Typography>
                          </div>
                        </div>
                      ))}
                    </Paper>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </OverlayText>
      {/** 
    <StandardCard header="Anlagestrategie">
      <ContentBlocks blocks={data.contentBlocks} />
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="space-around">
            {data.investmentCriterias.map((criteria) => (
              <Grid item xs={12} md={4} style={{ minHeight: "100%" }}>
                <div style={{ padding: "10px", height: "calc(100% - 20px)" }}>
                  <Paper
                    style={{
                      padding: "10px",
                      height: "calc(100% - 20px)",
                      borderRadius: "10px",
                      backgroundColor: "rgb(245 239 230)",
                      boxShadow: "black 0px 0px 10px -4px",
                      overflow: "hidden",
                    }}
                  >
                    <Typography variant="h6" style={{ textAlign: "center", fontWeight: 700, marginBottom: "10px" }}>
                      {criteria.header}
                    </Typography>
                    {criteria.criterias.map((crit) => (
                      <div style={{ display: "flex", margin: "5px 0 5px 0" }}>
                        <div style={{ padding: "5px", display: "flex", flexDirection: "column", color: "#1e401e", minWidth: "25px", fontWeight: 700 }}>
                          <CheckRoundedIcon style={{fontSize: "20px"}}/>
                        </div>
                        <div>
                          {" "}
                          <Typography variant="body1">{crit}</Typography>
                        </div>
                      </div>
                    ))}
                  </Paper>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </StandardCard>*/}
    </div>
  );
}
