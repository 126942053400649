import countries from "i18n-iso-countries"

export type Country = {
    alpha3: string,
    name: string
}

export default abstract class CountryUtils {
    static getCountries(): Country[] {
        var returnCountries: Country[] = []
        const alpha3s = countries.getAlpha3Codes()
        for (const alpha3 in alpha3s) { 
            const countryName = countries.getName(alpha3, "de", { select: "official" })
            var country: Country = { alpha3: alpha3, name: countryName }
            returnCountries.push(country)
        }
        return returnCountries
    }
}