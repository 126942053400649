import { useAuth0 } from "@auth0/auth0-react";
import { Divider, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../pages/portal/auth-router";
import theme from "../theme";

export default function SettingsMenu() {
  const appContext = useContext(AppContext);
  const { logout } = useAuth0();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log("open: ", open);
  }, [open]);

  const variants = {
    open: { opacity: 1, x: 0, display: "block", marginTop: "10px" },
    closed: { opacity: 0, x: "-100%", display: "none", marginTop: "-10px" },
  };

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function OutsideAlerter(props: any) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return <div ref={wrapperRef}>{props.children}</div>;
  }

  return (
    <OutsideAlerter style={{ position: "relative" }}>
      <div
        style={{
          width: "35px",
          height: "35px",
          backgroundColor: theme.palette.primary.dark,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme.palette.background.default,
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setOpen(true);
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {appContext.user?.natural_person?.forename?.charAt(0)}
        </Typography>
      </div>
      <motion.div
        layout
        animate={open ? "open" : "closed"}
        variants={variants}
        style={{
          position: "absolute",
          zIndex: 1000,
          marginLeft: "-150px",
          width: "180px",
          borderRadius: "5px",
          boxShadow: "black 0 0 10px -5px",
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.primary.dark,
          padding: "5px 10px",
          display: "none",
        }}
      >
        <Typography variant="subtitle1">
          {appContext.user?.natural_person?.forename}
        </Typography>
        <Divider style={{ marginBottom: "15px" }} />
        <div onClick={() => setOpen(false)}>
          <SettingsMenuPoint
            link="/portal/profile"
            name={"Einstellungen"}
            icon_location="https://img.icons8.com/ios/344/settings--v1.png"
          />
        </div>
        <SettingsMenuPoint
          name={"Logout"}
          icon_location="https://img.icons8.com/ios/2x/logout-rounded--v2.png"
          onClick={() => {
            logout();
          }}
        />
      </motion.div>
    </OutsideAlerter>
  );
}

interface SettingsMenuPointProps {
  icon_location?: string;
  name?: string;
  link?: string;
  onClick?: () => void;
}

function SettingsMenuPoint({
  name,
  link,
  icon_location,
  onClick,
}: SettingsMenuPointProps) {
  const [isHovered, setIsHovered] = useState(false);

  const menupoint = (
    <motion.div
      style={{
        width: "100%",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        margin: "10px 0",
        color: theme.palette.primary.dark,
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <motion.div
        layout
        transition={{
          opacity: { ease: "easeInOut" },
          layout: { duration: 0.2 },
        }}
        style={{
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          marginLeft: isHovered ? "5px" : "0",
          cursor: "pointer",
        }}
      >
        <img
          src={icon_location}
          style={{ width: "20px", marginRight: "10px" }}
        />

        <Typography style={{ textDecoration: "none" }} variant="subtitle1">
          {name}
        </Typography>
      </motion.div>
    </motion.div>
  );

  if (link) {
    return (
      <Link to={link} style={{ textDecoration: "none" }}>
        {menupoint}
      </Link>
    );
  }

  return menupoint;
}
