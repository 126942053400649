import { Grid, Typography } from "@mui/material";
import { sha256 } from "js-sha256";
import React, { useContext, useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { AppContext } from "../../../../pages/portal/auth-router";
import { InvestmentContext } from "../../../../pages/portal/investment/investment-process";
import { InvestmentContextNew } from "../../../../pages/portal/investment/investment-process-new";
import { Campaign } from "../../../../types/fund_types";
import { MatchingDTO } from "../../../../types/generic_types";
import { Investment } from "../../../../types/investment_types";
import API_CLIENT from "../../../../utility/api-client";
import LoadingScreen from "../../../loading-screen";
import MultistepFormButtons from "../../../vertical-multistep-form/multistep-form-buttons";
import AbortInvestment from "../abort-investment";
import CostRepresentation from "../cost-representation";

type CostStepProps = {
  back: () => void;
  next: (investment: Investment) => void;
};

export default function CostStepNew({ back, next }: CostStepProps) {
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);
  const investmentContext = useContext(InvestmentContextNew);
  const campaign = investmentContext.campaign;
  const investment = investmentContext.investment;
  const fund = investmentContext.fund;

  if (investment == null || campaign == null) return null;

  const track = () => {
    var uData = appContext.matchingData;
    var mData: MatchingDTO = {
      event: "Summary",
      eventId: investment.id,
      sourceUrl: window.location.href,
      em: uData?.em,
      ph: uData?.ph,
      fn: uData?.fn,
      ln: uData?.ln,
      product: fund?.name,
      focus: fund?.fokus,
      type: fund?.type,
      value: investment.amount.toString(),
      currency: "EUR",
    };
    API_CLIENT.postMatching(appContext.token, mData);

    TagManager.dataLayer({
      dataLayer: {
        event: "view-summary",
        event_id: investment.id,
        first_name: appContext.user?.natural_person.forename,
        last_name: appContext.user?.natural_person.surname,
        phone: appContext.user?.natural_person.phone,
        email: sha256(appContext.userEmail),
        product: fund?.name,
        focus: fund?.fokus,
        type: fund?.type,
        value: investment.amount,
        currency: "EUR",
      },
    });
  };

  const sendCostsAccepted = () => {
    window.scrollTo(0, 0);
    setLoading(true);
    API_CLIENT.putInvestmentCostAccepted(
      appContext.token,
      investment.id,
      (investment) => {
        track();
        investmentContext.setInvestment(investment);
        setTimeout(() => setLoading(false), 1000);
      },
      (err) => console.log(err)
    );
  };

  return (
    <>
      {loading ? (
        <LoadingScreen
          style={{ width: "100%", height: "calc(100vh - 200px)" }}
        />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendCostsAccepted();
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <Typography
                variant="h2"
                style={{
                  fontSize: "32px",
                  fontWeight: 500,
                  marginBottom: "20px",
                }}
              >
                Übersicht der Kosten und Zuwendungen
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "30px" }}>
                Im Rahmen dieser Emission entstehen Kosten für den Vertrieb,
                Marketing und die rechtliche Strukturierung des Produktes. Wir
                sind nach § 63 Abs. 7 WpHG gesetzlich verpflichtet, Dir diese
                Kosten in Form einer Tabelle transparent darzustellen.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{ overflowX: "auto", maxWidth: "calc(100vw - 30px)" }}
              >
                <div style={{ minWidth: "500px" }}>
                  <CostRepresentation
                    amount={investment.amount}
                    costData={campaign.costData}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <MultistepFormButtons disabled={false} back backFunction={back} />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "50px",
              }}
            >
              <AbortInvestment investment={investment} />
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}
