import { BottomNavigationClassKey } from "@mui/material";
import { COUNTRY } from "./inventure-user_types";
import { PROFESSION, EDUCATION } from "./investment_types";

export type OnboardingAddress = {
  street: string;
  address_addition: String;
  city: string;
  zip: string;
  country: string;
};

export type OnboardingPerson = {
  occupation: PROFESSION;
  educational_qualification: EDUCATION;
  citizenship: string;
  birthdate: string;
  birthplace: string;
  birthcountry: COUNTRY;
};

export type OnboardingZero = {
  title: string;
  salutation: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  termsAccepted: boolean;
  affiliate_program: string;
};

export type OnboardingCheckboxes = {
  pep_status: boolean;
  is_beneficiary: boolean;
};

export type OnboardingLegitimation = {
  document_type: DOCUMENT_TYPE;
  document_issuer: string;
  document_valid_from: string;
  document_valid_to: string;
  document_id: string;
};

export type OnboardingData = {
  investor_type: INVESTOR_TYPE;
  document_type: DOCUMENT_TYPE;
  document_issuer: string;
  document_valid_from: string;
  document_valid_to: string;
  birthdate: string;
  birthplace: string;
  birthcountry: COUNTRY;
  citizenship: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  pep_status: boolean;
  is_beneficiary: boolean;
  occupation: PROFESSION;
  educational_qualification: EDUCATION;
  address_addition: String;
  document_id: string;
};

export type OnboardingUpdateData = {
  document_type: DOCUMENT_TYPE;
  document_issuer: string;
  document_valid_from: string;
  document_valid_to: string;
  citizenship: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  pep_status: boolean;
  is_beneficiary: boolean;
  occupation: PROFESSION;
  educational_qualification: EDUCATION;
  address_addition: String;
  document_id: string;
};

export type SignupData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  salutation: string;
  title: string;
  phoneNumber: string;
  termsAccepted: boolean;
  effectaTermsAccepted: boolean;
  acquisition_channel: string;
  affiliate_code: string;
  affiliate_program: string;
  email_allowance: boolean;
  phone_allowance: boolean;
  origin: string;
  new: boolean;
};

export enum DOCUMENT_TYPE {
  IDCARD = 1,
  PASSPORT = 2,
}

export enum INVESTOR_TYPE {
  NATURAL_PERSON = "NATURAL_PERSON",
  LEGAL_PERSON = "LEGAL_PERSON",
}
