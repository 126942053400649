import { Dialog, DialogProps } from "@mui/material";
import React from "react";

export default function ResponsiveDialog(props: DialogProps) {
  return (
    <>
      <Dialog
        sx={{ display: { xs: "none", md: "block" } }}
        open={props.open}
        onClose={props.onClose}
      >
        {props.children}
      </Dialog>
      <Dialog
        sx={{ display: { xs: "block", md: "none" } }}
        fullScreen
        open={props.open}
        onClose={props.onClose}
      >
        {props.children}
      </Dialog>
    </>
  );
}

export function ResponsiveDialogV2(props: DialogProps) {
  return (
    <>
      <Dialog
        sx={{ display: { xs: "none", md: "block" } }}
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        {props.children}
      </Dialog>
      <Dialog
        sx={{ display: { xs: "block", md: "none" } }}
        fullScreen
        open={props.open}
        onClose={props.onClose}
      >
        {props.children}
      </Dialog>
    </>
  );
}
