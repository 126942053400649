
import { styled } from '@mui/material/styles';
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Investment, INVESTMENT_STATE } from "../../types/investment_types";
import clsx from "clsx";
import Check from "@mui/icons-material/Check";
import { createTheme, makeStyles, Step, StepConnector, StepIconProps, StepLabel, Stepper, ThemeProvider } from "@mui/material";

const PREFIX = 'investment-status-stepper';

const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  completed: `${PREFIX}-completed`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  [`&.${classes.active}`]: {
    backgroundImage: "linear-gradient(90deg, rgba(89,204,142,1) 0%, rgba(89,204,142,1) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  [`&.${classes.completed}`]: {
    backgroundImage: "linear-gradient(90deg, rgba(74,120,134,1) 0%, rgba(74,120,134,1) 100%)",
  },
});

type InvestmentStatusStepperProps = {
  investment: Investment;
  mobile?: boolean;
};

/**
const connectorTheme = createTheme({
  components: {
    // Name of the component
    MuiStepConnector: {
      
      styleOverrides: {
        // Name of the slot
        root: {
        alternativeLabel: {
          top: 20,
        },
        active: {
          "& $line": {
            backgroundImage: "linear-gradient(90deg, rgba(74,120,134,1) 0%, rgba(89,204,142,1) 100%)",
          },
        },
        completed: {
          "& $line": {
            backgroundImage: "linear-gradient(90deg, rgba(74,120,134,1) 0%, rgba(74,120,134,1) 100%)",
          },
        },
        line: {
          marginTop: "10px",
          height: 3,
          border: 0,
          backgroundColor: "#eaeaf0",
          borderRadius: 1,
        },
      },
      line: {
        alternativeLabel: {
          top: 20,
        },
        active: {
          "& $line": {
            backgroundImage: "linear-gradient(90deg, rgba(74,120,134,1) 0%, rgba(89,204,142,1) 100%)",
          },
        },
        completed: {
          "& $line": {
            backgroundImage: "linear-gradient(90deg, rgba(74,120,134,1) 0%, rgba(74,120,134,1) 100%)",
          },
        },
        line: {
          marginTop: "10px",
          height: 3,
          border: 0,
          backgroundColor: "#eaeaf0",
          borderRadius: 1,
        },
      },
      },
    },
  },
});
  */

export default function InvestmentStatusStepper({ investment, mobile }: InvestmentStatusStepperProps) {
  var signedAt = investment ? investment.signedAt : "";
  var acceptedAt = investment ? investment.acceptedAt : "";
  var paidAt = investment ? investment.paidAt : "";
  var deliveredAt = investment ? investment.deliveredAt : "";


  var text1="Investitionsangebot abgegeben"
  var text1Waiting="Investitionsangebot abgegeben"
  var text2="Investitionsangebot angenommen"
  var text2Waiting="Annahme des Angebots ausstehend"
  var text3="Überweisung ausstehend"
  var text3Waiting="Zahlungseingang ausstehend"
  var text4="Token übertragen"
  var text4Waiting="Übertragung der Tokens ausstehend"

  const activeStep = investment?.state === INVESTMENT_STATE.ACCEPTED
  ? 2
  : investment?.state === INVESTMENT_STATE.PAID
  ? 3
  : investment?.state === INVESTMENT_STATE.DELIVERED
  ? 4
  : 0

  return (
    <>
      {mobile ? (
        <Stepper
          connector={undefined}
          orientation="vertical"
          style={{ backgroundColor: "rgba(0,0,0,0)", marginTop: "50px", display: "block" }}
          alternativeLabel
          activeStep={
            activeStep
          }
        >
          <Step>
            <StepLabel> <b>
              {activeStep > -1 ? text1 : text1Waiting} </b>
              <br />
              {signedAt ? new Date(signedAt).toLocaleDateString() : ""}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
            <b> {activeStep > 0 ? text2 : text2Waiting} </b>
              <br />
              {acceptedAt ? new Date(acceptedAt).toLocaleDateString() : ""}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
            <b>{activeStep > 1 ? text3 : text3Waiting} </b>
              <br />
              {paidAt ? new Date(paidAt).toLocaleDateString() : ""}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
            <b>{activeStep >2 ? text4 : text4Waiting} </b>
              <br />
              {deliveredAt ? new Date(deliveredAt).toLocaleDateString() : ""}
            </StepLabel>
          </Step>
        </Stepper>
      ) : (
        <Stepper
          style={{ backgroundColor: "rgba(0,0,0,0)", marginTop: "50px", justifyContent: "center" }}
          alternativeLabel
          activeStep={
            investment?.state === INVESTMENT_STATE.ACCEPTED
              ? 2
              : investment?.state === INVESTMENT_STATE.PAID
              ? 3
              : investment?.state === INVESTMENT_STATE.DELIVERED
              ? 4
              : 1
          }
          connector={<StepConnector style={{marginTop: "12px"}}/>}
        >
          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <b>{activeStep > 0 ? text1 : text1Waiting} </b>
              <br />
              {signedAt ? new Date(signedAt).toLocaleDateString() : ""}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
            <b> {activeStep > 1 ? text2 : text2Waiting}</b>
              <br />
              {acceptedAt ? new Date(acceptedAt).toLocaleDateString() : ""}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
            <b>{activeStep > 2 ? text3 : text3Waiting} </b>
              <br />
              {paidAt ? new Date(paidAt).toLocaleDateString() : ""}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
            <b>{activeStep > 3 ? text4 : text4Waiting} </b>
              <br />
              {deliveredAt ? new Date(deliveredAt).toLocaleDateString() : ""}
            </StepLabel>
          </Step>
        </Stepper>
      )}
    </>
  );
}


/** 
const Root = styled("div")({
  [`&.${classes.root}`]: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  [`&.${classes.active}`]: {
    backgroundImage: "linear-gradient(90deg, rgba(89,204,142,1) 0%, rgba(89,204,142,1) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  [`&.${classes.completed}`]: {
    backgroundImage: "linear-gradient(90deg, rgba(74,120,134,1) 0%, rgba(74,120,134,1) 100%)",
  },
});
*/
function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <HourglassEmptyIcon />,
    2: <DoneIcon />,
    3: <PaymentIcon />,
    4: <AccountBalanceIcon />,
  };

  return (
    <Root
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <Check className={classes.completed} /> : icons[String(props.icon)]}
    </Root>
  );
}
