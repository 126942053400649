export type Document = {
  name: string;
  location: string;
  description: string;
  type: string;
};

export type RichText = {
  html: string;
};

export type Asset = {
  fileName: string;
  url: string;
};

export type Avatar = {
  name: string;
  img: Asset;
  imgUrl: string;
};

export type Image = {
  url: string;
};

export type BinaryDTO = {
  isTrue: boolean;
};

export type UserMatchingDTO = {
  em?: string;
  ph?: string;
  fn?: string;
  ln?: string;
};

export type MatchingDTO = {
  event: string;
  eventId?: string;
  sourceUrl?: string;

  em?: string;
  ph?: string;
  fn?: string;
  ln?: string;

  product?: string;
  focus?: string;
  type?: string;

  value?: string;
  currency?: string;
};

export enum USER_STATE {
  ZERO = "ZERO",
  NONE = "NONE",
  ONBOARDED_1 = "ONBOARDED_1",
  ONBOARDED_2 = "ONBOARDED_2",
}
