import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  alpha,
  Button,
  Divider,
  FormControl,
  InputBase,
  InputLabel,
  makeStyles,
  Select,
  styled,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from "@mui/material";
import React, { Children, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import deLocale from "date-fns/locale/de";
import { Control, Controller } from "react-hook-form";
import CountryUtils from "../utility/country-utils";
import PrimaryButton from "./input-elements/buttons";
import { EDUCATION, PROFESSION } from "../types/investment_types";
import {
  LEGAL_ENTITY_TYPE,
  SALUTATION,
  TITLE,
} from "../types/inventure-user_types";
import theme from "../theme";
import IBAN from "iban";
import { DOCUMENT_TYPE } from "../types/onboarding_types";
import IvSwitch, { IvSwitchV2 } from "./input-elements/iv-switch";

const BootstrapFormControl = styled(FormControl)(({ theme, error }) => ({}));

export const BootstrapInput = styled(TextField)(({ theme, error }) => ({
  "label + &": {
    marginTop: "20px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",

    "& fieldset": {
      border: "none",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: "none",
      borderColor: theme.palette.primary.light,
    },
    "&.Mui-focused fieldset": {
      border: "none",
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputBase-root": {
    borderStyle: "none",
    "&:hover": {
      borderStyle: "none",
    },
  },
  "& .MuiInputBase-input": {
    borderRadius: "10px",
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1.5px solid",
    borderColor: "rgba(0,0,0,0)",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover": {
      borderColor: theme.palette.primary.light,
    },
  },
}));
export const BootstrapInputDate = styled(TextField)(({ theme, error }) => ({
  "label + &": {
    marginTop: "20px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",

    "& fieldset": {
      border: "none",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: "none",
      borderColor: theme.palette.primary.light,
    },
    "&.Mui-focused fieldset": {
      border: "none",
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputBase-root": {
    borderStyle: "none",
    "&:hover": {
      borderStyle: "none",
    },
  },
  "& .MuiInputBase-input": {
    borderRadius: "10px",
    position: "relative",
    backgroundColor: "#E2EAF2",
    border: "1.5px solid",
    borderColor: "rgba(0,0,0,0)",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover": {
      borderColor: theme.palette.primary.light,
    },
  },
}));
export const BootstrapInput2 = styled(InputBase)(({ theme, error }) => ({
  "label + &": {
    marginTop: "20px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "solid",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.light,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputBase-input": {
    borderRadius: "10px",
    position: "relative",
    backgroundColor: "#E2EAF2",
    border: "1.5px solid",
    borderColor: "rgba(0,0,0,0)",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      borderColor: theme.palette.primary.light,
    },
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}));

// const BootstrapInputDatePicker = styled(TextField)(({ theme, error }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3),
//   },
//   "& .MuiInputBase-input": {
//     borderRadius: 4,
//     position: "relative",
//     backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
//     border: "1px solid",
//     borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
//     fontSize: 16,
//     width: "100%",
//     padding: "10px 12px",
//     transition: theme.transitions.create([
//       "border-color",
//       "background-color",
//       "box-shadow",
//     ]),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     // "&:focus": {
//     //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//     //   borderColor: theme.palette.primary.main,
//     // },
//   },
// }));

interface Picker {
  name: string;
  error: boolean;
  control: Control<any>;
  label: string;
  required?: boolean;
  tabIndex?: number;
  disabled?: boolean;
  placeholder?: string;
}

interface HookFormTextFieldProps extends Picker {
  defaultValue?: string | null;
}

interface HookFormEductaionPickerProps extends Picker {
  defaultValue?: EDUCATION;
}

interface HookFormProffesionPickerProps extends Picker {
  defaultValue?: PROFESSION;
}

interface HookFormTitleSelectPickerProps extends Picker {
  defaultValue?: TITLE;
}

interface HookFormLegitimationDocumentPickerProps extends Picker {
  defaultValue?: DOCUMENT_TYPE;
}

interface HookFormSalutationPickerProps extends Picker {
  defaultValue?: SALUTATION;
}

interface HookForSwitchProps extends Picker {
  defaultValue?: boolean | null;
}

interface HookFormCheckboxProps extends Picker {
  defaultValue?: boolean;
  children?: React.ReactNode;
}

type FileFormProps = {
  onFileDrop: (file: File) => void;
};

type DropZoneProps = {
  onChange: (file: File) => void;
};

export default function HookFormTextField({
  name,
  defaultValue,
  error,
  control,
  label,
  required,
  tabIndex,
}: HookFormTextFieldProps) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{ required: required }}
      control={control}
      render={({ field }) => (
        <TextField
          variant="outlined"
          error={error}
          fullWidth={true}
          label={label}
          inputProps={{ tabIndex: tabIndex }}
          {...field}
        />
      )}
    />
  );
}

export function HookFormTextFieldV2({
  name,
  defaultValue,
  error,
  control,
  label,
  required,
  tabIndex,
  disabled,
  placeholder,
}: HookFormTextFieldProps) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{ required: required }}
      control={control}
      render={({ field }) => (
        <FormControl
          variant="standard"
          error={error}
          className="w-full"
          style={{ width: "100%" }}
        >
          <InputLabel shrink htmlFor="bootstrap-input">
            {label}
          </InputLabel>
          <BootstrapInput2
            placeholder={placeholder}
            error={error}
            fullWidth={true}
            inputProps={{ tabIndex: tabIndex }}
            {...field}
            disabled={disabled}
          />
        </FormControl>
      )}
    />
  );
}

export function HookFormPasswordField({
  name,
  defaultValue,
  error,
  control,
  label,
  required,
  tabIndex,
}: HookFormTextFieldProps) {
  const [password, setPassword] = useState("");
  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const pwd_length = password.length > 8;
  const pwd_special_chars = RegExp("[!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]").test(
    password
  );
  const pwd_capital = RegExp("[A-Z]").test(password);
  const pwd_number = RegExp("[0-9]").test(password);

  var pwd_ok = pwd_length && pwd_special_chars && pwd_capital && pwd_number;

  return (
    <div>
      <Controller
        name={name}
        defaultValue={defaultValue}
        rules={{
          required: true,
          minLength: 8,
          pattern:
            /(?=.*[!"#\$%&'\(\)\*\+,-,\/:;<=>\?@\[\]\^_`{\}~.$])(?=.*[A-Z])(?=.*[0-9])/g,
          /** /(?=.*!|"|#|\$|%|&|'|\(|\)|\*|\+|,|-|,|\/|:|;|<|=|>|\?|@|\[|\]|\^|_|`|{|\||}|~)(?=.*[A-Z])(?=.*[0-9])/g,  */
        }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(e) => {
              setPassword(e.target.value);
              onChange(e);
            }}
            variant="outlined"
            error={error}
            type="password"
            fullWidth={true}
            label={label}
          />
        )}
      />
      {!pwd_ok && password.length > 0 && focused ? (
        <div
          style={{
            boxShadow: "black 0 0 10px -5px",
            position: "absolute",
            backgroundColor: "#fefaf4",
            zIndex: 100,
            padding: "10px",
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          <Typography variant="body1">Das Passwort muss:</Typography>
          {!pwd_length ? (
            <Typography variant="body2" style={{ color: "red" }}>
              &#10005; mehr als 8 Zeichen lang sein
            </Typography>
          ) : (
            <Typography variant="body2" style={{ color: "green" }}>
              &#10003; mehr als 8 Zeichen enthalten
            </Typography>
          )}
          {!pwd_special_chars ? (
            <Typography variant="body2" style={{ color: "red" }}>
              &#10005; mindestens ein Sonderzeichen enthalten
            </Typography>
          ) : (
            <Typography variant="body2" style={{ color: "green" }}>
              &#10003; mindestens ein Sonderzeichen
            </Typography>
          )}
          {!pwd_capital ? (
            <Typography variant="body2" style={{ color: "red" }}>
              &#10005; mindestens einen Großbuchstaben enthalten
            </Typography>
          ) : (
            <Typography variant="body2" style={{ color: "green" }}>
              &#10003; mindestens einen Großbuchstaben
            </Typography>
          )}
          {!pwd_number ? (
            <Typography variant="body2" style={{ color: "red" }}>
              &#10005; mindestens eine Zahl enthalten
            </Typography>
          ) : (
            <Typography variant="body2" style={{ color: "green" }}>
              &#10003; mindestens eine Zahl
            </Typography>
          )}
        </div>
      ) : null}
    </div>
  );
}

export function HookFormUppercaseField({
  name,
  defaultValue,
  error,
  control,
  label,
  required,
  tabIndex,
}: HookFormTextFieldProps) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{ required: required }}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          variant="outlined"
          error={error}
          fullWidth={true}
          label={label}
          inputProps={{ tabIndex: tabIndex }}
          value={value}
          onChange={(event) => onChange(event.target.value.toUpperCase())}
        />
      )}
    />
  );
}

export function HookFormUppercaseFieldV2({
  name,
  defaultValue,
  error,
  control,
  label,
  required,
  tabIndex,
}: HookFormTextFieldProps) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{ required: required }}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl
          variant="standard"
          error={error}
          style={{ minWidth: "100%" }}
        >
          <InputLabel shrink htmlFor="bootstrap-input">
            {label}
          </InputLabel>
          <BootstrapInput2
            error={error}
            fullWidth={true}
            inputProps={{ tabIndex: tabIndex }}
            value={value}
            onChange={(event) => onChange(event.target.value.toUpperCase())}
          />
        </FormControl>
      )}
    />
  );
}

export function HookFormIBANField({
  name,
  defaultValue,
  error,
  control,
  label,
  required,
  tabIndex,
}: HookFormTextFieldProps) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{ required: required }}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          variant="outlined"
          error={error}
          fullWidth={true}
          label={label}
          inputProps={{ tabIndex: tabIndex }}
          value={value}
          onChange={(event) =>
            onChange(IBAN.printFormat(event.target.value.toUpperCase()))
          }
        />
      )}
    />
  );
}

export function HookFormIBANFieldV2({
  name,
  defaultValue,
  error,
  control,
  label,
  required,
  tabIndex,
}: HookFormTextFieldProps) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{ required: required }}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl
          variant="standard"
          error={error}
          style={{ minWidth: "100%", border: "none !important" }}
        >
          <InputLabel shrink htmlFor="bootstrap-input">
            {label}
          </InputLabel>
          <BootstrapInput2
            error={error}
            fullWidth={true}
            inputProps={{ tabIndex: tabIndex }}
            value={value}
            onChange={(event) =>
              onChange(IBAN.printFormat(event.target.value.toUpperCase()))
            }
          />
        </FormControl>
      )}
    />
  );
}

export function HookFormCompanyTypeSelect({
  name,
  defaultValue,
  error,
  control,
  label,
}: HookFormTextFieldProps) {
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : LEGAL_ENTITY_TYPE.GMBH}
        render={({ field: { onChange, value } }) => (
          <Select
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label={label}
            variant="outlined"
          >
            <option value={LEGAL_ENTITY_TYPE.GMBH}>GmbH</option>
            <option value={LEGAL_ENTITY_TYPE.UG}>UG</option>
          </Select>
        )}
      />
    </FormControl>
  );
}

export function HookFormCompanyTypeSelectV2({
  name,
  defaultValue,
  error,
  control,
  label,
}: HookFormTextFieldProps) {
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : LEGAL_ENTITY_TYPE.GMBH}
        render={({ field: { onChange, value } }) => (
          <Select
            input={<BootstrapInput2 />}
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label={label}
            variant="outlined"
          >
            <option value={LEGAL_ENTITY_TYPE.GMBH}>GmbH</option>
            <option value={LEGAL_ENTITY_TYPE.UG}>UG</option>
          </Select>
        )}
      />
    </FormControl>
  );
}

export function HookFormCountrySelect({
  name,
  defaultValue,
  error,
  control,
  label,
}: HookFormTextFieldProps) {
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : "DEU"}
        render={({ field: { onChange, value } }) => (
          <Select
            native
            defaultValue={value}
            error={error}
            label={label}
            variant="outlined"
            onChange={onChange}
          >
            {CountryUtils.getCountries().map((country) => (
              <option value={country.alpha3}>{country.name}</option>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
}
export function HookFormCountrySelectV2({
  name,
  defaultValue,
  error,
  control,
  label,
  disabled,
}: HookFormTextFieldProps) {
  return (
    <FormControl variant="standard" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : "DEU"}
        render={({ field: { onChange, value } }) => (
          <Select
            disabled={disabled}
            input={<BootstrapInput2 />}
            native
            defaultValue={value}
            error={error}
            label={label}
            variant="outlined"
            onChange={onChange}
          >
            {CountryUtils.getCountries().map((country) => (
              <option value={country.alpha3}>{country.name}</option>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
}

export function HookFormSalutationSelect({
  name,
  defaultValue,
  error,
  control,
  label,
  tabIndex,
}: HookFormSalutationPickerProps) {
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : SALUTATION.MS}
        render={({ field: { onChange, value } }) => (
          <Select
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label={label}
            variant="outlined"
            inputProps={{ tabIndex: tabIndex }}
          >
            <option value={SALUTATION.MS}>Frau</option>
            <option value={SALUTATION.MR}>Herr</option>
            <option value={SALUTATION.D}>Divers</option>
          </Select>
        )}
      />
    </FormControl>
  );
}
export function HookFormSalutationSelectV2({
  name,
  defaultValue,
  error,
  control,
  label,
  tabIndex,
}: HookFormSalutationPickerProps) {
  return (
    <FormControl variant="standard" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : SALUTATION.MS}
        render={({ field: { onChange, value } }) => (
          <Select
            input={<BootstrapInput2 />}
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label={label}
            inputProps={{ tabIndex: tabIndex }}
          >
            <option value={SALUTATION.MS}>Frau</option>
            <option value={SALUTATION.MR}>Herr</option>
            <option value={SALUTATION.D}>Divers</option>
          </Select>
        )}
      />
    </FormControl>
  );
}

export function HookFormTitleSelect({
  name,
  defaultValue,
  error,
  control,
  label,
  tabIndex,
}: HookFormTitleSelectPickerProps) {
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : TITLE.NONE}
        render={({ field: { onChange, value } }) => (
          <Select
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label={label}
            variant="outlined"
            inputProps={{ tabIndex: tabIndex }}
          >
            <option value={TITLE.NONE}>-</option>
            <option value={TITLE.DR}>Dr.</option>
          </Select>
        )}
      />
    </FormControl>
  );
}
export function HookFormTitleSelectV2({
  name,
  defaultValue,
  error,
  control,
  label,
  tabIndex,
}: HookFormTitleSelectPickerProps) {
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : TITLE.NONE}
        render={({ field: { onChange, value } }) => (
          <Select
            input={<BootstrapInput2 />}
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label={label}
            variant="outlined"
            inputProps={{ tabIndex: tabIndex }}
          >
            <option value={TITLE.NONE}>-</option>
            <option value={TITLE.DR}>Dr.</option>
          </Select>
        )}
      />
    </FormControl>
  );
}

export function HookFormDatePicker({
  name,
  defaultValue,
  error,
  control,
  label,
  tabIndex,
}: HookFormTextFieldProps) {
  const [date, setDate] = useState<Date | null>(null);
  if (defaultValue) {
    setDate(new Date(defaultValue));
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <Controller
        name={name}
        rules={{
          required: true,
          pattern: /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/,
        }}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <DatePicker
              mask="__.__.____"
              label={label}
              value={date}
              onChange={(newValue: Date | null) => {
                setDate(newValue);
                if (
                  newValue?.toString() == "Invalid Date" &&
                  Number(newValue.getFullYear) > 1000
                ) {
                  field.onChange("");
                  return;
                }

                var newVal = DateToString(newValue);

                field.onChange(newVal);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={error}
                  fullWidth={true}
                  defaultValue={defaultValue}
                />
              )}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}
export function HookFormDatePickerV2({
  name,
  defaultValue,
  error,
  control,
  label,
  tabIndex,
  disabled,
}: HookFormTextFieldProps) {
  const def_val = defaultValue ? new Date(defaultValue) : null;
  const [date, setDate] = useState<Date | null>(def_val);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <Controller
        name={name}
        rules={{
          required: true,
          pattern: /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/,
        }}
        control={control}
        render={({ field }) => {
          return (
            <DatePicker
              disabled={disabled}
              mask="__.__.____"
              value={date}
              onChange={(newValue: Date | null) => {
                setDate(newValue);
                if (
                  newValue?.toString() == "Invalid Date" &&
                  Number(newValue.getFullYear) > 1000
                ) {
                  field.onChange("");
                  return;
                }

                var newVal = DateToString(newValue);

                field.onChange(newVal);
              }}
              renderInput={(params) => (
                <FormControl
                  variant="standard"
                  error={error}
                  style={{ minWidth: "calc(100%)" }}
                >
                  <InputLabel shrink htmlFor="bootstrap-input">
                    {label}
                  </InputLabel>
                  <BootstrapInputDate
                    disabled={disabled}
                    style={{ border: "none !important" }}
                    {...params}
                    error={error}
                    fullWidth={true}
                  />
                </FormControl>
              )}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}

export function HookFormEducationPicker({
  name,
  defaultValue,
  error,
  control,
  label,
  tabIndex,
}: HookFormEductaionPickerProps) {
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : EDUCATION.NO_ANSWER}
        render={({ field: { onChange, value } }) => (
          <Select
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label={label}
            variant="outlined"
            inputProps={{ tabIndex: tabIndex }}
          >
            <option value={EDUCATION.NO_ANSWER}>Keine Angabe</option>
            <option value={EDUCATION.SECONDARY}>Sekundarschulabschluss</option>
            <option value={EDUCATION.HIGHSCHOOL}>Abitur</option>
            <option value={EDUCATION.STUDIUM}>
              Bachelor- und/oder Master-Abschluss
            </option>
            <option value={EDUCATION.DOKTOR}>Doktorgrad</option>
          </Select>
        )}
      />
    </FormControl>
  );
}
export function HookFormEducationPickerV2({
  name,
  defaultValue,
  error,
  control,
  label,
  tabIndex,
}: HookFormEductaionPickerProps) {
  return (
    <FormControl variant="standard" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : EDUCATION.NO_ANSWER}
        render={({ field: { onChange, value } }) => (
          <Select
            input={<BootstrapInput2 />}
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label={label}
            variant="outlined"
            inputProps={{ tabIndex: tabIndex }}
          >
            <option value={EDUCATION.NO_ANSWER}>Keine Angabe</option>
            <option value={EDUCATION.SECONDARY}>Sekundarschulabschluss</option>
            <option value={EDUCATION.HIGHSCHOOL}>Abitur</option>
            <option value={EDUCATION.STUDIUM}>
              Bachelor- und/oder Master-Abschluss
            </option>
            <option value={EDUCATION.DOKTOR}>Doktorgrad</option>
          </Select>
        )}
      />
    </FormControl>
  );
}

export function HookFormProfessionPicker({
  name,
  defaultValue,
  error,
  control,
  label,
}: HookFormProffesionPickerProps) {
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : PROFESSION.NO_ANSWER}
        render={({ field: { onChange, value } }) => (
          <Select
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label={label}
            variant="outlined"
            id="demo-simple-select-filled"
          >
            <option value={PROFESSION.NO_ANSWER}>Keine Angabe</option>
            <option value={PROFESSION.ANGESTELLTER}>Angestellte:r</option>
            <option value={PROFESSION.RENTNER}>
              Rentner:in / Pensionär:in
            </option>
            <option value={PROFESSION.SELBSTSTÄNDIG}>Selbstständig:e</option>
            <option value={PROFESSION.STUDENT}>Studierende:r</option>{" "}
            <option value={PROFESSION.APPRENTICE}>Auszubildende:r</option>
            <option value={PROFESSION.MARGINAL_EMPLOYMENT}>
              Geringfügige Beschäftigung
            </option>
            <option value={PROFESSION.WITHOUT}>Arbeitslos</option>
          </Select>
        )}
      />
    </FormControl>
  );
}
export function HookFormProfessionPickerV2({
  name,
  defaultValue,
  error,
  control,
  label,
}: HookFormProffesionPickerProps) {
  return (
    <FormControl variant="standard" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : PROFESSION.NO_ANSWER}
        render={({ field: { onChange, value } }) => (
          <Select
            input={<BootstrapInput2 />}
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label={label}
            variant="outlined"
            id="demo-simple-select-filled"
          >
            <option value={PROFESSION.NO_ANSWER}>Keine Angabe</option>
            <option value={PROFESSION.ANGESTELLTER}>Angestellte:r</option>
            <option value={PROFESSION.RENTNER}>
              Rentner:in / Pensionär:in
            </option>
            <option value={PROFESSION.SELBSTSTÄNDIG}>Selbstständig:e</option>
            <option value={PROFESSION.STUDENT}>Studierende:r</option>{" "}
            <option value={PROFESSION.APPRENTICE}>Auszubildende:r</option>
            <option value={PROFESSION.MARGINAL_EMPLOYMENT}>
              Geringfügige Beschäftigung
            </option>
            <option value={PROFESSION.WITHOUT}>Arbeitslos</option>
          </Select>
        )}
      />
    </FormControl>
  );
}

export function HookFormLegitimationDocumentPicker({
  name,
  defaultValue,
  error,
  control,
  label,
}: HookFormLegitimationDocumentPickerProps) {
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>Ausweisart*</InputLabel>
      <Controller
        name="document_type"
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : DOCUMENT_TYPE.IDCARD}
        render={({ field: { onChange, value } }) => (
          <Select
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label="Dokumententyp*"
            variant="outlined"
            id="demo-simple-select-filled"
          >
            <option value={DOCUMENT_TYPE.IDCARD}>Personalausweis</option>
            <option value={DOCUMENT_TYPE.PASSPORT}>Reisepass</option>
          </Select>
        )}
      />
    </FormControl>
  );
}
export function HookFormLegitimationDocumentPickerV2({
  name,
  defaultValue,
  error,
  control,
  label,
}: HookFormLegitimationDocumentPickerProps) {
  return (
    <FormControl variant="standard" fullWidth={true}>
      <InputLabel>Ausweisart*</InputLabel>
      <Controller
        name="document_type"
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue ? defaultValue : DOCUMENT_TYPE.IDCARD}
        render={({ field: { onChange, value } }) => (
          <Select
            input={<BootstrapInput2 />}
            defaultValue={value}
            onChange={onChange}
            native
            error={error}
            label="Dokumententyp*"
            variant="outlined"
            id="demo-simple-select-filled"
          >
            <option value={DOCUMENT_TYPE.IDCARD}>Personalausweis</option>
            <option value={DOCUMENT_TYPE.PASSPORT}>Reisepass</option>
          </Select>
        )}
      />
    </FormControl>
  );
}

export function HookFormSwitch({
  name,
  defaultValue,
  error,
  control,
  label,
  required,
  tabIndex,
}: HookFormTextFieldProps) {
  return (
    <FormControl variant="outlined" fullWidth={true} className="grid gap-2">
      <div>{label}</div>
      <Controller
        name={name}
        rules={{ required: required }}
        control={control}
        defaultValue={defaultValue ? defaultValue : false}
        render={({ field: { onChange, value } }) => (
          <IvSwitch
            style={{ maxWidth: "200px" }}
            onChange={onChange}
            defaultValue={value}
          />
        )}
      />
    </FormControl>
  );
}
export function HookFormSwitchV2({
  name,
  defaultValue,
  error,
  control,
  label,
  required,
  tabIndex,
}: HookForSwitchProps) {
  return (
    <FormControl variant="outlined" fullWidth={true} className="grid gap-2">
      <Typography variant="body2" className="mb-2">
        {label}
      </Typography>
      <Controller
        name={name}
        rules={{ required: required }}
        control={control}
        defaultValue={defaultValue ? defaultValue : false}
        render={({ field: { onChange, value } }) => (
          <IvSwitchV2
            style={{ maxWidth: "200px" }}
            onChange={onChange}
            defaultValue={value}
          />
        )}
      />
    </FormControl>
  );
}

export function HookFormCheckbox({
  name,
  defaultValue,
  error,
  control,
  label,
  required,
  tabIndex,
  children,
}: HookFormCheckboxProps) {
  return (
    <FormControl variant="outlined" fullWidth={true} className="grid gap-2">
      <Typography variant="body2" className="mb-2">
        {label}
      </Typography>

      <Controller
        name={name}
        rules={{ required: required }}
        control={control}
        defaultValue={defaultValue ? defaultValue : false}
        render={({ field: { onChange, value } }) => (
          <label className="flex gap-2">
            <input
              type="checkbox"
              onChange={(e) => onChange(e.target.checked)}
              checked={value}
            />
            {children}
          </label>
        )}
      />
    </FormControl>
  );
}

export function HookFormFile({ onFileDrop }: FileFormProps) {
  return <Dropzone onChange={(file: File) => onFileDrop(file)} />;
}

export function HookFormFileV2({ onFileDrop }: FileFormProps) {
  return <DropzoneV2 onChange={(file: File) => onFileDrop(file)} />;
}

const Dropzone = ({ onChange }: DropZoneProps) => {
  const [file, setFile] = useState<File>();

  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0]);
    onChange(acceptedFiles[0]);
  }, []);
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: "application/pdf",
    multiple: false,
  });

  return (
    <div
      className="border"
      {...getRootProps()}
      style={{
        cursor: "pointer",
        textAlign: "center",
        borderStyle: "dashed",
        borderColor: "#4A7886",
        width: "calc(100% - 20px)",
        maxWidth: "400px",
        height: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        padding: "10px 10px",
        backgroundColor: isDragActive ? "#4A7886" : "",
      }}
    >
      {!file ? (
        <>
          <input {...getInputProps()} />
          <Typography variant="body1">
            <p style={{ fontSize: "18px", lineHeight: "20px" }}>
              Ziehe ein Dokument in das Feld.
            </p>
            <p style={{ fontWeight: 300 }}>Der Dateityp muss .pdf sein.</p>
          </Typography>

          <div
            style={{
              marginTop: "15px",
              color: "white",
              backgroundColor: theme.palette.primary.light,
              padding: "14px 30px",
              borderRadius: "5px",
            }}
          >
            Dokument hochladen
          </div>
        </>
      ) : (
        <Typography variant="body1">{file?.name}</Typography>
      )}
    </div>
  );
};

const DropzoneV2 = ({ onChange }: DropZoneProps) => {
  const [file, setFile] = useState<File>();

  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0]);
    onChange(acceptedFiles[0]);
  }, []);
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: "application/pdf",
    multiple: false,
  });

  return (
    <div
      className="bg-gray-100 p-6 flex flex-col items-center gap-4 rounded-lg h-44 justify-center"
      {...getRootProps()}
    >
      {!file ? (
        <>
          <input {...getInputProps()} />
          <div className="flex flex-col items-center">
            <p className="text-lg font-medium">
              Ziehe ein Dokument in das Feld.
            </p>
            <p className="text-sm text-gray-600">
              Der Dateityp muss .pdf sein.
            </p>
          </div>
          <div className="bg-primary px-4 py-2 rounded-lg text-white">
            Dokument hochladen
          </div>
        </>
      ) : (
        <Typography variant="body1">{file?.name}</Typography>
      )}
    </div>
  );
};

const DateToString = (date: Date | null) => {
  const pad = (number: number) => {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  };
  if (date == null) {
    return "Invalid Date";
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate())
  );
};
