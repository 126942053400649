import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import loadable from "@loadable/component";
import API_CLIENT from "../../../utility/api-client";

import queryString from "query-string";
import {
  Campaign,
  CAMPAIGN_TYPE,
  Fund,
  FUND_STATUS,
  FUND_TYPE,
  STRUCTURE,
} from "../../../types/fund_types";
import { Document, MatchingDTO } from "../../../types/generic_types";
import LoadingScreen from "../../../components/loading-screen";
import CompanySection from "../../../components/page-components/fund-details/sections/company-section";
import { Link } from "react-router-dom";
import { AppContext } from "../auth-router";
import SubHeader from "../../../template/sub-header";
import theme from "../../../theme";
import InvestmentStrategySection, {
  InvestmentStrategySectionNew,
} from "../../../components/page-components/fund-details/sections/investment-strategy-section";
import OverviewSection from "../../../components/page-components/fund-details/sections/overview-section";
import TeamSection, {
  TeamSectionNew,
} from "../../../components/page-components/fund-details/sections/team-section";
import IntroductionSection from "../../../components/page-components/fund-details/sections/introduction-section";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { useAuth0 } from "@auth0/auth0-react";
import StandardCard from "../../../components/data-display/standard-card/standard-card";
import { motion } from "framer-motion";
import ContentBlocks, {
  ContentBlocksNew,
} from "../../../components/page-components/fund-details/content-blocks";
import PrimaryButton, {
  PrimaryButtonMobile,
} from "../../../components/input-elements/buttons";
import ReactPlayer from "react-player";
import InvestmentProgressBar from "../../../components/data-display/investment-progress-bar";
import { HeaderSectionNew } from "../../../components/page-components/fund-details/sections/hader-section";
import WhatToKnowSection from "../../../components/page-components/fund-details/sections/what-to-know-section";
import ReasonSection from "../../../components/page-components/fund-details/sections/reason-section";
import RiskSection from "../../../components/page-components/fund-details/sections/risk-section";
import TagManager from "react-gtm-module";
import { sha256 } from "js-sha256";
import { v4 } from "uuid";
import NewsSection from "../../../components/page-components/fund-details/sections/news-section";
import IntroSection from "../../../components/page-components/fund-details/sections/intro-section";
import TrackRecordSection, {
  TrackRecordSectionNew,
} from "../../../components/page-components/fund-details/track-record-section";
import { VC } from "../../../types/vc-platform/news_types";
import NEWS_CLIENT from "../../../utility/news-client";

const PREFIX = "fund-details";

const classes = {
  tooltip: `${PREFIX}-tooltip`,
  outerBox: `${PREFIX}-outerBox`,
  logoBox: `${PREFIX}-logoBox`,
  imageBox: `${PREFIX}-imageBox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.outerBox}`]: {
    maxWidth: "1080px",
    padding: "30px",
    margin: "auto",
    [theme.breakpoints.between("xs", "md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
  },
  [`& .${classes.logoBox}`]: {
    [theme.breakpoints.between("xs", "md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingRight: "100px",
    },
  },
  [`& .${classes.imageBox}`]: {
    flexDirection: "column",
    [theme.breakpoints.between("xs", "md")]: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "0px",
    },
  },
});

type HeaderCardProps = {
  campaign: Campaign | null;
  fund: Fund | null;
};

const dateOptions = {
  day: "2-digit" as "2-digit" | "numeric" | undefined,
  month: "2-digit" as "2-digit" | "numeric" | undefined,
  year: "numeric" as "2-digit" | "numeric" | undefined,
};

function HeaderCard({ fund, campaign }: HeaderCardProps) {
  var drawnSize = 0;
  if (fund?.cashlinkCampaigns !== undefined) {
    if (fund?.cashlinkCampaigns.length > 0) {
      drawnSize = Number(
        fund?.cashlinkCampaigns
          ?.map((campaign) => campaign.drawnSize)
          .reduce((acc, i) => acc + i)
      );
    }
  }
  const HtmlTooltip = Tooltip;
  return (
    <div style={{ maxWidth: "400px", position: "relative" }}>
      <div
        style={{
          width: "1px",
          position: "absolute",
          height: "98%",
          top: "0px",
          backgroundColor: theme.palette.grey[400],
        }}
      />
      <div
        //elevation={0}
        style={{
          padding: "0",
          //boxShadow: "0 0 30px -25px #000",
          //backgroundColor: "#fefaf4",
          marginLeft: "30px",
          marginRight: "auto",
          borderRadius: "5px",
        }}
      >
        {fund ? (
          <Root>
            {/**
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                width: "100%",
                height: "120px",
                display: "flex",
                alignItems: "center",
                borderRadius: "5px 5px 0 0",
              }}
            >
              <img
                src={fund?.tokenLogo}
                style={{ width: "70%", marginLeft: "15%", marginTop: "5px" }}
              />
            </div> */}
            <div
              style={{
                padding: "0px 20px 0px 20px",
                overflow: "hidden",
                borderRadius: "5px",
              }}
            >
              <Grid
                container
                spacing={0}
                style={{ position: "relative", zIndex: 100 }}
              >
                <Grid item xs={false} md={1} />
                <Grid item xs={12} style={{ marginTop: "0px" }}>
                  <Grid
                    container
                    spacing={0}
                    style={{ overflow: "hidden", marginTop: "0px" }}
                  >
                    <Grid item xs={8} style={{ display: "block" }}>
                      <Typography
                        variant="h6"
                        style={{
                          marginTop: "0px",
                          marginRight: "2px",
                          display: "contents",
                        }}
                      >
                        Renditeprofil
                      </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography
                          variant="body2"
                          style={{ marginTop: "0px" }}
                        >
                          nach Kosten und Gebühren
                        </Typography>
                        <HtmlTooltip
                          style={{ verticalAlign: "middle" }}
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                Rendite & Risiko
                              </Typography>

                              <p style={{ fontSize: "14px" }}>
                                Das Renditeprofil gibt an, welche Rendite mit
                                der Schuldverschreibung{" "}
                                <b>nach Abzug der Kosten</b> angestrebt wird.
                                Sie ist nicht garantiert, sondern kann auch
                                niedriger ausfallen und es kann weiterhin zu
                                einem Totalausfall kommen. Die Rendite wird
                                definiert anhand eines{" "}
                                <b>optimistischen Szenarios</b>. Es wird darauf
                                hingewiesen, dass signifikante Rückflüsse
                                frühestens ab der zweiten Hälfte der Laufzeit zu
                                erwarten sind.
                              </p>
                            </React.Fragment>
                          }
                          classes={{
                            tooltip: classes.tooltip,
                          }}
                        >
                          <InfoRoundedIcon
                            style={{
                              fontSize: "20px",
                              display: "inline",
                              marginTop: "-6px",
                            }}
                          />
                        </HtmlTooltip>
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div
                        style={{
                          textAlign: "right",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          variant="h4"
                          style={{ fontWeight: 800, fontSize: "28px" }}
                        >
                          {fund.nettoMoneyMultiplier}
                        </Typography>
                      </div>
                    </Grid>
                    {/**
                     *
                     */}
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <Grid container spacing={1}>
                        {/**
                        <Grid item xs={6}>
                          <Typography variant="body1">Emissionsende</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            {new Date(fund.closingDate).toLocaleDateString(
                              "de-de",
                              dateOptions
                            )}
                          </Typography>
                        </Grid> */}
                        {/**<Grid item xs={8}>
                          <Typography variant="body1">
                            Erste erwartete Auszahlung
                          </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            {new Date(
                              fund.expectedFirstReturnDate
                            ).getFullYear()}
                          </Typography>
                            </Grid>**/}
                        <Grid item xs={6}>
                          <Typography variant="body1">Laufzeit</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            {fund.duration} Jahre
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="body1">Zielvolumen</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            {fund.emissionGoal.toLocaleString("de-de")} €
                          </Typography>
                        </Grid>
                        {/**<Grid item xs={6}>
                          <Typography variant="body1">
                            Mindestinvestment
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            {Number(1000).toLocaleString()}€
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="body1">
                            Maximalinvestment
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            {Number(25000).toLocaleString()}€
                          </Typography>
                          </Grid>**/}
                        <Grid item xs={3}>
                          <Typography variant="body1">Produkt</Typography>
                        </Grid>
                        <Grid item xs={9} style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            Digitales Wertpapier
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div
              style={{ paddingBottom: "1px", marginTop: "40px" }}
              onClick={() => window.scrollTo(0, 0)}
            >
              {fund.fundStatus !== FUND_STATUS.CLOSED ? (
                <>
                  <Typography variant="body2" style={{ textAlign: "center" }}>
                    Du möchtest dich persönlich mit uns unterhalten? Dann buche{" "}
                    <a
                      href="https://calendly.com/inventuredatle/30min"
                      target="blank"
                      style={{ color: "rgb(19, 59, 67)" }}
                    >
                      hier
                    </a>{" "}
                    einen Termin mit uns.
                  </Typography>
                  {fund.structure == STRUCTURE.AIF ? (
                    <Link
                      //to={`/portal/investment-process?campaign=${campaign?.cashlinkId}&fund=${fund.cashlinkId}`}
                      to={`/portal/investment-process-aif?campaign=${campaign?.cashlinkId}&fund=${fund.cashlinkId}`}
                      style={{ textDecoration: "none" }}
                    >
                      <PrimaryButton
                        style={{
                          width: "95%",
                          height: "60px",
                          fontWeight: 500,
                          marginTop: "0px",
                          margin: "10px",
                        }}
                      >
                        <Typography variant="h6">Jetzt investieren</Typography>
                      </PrimaryButton>
                    </Link>
                  ) : null}
                </>
              ) : (
                <a
                  href="https://calendly.com/inventuredatle/30min"
                  style={{ textDecoration: "none" }}
                >
                  <PrimaryButton
                    style={{
                      width: "95%",
                      height: "60px",
                      fontWeight: 500,
                      marginTop: "0px",
                      margin: "10px",
                    }}
                  >
                    <Typography variant="h6">Termin vereinbaren</Typography>
                  </PrimaryButton>
                </a>
              )}
            </div>
          </Root>
        ) : null}
      </div>
    </div>
  );
}

export default function FundDetails() {
  const DocumentSection = loadable(
    () =>
      import(
        "../../../components/page-components/fund-details/sections/document-section"
      )
  );

  const queries = queryString.parse(window.location.search);
  const fundId = queries.id ? queries.id.toString() : "";
  const appContext = useContext(AppContext);
  const [fund, setFund] = useState<Fund | null>(null);
  const [vcplatformfund, setVcPatformfund] = useState<VC | null>(null);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const callId = v4();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently({});

      API_CLIENT.getFund(
        accessToken,
        fundId,
        (fund: Fund) => {
          setFund(fund);
          track(accessToken, fund);
          setLoading(false);
          NEWS_CLIENT.getFund(fund?.targetInvestmentId, (fund) =>
            setVcPatformfund(fund)
          );
        },
        (error: string) => {
          window.location.href = "/error?error=" + error;
        }
      );
    };
    fetchData();
  }, []);

  function track(accessToken: string, fund: Fund) {
    var uData = appContext.matchingData;

    var mData: MatchingDTO = {
      event: "ViewContent",
      eventId: callId,
      sourceUrl: window.location.href,
      em: uData?.em,
      ph: uData?.ph,
      fn: uData?.fn,
      ln: uData?.ln,
      product: fund?.name,
      focus: fund?.fokus,
      type: fund?.type,
    };

    API_CLIENT.postMatching(accessToken, mData);

    TagManager.dataLayer({
      dataLayer: {
        event: "view-product",
        event_id: callId,
        first_name: appContext.user?.natural_person?.forename,
        last_name: appContext.user?.natural_person?.surname,
        phone: uData?.ph,
        email: appContext.userEmail,
        product: fund?.name,
        focus: fund?.fokus,
        type: fund?.type,
      },
    });
  }

  var buf = fund?.cashlinkCampaigns?.find(
    (campaign) => campaign.type === CAMPAIGN_TYPE.PUBLIC
  );
  var campaign: Campaign | null = buf ? buf : null;
  var publicDocuments: Document[] = [];
  var privateDocuments: Document[] = [];

  fund?.documents.forEach((document) => {
    switch (document.type) {
      case "PRIVATE":
        privateDocuments?.push(document);
        break;
      case "PUBLIC":
        publicDocuments?.push(document);
        break;
      default:
        break;
    }
  });

  const isCadence = fund?.cashlinkId == "ae34b94f-06af-40f8-9d4d-70652220f231";

  const opts = {
    position: "absolute",
    top: 0,
    left: 0,
  };

  var riskdocument = publicDocuments.find(
    (document) => document.name == "Risikohinweise"
  );

  return (
    <>
      {loading || fund === undefined || fund === null ? (
        <LoadingScreen style={{ width: "100vw", height: "100vh" }} />
      ) : (
        <Grid
          container
          justifyContent="center"
          style={{ padding: "0", height: "100%" }}
        >
          <Box
            sx={{ display: { xs: "block", md: "none" } }}
            style={{ width: "100vw", position: "fixed", top: 0, zIndex: 500 }}
          >
            <SubHeader titleMobile={fund.name} titleDesktop={fund.name} />
          </Box>
          <Box
            sx={{ display: { xs: "block", md: "none" } }}
            style={{
              position: "fixed",
              left: "0",
              bottom: "61px",
              width: "100vw",
              zIndex: 100,
              padding: "10px",
            }}
          >
            {fund.fundStatus !== FUND_STATUS.CLOSED ? (
              <></>
            ) : (
              /**<PrimaryButtonMobile
                href={`/portal/investment-process?campaign=${campaign?.cashlinkId}&fund=${fund.cashlinkId}`}
              >
                Jetzt investieren
              </PrimaryButtonMobile>**/
              <a
                href="https://calendly.com/inventuredatle/30min"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <PrimaryButtonMobile>Termin vereinbaren</PrimaryButtonMobile>
              </a>
            )}
          </Box>
          {/** <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <Link to={`/portal/investment-process?campaign=${campaign?.cashlinkId}&fund=${fund.cashlinkId}`} style={{ textDecoration: "none" }}>
              <Button
                disableElevation
                style={{
                  borderRadius: "0px",
                  position: "fixed",
                  bottom: "60px",
                  width: "100vw",
                  height: "50px",
                  fontWeight: 500,
                  zIndex: 1000,
                }}
                color="primary"
                variant="contained"
              >
                Jetzt Investieren
              </Button>
            </Link>
          </Box> */}
          <Grid
            item
            xs={12}
            style={{
              backgroundImage: "url(" + fund.backgroundImgUrl + ")",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              backgroundPosition: "center",
            }}
          >
            <HeaderSectionNew
              fund={fund}
              campaign={campaign}
              targetInvestment={vcplatformfund}
            />
          </Grid>
          {/**
             <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            style={{ zIndex: 1000, position: "sticky", top: 0, marginTop: "0px", width: "100vw", height: "50px", justifyContent: "center" }}
          >
            <div style={{ width: "1200px", height: "100%" }}>
              <ButtonGroup style={{ height: "100%" }} sx={{ display: { xs: "none", lg: "block" } }}>
                <Button onClick={() => scrollToId("introduction")} style={{ height: "100%", width: "157px" }} variant="contained">
                  Struktur
                </Button>
                <Button onClick={() => scrollToId("strategy")} style={{ height: "100%", width: "157px" }} variant="contained">
                  Strategie
                </Button>
                <Button onClick={() => scrollToId("startups")} style={{ height: "100%", width: "157px" }} variant="contained">
                  Start-ups
                </Button>
                <Button onClick={() => scrollToId("team")} style={{ height: "100%", width: "157px" }} variant="contained">
                  Team
                </Button>
                <Button onClick={() => scrollToId("documents")} style={{ height: "100%", width: "157px" }} variant="contained">
                  Dokumente
                </Button>
              </ButtonGroup>
            </div>
          </Box>
           */}

          <Grid
            item
            xs={12}
            style={{
              maxWidth: "800px",
              width: "100%",
            }}
          >
            <Grid container style={{ padding: "0px 10px 0px 10px" }}>
              {fund!!.fundTab1 !== null ? (
                <>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: {
                          sx: {
                            width: "100%",
                            marginLeft: "-5px",
                            border: "none",
                          },
                          md: {
                            width: "calc(100% - 22px)",
                            borderColor: "rgb(100,100,100)",
                            borderWidth: "1px",
                            borderRadius: "5px",
                            borderStyle: "solid",
                          },
                        },
                      }}
                      style={{
                        marginTop: "50px",
                        padding: "0px",
                      }}
                    >
                      <Typography variant="body1">
                        <b>Risikohinweis</b>: Der Erwerb dieser Wertpapiere ist
                        mit erheblichen Risiken verbunden und kann zum
                        vollständigen Verlust des eingesetzten Vermögens
                        führen.​ ​Sie sind im Begriff ein Produkt zu erwerben,
                        das nicht einfach ist und schwer zu verstehen sein kann.
                      </Typography>
                    </Box>
                  </Grid>
                  {fund!!.fundTab1.introSection ? (
                    <Grid item xs={12} id="intro">
                      <IntroSection data={fund!!.fundTab1.introSection} />
                    </Grid>
                  ) : null}
                  {fund.videoUrl != "" && fund.videoUrl != null ? (
                    <Grid item xs={12} style={{ marginTop: "30px" }}>
                      <div
                        style={{ position: "relative", paddingTop: "56.25%" }}
                      >
                        <ReactPlayer
                          style={{ position: "absolute", top: 0, left: 0 }}
                          controls
                          url={fund.videoUrl}
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </Grid>
                  ) : null}
                  {fund!!.fundTab1.introductionSection ? (
                    <Grid item xs={12} id="introduction">
                      <IntroductionSection
                        data={fund!!.fundTab1.introductionSection}
                      />
                    </Grid>
                  ) : null}

                  {fund!!.fundTab1.genericSections?.map((section, i) => (
                    <Grid
                      item
                      xs={12}
                      id={"genericsection" + i}
                      style={{ marginTop: "30px" }}
                    >
                      <Typography
                        variant="h2"
                        style={{ marginTop: "30px", fontSize: "32px" }}
                      >
                        {section.header}
                      </Typography>
                      <ContentBlocksNew blocks={section.contentBlocks} />
                    </Grid>
                  ))}
                  {fund!!.fundTab1.whatToKnowSection ? (
                    <Grid item xs={12} id="whatToKnow">
                      <WhatToKnowSection
                        whatToKnowSection={fund!!.fundTab1.whatToKnowSection}
                      />
                    </Grid>
                  ) : null}
                  {fund!!.fundTab1.fundOverviewSection ? (
                    <Grid item xs={12} id="overview">
                      <OverviewSection
                        data={fund!!.fundTab1.fundOverviewSection}
                      />
                    </Grid>
                  ) : null}
                  {fund!!.fundTab1.investmentStrategySection ? (
                    <Grid item xs={12} id="strategy">
                      <InvestmentStrategySectionNew
                        data={fund!!.fundTab1.investmentStrategySection}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} id="trackRecord">
                    <TrackRecordSection fundId={fund!!.cashlinkId} />
                  </Grid>

                  <NewsSection fundId={fundId} />
                  {fund!!.fundTab1.teamMemberSection ? (
                    <Grid item xs={12} id="team">
                      <TeamSection data={fund!!.fundTab1.teamMemberSection} />
                    </Grid>
                  ) : null}
                  {isCadence ? (
                    <Grid item xs={12}>
                      <TeamSectionNew
                        team={vcplatformfund?.investmentCompany.manager}
                      />
                    </Grid>
                  ) : null}

                  {fund!!.fundTab1.reasonSection ? (
                    <Grid item xs={12} style={{ marginTop: "40px" }}>
                      <ReasonSection
                        reasonSection={fund!!.fundTab1.reasonSection}
                      />
                    </Grid>
                  ) : null}
                  {/**riskdocument ? (
                    <Grid item xs={12} style={{ marginTop: "40px" }}>
                      <RiskSection document={riskdocument} fund={fund} />
                    </Grid>
                  ) : null*/}

                  {fund!!.fundTab1.trackRecordSection ? (
                    <Grid
                      item
                      xs={12}
                      id={"track-record-section"}
                      style={{ marginTop: "50px" }}
                    >
                      <TrackRecordSectionNew
                        section={fund!!.fundTab1.trackRecordSection}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} id="documents">
                    <DocumentSection
                      publicDocuments={publicDocuments}
                      privateDocuments={privateDocuments}
                      fundId={fund.cashlinkId}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ color: "rgb(0,0,0)" }}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "center", marginTop: "70px" }}
                    >
                      <b>WARNHINWEIS</b>
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ textAlign: "left", marginBottom: "20px" }}
                    >
                      Der Erwerb dieser tokenisierten Schuldverschreibungen ist
                      mit erheblichen Risiken verbunden und kann zum
                      vollständigen Verlust des eingesetzten Vermögens führen.
                      Die Informationen in diesem Dokument richten sich
                      ausschließlich an Personen, die ihren Wohnsitz bzw.
                      gewöhnlichen Aufenthalt in der Bundesrepublik Deutschland
                      haben. Die Informationen stellen weder ein Angebot zum
                      Kauf oder zur Zeichnung der tokenisierten
                      Schuldverschreibungen noch eine Aufforderung zur Abgabe
                      eines Angebots zum Erwerb der tokenisierten
                      Schuldverschreibungen der Emittentin dar. Die
                      Informationen haben ausschließlich werblichen Charakter.
                      Insbesondere stellen diese Informationen keine Finanz-
                      oder sonstige Anlageberatung dar. Die hier enthaltenen
                      Informationen können eine auf die individuellen
                      Verhältnisse des Anlegers abgestellte anleger- und
                      anlagegerechte Beratung nicht ersetzen. Die Emittentin
                      weist deutlich darauf hin, dass zur Beurteilung der
                      tokenisierten Schuldverschreibungen ausschließlich die
                      Angaben im Basisinformationsblatt und den
                      Schuldverschreibungsbedingungen der Emittentin maßgeblich
                      sind, der auf der Internetseite der veröffentlicht wurde
                      und dort kostenlos heruntergeladen werden kann.
                    </Typography>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
          {/**  <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <div
              style={{
                position: "sticky",
                top: "5vh",
                marginTop: "50px",
                marginLeft: "20px",
                height: "fit-content",
                marginBottom: "50px",
              }}
            >
              <HeaderCard fund={fund} campaign={campaign} />
            </div>
          </Box> */}

          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <div
              style={{
                position: "sticky",
                top: "5vh",
                paddingTop: "70px",
                marginLeft: "20px",
                height: "fit-content",
                marginBottom: "50px",
              }}
            >
              <HeaderCard fund={fund} campaign={campaign} />
            </div>
          </Box>
        </Grid>
      )}
    </>
  );
}
