import {
  Grid,
  InputAdornment,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import theme from "../../theme";
import InfoIcon from "@mui/icons-material/Info";

export default function CalculatorWidget() {
  //visual
  const balkWidth = "80px";
  const textWidth = "130px";

  const textColor = "rgb(65,65,65)";

  const [investmentAmount, setInvestmentAmount] = useState(1000);
  const [expectedPerformance, setExpectedPerformance] = useState(2.8);
  const [years, setYears] = useState(5);

  const totalReturn = Math.round(investmentAmount * expectedPerformance);

  const height = 500;
  const barHeight = height - 350
  const maxPerformance = 3.2
  const minPerformance = 1

  var heightInputBar = barHeight
  var heightOutputBar = barHeight * expectedPerformance;
  console.log(heightOutputBar)
  if (heightOutputBar >= (height - 140)) {
    heightInputBar = (height - 140) / expectedPerformance
    heightOutputBar = height - 140
  }

  const heightOutputBarWithoutCosts = heightOutputBar - heightInputBar;

  return (
    <div
      id="calc-widget"
      style={{
        margin: "10px",
        width: "calc(100% - 20px)",
        height: `${height}px`,
        display: "flex",
      }}
    >
      <div
        id="calc-inputs"
        style={{
          width: "50%",
          margin: "30px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          Gib hier die Summe und Laufzeit ein, die Du investieren möchtest.
        </Typography>
      <div style={{display: "flex"}}>
            <TextField
              style={{width: "200px"}}
              hiddenLabel
              size="small"
              defaultValue={investmentAmount}
              type="number"
              onChange={(e) => {
                const newVal = Number(e.target.value);
                if (newVal) {
                  setInvestmentAmount(newVal);
                }
              }}
              InputProps={{
                style: {
                  fontSize: "20px",
                  marginTop: "10px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="h5">€</Typography>
                  </InputAdornment>
                ),
              }}
            />
         
 
            <TextField
              hiddenLabel
              size="small"
              defaultValue={years}
              type="number"
              style={{marginLeft: "10px", width: "150px"}}
              onChange={(e) => {
                const newVal = Number(e.target.value);
                if (newVal) {
                  setYears(newVal);
                }
              }}
              InputProps={{
                style: {
                  fontSize: "20px",
                  marginTop: "10px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="h5">Jahre</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </div>

        <Typography variant="body1" style={{ fontWeight: 600, marginTop: "30px" }}>
          Welche Performance möchtest Du berechnet haben?
        </Typography>
        <Typography
          variant="h5"
          style={{ fontWeight: 600 }}
          color={theme.palette.primary.dark}
        >
          {expectedPerformance}x
        </Typography>
        <Slider
          size="small"
          min={minPerformance}
          max={maxPerformance}
          step={0.1}
          aria-label="Volume"
          value={expectedPerformance}
          onChange={(e, number) => {
            const newVal = Number(number);
            if (newVal >= 0) {
              setExpectedPerformance(newVal);
            }
          }}
        />
        <img
          src="https://ik.imagekit.io/inventure/App/static/indikatives-auszahlungsprofil_QzqFMy5Jts.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1661858457446"
          style={{ height: `${height - 300}px`, marginTop: "30px" }}
        />
      </div>
      <div
        id="calculator"
        style={{
          width: "50%",
          backgroundColor: "#fff9f1",
          height: "100%", //abcde
          padding: "0px 0 0 0",
          borderRadius: "5px",
          position: "relative"
        }}
      >
        <div id="meta-data" style={{ height: "150px" }}>
          <div style={{ width: "calc(100% - 60px)", margin: "30px" }}>
         <div style={{position: "absolute", zIndex: 100}}>
            <Typography variant="h4">Auszahlungsprofil</Typography>
           

            <Typography
              variant="h4"
              style={{ fontWeight: 600, color: textColor, marginTop: "30px" }}
            >
              {totalReturn} €
            </Typography>
            <Tooltip
              title={`kumulierte Auszahlungen über die nächsten ${years} Jahre`}
            >
            <div style={{display: "flex", alignItems: "center",  width: "fit-content"}}>
            <Typography
              variant="body1"
              style={{ fontWeight: 500, color: textColor }}
            >
              Netto-Rückflüsse
            </Typography>
           
              <InfoIcon style={{color: "rgb(50,50,50)", fontSize: "16px", marginLeft: "5px"}}/>
            
            </div>
            </Tooltip>
            <Typography
              variant="h4"
              style={{ fontWeight: 600, color: textColor, marginTop: "30px" }}
            >
              {Math.round((Math.pow(expectedPerformance, 1 / years) - 1) * 100)}{" "}
              %
            </Typography>
            <Tooltip
              title={`kumulierte Auszahlungen über die nächsten ${years} Jahre`}
            >
                  <div style={{display: "flex", alignItems: "center",  width: "fit-content"}}>
            <Typography
              variant="body1"
              style={{ fontWeight: 500, color: textColor }}
            >
              Nettorendite p.a.
            </Typography>  <InfoIcon style={{color: "rgb(50,50,50)", fontSize: "16px", marginLeft: "5px"}}/></div>
            </Tooltip>
          </div>
          </div>
        </div>
        <div
          id="calculator-wrapper"
          style={{
            position: "absolute",
            zIndex: 0,
            width: "100%",
            bottom: "40px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              width: balkWidth,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <motion.div
              layout
              animate={{
                height: `${heightInputBar}px`,
              }}
              transition={{ duration: 1 }}
              style={{
                position: "relative",
                borderRadius: "5px",
                width: balkWidth,
                backgroundColor: theme.palette.primary.light,
              }}
            ></motion.div>
          </div>
          <div
            style={{
              width: balkWidth,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <motion.div
              animate={{
                height: `${heightOutputBarWithoutCosts}px`,
              }}
              transition={{ duration: 1 }}
              style={{
                position: "relative",
                borderRadius: "5px 5px 0px 0px",
                width: balkWidth,
                backgroundColor: theme.palette.primary.main,
              }}
            ></motion.div>
            <motion.div
              layout
              animate={{
                height: `${heightInputBar}px`,
              }}
              transition={{ duration: 1 }}
              style={{
                position: "relative",
                borderRadius: "0 0 5px 5px",
                width: balkWidth,
                backgroundColor: theme.palette.primary.light,
              }}
            ></motion.div>
          </div>
        </div>
        <div
          id="calc"
          style={{
            bottom: "10px",
            position: "absolute",
            width: "100%",
         
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-around",
          }}
        >
          <LegendText t1="Jahr 0" t2="Investition" width={textWidth} />
          <LegendText t1={`Jahr ${years}`} t2="Rückzahlung" width={textWidth} />
        </div>
      </div>
    </div>
  );
}

interface TupleText {
  t1: string;
  t2: string;
}

function BarText({ t1, t2 }: TupleText) {
  return (
    <div style={{ padding: "10px" }}>
      <Typography color={theme.palette.primary.contrastText} variant="body1">
        {t1}
      </Typography>
      <Typography
        style={{ marginTop: "-8px", fontWeight: 600 }}
        color={theme.palette.primary.contrastText}
        variant="h6"
      >
        {t2}
      </Typography>
    </div>
  );
}

interface LegendText extends TupleText {
  width: string;
}

function LegendText({ t1, t2, width }: LegendText) {
  return (
    <div
      style={{
        width: width,
        display: "flex",
        alignItems: "center",

        flexDirection: "column",
      }}
    >
      <Typography variant="subtitle1">
        <b>{}</b>
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: "-8px" }}>
        {t2}
      </Typography>
    </div>
  );
}
