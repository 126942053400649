import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { RedirectBlank as MyRedirect } from "./pages/redirect";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import "./App.css";
import loadable from "@loadable/component";
import theme from "./theme";
import { AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import SignUp from "./pages/signup/signup";
import ReactGA from "react-ga";
import countries from "i18n-iso-countries";
import AuthRouter from "./pages/portal/auth-router";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import SignUpNew from "./pages/signup/signup-new";
import GenericErrorPage from "./pages/generic-error-page";
import { ThemeProvider } from "@mui/material";
import CostWidget from "./components/data-display/cost-widget";
import Cookies from "js-cookie";
import InvestmentProcessFull from "./pages/direct/investment-process-full/investment-process-full";
import DirectRouter from "./pages/direct/direct-router";

function Callback() {
  return <div></div>;
}

function App() {
  const search = useLocation().search;
  //start setting affiliate cookie
  var cookie = Cookies.get("iv-affiliate");
  if (cookie == null) {
    const searchParams = new URLSearchParams(search);
    var ivaf = searchParams.get("ivaf");

    if (ivaf) {
      const affiliate_cookie = ivaf;
      var date = new Date();
      date.setTime(date.getTime() + 90 * 24 * 60 * 60 * 1000);
      var expires = date.toUTCString();

      document.cookie =
        "iv-affiliate=" + affiliate_cookie + "; Path=/; expires=" + expires;
    }
  }
  //end setting cookie

  var viewport: any = document.querySelector("meta[name=viewport]");
  if (viewport)
    viewport.setAttribute(
      "content",
      viewport.content + ", height=" + window.innerHeight
    );

  countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

  const ErrorPage = loadable(() => import("./pages/errorpage"));
  const auth0domain =
    process.env.REACT_APP_AUTH0_URL != null
      ? process.env.REACT_APP_AUTH0_URL
      : "";
  const clientId =
    process.env.REACT_APP_AUTH0_CLIENTID != null
      ? process.env.REACT_APP_AUTH0_CLIENTID
      : "";
  const gaId =
    process.env.REACT_APP_GA_ID != null ? process.env.REACT_APP_GA_ID : "";
  const gTagId =
    process.env.REACT_APP_GTAG_ID != null ? process.env.REACT_APP_GTAG_ID : "";
  const gTagEnvironment =
    process.env.REACT_APP_GTAG_ENVIRONMENT != null
      ? process.env.REACT_APP_GTAG_ENVIRONMENT
      : "";
  const gTagAuth =
    process.env.REACT_APP_GTAG_AUTH != null
      ? process.env.REACT_APP_GTAG_AUTH
      : "";

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  useEffect(() => {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: gTagId,
      auth: gTagAuth,
      preview: gTagEnvironment,
    };
    TagManager.initialize(tagManagerArgs);
    if (gaId == "UNDEFINED") return;
    ReactGA.initialize(gaId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    var returnAddress = appState.returnTo;
    history.push(returnAddress);
  };

  return (
    <Auth0Provider
      organization={undefined}
      domain={auth0domain}
      clientId={clientId}
      redirectUri={window.location.origin + "/callback"}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
      setRefreshTokens={true}
      scope="openid profile email current_user current_user_metadata:read manage:investor"
    >
      <HelmetProvider>
        <div className="flex-1 bg-slate-50">
          <ThemeProvider theme={theme}>
            <Switch>
              <Route path="/portal" component={AuthRouter} />
              <Route path="/direct" component={DirectRouter} />
              <Route path="/redirect" component={MyRedirect} />
              <Route path="/callback" component={Callback} />
              <Route path="/error" component={GenericErrorPage} />
              <Route exact path="/signup" component={SignUpNew} />
              <Route path="/test/widget1">
                <CostWidget
                  pessimistic_szenario={0.6}
                  medium_szenario={1.9}
                  optimistic_szenario={2.8}
                  upfront_fee={0.0549}
                  management_fee={0.0125}
                  carry_fee_pessimistic={0.0}
                  carry_fee_medium={0.0087}
                  carry_fee_optimistic={0.0396}
                  years={10}
                />
              </Route>
              <Redirect from="/" to="/direct/dashboard" />
            </Switch>
          </ThemeProvider>
        </div>
      </HelmetProvider>
    </Auth0Provider>
  );
}

export default App;
