import { Checkbox, Grid, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { SALUTATION, TITLE } from "../../types/inventure-user_types";
import { SignupData } from "../../types/onboarding_types";
import API_CLIENT from "../../utility/api-client";
import PdfLinkOverlay from "../../components/data-display/pdf-link-overlay";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingScreen from "../../components/loading-screen";
import ReactGA from "react-ga";
import auth0 from "auth0-js";
import Cookies from "js-cookie";
import { sha256 } from "js-sha256";
import TagManager from "react-gtm-module";
import Normalizer from "../../utility/normalizer";
import MultistepForm from "../../components/vertical-multistep-form/multistep-form";
import MultistepFormNavigation from "../../components/vertical-multistep-form/multistep-form-navigation";
import MultistepFormBody from "../../components/vertical-multistep-form/multistep-form-body";
import MultistepFormStep from "../../components/vertical-multistep-form/multistep-form-step";
import HookFormTextField, {
  HookFormPasswordField,
  HookFormSalutationSelect,
  HookFormTitleSelect,
} from "../../components/hook-form-inputs";
import MultistepFormButtons from "../../components/vertical-multistep-form/multistep-form-buttons";
import { Box } from "@mui/system";
import VerticalStepper from "../../components/vertical-multistep-form/vertical-stepper";
import FooterV2 from "../../template/footer-v2";
import { Footer } from "@inventurefinancial/frontend-components";
import theme from "../../theme";

interface StepOneData {
  salutation: SALUTATION;
  title: TITLE;
  firstname: string;
  surname: string;
  phone: string;
}

interface StepTwoData {
  email: string;
  password: string;
  termsAccepted: boolean;
  effectaTermsAccepted: boolean;
  email_allowance: boolean;
  captcha_token: string | null;
  new: boolean;
}

interface FormData {
  stepOneData: StepOneData | null;
  stepTwoData: StepTwoData | null;
}

declare const window: any;

const auth0domain =
  process.env.REACT_APP_AUTH0_URL != null
    ? process.env.REACT_APP_AUTH0_URL
    : "";
const clientId =
  process.env.REACT_APP_AUTH0_CLIENTID != null
    ? process.env.REACT_APP_AUTH0_CLIENTID
    : "";
var a0 = new auth0.WebAuth({
  domain: auth0domain,
  clientID: clientId,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  responseType: "token",
  redirectUri: window.location.origin + "/portal/products",
});

export default function SignUpNew() {
  const emptySignUp: FormData = { stepOneData: null, stepTwoData: null };
  const [signUpData, setSignUpData] = useState<FormData>(emptySignUp);
  const [emailTakenError, setEmailTakenError] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  var affiliate_program = "";
  var cookie = Cookies.get("iv-affiliate");
  if (cookie != undefined) {
    affiliate_program = cookie;
  }

  const onSubmit = (data: FormData) => {
    console.log(data);
    if (data.stepOneData == null || data.stepTwoData == null) return;
    if (data.stepTwoData.captcha_token == null) return;
    setLoading(true);

    var phoneNumber = data.stepOneData.phone;
    var normalizedPhoneNumber = phoneNumber
      ? Normalizer.normalizePhone(data.stepOneData.phone)
      : "";

    var signupData: SignupData = {
      email: data.stepTwoData.email,
      password: data.stepTwoData.password,
      firstName: data.stepOneData.firstname,
      lastName: data.stepOneData.surname,
      title: data.stepOneData.title,
      salutation: data.stepOneData.salutation,
      phoneNumber: normalizedPhoneNumber,
      termsAccepted: true,
      effectaTermsAccepted: true,
      acquisition_channel: "",
      origin: "na",
      affiliate_code: "",
      affiliate_program: affiliate_program,
      email_allowance: true,
      phone_allowance: true,
      new: data.stepTwoData.new,
    };
    console.log(data.stepTwoData.captcha_token);
    API_CLIENT.signup(
      signupData,
      data.stepTwoData.captcha_token,
      () => {
        a0.login(
          {
            realm: "Username-Password-Authentication",
            username: signupData.email,
            password: signupData.password,
          },
          (callback) => {
            setLoading(true);
          }
        );
      },
      (error: any) => {
        console.log("1234321");
        console.log(error);
        if (error.message.includes("409 Conflict from POST")) {
          setEmailTakenError(true);
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  function onChange(value: any) {
    setToken(value);
  }

  function submitStepOne(data: StepOneData) {
    window.scrollTo(0, 0);
    var cur_su_data = signUpData;
    cur_su_data.stepOneData = data;
    setSignUpData(cur_su_data);
    setActiveStep(1);
  }

  function submitStepTwo(data: StepTwoData) {
    var cur_su_data = signUpData;
    cur_su_data.stepTwoData = data;
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
      "identify",
      {
        email: cur_su_data.stepTwoData.email,
      },
    ]);
    _hsq.push(["setPath", "/"]);
    _hsq.push(["trackPageView"]);
    setSignUpData(cur_su_data);
    onSubmit(cur_su_data);
  }

  return (
    <>
      {loading ? (
        <LoadingScreen style={{ height: "100vh" }} />
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{ display: { xs: "flex", md: "none" } }}
              style={{ flexDirection: "column", alignItems: "center" }}
            >
              <a href="https://www.inventure.de/">
                <img
                  src={process.env.REACT_APP_LOGO_LIGHT}
                  style={{ height: "60px", margin: "20px 0" }}
                />
              </a>
              <Typography variant="body1">
                Bereits angemeldet? Hier zum{" "}
                <a style={{ color: "black" }} href="/">
                  Login
                </a>
                .
              </Typography>
            </Box>
          </div>

          <MultistepForm style={{ height: "100vh", minHeight: "700px" }}>
            <MultistepFormNavigation opacity={0.02}>
              <Box
                sx={{ display: { xs: "none", md: "flex" } }}
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "150px",
                  flexDirection: "column",
                }}
              >
                <a href="https://www.inventure.de/">
                  <img
                    src={process.env.REACT_APP_LOGO_LIGHT}
                    style={{ height: "60px", margin: "40px auto" }}
                  />
                </a>
                <Typography variant="body1">
                  Bereits angemeldet? Hier zum{" "}
                  <a style={{ color: "black" }} href="/">
                    Login
                  </a>
                  .
                </Typography>
              </Box>{" "}
              <VerticalStepper
                totalSteps={2}
                blockTo={0}
                highestStep={activeStep}
                onStepClick={(step) => {
                  if (step < activeStep) setActiveStep(step);
                }}
                style={{ width: "100%", minHeight: "calc(100% - 150px)" }}
                activeStep={activeStep}
                labels={["Persönliche Angaben", "Zugangsdaten"]}
              />
            </MultistepFormNavigation>
            <MultistepFormBody>
              <MultistepFormStep step={0} activeStep={activeStep}>
                <StepOne onSuccess={(data) => submitStepOne(data)} />
              </MultistepFormStep>
              <MultistepFormStep step={1} activeStep={activeStep}>
                <StepTwo
                  emailTaken={emailTakenError}
                  onSuccess={(data) => submitStepTwo(data)}
                  back={() => setActiveStep(0)}
                />
              </MultistepFormStep>
            </MultistepFormBody>
          </MultistepForm>
        </>
      )}
      <Footer
        theme={theme}
        logo_url={process.env.REACT_APP_LOGO_LIGHT}
        copyright={process.env.REACT_APP_COPYRIGHT}
        data_protection_url={process.env.REACT_APP_DATA_PROTECTION_URL}
        risk_information_url={process.env.REACT_APP_RISK_INFROMATION_URL}
        imprint_url={process.env.REACT_APP_IMPRINT_URL}
        disclamer={process.env.REACT_APP_DISCLAIMER}
        license_shield_information={
          process.env.REACT_APP_LICENSE_SHIELD_INFORMATION
        }
      />
    </>
  );
}

interface StepOneProps {
  onSuccess: (data: StepOneData) => void;
  back?: () => void;
}

interface StepTwoProps {
  emailTaken: boolean;
  onSuccess: (data: StepTwoData) => void;
  back?: () => void;
}

function StepOne({ onSuccess, back }: StepOneProps) {
  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<any>({ mode: "onChange" });

  function test(data: StepOneData) {
    onSuccess(data);
  }

  return (
    <form onSubmit={handleSubmit(test)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Sign Up</Typography>
          <Typography variant="h4" style={{ marginBottom: "20px" }}>
            Willkommen bei inVenture
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <HookFormSalutationSelect
            control={control}
            name={"salutation"}
            error={false}
            label={"Anrede*"}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <HookFormTitleSelect
            control={control}
            name={"title"}
            error={false}
            label={"Titel"}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <HookFormTextField
            required
            control={control}
            name={"firstname"}
            error={errors.firstname != null}
            label={"Vorname*"}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <HookFormTextField
            required
            control={control}
            name={"surname"}
            error={errors.surname != null}
            label={"Nachname*"}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <HookFormTextField
            control={control}
            name={"phone"}
            error={false}
            label={"Telefonnummer"}
          />
        </Grid>

        <Grid item xs={12} md={6} />
        <Grid item xs={12}>
          <MultistepFormButtons
            style={{ bottom: "0" }}
            back={false}
            backFunction={() => null}
            disabled={!formState.isValid}
          />
        </Grid>
      </Grid>
    </form>
  );
}

function StepTwo({ onSuccess, back, emailTaken }: StepTwoProps) {
  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<any>({ mode: "onChange" });

  const captchaRef = useRef(null);
  const test = async (data: StepTwoData) => {
    // @ts-ignore
    //console.log("email" , referenceEmail.current.getValue())
    const token = await captchaRef.current.executeAsync();
    data.captcha_token = token;
    data.new = true;

    //console.log(data.captcha_token)
    onSuccess(data);
  };
  return (
    <form onSubmit={handleSubmit(test)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Sign Up</Typography>
          <Typography variant="h4">Anmeldedaten festlegen</Typography>
          {emailTaken ? (
            <Typography
              variant="body1"
              style={{ color: "red", marginTop: "20px" }}
            >
              Die E-Mail Adresse wird bereits verwendet. Du kannst beim Login
              das Passwort zurücksetzen.
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={8} style={{ marginTop: "20px" }}>
          <HookFormTextField
            required
            control={control}
            name={"email"}
            error={errors.email != null}
            label={"E-Mail*"}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <HookFormPasswordField
            control={control}
            name={"password"}
            error={errors.password != null}
            label={"Passwort*"}
          />
        </Grid>
        <Grid item xs={false} md={4} />
        <Grid item xs={12} md={8}>
          <Controller
            name="effectaTermsAccepted"
            control={control}
            defaultValue={false}
            rules={{
              required:
                "Bitte bestätige die AGB und die Kundeninformation der Effecta GmbH.",
            }}
            render={({ field: { onChange, value } }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                />

                <Typography variant="body2" style={{ fontSize: "12px" }}>
                  Die inVenture Capital iVC GmbH wird bei der Vermittlung von
                  Finanzinstrumenten ausschließlich im Namen, auf Rechnung und
                  unter der Haftung des Wertpapierinstituts Effecta GmbH tätig.
                  Ich habe die{" "}
                  <PdfLinkOverlay
                    title="Effecta AGB"
                    pdf="https://inventurestorage.blob.core.windows.net/platform/effecta_terms_of_service.pdf"
                  >
                    AGB
                  </PdfLinkOverlay>{" "}
                  und die{" "}
                  <PdfLinkOverlay
                    title="Effecta Kundeninformation"
                    pdf="https://inventurestorage.blob.core.windows.net/platform/kundeninformationen_effecta.pdf"
                  >
                    Kundeninformationen
                  </PdfLinkOverlay>{" "}
                  der Effecta GmbH gelesen und nehme diese zur Kenntnis.*
                </Typography>
              </div>
            )} // props contains: onChange, onBlur and value
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            maxWidth: "400px",
          }}
        >
          <ReCAPTCHA
            size="invisible"
            ref={captchaRef}
            sitekey="6LeugawgAAAAADskSY95KPFNNOgyHSI9d7FdRxok"
          />
        </Grid>
        <Grid item xs={12} style={{ display: "flex" }}>
          <MultistepFormButtons
            style={{ bottom: "0px" }}
            back={true}
            backFunction={() => back && back()}
            nextButtonText="Account erstellen"
            disabled={!formState.isValid}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            variant="body2"
            style={{ fontSize: "12px", marginBottom: "15px" }}
          >
            Mit der Registrierung erteile ich meine Einwilligung in die
            Verarbeitung meiner personenbezogenen Daten zu Zwecken der Nutzung
            der Applikation, sowie der Kontaktaufnahme und/oder zum Versand von
            Newslettern und/oder von Einladungen. Ich kann die
            datenschutzrechtliche Einwilligung jederzeit mit Wirkung für die
            Zukunft durch die Betätigung eines Abmeldelinks widerrufen. Mit der
            Nutzung der Web-Applikation bestätige ich, die{" "}
            <PdfLinkOverlay
              title="Datenschutzerklärung"
              pdf="https://inventurestorage.blob.core.windows.net/platform/privacy_policy.pdf"
            >
              Datenschutzerklärung
            </PdfLinkOverlay>{" "}
            sowie die{" "}
            <PdfLinkOverlay
              title="AGB"
              pdf="https://inventurestorage.blob.core.windows.net/platform/terms_of_service.pdf"
            >
              Geschäftsbedingungen
            </PdfLinkOverlay>{" "}
            der inVenture Capital iVC GmbH gelesen und verstanden habe.
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
}
