import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

type StepWrapperProps = {
  activestep: number;
  step: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

const spring = {
  type: "spring",
  stiffness: 300,
  damping: 30,
};

export default function StepWrapper({
  style,
  activestep,
  step,
  children,
}: StepWrapperProps) {
  const [isShown, setIsShown] = useState(activestep == step);

  useEffect(() => {
    if (activestep == step) {
      setTimeout(() => {
        setIsShown(true);
      }, 300);
    } else {
      setTimeout(() => {
        setIsShown(false);
      }, 300);
    }
  }, [activestep]);

  return (
    <motion.div
      style={{
        display: isShown ? "block" : "none",
        maxWidth: "800px",
        width: "100%",
        marginRight: "10px",
        position: "absolute",
        marginTop:
          activestep > step ? "-400vh" : activestep < step ? "400vh" : "0",
        zIndex: 0,
        ...style,
      }}
      layout
      transition={spring}
    >
      {children}
    </motion.div>
  );
}

export function StepWrapperStatic({
  style,
  activestep,
  step,
  children,
}: StepWrapperProps) {
  const [isShown, setIsShown] = useState(activestep == step);

  useEffect(() => {
    if (activestep == step) {
      setTimeout(() => {
        setIsShown(true);
      }, 300);
    } else {
      setTimeout(() => {
        setIsShown(false);
      }, 300);
    }
  }, [activestep]);

  return (
    <div
      style={{
        display: isShown ? "block" : "none",
        maxWidth: "800px",
        width: "100%",
        marginRight: "10px",
        zIndex: 0,
        ...style,
      }}
    >
      {isShown ? children : null}
    </div>
  );
}
