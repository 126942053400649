import { Amount, CURRENCY } from "../types/investment_types";

const zero_amount: Amount = {
  amount: "000",
  decimals: 2,
  currency: CURRENCY.EUR,
};

export default abstract class AmountUtils {
  static toString(amount: Amount | undefined, noComma?: boolean): string {
    if (amount === undefined || amount === null) return "";
    var number = amount.amount;
    if (Number(number) == 0) return "";
    var numberLength = number.length;
    var decimals = amount.decimals;

    var dot_position = numberLength - decimals;
    var integer_value = number.slice(0, dot_position);
    var fractional_value = number.slice(dot_position, dot_position + 2);

    var amountString = `${Number(integer_value).toLocaleString(
      "de-de"
    )},${fractional_value} €`;

    if (noComma) {
      amountString = `${Number(integer_value).toLocaleString("de-de")} €`;
    }
    return amountString;
  }

  static toStringNoDigits(
    amount: Amount | undefined,
    noComma?: boolean
  ): string {
    if (amount === undefined || amount === null) return "";
    var number = amount.amount;
    if (Number(number) == 0) return "";
    var numberLength = number.length;
    var decimals = amount.decimals;

    var dot_position = numberLength - decimals;
    var integer_value = number.slice(0, dot_position);
    var fractional_value = number.slice(dot_position, dot_position + 2);

    var amountString = `${Number(integer_value).toLocaleString("de-de")} €`;

    if (noComma) {
      amountString = `${Number(integer_value).toLocaleString("de-de")} €`;
    }
    return amountString;
  }

  static add(amount_a: Amount, amount_b: Amount): Amount {
    console.log(amount_a, amount_b);
    if (amount_a === undefined || amount_b === undefined) return zero_amount;
    var newAmount: Amount = {
      amount: (Number(amount_a.amount) + Number(amount_b.amount)).toString(),
      decimals: amount_a.decimals,
      currency: CURRENCY.EUR,
    };
    return newAmount;
  }
}
