import { Chip, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import InvestmentStatusChip from "../../../components/data-display/investment-status-chip";
import { INVESTMENT_STATE } from "../../../types/investment_types";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from "../../../theme";
import { Asset } from "../../../types/generic_types";

export enum INVESTMENT_TYPE {
  PLATFORM,
  COINVEST,
}

export interface Transaction {
  type: INVESTMENT_TYPE;
  id: string;
  fundId: string;
  fundName: string;
  campaignId: string;
  state?: INVESTMENT_STATE;
  signedAt: Date | null;
  offerCreatedAt: Date | null;
  amount: number;
  documents?: Asset[];
}

interface TransactionProps {
  transaction: Transaction;
}

export default function Transaction({ transaction }: TransactionProps) {
  const t = (
    <div
      style={{
        width: "calc(100% - 10px)",
        display: "flex",
        flexDirection: "row",
        marginTop: "0px",
        height: "40px",
        alignItems: "center",
        borderRadius: "5px",
        padding: "5px",
        color: "rgb(80,80,80)",
      }}
    >
      <Grid container alignItems="center">
        <Grid item md={2} sx={{ display: { md: "block", xs: "none" } }}>
          <Typography variant="body1">
            <b>
              {transaction.signedAt
                ? new Date(transaction.signedAt).toLocaleDateString()
                : null}
            </b>
          </Typography>
        </Grid>
        <Grid item xs={7} md={3}>
          <Typography variant="body1">
            <b>{transaction.fundName}</b>
          </Typography>
        </Grid>
        <Grid item xs={3} md={2}>
          <Typography variant="body1">
            <b>{transaction.amount.toLocaleString("de-de")}€</b>
          </Typography>
        </Grid>
        <Grid item md={2} sx={{ display: { md: "block", xs: "none" } }}>
          <Typography variant="body1" style={{ textAlign: "right" }}>
            {transaction.state ? (
              <InvestmentStatusChip
                state={transaction.state}
              ></InvestmentStatusChip>
            ) : (
              <Chip
                label={"Co-Investment"}
                style={{
                  backgroundColor: theme.palette.primary.dark,
                  color: "white",
                  padding: "0 9px 0 10px",
                }}
              />
            )}
          </Typography>
        </Grid>
        <Grid item xs={2} md={3}>
          <Typography variant="body1" style={{ textAlign: "right" }}>
            {transaction.type == INVESTMENT_TYPE.PLATFORM ? (
              <Link
                to={"/portal/investment?id=" + transaction.id}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  color: "rgb(80,80,80)",
                  justifyContent: "flex-end",
                }}
              >
                <b>Details</b>
                <ArrowForwardIosIcon />
              </Link>
            ) : null}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );

  if (transaction.type == INVESTMENT_TYPE.PLATFORM)
    return (
      <motion.div whileHover={{ scale: 1.01 }} transition={{ duration: 0.2 }}>
        <Link
          to={"/portal/investment?id=" + transaction.id}
          style={{ textDecoration: "none", color: "rgb(80,80,80)" }}
        >
          {t}
        </Link>
      </motion.div>
    );

  return t;
}
