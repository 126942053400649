
import { Button, ButtonGroup, Dialog, DialogActions, Typography } from "@mui/material";
import React, { useState } from "react";
// using ES6 modules
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PdfOverlayProps = {
  title: string;
  pdf: string;
  open: boolean;
  handleClose: (close: boolean) => void;
};

export default function PdfOverlay({ title, pdf, open, handleClose }: PdfOverlayProps) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  var isWideScreen = window.innerWidth > window.innerHeight;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{
        margin: "0 auto 0 auto",
        padding: "0",
      }}
    >
      <div style={{width: "100%", backgroundColor: "rgb(150,150,150)", color: "white", textAlign: "center"}}><Typography variant="body1">Vorschau</Typography></div>
      <div style={{ backgroundColor: "rgb(250,250,250)", overflow: "hidden" }}>
        <ButtonGroup style={{ position: "absolute", zIndex: 100, marginLeft: "50%", transform: "translateX(-50%)", bottom: "50px", backgroundColor: "white" }}>
          <Button disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>
            {"<"}
          </Button>
          <Button disabled={pageNumber >= numPages!} onClick={() => setPageNumber(pageNumber + 1)}>
            {">"}
          </Button>
        </ButtonGroup>
        <Document onLoadSuccess={(numPages: any) => onDocumentLoadSuccess(numPages)} file={pdf}>
          <Page pageNumber={pageNumber} height={isWideScreen? window.innerHeight * 0.6 : 0} width={!isWideScreen? window.innerWidth * 0.8 : 0} />
        </Document>
      </div>

      <DialogActions style={{ padding: "0px" }}>
        <Button color="primary" variant="contained" href={pdf} target="blank" style={{ width: "100%", borderRadius: "0 0 3px 3px" }}>
          Zum Dokument
        </Button>
      </DialogActions>
    </Dialog>
  );
}
