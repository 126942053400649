import React, { useState } from "react";
import IBAN from "iban";
import API_CLIENT from "../../utility/api-client";
import {
  TaxInformation,
  InventureUser,
} from "../../types/inventure-user_types";
import { AppContext } from "../../pages/portal/auth-router";
import PrimaryButton from "../input-elements/buttons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  TextField,
  DialogActions,
} from "@mui/material";

type SetTaxDialogProps = {
  user: InventureUser;
  open: boolean;
  handleClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  submit: Function;
};

export default function SetTaxDialog({
  user,
  submit,
  open,
  handleClose,
}: SetTaxDialogProps) {
  const [owner] = useState(
    user.natural_person.forename + " " + user.natural_person.surname
  );
  const [error, setError] = useState("");
  const [taxIdentificationNumber, setTaxIdentificationNumber] = useState("");
  const [nonAssessmentCertificate, setNonAssessmentCertificate] = useState("");
  const setTaxInformation = (token: String) => {
    if (owner.length === 0) {
      setError("Kontoinhaber nicht angegeben");
      return;
    }
    setError("");

    var taxInformation: TaxInformation = {
      tax_identification_number: taxIdentificationNumber,
      non_assessment_certificate: nonAssessmentCertificate,
    };
    //   API_CLIENT.putBankAccount(
    //     token.toString(),
    //     bankAccount,
    //     () => submit(),
    //     () => setError("sending Data Error")
    //   );
  };

  return (
    <AppContext.Consumer>
      {(appContext) =>
        AppContext && (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="tax-dialog">{"Deine Taxdaten"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Aus rechtlichen Gründen darf der Kontoinhaber nicht vom
                        Nutzer abweichen.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Kontoinhaber: {owner}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={taxIdentificationNumber}
                        label="Tax Identification Number"
                        style={{ width: "100%" }}
                        onChange={(event) =>
                          setTaxIdentificationNumber(
                            event.target.value.toUpperCase()
                          )
                        }
                      ></TextField>
                    </Grid>

                    {error !== "" ? (
                      <Grid item xs={12}>
                        <Typography variant="body2" style={{ color: "red" }}>
                          {error}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <PrimaryButton
                style={{ width: "200px", height: "50px", margin: "15px" }}
                onClick={() => setTaxInformation(appContext.token)}
              >
                Edit
              </PrimaryButton>
            </DialogActions>
          </Dialog>
        )
      }
    </AppContext.Consumer>
  );
}
