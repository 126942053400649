import { Box, SxProps, Theme } from "@mui/material";
import React from "react";
import theme from "../../../theme";

interface IvBoxProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>
}

export default function IvBox({children, style, sx}: IvBoxProps) {
  return (
    <Box
      sx={sx}
      style={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: "5px",

        boxShadow: "black 0 0 30px -25px",
        padding: "20px",
        ...style
      }}
    >{children}
    </Box>
  );
}
