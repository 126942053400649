import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { NewsCardv2 } from "../components/data-display/news-card";
import { AppContext } from "./portal/auth-router";

export default function NewsPage() {
  const appContext = useContext(AppContext);
  return (
    <Box
      sx={{
        padding: {
          xs: "40px 20px",
          lg: "50px 0px",
        },
      }}
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "1200px", width: "100%" }}>
        <Typography variant="h2">News unserer Zielfonds</Typography>
        <Box
          style={{ marginTop: "50px" }}
          sx={{
            display: "grid",
            gap: 5,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            },
          }}
        >
          {appContext.news.map((news) => (
            <NewsCardv2 news={news} />
          ))}
        </Box>
      </div>
    </Box>
  );
}
