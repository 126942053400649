import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { error } from "console";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../pages/portal/auth-router";
import { Investment } from "../../../types/investment_types";
import API_CLIENT from "../../../utility/api-client";

type AbortInvestmentProps = {
  investment: Investment;
};

export default function AbortInvestment({ investment }: AbortInvestmentProps) {
  const [open, setOpen] = useState(false);
  const appContext = useContext(AppContext);

  return (
    <>
      <Typography
        onClick={() => setOpen(true)}
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        Investition abbrechen
      </Typography>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="abbruch-dialog">{"Investition Abbrechen"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body2">
              Möchtest Du die Investition wirklich abbrechen?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", flexDirection: "row" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            Nein
          </Button>
          <div style={{ flexGrow: 1 }} />
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              API_CLIENT.abortInvestment(
                appContext.token,
                investment.id,
                () => (window.location.href = "/portal/products"),
                () => console.log("error")
              );
            }}
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
