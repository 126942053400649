import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IOSSwitch } from "../components/input-elements/ios-switch";
import PrimaryButton from "../components/input-elements/buttons";
import API_CLIENT from "../utility/api-client";
import { useAuth0 } from "@auth0/auth0-react";
import { scroller } from "react-scroll";

type RABulletPointProps = {
  id?: string;
  header: string;
  text1: string;
  text2: string;
  visible: boolean;
  onChange: (checked: boolean) => void;
};

function RABulletPoint({
  id,
  header,
  text1,
  text2,
  visible,
  onChange,
}: RABulletPointProps) {
  return (
    <div
      id={id}
      style={{
        textAlign: "left",
        marginTop: "50px",
        marginBottom: "50px",
        display: visible ? "block" : "block",
      }}
    >
      <Typography variant="h3">{header}</Typography>
      <Typography variant="body1" style={{ marginTop: "10px" }}>
        {text1}
      </Typography>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <IOSSwitch onChange={(event) => onChange(event.target.checked)} />
        <Typography variant="body1" style={{ marginLeft: "10px" }}>
          <b>{text2}</b>
        </Typography>
      </div>
    </div>
  );
}

export default function RiskAssessment() {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccesstoken] = useState("");
  const [firstAccepted, setFirstAccepted] = useState(false);
  const [secondAccepted, setSecondAccepted] = useState(false);
  const [thirdAccepted, setThirdAccepted] = useState(false);
  const [fourthAccepted, setFourthAccepted] = useState(false);
  const [fifthAccepted, setFifthAccepted] = useState(false);
  const [sixthAccepted, setSixthAccepted] = useState(false);
  const [seventhAccepted, setSeventhAccepted] = useState(false);

  useEffect(() => {
    const getUserMetadata = async () => {
      const accessToken = await getAccessTokenSilently({});
      setAccesstoken(accessToken);
    };
    getUserMetadata();
  }, []);

  const allAccepted =
    firstAccepted &&
    secondAccepted &&
    thirdAccepted &&
    fourthAccepted &&
    fifthAccepted &&
    sixthAccepted &&
    seventhAccepted;

  const scrollToId = (id: string) => {
    scroller.scrollTo(id, {
      offset: -100,
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 0px 150px 0px",
      }}
    >
      <div
        style={{
          backgroundImage:
            'linear-gradient(to right, rgba(255, 248, 238, 0.8), rgba(255, 248, 238, 0.8)), url("https://ik.imagekit.io/inventure/App/static/tr:e-grayscale/photo-1517483000871-1dbf64a6e1c6_LrHyrszg_s.jfif?updatedAt=1641413039964")',
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            width: "calc(100vw - 20px)",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10px 0 10px",
          }}
        >
          <Typography
            sx={{ display: { xs: "none", md: "block" } }}
            variant="h3"
            style={{ marginTop: "100px" }}
          >
            Werde Teil der inVenture Capitalist Community &#128075;
          </Typography>
          <Typography
            sx={{ display: { xs: "block", md: "none" } }}
            variant="h4"
            style={{ marginTop: "50px" }}
          >
            <b>Werde Teil der inVenture Capitalist Community</b> &#128075;
          </Typography>
          <Box
            sx={{ marginBottom: { xs: "50px", ms: "100px" } }}
            style={{
              padding: "0 20px",
              margin: "auto",
              maxWidth: "500px",
              width: "calc(100%-40px)",
              marginTop: "50px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "150px",
                textAlign: "center",
              }}
            >
              <img
                style={{ maxWidth: "60px", marginBottom: "10px" }}
                src="https://ik.imagekit.io/inventure/App/static/number-one_uCPGfVK_x.png?updatedAt=1641410995384"
              />
              <Typography variant="subtitle1">
                <b>Risikohinweise</b>
              </Typography>
            </div>
            <div>
              <img
                src="https://ik.imagekit.io/inventure/App/static/right-arrow_Lpt6ZZ9QQ.png?updatedAt=1641411646488"
                style={{ maxWidth: "60px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "150px",
                textAlign: "center",
              }}
            >
              <img
                style={{ maxWidth: "60px", marginBottom: "10px" }}
                src="https://ik.imagekit.io/inventure/App/static/number-2__1__RJ0s045Dy.png?updatedAt=1641411159236"
              />
              <Typography variant="subtitle1">
                <b>Zu den Produkten</b>
              </Typography>
            </div>
          </Box>
          <Typography
            sx={{
              textAlign: { xs: "left", md: "center" },
              marginBottom: { xs: "50px", md: "100px" },
            }}
            variant="body1"
            style={{ marginTop: "50px" }}
          >
            Um über inVenture Investitionen tätigen zu können, solltest Du die
            grundlegenden Risiken von Growth und Venture Capital verstehen. Bei
            den angebotenen Produkten handelt es sich um Produkte mit sehr hohem
            Risiko, die sowohl illiquide sind als auch zum Totalverlust des
            eingesetzten Kapitals führen können. Bitte lies dir dafür die
            folgenden Texte durch.
          </Typography>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <PrimaryButton
              onClick={() => scrollToId("first-question")}
              style={{ width: "90vw", position: "relative", bottom: "10px" }}
            >
              <Typography variant="h6">Start</Typography>
            </PrimaryButton>
          </Box>

          <div style={{ display: "flex", justifyContent: "center" }}></div>
        </div>
      </div>

      <Grid
        container
        style={{
          maxWidth: "1080px",
          textAlign: "center",
          padding: "0 10px 0 10px",
        }}
        justifyContent="center"
      >
        <RABulletPoint
          id="first-question"
          visible={!firstAccepted}
          header="1. Digitale Wertpapiere"
          text1="Die Angebote auf inVenture stellen keine direkten Beteiligungen an Fonds dar. Mit einer Investition erwirbst Du ein digitales Wertpapier, welches durch eine zwischengeschaltete Zweckgesellschaft emittiert wird. Die Zweckgesellschaft nimmt die Rolle der Investorin in den Fonds ein."
          text2="Ich verstehe, dass die angebotenen Wertpapieren keine direkte Investition in einen Fonds darstellen."
          onChange={(checked) => {
            setFirstAccepted(checked);
            scrollToId("second-question");
          }}
        />
        <RABulletPoint
          id="second-question"
          visible={!secondAccepted}
          header="2. Risiko"
          text1="Die auf der Investmentplattform von inVenture angebotenen Produkte sind mit einem erheblichen Risiko verbunden. Investitionen auf inVenture sind daher sehr riskant und können zum vollständigen Verlust des eingesetzten Kapitals führen. Du solltest nur einen Betrag investieren, der bei einem Totalverlust keinen Einfluss auf Deinen Lebensstil hat."
          text2="Ich verstehe, dass ich mein investiertes Kapital vollständig verlieren kann.."
          onChange={(checked) => {
            setSecondAccepted(checked);
            scrollToId("third-question");
          }}
        />
        <RABulletPoint
          id="third-question"
          visible={!thirdAccepted}
          header="3. Eingeschränkte Liquidität"
          text1="Die Übertragung der auf inVenture erworbenen digitalen Wertpapiere ist nur eingeschränkt möglich. Darüber hinaus kann es in den ersten Monaten Beschränkungen für den Verkauf, den Handel oder die Übertragung der digitalen Wertpapiere geben."
          text2="Ich verstehe, dass die Übertragung meiner digitalen Wertpapiere schwierig sein kann."
          onChange={(checked) => {
            setThirdAccepted(checked);
            scrollToId("fourth-question");
          }}
        />
        <RABulletPoint
          id="fourth-question"
          visible={!fourthAccepted}
          header="4. Diversifizierung"
          text1="Investitionen auf inVenture sind spekulativ und jede Investition kann zu einem Verlust führen. Auch wenn die Zielfonds bereits eine gewisse Diversifikation vornehmen, kannst Du durch die Investition kleinerer Beträge in mehrere Produkte Dein Risiko im Vergleich zu einer großen Investition in ein einzelnes Produkt verringern."
          text2="Ich verstehe, dass es sicherer ist, mein Kapital auf viele Investitionen zu verteilen."
          onChange={(checked) => {
            setFourthAccepted(checked);
            scrollToId("fifth-question");
          }}
        />
        <RABulletPoint
          id="fifth-question"
          visible={!fifthAccepted}
          header="5. Keine laufende staatliche Aufsicht"
          text1="Bei den auf inVenture angebotenen Produkten handelt es sich um Wertpapiere und nicht um Fonds. Im Gegensatz zur Ausgabe von Fonds unterliegt die Emission von Wertpapieren keiner laufenden staatlichen Aufsicht. Es werden keine gesetzlichen Anforderungen an die Geschäftsorganisation von inVenture und die Auswahl sowie Verwaltung der Zielfonds gestellt."
          text2="Ich habe verstanden, dass inVenture keiner laufenden staatlichen Aufsicht unterliegt."
          onChange={(checked) => {
            setFifthAccepted(checked);
            scrollToId("sixth-question");
          }}
        />
        <RABulletPoint
          id="sixth-question"
          visible={!sixthAccepted}
          header="6. Wenig gesetzlich reguliert"
          text1="Bei den Angeboten auf inVenture handelt es sich um Finanzinnovationen, die sich unter anderem der Blockchain bedienen. Die Blockchaininfrastruktur ist neuartig und bisher in Deutschland nicht so weit verbreitet, wie traditionelle Finanzprodukte. Hieraus resultieren technologische und regulatorische Risiken."
          text2="Ich verstehe, dass die Ausgabe der digitalen Wertpapiere nur wenig gesetzlich reguliert ist."
          onChange={(checked) => {
            setSixthAccepted(checked);
            scrollToId("seventh-question");
          }}
        />
        <RABulletPoint
          id="seventh-question"
          visible={!seventhAccepted}
          header="7. Do your own Research"
          text1="Stelle immer eigene Nachforschungen zu den Produkten an. Lies Dir stets aufmerksam die auf den Produktprofilen und von den Zielfonds zur Verfügung gestellten Unterlagen. Wenn Du Fragen haben oder weitere Informationen benötigen solltest, zögere dennoch nicht uns anzusprechen."
          text2="Ich verstehe, dass ich für die Recherche selbst verantwortlich bin."
          onChange={(checked) => {
            setSeventhAccepted(checked);
            scrollToId("three-things-to-know");
          }}
        />
        <div
          style={{
            textAlign: "left",
            marginTop: "50px",
            marginBottom: "100px",
          }}
          id="three-things-to-know"
        >
          <Typography variant="h5">
            Drei letzte Dinge, die wir Dir gerne noch mitteilen wollen:
          </Typography>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            <Grid
              item
              md={4}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="https://ik.imagekit.io/inventure/App/static/return_0VTTTSJd6a.png?updatedAt=1641415123794"
                style={{ height: "60px" }}
              />
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                Wenn Du investierst und das Angebot bis zum Ende des
                Emissionszeitraum seine Fundingschwelle nicht erreicht, erhältst
                Du eine Rückzahlung von 100%.
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="https://ik.imagekit.io/inventure/App/static/security_F5WFkmJWg.png?updatedAt=1641415251255"
                style={{ height: "60px" }}
              />
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                Die Emittenten von denen Du die Wertpapiere erwirbst, sind
                rechtlich zu 100% unabhängig von inVenture. Deine Investition
                ist vor dem unwahrscheinlichen Fall, dass inVenture in Konkurs
                gerät, sicher und geschützt.
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="https://ik.imagekit.io/inventure/App/static/traffic-signal_6sQ2BYE5M3P.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642496898040"
                style={{ height: "60px" }}
              />
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                Auch wenn die Investitionen über inVenture das Risiko eines
                Totalverlusts bergen, sind die angebotenen Produkte jedoch stets
                von einer Nachschusspflicht ausgeschlossen.
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!allAccepted ? (
            <Typography variant="body1">
              <b>
                Um zu den Produkten zu gelangen musst Du alle Punkte zur
                Kenntnis nehmen.
              </b>
            </Typography>
          ) : (
            <div style={{ height: "24px" }} />
          )}
          <PrimaryButton
            disabled={!allAccepted}
            style={{ padding: "00px 40px", width: "300px" }}
            onClick={() =>
              API_CLIENT.postRiskConfimed(
                accessToken,
                () => window.location.reload(),
                () => null
              )
            }
          >
            Zu den Produkten
          </PrimaryButton>
        </div>
      </Grid>
    </div>
  );
}
