import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import theme from "../../theme";
import { News } from "../../types/vc-platform/news_types";

type NewsCardProps = {
  style?: React.CSSProperties;
  news: News;
};
export default function NewsCard({ style, news }: NewsCardProps) {
  return (
    <a
      href={news.link}
      target="blank"
      style={{ textDecoration: "none", color: "black", ...style }}
    >
      <motion.div
        style={{
          cursor: "pointer",
          width: "calc(100%)",
          backgroundColor: "white",
          borderRadius: "5px",
          overflow: "hidden",
        }}
        transition={{ duration: 0.2, ease: "easeOut" }}
        whileHover={{
          scale: 1.01,
        }}
      >
        <div
          style={{
            backgroundImage: `url(${news.image?.url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "150px",
          }}
        >
          {news.startup?.logo ? (
            <div
              style={{
                width: "60px",
                height: "50px",
                backgroundColor: "rgba(254, 250, 244, 0.9)",
                borderRadius: "5px",
                margin: "5px",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={news.startup?.logo?.url} style={{ width: "80%" }} />
            </div>
          ) : null}
        </div>
        <div
          style={{
            height: "170px",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <div style={{ padding: "10px 10px" }}>
            <div
              style={{
                display: "flex",
                color: "rgb(100,100,100)",
                marginBottom: "10px",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{ fontSize: "13px", fontWeight: 700 }}
              >
                {news.medium}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ marginLeft: "10px", fontSize: "13px" }}
              >
                {new Date(news.published).toLocaleDateString()}
              </Typography>
            </div>
            <Typography style={{ fontSize: "17px", fontWeight: 800 }}>
              {news.headline}
            </Typography>
          </div>
        </div>
      </motion.div>
    </a>
  );
}

export function NewsCardv2({ style, news }: NewsCardProps) {
  return (
    <a
      href={news.link}
      target="blank"
      style={{ textDecoration: "none", color: "black", ...style }}
    >
      <motion.div
        style={{
          cursor: "pointer",
          width: "calc(100%)",
          backgroundColor: theme.palette.background.default,
          boxShadow: "black 0 0 50px -40px",
          overflow: "hidden",
        }}
        transition={{ duration: 0.2, ease: "easeOut" }}
        whileHover={{
          scale: 1.01
        }}
      >
        <div
          style={{
            backgroundImage: `url(${news.image?.url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "170px",
          }}
        ></div>
        <div
          style={{
            height: "270px",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                color: "rgb(100,100,100)",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  height: "20px",
                  padding: "10px 10px",
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <img src={news.startup?.logo?.url} style={{ height: "80%" }} />
                <div style={{flexGrow: 1}}/>
                {news.funds? 
                <img src={news.funds[0]?.logo?.url} style={{ height: "80%" }} />
                : null }
              </div>
            </div>
            <div style={{ padding: "0px 10px", display: "flex", flexDirection: "column", height: "210px" }}>
              <Typography style={{ fontSize: "20px", fontWeight: 500 }}>
                {news.headline}
              </Typography>
              <Typography style={{ overflow: "hidden", fontSize: "14px", fontWeight: 500,   WebkitLineClamp: 5, display: "-webkit-box", WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis", }}>
                {news.teaser}
              </Typography>
              <div style={{flexGrow: 1}}/>
              <div style={{ display: "flex" }}>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: "13px", fontWeight: 700 }}
                >
                  {news.medium}
                </Typography>
                <div style={{flexGrow: 1}}/>
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", fontSize: "13px" }}
                >
                  {new Date(news.published).toLocaleDateString()}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </a>
  );
}

export function NewsCardWide({ style, news }: NewsCardProps) {
  return (
    <a
      href={news.link}
      target="blank"
      style={{ textDecoration: "none", color: "black", ...style }}
    >
      <motion.div
        style={{
          cursor: "pointer",
          borderRadius: "5px",
          overflow: "hidden",
          width: "calc(100%)",
          backgroundColor: "white",
          float: "right",
        }}
        transition={{ duration: 0.2, ease: "easeOut" }}
        whileHover={{
          scale: 1.01,
        }}
      >
        <div
          style={{
            backgroundImage: `url(${news.image?.url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "150px",
          }}
        >
          {news.startup?.logo ? (
            <div
              style={{
                width: "60px",
                height: "50px",
                backgroundColor: theme.palette.background.default,
                borderRadius: "5px",
                margin: "5px",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={news.startup?.logo?.url} style={{ width: "80%" }} />
            </div>
          ) : null}
        </div>
        <Box
          sx={{ height: { xs: "auto", md: "170px" } }}
          style={{
            backgroundColor: theme.palette.background.paper,
            minHeight: "170px",
          }}
        >
          <div style={{ padding: "10px 20px", height: "calc(100% - 20px)" }}>
            <div
              style={{
                display: "flex",
                color: "rgb(100,100,100)",
                marginBottom: "5px",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{ fontSize: "13px", fontWeight: 700 }}
              >
                {news.medium}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ marginLeft: "10px", fontSize: "13px" }}
              >
                {new Date(news.published).toLocaleDateString()}
              </Typography>
            </div>
            <Typography style={{ fontSize: "18px", fontWeight: 800 }}>
              {news.headline}
            </Typography>
            <Typography
              style={{
                display: "-webkit-box",
                fontSize: "14px",
                fontWeight: 500,
                marginTop: "5px",
                height: "60px",
                overflow: "hidden",
                WebkitLineClamp: 3,
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
              }}
            >
              {news.teaser}
            </Typography>
          </div>
        </Box>
      </motion.div>
    </a>
  );
}
