import React, { useState } from "react";
import { alpha, Box } from "@mui/material";
import theme from "../../theme";

type MultistepFormNavigationProps = {
  children?: React.ReactNode;
  backgroundImage?: string;
  opacity?: number;
};

export default function MultistepFormNavigation({
  children,
  backgroundImage,
  opacity,
}: MultistepFormNavigationProps) {
  if (opacity == undefined) opacity = 0.01;
  return (
    <Box
      className="multistep-form-navigation"
      sx={{
        width: { md: "30%", xl: "calc(50% - 200px)" },
        background: {
          xs: "",
          md: `url(${backgroundImage})`,
        },
      }}
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
      }}
    >
      <Box
        sx={{
          background: {
            xs: "",
            md: `linear-gradient(178deg, ${alpha(
              theme.palette.secondary.dark,
              opacity
            )} 0%, ${alpha(theme.palette.secondary.dark, opacity)} 80%)`,
          },
        }}
        className="stepper"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export function MultistepFormNavigationV2({
  children,
  backgroundImage,
  opacity,
}: MultistepFormNavigationProps) {
  if (opacity == undefined) opacity = 0.01;
  return (
    <Box
      className="multistep-form-navigation p-0 sm:p-4 "
      sx={{
        minWidth: { md: "300px" },
        background: {
          xs: "",
          md: `url(${backgroundImage})`,
        },
      }}
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
      }}
    >
      <Box
        sx={{
          background: {
            xs: "",
            md: `linear-gradient(178deg, ${alpha(
              theme.palette.secondary.dark,
              opacity
            )} 0%, ${alpha(theme.palette.secondary.dark, opacity)} 80%)`,
          },
        }}
        className="stepper sticky top-4"
        style={{
          width: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
