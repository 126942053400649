export default abstract class Normalizer {
    static normalizePhone(phone: string): string {
        var phoneNumber = phone
        phoneNumber = phoneNumber.replace(/[-()^]/g, '');
        var firstChar = phoneNumber?.charAt(0)
        var secondChar = phoneNumber?.charAt(1)
      
        if (firstChar == "0") {
            if (secondChar == "0") {
              phoneNumber = phoneNumber?.substring(2,phoneNumber.length)
            }
            else {
              phoneNumber = "49" + phoneNumber?.substring(1, phoneNumber.length)
            }
        }
        if (firstChar == "+") {
            phoneNumber = phoneNumber?.substring(1, phoneNumber?.length)
        }
        return phoneNumber
    }

    static normalizeMail(mail: string): string {
      return mail.toLowerCase()
    }
}