import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import HookFormTextField, {
  HookFormCompanyTypeSelect,
  HookFormCompanyTypeSelectV2,
  HookFormCountrySelect,
  HookFormFile,
} from "../../../components/hook-form-inputs";
import PrimaryButton from "../../../components/input-elements/buttons";
import LoadingScreen from "../../../components/loading-screen";
import MultistepForm from "../../../components/vertical-multistep-form/multistep-form";
import MultistepFormBody from "../../../components/vertical-multistep-form/multistep-form-body";
import MultistepFormNavigation from "../../../components/vertical-multistep-form/multistep-form-navigation";
import MultistepFormStep from "../../../components/vertical-multistep-form/multistep-form-step";
import VerticalStepper from "../../../components/vertical-multistep-form/vertical-stepper";
import {
  LegalPerson,
  LEGAL_ENTITY_TYPE,
} from "../../../types/inventure-user_types";
import { EDUCATION, PROFESSION } from "../../../types/investment_types";
import API_CLIENT from "../../../utility/api-client";
import { AppContext } from "../auth-router";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import countries from "i18n-iso-countries";
import EnumConverter from "../../../utility/enum-converter";
import CountryUtils from "../../../utility/country-utils";
import CloseIcon from "@mui/icons-material/Close";
import MultistepFormButtons from "../../../components/vertical-multistep-form/multistep-form-buttons";
import ResponsiveDialog from "../../../components/dialogs/responsive-dialog";
import BenefitingPersonManager from "../../../components/data-display/benefiting-persons";

export default function CompanyOnboarding() {
  const appContext = useContext(AppContext);
  const steps = [
    "Unternehmensdaten",
    "Gesellschaftsunterlagen",
    "Wirtschaftlich Berechtigte",
  ];

  const COMPANY_DATA_STEP = 0;
  const DOCUMENT_STEP = 1;
  const BENEFITIARY_STEP = 2;

  const [activeStep, setActiveStep] = useState(COMPANY_DATA_STEP);
  const [highestStep, setHighestStep] = useState(COMPANY_DATA_STEP);
  const [blockto, setBlockto] = useState(0);
  const [user, setUser] = useState(appContext.user);
  const [loading, setLoading] = useState(true);

  var company: LegalPerson;
  var companyDataString = localStorage.getItem("company-form-company-data");

  if (companyDataString != null) {
    company = JSON.parse(companyDataString);
  }

  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({ mode: "onChange" });

  const updateStep = (step: number) => {
    setActiveStep(step);
    setHighestStep(step);
  };

  const chooseStep = () => {
    var chosenStep = COMPANY_DATA_STEP;
    const legalPerson = user?.legal_person;
    if (company != null || legalPerson != null) {
      chosenStep = DOCUMENT_STEP;
      if (
        legalPerson?.company_identity_proof_id &&
        legalPerson?.company_identity_proof_id
      ) {
        chosenStep = BENEFITIARY_STEP;
        setBlockto(BENEFITIARY_STEP);
      }
    }
    updateStep(chosenStep);
    setLoading(false);
  };

  useEffect(() => {
    chooseStep();
  }, []);

  const uploadLegalPerson = (structureFile: File, identityFile: File) => {
    var company: LegalPerson;
    var companyDataString = localStorage.getItem("company-form-company-data");
    if (user?.legal_person != null) {
      company = user?.legal_person;
    } else if (companyDataString != null) {
      company = JSON.parse(companyDataString);
    } else {
      return;
    }

    API_CLIENT.postLegalPerson(
      appContext.token,
      (user) => {
        API_CLIENT.postLegalPersonFiles(
          appContext.token,
          () => {
            appContext.updateUser(user);
            setUser(user);
            updateStep(BENEFITIARY_STEP);
            setBlockto(BENEFITIARY_STEP);
            localStorage.removeItem("company-form-company-data");
            window.location.reload();
          },
          structureFile,
          identityFile
        );
      },
      company
    );
  };

  return (
    <>
      {loading ? (
        <LoadingScreen style={{ height: "100vh", width: "100vw" }} />
      ) : (
        <MultistepForm>
          <MultistepFormNavigation>
            <VerticalStepper
              totalSteps={3}
              returnOnFirstStep={false}
              blockTo={blockto}
              activeStep={activeStep}
              highestStep={highestStep}
              onStepClick={(step) => {
                if (step <= highestStep) setActiveStep(step);
              }}
              style={{ width: "100%", minHeight: "100%" }}
              labels={steps}
            />
          </MultistepFormNavigation>
          <MultistepFormBody>
            <MultistepFormStep activeStep={activeStep} step={COMPANY_DATA_STEP}>
              <LegalPersonStep onSuccess={() => updateStep(DOCUMENT_STEP)} />
            </MultistepFormStep>
            <MultistepFormStep activeStep={activeStep} step={DOCUMENT_STEP}>
              <CompanyDocumentsStep
                onSuccess={(structureFile, identityFile) => {
                  setLoading(true);
                  uploadLegalPerson(structureFile, identityFile);
                }}
              />
            </MultistepFormStep>
            <MultistepFormStep activeStep={activeStep} step={BENEFITIARY_STEP}>
              <BeneficiaryPersonStep onSuccess={() => null} />
            </MultistepFormStep>
          </MultistepFormBody>
        </MultistepForm>
      )}
    </>
  );
}

type StepProps = {
  onSuccess: () => void;
  back?: () => void;
};

var person: LegalPerson | null = null;
const personString = localStorage.getItem("company-form-company-data");
if (personString) {
  person = JSON.parse(personString);
}

function LegalPersonStep({ onSuccess, back }: StepProps) {
  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<any>({ mode: "onChange" });

  const onSubmit = (data: LegalPerson) => {
    localStorage.setItem("company-form-company-data", JSON.stringify(data));
    onSuccess();
  };

  return (
    <Box
      sx={{
        height: { xs: `calc(100vh - 70px)`, md: "" },
        marginBottom: { xs: "40px", md: "" },
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          height: "100%",

          flexDirection: "column",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">Unternehmensdaten</Typography>
            <Typography variant="h5">
              Bitte trage die Unternehmensdaten Deiner Gesellschaft ein
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <HookFormTextField
              name="company_name"
              control={control}
              label="Unternehmensname*"
              defaultValue={person?.company_name}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <HookFormCompanyTypeSelect
              name="legal_entity_type"
              control={control}
              label="Unternehmensform*"
              defaultValue={person?.legal_entity_type}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <HookFormTextField
              name="commercial_register_number"
              control={control}
              label="Handelsregisternummer*"
              defaultValue={person?.commercial_register_number}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <HookFormTextField
              name="commercial_register"
              control={control}
              label="Handelsregister*"
              defaultValue={person?.commercial_register}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <HookFormTextField
              name="phone"
              control={control}
              label="Telefonnummer"
              defaultValue={person?.phone}
              error={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HookFormTextField
              name="street"
              control={control}
              label="Straße und Haußnummer*"
              defaultValue={person?.street}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HookFormTextField
              name="city"
              control={control}
              label="Stadt*"
              defaultValue={person?.city}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HookFormTextField
              name="zip"
              control={control}
              label="Postleitzahl*"
              defaultValue={person?.zip}
              error={false}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <HookFormCountrySelect
              name="country"
              control={control}
              label="Land*"
              defaultValue={person?.country}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">* Pflichtfeld</Typography>
          </Grid>
          <Grid item xs={12}>
            <MultistepFormButtons
              back={false}
              backFunction={() => null}
              disabled={!formState.isValid}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

type CompanyDocumentsStepProps = {
  onSuccess?: (structureFile: File, identityFile: File) => void;
  back?: () => void;
};

function CompanyDocumentsStep({ onSuccess, back }: CompanyDocumentsStepProps) {
  const appContext = useContext(AppContext);
  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({ mode: "onChange" });

  const [identityFile, setIdentityFile] = useState<File | null>();
  const [structureFile, setStructureFile] = useState<File | null>();

  const filesUploaded = (): Boolean => {
    return identityFile != null && structureFile != null;
  };

  const onSubmit = (data: any) => {
    uploadFiles();
  };

  const uploadFiles = () => {
    if (identityFile && structureFile) {
      onSuccess && onSuccess(structureFile, identityFile);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        height: "100%",

        flexDirection: "column",
      }}
    >
      <Grid container style={{ width: "calc(100%)" }} spacing={0}>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Typography variant="body1">Gesellschaftsunterlagen</Typography>
          <Typography variant="h6">
            Bitte lade einen aktuellen Handelsregisterauszug (nicht älter als 2
            Monate), sowie die Liste der Gesellschafter hoch
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "calc(100% - 15px)" }}>
            <Typography variant="h6" style={{ fontWeight: 700 }}>
              Handelsregisterauzug
            </Typography>
            <HookFormFile onFileDrop={(file) => setIdentityFile(file)} />
          </div>
        </Grid>
        <Grid
          sx={{
            marginTop: {
              xs: "20px",
              sm: "0",
            },
          }}
          item
          xs={12}
          sm={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "calc(100% - 15px)" }}>
            <Typography variant="h6" style={{ fontWeight: 700 }}>
              Liste der Gesellschafter
            </Typography>
            <HookFormFile onFileDrop={(file) => setStructureFile(file)} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <MultistepFormButtons
            back={back != undefined}
            backFunction={() => (back ? back() : null)}
            disabled={!filesUploaded()}
          />
        </Grid>
      </Grid>
    </form>
  );
}

function BeneficiaryPersonStep({ onSuccess, back }: StepProps) {
  const appContext = useContext(AppContext);
  const user = appContext.user;
  if (user == null) return null;

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Wirtschaftlich Berechtigte</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Du kannst später weitere Personen unter Profil hinzufügen.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "30px" }}>
          <BenefitingPersonManager />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "30px" }}>
          <Typography variant="body2" style={{ marginBottom: "15px" }}>
            Sobald du mindestens eine wirtschaftlich Berechtigte Person
            hinzugefügt hast, kannst du das Onboarding Abschließen.
          </Typography>

          <PrimaryButton
            href="/portal/products"
            style={{ minWidth: "250px" }}
            disabled={user.benefiting_persons.length === 0}
          >
            Onboarding Abschließen
          </PrimaryButton>
        </Grid>
      </Grid>
    </Box>
  );
}
