
import { Dialog, DialogProps, Typography } from "@mui/material";
import React, { useState } from "react";
import { ContentBlock } from "../../types/fund_types";
import ContentBlocks from "../page-components/fund-details/content-blocks";

type OverlayTextProps = {
  header?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  contentBlocks?: ContentBlock[];
};

export default function OverlayText({ header, children, style, contentBlocks }: OverlayTextProps) {
    const [maxWidth] = React.useState<DialogProps['maxWidth']>('md');
    const [open, setOpen] = useState(false)
  if (contentBlocks == undefined) {
    return <Typography variant="body2">Not Found</Typography>;
  }
  const firstBlock = contentBlocks[0];

  return (
    <div>
      <Typography variant="h5" style={{ color: "#494949", fontWeight: 600, marginBottom: "10px" }}>
        {header}
      </Typography>
      <Typography variant="body1" component={'span'}>        <div dangerouslySetInnerHTML={{ __html: firstBlock.textBlock.html }} />
      </Typography>

      {contentBlocks.length > 1 ?
      <Typography variant="body2" style={{ color: "#494949", marginBottom: "10px", textDecoration: "underline", cursor: "pointer" }} onClick={() => setOpen(true)}>
        mehr anzeigen
      </Typography> : null }
      <Dialog open={open} fullWidth={true}  maxWidth={maxWidth} onClose={() => setOpen(false)}>
      <Typography variant="h5" style={{ color: "#494949", fontWeight: 600, margin: "20px" }}>
        {header}
      </Typography>
          <ContentBlocks blocks={contentBlocks}/>
          {children}
      </Dialog>
    </div>
  );
}
