import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { sha256 } from "js-sha256";
import React, { useContext, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { AppContext } from "../../../../pages/portal/auth-router";
import { InvestmentContext } from "../../../../pages/portal/investment/investment-process";
import { InvestmentContextNew } from "../../../../pages/portal/investment/investment-process-new";
import { Campaign } from "../../../../types/fund_types";
import { Document, MatchingDTO } from "../../../../types/generic_types";
import { InventureUser } from "../../../../types/inventure-user_types";
import { Investment } from "../../../../types/investment_types";
import API_CLIENT from "../../../../utility/api-client";
import PdfLinkOverlay from "../../../data-display/pdf-link-overlay";
import IvSwitch from "../../../input-elements/iv-switch";
import LoadingScreen from "../../../loading-screen";
import MultistepFormButtons from "../../../vertical-multistep-form/multistep-form-buttons";
import AbortInvestment from "../abort-investment";

type WalletStepProps = {
  next: () => void;
  back: () => void;
};

export default function WalletStep({ back, next }: WalletStepProps) {
  const appContext = useContext(AppContext);
  const investmentContext = useContext(InvestmentContextNew);
  const fund = investmentContext.fund;
  const campaign = investmentContext.campaign;
  const investment = investmentContext.investment;
  const user = appContext.user;

  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(investment?.tangany_wallet_accepted);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (campaign == null || user == null || investment == null) return null;
  var tanganyTerms: Document | undefined;
  tanganyTerms = campaign.documents.find(
    (document) => document.name == "Tangany AGB"
  );
  const hasTanganyWallet = user.tangany_wallet != null;

  const sendWallet = () => {
    window.scrollTo(0, 0);
    setLoading(true);
    API_CLIENT.putInvestmentWalletAccepted(
      appContext.token,
      investment.id,
      (investment) => {
        track();
        investmentContext.setInvestment(investment);
        next();
        setTimeout(() => setLoading(false), 1000);
      },
      (err) => console.log(err)
    );
  };

  const track = () => {
    var uData = appContext.matchingData;
    var mData: MatchingDTO = {
      event: "Costs",
      eventId: investment.id,
      sourceUrl: window.location.href,
      em: uData?.em,
      ph: uData?.ph,
      fn: uData?.fn,
      ln: uData?.ln,
      product: fund?.name,
      focus: fund?.fokus,
      type: fund?.type,
      value: investment.amount.toString(),
      currency: "EUR",
    };
    API_CLIENT.postMatching(appContext.token, mData);

    TagManager.dataLayer({
      dataLayer: {
        event: "view-costs",
        event_id: investment.id,
        first_name: appContext.user?.natural_person.forename,
        last_name: appContext.user?.natural_person.surname,
        phone: uData?.ph,
        email: sha256(appContext.userEmail),
        product: fund?.name,
        focus: fund?.fokus,
        type: fund?.type,
        value: investment.amount,
        currency: "EUR",
      },
    });
  };

  return (
    <>
      {loading ? (
        <LoadingScreen
          style={{ width: "100%", height: "calc(100vh - 200px)" }}
        />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendWallet();
          }}
        >
          <Grid container style={{ textAlign: "left" }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
                variant="h2"
                style={{
                  fontSize: "32px",
                  fontWeight: 500,
                  marginBottom: "20px",
                }}
              >
                Verwahrung der digitalen Wertpapiere
              </Typography>
              <Typography
                sx={{
                  display: {
                    xs: "block",
                    md: "none",
                  },
                }}
                variant="h2"
                style={{
                  fontSize: "32px",
                  fontWeight: 500,
                  marginBottom: "20px",
                }}
              >
                Wallet
              </Typography>
              <Typography variant="body1">Tangany GmbH</Typography>
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                Für die sichere Verwahrung Deiner {campaign.name} benötigst Du
                ein Wallet. Ein Wallet ist ein digitales Schließfach, ähnlich
                einem Depotkonto, auf das Du jederzeit über unser Dashboard
                zugreifen kannst. Das Wallet stellen wir Dir kostenlos über
                unseren Partner, der Tangany GmbH aus München, zur Verfügung.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",

                marginTop: "30px",
              }}
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  md: "row",
                },
                alignItems: {},
              }}
            >
              <IvSwitch
                style={{}}
                sx={{
                  marginTop: {
                    xs: "30px",
                    md: "0px",
                  },
                  minWidth: {
                    xs: "100px",
                    md: "100px",
                  },
                  maxWidth: {
                    xs: "400px",
                    md: "100px",
                  },
                }}
                defaultValue={accepted}
                onChange={(checked) => setAccepted(checked)}
              />
              {!hasTanganyWallet ? (
                <Typography
                  variant="body1"
                  sx={{
                    marginLeft: {
                      xs: "",
                      md: "20px",
                    },
                  }}
                >
                  Ich habe die{" "}
                  <PdfLinkOverlay title="AGB" pdf={tanganyTerms!!.location}>
                    AGB
                  </PdfLinkOverlay>{" "}
                  der Tangany GmbH gelesen und stimme diesen auch zu. Ich
                  beauftrage inVenture ein kostenloses Wallet bei der Tangany
                  GmbH für mich zu eröffnen.
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  sx={{ marginLeft: { xs: "none", md: "20px" } }}
                >
                  Ich habe bereits ein Wallet zur Verwahrung meiner Tokens von
                  inVenture zur Verfügung gestellt bekommen und bestätige
                  dieses.
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginTop: "50px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <MultistepFormButtons
                disabled={!accepted}
                back
                backFunction={back}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "50px",
              }}
            >
              <AbortInvestment investment={investment} />
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}
