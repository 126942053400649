import React, { useContext, useEffect, useState } from "react";
import { Voucher } from "../../types/voucher_types";
import { motion } from "framer-motion";
import API_CLIENT from "../../utility/api-client";
import { AppContext } from "../../pages/portal/auth-router";
import { Box, Dialog, Divider, Grid, Tooltip, Typography } from "@mui/material";
import theme from "../../theme";

export default function ReferralBanner() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [referralCode, setReferralCode] = useState<string | undefined>();
  const [activeReferral, setActiveReferral] = useState<undefined | Voucher>();
  const appContext = useContext(AppContext);
  const handleTooltipClose = () => {
    setCopied(false);
  };

  const handleTooltipOpen = () => {
    setCopied(true);
  };

  useEffect(() => {
    var voucherString = "";
    var possibleVoucherString = localStorage.getItem("vouchers");
    if (possibleVoucherString != undefined && possibleVoucherString != null) {
      voucherString = possibleVoucherString;
    }
    API_CLIENT.getVouchers(
      appContext.token,
      (vouchers) =>
        setActiveReferral(
          vouchers?.vouchers?.find(
            (voucher) => voucher.campaign == "Referral Kampagne 5"
          )
        ),
      (error) => console.log(error)
    );
  }, []);
  const text =
    "Langfristiger Vermögensaufbau für Dein Portfolio mit inVenture - der ersten Plattform für mittelbare Investitionen in Venture Capital Fonds. Tritt auch der inVenture Capitalist Community bei! Registriere Dich jetzt unter: app.inventure.de/signup und erhalte unter der Angabe meines persönlichen Codes einen Gutschein in Höhe von 50€ bei Deiner ersten Investition: " +
    activeReferral?.code;

  if (activeReferral == undefined) return <></>;
  return (
    <>
      {activeReferral === undefined ? null : (
        <>
          <Box
            sx={{
              borderRadius: {
                md: "0 0 0px 0px",
                xs: "0",
              },
            }}
            style={{
              margin: "auto auto",
              padding: "10px",
              cursor: "pointer",
              background:
                "linear-gradient(90deg, rgb(136, 186, 190) 0%, rgb(74, 120, 134) 100%)",
              width: "calc(100% - 20px)",
            }}
            onClick={() => setDialogOpen(true)}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 500,
                    textAlign: "center",
                    width: "100%",
                    color: "white",
                  }}
                >
                  <motion.div
                    style={{ color: "rgba(0,0,0,0)" }}
                    animate={{ color: "white" }}
                    transition={{ ease: "easeOut", delay: 0.2 }}
                  >
                    Lade Freunde ein und erhaltet jeweils zusammen 100€
                  </motion.div>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            maxWidth="md"
          >
            <div
              style={{ padding: "20px", color: "#22515F", overflowX: "hidden" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 700,
                    marginBottom: "50px",
                    marginTop: "20px",
                    fontSize: "28px",
                  }}
                >
                  Lade Freunde ein und erhaltet jeweils zusammen 100€
                </Typography>
              </div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <motion.div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{ x: "0", opacity: 1 }}
                    transition={{
                      delay: 0.5,
                      ease: "easeInOut",
                    }}
                  >
                    <img
                      src="https://ik.imagekit.io/inventure/App/Icons/megaphone_anoZqiX4u.svg?updatedAt=1634288751893"
                      style={{ width: "80px", height: "80px" }}
                    />
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#5B8E9B",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Typography variant="body1">
                        <b>1</b>
                      </Typography>
                    </div>
                    <Typography variant="h6">
                      <b>Teile Deinen Code</b>
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: "#6699A5", fontSize: "20px" }}
                    >
                      Lade eine:n Freund:in zu inVenture ein
                    </Typography>
                  </motion.div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <motion.div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{ x: "0", opacity: 1 }}
                    transition={{
                      delay: 1,
                      ease: "easeInOut",
                    }}
                  >
                    <img
                      src="https://ik.imagekit.io/inventure/App/Icons/rocket_gQ7KiR4lB.svg?updatedAt=1634288751736"
                      style={{ width: "80px", height: "80px" }}
                    />
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#5B8E9B",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Typography variant="body1">
                        <b>2</b>
                      </Typography>
                    </div>
                    <Typography variant="h6">
                      <b>Dein:e Freund:in investiert</b>
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: "#6699A5", fontSize: "20px" }}
                    >
                      Bei der ersten Investition wird ein Gutschein über 50 €
                      angerechnet
                    </Typography>
                  </motion.div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <motion.div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{ x: "0", opacity: 1 }}
                    transition={{
                      delay: 1.5,
                      ease: "easeInOut",
                    }}
                  >
                    <img
                      src="https://ik.imagekit.io/inventure/App/Icons/save-money_jt9-a1gF80.svg?updatedAt=1634288751718"
                      style={{ width: "80px", height: "80px" }}
                    />
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#5B8E9B",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Typography variant="body1">
                        <b>3</b>
                      </Typography>
                    </div>
                    <Typography variant="h6">
                      <b>Du erhältst 50 €</b>
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: "#6699A5", fontSize: "20px" }}
                    >
                      Sobald die Investition abgeschlossen ist, erhältst du 50
                      € als Cashback
                    </Typography>
                  </motion.div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "40px", marginBottom: "40px" }}
                >
                  <Divider
                    style={{
                      width: "80%",
                      height: "1px",
                      backgroundColor: "#5B8E9B",
                      margin: "auto",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
                  <Typography variant="h5" style={{ color: "#6699A5" }}>
                    <b>DEIN CODE</b>
                  </Typography>
                  <Typography variant="h4">
                    <motion.div whileHover={{ scale: 1.05 }}>
                      <b>{activeReferral?.code}</b>
                    </motion.div>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
                  <Typography variant="h5" style={{ color: "#6699A5" }}>
                    <b>Teile ihn mit Freunden</b>
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() =>
                        navigator.clipboard.writeText(activeReferral?.code)
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <motion.div whileHover={{ scale: 1.05 }}>
                        <img
                          src={
                            "https://ik.imagekit.io/inventure/App/Icons/copy_b4vGE2wQQ.svg?updatedAt=1634286504547"
                          }
                          style={{
                            color: "green",
                            width: "40px",
                            height: "40px",
                          }}
                        />
                      </motion.div>
                    </div>
                    <a
                      href={"https://api.whatsapp.com/send?text=" + text}
                      target="_blank"
                    >
                      {" "}
                      <motion.div whileHover={{ scale: 1.05 }}>
                        <img
                          src={
                            "https://ik.imagekit.io/inventure/App/Icons/whatsapp_fjkki77qa.svg?updatedAt=1634286504413"
                          }
                          style={{
                            color: "green",
                            width: "40px",
                            height: "40px",
                            marginLeft: "15px",
                            marginRight: "15px",
                          }}
                        />
                      </motion.div>
                    </a>
                    <a
                      href={`mailto:friend@beispiel.com?subject=Dein Code für inVenture&body=${text}`}
                    >
                      {" "}
                      <motion.div whileHover={{ scale: 1.05 }}>
                        <img
                          src={
                            "https://ik.imagekit.io/inventure/App/Icons/email_kHdq357Ue.svg?updatedAt=1634286504364"
                          }
                          style={{
                            color: "green",
                            height: "50px",
                            width: "auto",
                          }}
                        />
                      </motion.div>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ marginTop: "40px" }}>
                    Indem Du den Gutscheincode teilst, stimmst Du den{" "}
                    <a
                      href="https://inventurestorage.blob.core.windows.net/platform/referral/Nutzungsbedingungen-Referral.pdf"
                      style={{ color: "black" }}
                      target="blank"
                    >
                      Nutzungsbedingungen
                    </a>{" "}
                    zu.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}

interface ReferralWidgetProps {
  style?: React.CSSProperties;
}
export function ReferraWidget({ style }: ReferralWidgetProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [referralCode, setReferralCode] = useState<string | undefined>();
  const [activeReferral, setActiveReferral] = useState<undefined | Voucher>();
  const appContext = useContext(AppContext);
  const handleTooltipClose = () => {
    setCopied(false);
  };

  const handleTooltipOpen = () => {
    setCopied(true);
  };

  useEffect(() => {
    var voucherString = "";
    var possibleVoucherString = localStorage.getItem("vouchers");
    if (possibleVoucherString != undefined && possibleVoucherString != null) {
      voucherString = possibleVoucherString;
    }
    API_CLIENT.getVouchers(
      appContext.token,
      (vouchers) =>
        setActiveReferral(
          vouchers?.vouchers?.find(
            (voucher) => voucher.campaign == "Referral Kampagne 5"
          )
        ),
      (error) => console.log(error)
    );
  }, []);
  const text =
    "Langfristiger Vermögensaufbau für Dein Portfolio mit inVenture - der ersten Plattform für mittelbare Investitionen in Venture Capital Fonds. Tritt auch der inVenture Capitalist Community bei! Registriere Dich jetzt unter: app.inventure.de/signup und erhalte unter der Angabe meines persönlichen Codes einen Gutschein in Höhe von 50€ bei Deiner ersten Investition: " +
    activeReferral?.code;

  if (activeReferral == undefined) return <></>;
  return (
    <>
      {activeReferral === undefined ? null : (
        <>
          <Box
            sx={{
              borderRadius: {
                md: "0 0 0px 0px",
                xs: "0",
              },
            }}
            style={{
              margin: "auto auto",
              padding: "4px 8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              cursor: "pointer",
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.background.paper,
              border: "solid",
              borderWidth: "0.4px",
              borderColor: theme.palette.primary.main,
              ...style,
            }}
            onClick={() => setDialogOpen(true)}
          >
            <Typography variant="body1">50€ Prämie</Typography>
            <img
              style={{
                marginLeft: "4px",
                marginTop: "-3px",
                height: "16px",
              }}
              src="https://ik.imagekit.io/inventure/App/Icons/gift_KlLJEr-QV.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668184267967"
            />
          </Box>
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            maxWidth="md"
          >
            <div
              style={{ padding: "0px", color: "#22515F", overflowX: "hidden" }}
            >
              <div
                style={{
                  backgroundColor: theme.palette.background.default,
                  background:
                    "url(https://ik.imagekit.io/inventure/App/static/image__7__qhADQN5CF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668516083929)",
                  backgroundSize: "cover",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "180px",
                }}
              >
                <Typography variant="body2">Empfehlungsprogramm</Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: {
                      xs: "30px",
                      md: "none",
                    },
                  }}
                  style={{
                    fontWeight: 500,
                    color: "black",
                  }}
                >
                  Verschenke 50€ und
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: {
                      xs: "30px",
                      md: "none",
                    },
                  }}
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  Erhalte 50€ Cashback
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: theme.palette.primary.dark,
                }}
              ></div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    padding: "0px 20px",
                    transform: "translateY(-50px)",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <motion.div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{ x: "0", opacity: 1 }}
                    transition={{
                      delay: 0.5,
                      ease: "easeInOut",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#F1EEE9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        src="https://ik.imagekit.io/inventure/App/static/icons/share__1__gHUkNTZtG.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668517809486"
                        style={{
                          width: "60%",
                          height: "60%",
                          transform: "translateX(-5px)",
                        }}
                      />
                    </div>
                    <Typography variant="body1" style={{ marginTop: "10px" }}>
                      <b>Teile Deinen Gutscheincode</b>
                    </Typography>
                    <Typography variant="body1">
                      Nachdem Du das erste Mal auf unserer Plattform investiert
                      hast, erhälst Du einen persönlichen Gutscheincode zum
                      Teilen.
                    </Typography>
                  </motion.div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    marginTop: {
                      xs: "40px",
                      md: "0",
                    },
                  }}
                  style={{
                    padding: "0px 20px",
                    transform: "translateY(-50px)",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <motion.div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{ x: "0", opacity: 1 }}
                    transition={{
                      delay: 0.8,
                      ease: "easeInOut",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#F1EEE9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        src="https://ik.imagekit.io/inventure/App/static/icons/giftbox__iFaFl0qE.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668517776991"
                        style={{
                          width: "60%",
                          height: "60%",
                          transform: "translateX(0px)",
                        }}
                      />
                    </div>
                    <Typography variant="body1" style={{ marginTop: "10px" }}>
                      <b>Dein Code wird verwendet</b>
                    </Typography>
                    <Typography variant="body1">
                      Wenn Deine Freund*innen den Code bei ihrer ersten
                      Investition angeben, bekommen sie 50€ gutgeschrieben.
                    </Typography>
                  </motion.div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    marginTop: {
                      xs: "40px",
                      md: "0",
                    },
                  }}
                  style={{
                    padding: "0px 20px",
                    transform: "translateY(-50px)",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <motion.div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{ x: "0", opacity: 1 }}
                    transition={{
                      delay: 1.1,
                      ease: "easeInOut",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#F1EEE9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        src="https://ik.imagekit.io/inventure/App/static/icons/wallet__1__qUSV4wb_E.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668517776967"
                        style={{
                          width: "60%",
                          height: "60%",
                          transform: "translateX(0px)",
                        }}
                      />
                    </div>
                    <Typography variant="body1" style={{ marginTop: "10px" }}>
                      <b>Du erhältst ein Dankeschön</b>
                    </Typography>
                    <Typography variant="body1">
                      Pro abgeschlossener Erstinvestition unter Angabe Deines
                      Codes erhältst Du eine 50€ als Cashback direkt auf Dein
                      Konto.
                    </Typography>
                  </motion.div>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      flexDirection: {
                        xs: "column",
                        md: "row",
                      },
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        marginRight: {
                          xs: "none",
                          md: "30px",
                        },
                      }}
                      style={{ fontWeight: 700 }}
                    >
                      Jetzt Deinen Code teilen
                    </Typography>
                    <Box
                      style={{ display: "flex" }}
                      sx={{ marginTop: { xs: "20px", md: "0" } }}
                    >
                      <div
                        style={{
                          margin: "0 5px",
                          backgroundColor: theme.palette.primary.main,
                          height: "45px",
                          padding: "0 20px",
                          color: "white",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6">
                          {activeReferral?.code}
                        </Typography>
                      </div>
                      <div
                        onClick={() =>
                          navigator.clipboard.writeText(activeReferral?.code)
                        }
                        style={{
                          margin: "0 5px",
                          backgroundColor: theme.palette.primary.main,
                          height: "45px",
                          width: "45px",
                          color: "white",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={
                            "https://ik.imagekit.io/inventure/App/static/icons/copy-logo_eqFeeJ35PG.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668520009902"
                          }
                          style={{
                            color: "white",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                      <a
                        style={{ margin: "0 5px" }}
                        href={`mailto:friend@beispiel.com?subject=Dein Code für inVenture&body=${text}`}
                      >
                        <div
                          style={{
                            backgroundColor: theme.palette.primary.main,
                            height: "45px",
                            width: "45px",
                            color: "white",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={
                              "https://ik.imagekit.io/inventure/App/static/icons/mail-logo_7br4hf5UOe.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668520002514"
                            }
                            style={{
                              color: "white",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </div>
                      </a>
                      <a
                        style={{ margin: "0 5px" }}
                        href={"https://api.whatsapp.com/send?text=" + text}
                        target="_blank"
                      >
                        <div
                          style={{
                            backgroundColor: theme.palette.primary.main,
                            height: "45px",
                            width: "45px",
                            color: "white",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={
                              "https://ik.imagekit.io/inventure/App/static/icons/whatsapp_ZM8EQWmTg.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668521513662"
                            }
                            style={{
                              color: "white",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </div>
                      </a>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      padding: "0 20px",
                      marginTop: "30px",
                      textAlign: "center",
                      fontWeight: 600,
                    }}
                  >
                    Teilen lohnt sich: Sobald 5 Personen unter Angabe Deines
                    Codes ihre Erstinvestition abgeschlossen haben, wirst Du zu
                    unserem Evangelist Programm mit einem attraktiven
                    Prämienplan eingeladen.
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "30px",
                    marginTop: "20px",
                  }}
                >
                  <Typography variant="body2" style={{ margin: "10px" }}>
                    Indem Du den Gutscheincode teilst, stimmst Du den{" "}
                    <a
                      href="https://inventurestorage.blob.core.windows.net/platform/referral/Nutzungsbedingungen-Referral.pdf"
                      style={{ color: "black", textDecoration: "none" }}
                      target="blank"
                    >
                      Nutzungsbedingungen{" "}
                    </a>
                    zu.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}
