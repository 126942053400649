import fetch from 'cross-fetch';
import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import {
  News,
  AllNews,
  Startup,
  VC,
  Fund,
} from "../types/vc-platform/news_types";



export default abstract class NEWS_CLIENT {
  fetch = require('cross-fetch');
  static client = new ApolloClient({
    link: new HttpLink({
      uri: "https://api-eu-central-1.graphcms.com/v2/ckqmobwjp484401xodfxu0sww/master",
      headers: {'gcms-stage': process.env.REACT_APP_NEWS_STAGE ? process.env.REACT_APP_NEWS_STAGE : "DRAFT"},
  
      fetch}),
    cache: new InMemoryCache(),
   });

  static image = `
    {
      url
    }
  `;

  static bulletPoint = `
  {
    headline
    content
  }
`;

  static fundManager = `
    {
      name
      position
      description
      image ${NEWS_CLIENT.image}
    }
  `;

  static investor = `
    {
      name
      image ${NEWS_CLIENT.image}
    }
  `;

  static investmentCompany = `
    {
      name
      logo ${NEWS_CLIENT.image}
      manager ${NEWS_CLIENT.fundManager}
      website
    }
  `;

  static vc = `
    {
      investmentCompany ${NEWS_CLIENT.investmentCompany}
    }
  `;

  static deal_to_vc = `
    {
      investors ${NEWS_CLIENT.investor}
      vCs ${NEWS_CLIENT.vc}
    }
  `;

  static startup = `
    {
      name
      claim
      description
      website
      highlights
      logo ${NEWS_CLIENT.image}
      deals ${NEWS_CLIENT.deal_to_vc}
    }
  `;

  static deal_to_startup = `
  {
    startup ${NEWS_CLIENT.startup}
  }
`;

  static fund_to_startup = `
{
  fundId
  logo ${NEWS_CLIENT.image}
  logoInverted ${NEWS_CLIENT.image}
  deals ${NEWS_CLIENT.deal_to_startup}
  investmentCompany ${NEWS_CLIENT.investmentCompany}
  name
}
`;

  static fund = `
    {
      deals ${NEWS_CLIENT.deal_to_vc}
      investmentCompany ${NEWS_CLIENT.investmentCompany}
      name
    }
  `;

  static getFund(fundId: String, onSuccess: (fund: VC) => void) {
    NEWS_CLIENT.client
      .query({
        query: gql`
        query MyQuery {
          fund(where: {fundId: "${fundId}"}) {
            logo ${NEWS_CLIENT.image}
            logoInverted ${NEWS_CLIENT.image}
            investmentCompany {
              name
              trackRecord ${NEWS_CLIENT.bulletPoint}
              trackRecordDate
              manager ${NEWS_CLIENT.fundManager}
              website
              portfolio
            }
          }
        }
      `,
      })
      .then((result) => {
        onSuccess(result.data.fund);
      });
  }

  static getFunds(fundIds: string[], onSuccess: (funds: Fund[]) => void) {
    var fundIdString = fundIds.map((fundId) => '"' + fundId + '"').join(",");
    NEWS_CLIENT.client
      .query({
        query: gql`
          query MyQuery {
            funds(where: {fundId_in: [${fundIdString}]})
            ${NEWS_CLIENT.fund_to_startup}
          }
        `,
      })
      .then((result) => {
        onSuccess(result.data.funds);
      });
  }

  static getAllFunds(onSuccess: (funds: Fund[]) => void) {
  
    NEWS_CLIENT.client
      .query({
        query: gql`
          query MyQuery {
            funds()
            ${NEWS_CLIENT.fund_to_startup}
          }
        `,
      })
      .then((result) => {
        onSuccess(result.data.funds);
      });
  }

  static getStartupsForFund(
    fundId: String,
    onSuccess: (funds: Startup[]) => void
  ) {
    NEWS_CLIENT.client
      .query({
        query: gql`
          query MyQuery {
            startups(where: {deals_some: {vCs_some: {fundId: "${fundId}"}}}
            orderBy: score_DESC) 
            ${NEWS_CLIENT.startup}
          }
        `,
      })
      .then((result) => {
        onSuccess(result.data.startups);
      });
  }

  static getNewsForFunds(fundIds: String[], onSuccess: (news: News[]) => void) {
    var fundIdString = fundIds.map((fundId) => '"' + fundId + '"').join(",");
    NEWS_CLIENT.client
      .query({
        query: gql`
          query MyQuery {
            newss(
              orderBy: published_DESC
              where: {funds_some: {fundId_in: [${fundIdString}] }}) {
              headline
              published
              link
              medium
              teaser
              content {
                html
              }
              funds {
                name
                logo ${NEWS_CLIENT.image}
              }
              startup ${NEWS_CLIENT.startup}
              image ${NEWS_CLIENT.image}
            }
          }
        `,
      })
      .then((result) => {
        onSuccess(result.data.newss);
      });
  }

  static getNewsForFund(fundId: String, onSuccess: (news: News[]) => void) {
    NEWS_CLIENT.client
      .query({
        query: gql`
          query MyQuery {
            newss(
              orderBy: published_DESC
              where: {funds_some: {fundId_in: "${fundId}" }}) {
              headline
              published
              medium
              link
              teaser
              content {
                html
              }
              startup ${NEWS_CLIENT.startup}
              image ${NEWS_CLIENT.image}
            }
          }
        `,
      })
      .then((result) => {
        onSuccess(result.data.newss);
      });
  }

  static getNews(onSuccess: (news: News[]) => void) {
    NEWS_CLIENT.client
    .query({
      query: gql`
        query MyQuery {
          newss(
            orderBy: published_DESC
          ) {
            headline
            published
            medium
            link
            teaser
            content {
              html
            }
            funds {
              name
              logo ${NEWS_CLIENT.image}
            }
            startup ${NEWS_CLIENT.startup}
            image ${NEWS_CLIENT.image}
          }
        }
      `,
    })
      .then((result) => {
        onSuccess(result.data.newss);
      });
  }
}
