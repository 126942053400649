import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import { PrimaryButtonMobile } from "../../../components/input-elements/buttons";
import MultistepForm from "../../../components/vertical-multistep-form/multistep-form";
import MultistepFormBody from "../../../components/vertical-multistep-form/multistep-form-body";
import MultistepFormButtons from "../../../components/vertical-multistep-form/multistep-form-buttons";
import MultistepFormNavigation from "../../../components/vertical-multistep-form/multistep-form-navigation";

export default function OnboardingSuccess() {
  return (
    <MultistepForm>
      <MultistepFormNavigation />
      <MultistepFormBody>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "600px",
          }}
        >
          <Typography variant="h3" sx={{ marginTop: { xs: "40px", md: "0" } }}>
            Onboarding erfolgreich
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginTop: { xs: "40px", md: "0" },
              fontSize: { xs: "20px", md: "16px" },
            }}
          >
            Du hast Dich erfolgreich legitimiert. Nun kannst Du in eines unserer
            Produkte investieren.
          </Typography>
          <PrimaryButtonMobile href={"/portal/products"}>
            Zur Produktübersicht
          </PrimaryButtonMobile>
        </Box>
      </MultistepFormBody>
    </MultistepForm>
  );
}
