import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FundCardNew } from "../../../components/page-components/funds/fund-card";
import LoadingScreen from "../../../components/loading-screen";
import API_CLIENT from "../../../utility/api-client";
import { Fund } from "../../../types/fund_types";
import { AppContext } from "../auth-router";
import ReactGA from "react-ga";
import { useAuth0 } from "@auth0/auth0-react";
import OnboardingBannerNew from "../../../components/banner/onboarding-banner-new";
import TagManager from "react-gtm-module";
import { v4 } from "uuid";
import { MatchingDTO, USER_STATE } from "../../../types/generic_types";
import LegalPersonOnboardingBanner from "../../../components/banner/legal-person-onboarding-banner";
import NEWS_CLIENT from "../../../utility/news-client";
import {
  Fund as TargetFund,
  News,
} from "../../../types/vc-platform/news_types";
import theme from "../../../theme";

import { SecondaryButton } from "../../../components/input-elements/buttons";
import NewsSwiper from "../../../components/page-components/news/news-swiper";
import { SimpleActionBanner } from "@inventurefinancial/frontend-components";

export default function Funds() {
  const [targetFunds, setTargetFunds] = useState<TargetFund[]>([]);
  const appContext = useContext(AppContext);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  let callId = v4();
  useEffect(() => {
    var uData = appContext.matchingData;

    TagManager.dataLayer({
      dataLayer: {
        event: "view-products",
        event_id: callId,
        first_name: uData?.fn,
        last_name: uData?.ln,
        phone: uData?.ph,
        email: uData?.em,
      },
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    var uData = appContext.matchingData;

    var mData: MatchingDTO = {
      event: "ViewContent",
      eventId: callId,
      sourceUrl: window.location.href,
      em: uData?.em,
      ph: uData?.ph,
      fn: uData?.fn,
      ln: uData?.ln,
    };
    if (mData == null) return;
    window.scrollTo(0, 0);
    const loadData = async () => {
      const accessToken = await getAccessTokenSilently({});
      try {
        if (mData) {
          API_CLIENT.postMatching(accessToken, mData);
        }

        /**API_CLIENT.getFunds(
          accessToken,
          (funds) => {
            setFunds(funds);
            setFundsLoading(false);
            var fundIds = appContext.funds.map((fund) => fund.targetInvestmentId);
            if (!fundIds) fundIds = [];
            NEWS_CLIENT.getFunds(fundIds, (funds: TargetFund[]) => {
              if (funds) {
                setTargetFunds(funds);
              }
            });
          },
          (error) => console.log(error)
        );**/
      } catch (e) {
        console.log(e);
      }
    };

    if (!finishedLoading && !appContext.loading && targetFunds) {
      setFinishedLoading(true);
      loadData();
    }
  }, [setFinishedLoading, appContext, finishedLoading]);

  useEffect(() => {
    var fundIds = appContext.funds.map((fund) => fund.targetInvestmentId);
    NEWS_CLIENT.getFunds(fundIds, (funds: TargetFund[]) => {
      if (funds) {
        setTargetFunds(funds);
      }
    });
  }, [appContext.funds]);

  return (
    <AppContext.Consumer>
      {(appContext) =>
        AppContext && (
          <Grid
            container
            spacing={0}
            style={{ alignContent: "start", minHeight: "calc(100vh)" }}
            justifyContent="center"
            alignItems="flex-start"
          >
            {appContext.funds.length == 0 ? (
              <LoadingScreen style={{ height: "100vh", width: "100vw" }} />
            ) : (
              <>
                <>
                  <Grid item xs={12} style={{ marginTop: "0px" }}>
                    <Grid container justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        style={{
                          overflow: "hidden",
                          maxWidth: "1200px",
                          padding: "0px 0px 100px 0",
                        }}
                      >
                        <Grid container spacing={0}>
                          <Grid item xs={12} style={{ padding: "20px" }}>
                            <SimpleActionBanner
                              headline="Investition"
                              button_link="/redirect?target=https://calendly.com/inventuredatle/30min"
                              button_text="Termin buchen"
                              theme={theme}
                            >
                              Das Angebot richtet sich ausschließlich an
                              semi-professionelle und professionelle Anleger
                            </SimpleActionBanner>
                          </Grid>

                          <LegalPersonOnboardingBanner
                            style={{ marginTop: "20px" }}
                          />

                          {appContext.userState != USER_STATE.ONBOARDED_2 ? (
                            <Grid
                              sx={{
                                paddingTop: { xs: "0px", md: "30px" },
                                paddingBottom: { xs: "0px", mad: "30px" },
                              }}
                              item
                              xs={12}
                            >
                              <Box
                                style={{
                                  marginLeft: "20px",
                                  marginRight: "20px",
                                }}
                                sx={{ marginTop: { xs: "2rem", md: "0px" } }}
                              >
                                <SimpleActionBanner
                                  headline="Onboarding"
                                  theme={theme}
                                  button_text="Zum Onboarding"
                                  button_link="/portal/onboarding"
                                >
                                  Schließe vor deiner ersten Investition das
                                  Onboarding ab
                                </SimpleActionBanner>
                              </Box>
                              {/**<OnboardingBannerNew
                                state={appContext.userState}
                            />**/}
                            </Grid>
                          ) : null}

                          <Grid item xs={12}>
                            <Grid
                              container
                              spacing={4}
                              style={{ padding: "0 20px" }}
                              sx={{ marginTop: { xs: "0px", md: "0px" } }}
                            >
                              <>
                                {appContext.funds
                                  //put filters here
                                  .filter((fund) => fund.hidden == false)
                                  .sort(
                                    (fundA, fundB) =>
                                      new Date(fundB.closingDate).getTime() -
                                      new Date(fundA.closingDate).getTime()
                                  )
                                  .map((fund: Fund) => {
                                    var targetFund = targetFunds?.find(
                                      (f) => f.fundId == fund.targetInvestmentId
                                    );
                                    return (
                                      <Grid
                                        key={String(fund.id)}
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={4}
                                      >
                                        {/**<Grid key={String(fund.id)} item xs={12} sm={12} md={6}>*/}
                                        {/**<FundCard key={fund.id} fund={fund} />**/}
                                        <FundCardNew
                                          key={fund.id}
                                          fund={fund}
                                          targetFund={targetFund}
                                        />
                                      </Grid>
                                    );
                                  })}
                              </>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              </>
            )}
          </Grid>
        )
      }
    </AppContext.Consumer>
  );
}
