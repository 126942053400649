import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    dark?: string;
  }
}

const theme = createTheme({
  typography: {
    // Use the system font.
    fontFamily: "Nunito Sans",
    body1: {
      fontSize: "16px",
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 600,
      fontSize: "40px",
    },
    h2: {
      fontSize: "40px",
      fontWeight: 600,
    },
    h1: {
      fontSize: "50px",
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR_MAIN
        ? process.env.REACT_APP_PRIMARY_COLOR_MAIN
        : "#293437", //"#005a8c"", //'#f5efe6'
      light: process.env.REACT_APP_PRIMARY_COLOR_LIGHT
        ? process.env.REACT_APP_PRIMARY_COLOR_LIGHT
        : "#7B9197",
      dark: process.env.REACT_APP_PRIMARY_COLOR_DARK
        ? process.env.REACT_APP_PRIMARY_COLOR_DARK
        : "#293437",
    },
    secondary: {
      main: process.env.REACT_APP_SECONDARY_COLOR_MAIN
        ? process.env.REACT_APP_SECONDARY_COLOR_MAIN
        : "#f67777", //"#f6f5ed" //"#f5efe6",
      light: process.env.REACT_APP_SECONDARY_COLOR_LIGHT
        ? process.env.REACT_APP_SECONDARY_COLOR_LIGHT
        : "#f79292",
      dark: process.env.REACT_APP_SECONDARY_COLOR_DARK
        ? process.env.REACT_APP_SECONDARY_COLOR_DARK
        : "#ac5353",
    },
    background: {
      default: process.env.REACT_APP_BACKGROUND_COLOR_DEFAULT
        ? process.env.REACT_APP_BACKGROUND_COLOR_DEFAULT
        : "#f9f5f0", //"#f6f5ed" //"#f5efe6",
      paper: process.env.REACT_APP_BACKGROUND_COLOR_PAPER
        ? process.env.REACT_APP_BACKGROUND_COLOR_PAPER
        : "#fefdfb",
      dark: process.env.REACT_APP_BACKGROUND_COLOR_DARK
        ? process.env.REACT_APP_BACKGROUND_COLOR_DARK
        : "#f1eee9",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export default theme;
