import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "./yousign-iframe.css";
import { Yousign } from "./yousign";
import API_CLIENT from "../../utility/api-client";
import { AppContext } from "../../pages/direct/direct-router";
import { Investment } from "../../types/investment_types";

interface YouSignDialogProps {
  signature_link?: string;
  is_open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export default function YouSignDialog({
  signature_link,
  is_open,
  onClose,
  onSuccess,
}: YouSignDialogProps) {
  const sandbox = process.env.REACT_APP_YOUSIGN_SANDBOX === "true";
  console.log("yousign sandbox: " + sandbox);
  useEffect(() => {
    try {
      const yousign = new Yousign({
        signatureLink: signature_link,
        iframeContainerId: "yousign-iframe-container",
        isSandbox: sandbox,
      });
      yousign.onSuccess(() => {
        onClose();
        onSuccess();
      });
    } catch {}
  }, []);

  return (
    <div>
      {is_open ? (
        <div
          onClick={() => {
            onClose();
          }}
          className={`w-full h-full flex justify-center items-center overflow-hidden bg-black bg-opacity-50 ${
            is_open ? "fixed top-0 left-0 z-[100]" : "hidden"
          }`}
        >
          <div className="w-5/6 h-5/6  bg-white rounded-lg overflow-hidden">
            <div className="w-full h-full">
              <div
                className="w-full h-full"
                id="yousign-iframe-container"
              ></div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
