import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import React, { useContext, useState } from "react";
import MobileBottomNavigation from "./mobile-bottom-navigation";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import { AppContext } from "../pages/portal/auth-router";
import theme from "../theme";
import SettingsMenu from "./settings-menu";
import CustomerSupportWidget from "./customer-support-widget";
import ReferralBanner, {
  ReferraWidget,
} from "../components/banner/referral-banner";

const PREFIX = "header";

const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  button: `${PREFIX}-button`,
  whiteSpace: `${PREFIX}-whiteSpace`,
  loginButton: `${PREFIX}-loginButton`,
  logo: `${PREFIX}-logo`,
  logoDrawer: `${PREFIX}-logoDrawer`,
  toolbar: `${PREFIX}-toolbar`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
    zIndex: 100,
    position: "relative",
  },
  [`& .${classes.menuButton}`]: {
    marginRight: "20px",
  },
  [`& .${classes.title}`]: {
    flexGrow: 0.3,
    textAlign: "left",
  },
  [`& .${classes.button}`]: {
    margin: "0 5px 0 5px !important",
    textDecoration: "none",
  },
  [`& .${classes.whiteSpace}`]: {
    flexGrow: 1,
  },
  [`& .${classes.loginButton}`]: {
    marginRight: "0",
  },
  [`& .${classes.logo}`]: {
    backgroundImage: `url(${process.env.REACT_APP_LOGO_LIGHT})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "100px",
    height: "30px",
    marginRight: "50px",
    marginTop: "4px",
  },
  [`& .${classes.logoDrawer}`]: {
    backgroundImage: `url(${process.env.REACT_APP_LOGO_LIGHT})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "60%",
    height: "50px",
    marginRight: "auto",
    marginLeft: "50%",
    marginTop: "30px",
    marginBottom: "10px",
    transform: "translateX(-50%)",
  },
  [`& .${classes.toolbar}`]: {
    backgroundColor: "black",
  },
});

export default function HeaderV2() {
  const [anchor, setAnchor] = useState(false);

  const appContext = useContext(AppContext);
  const { logout } = useAuth0();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setAnchor(open);
    };

  var headless = !window.location.href.includes("portal");

  const list = (anchor: string | boolean) => (
    <div
      style={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List style={{ padding: "30px" }}>
        <Link
          to="/portal/products"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button key="Produkte">
            <ListItemIcon>
              <Avatar
                style={{
                  backgroundColor: "#1E3A42",
                  width: "30px",
                  height: "30px",
                }}
                variant="rounded"
              >
                <TrendingUpIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText primary="Produkte"></ListItemText>
          </ListItem>
        </Link>
        <Divider />
        <Link
          to="/portal/dashboard"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button key="Dashboard">
            <ListItemIcon>
              <Avatar
                style={{
                  backgroundColor: "#1E3A42",
                  width: "30px",
                  height: "30px",
                }}
                variant="rounded"
              >
                <DonutLargeIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText primary="Dashboard"></ListItemText>
          </ListItem>
        </Link>
        <Divider />
        <Link
          to="/portal/profile"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem button key="Profil">
            <ListItemIcon>
              <Avatar
                style={{
                  backgroundColor: "#1E3A42",
                  width: "30px",
                  height: "30px",
                }}
                variant="rounded"
              >
                <PermIdentityIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText primary="Profil"></ListItemText>
          </ListItem>
        </Link>
        <Divider />
        <ListItem button key="Logout" onClick={() => logout()}>
          <ListItemIcon>
            <Avatar
              style={{
                backgroundColor: "#1E3A42",
                width: "30px",
                height: "30px",
              }}
              variant="rounded"
            >
              <ExitToAppIcon />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary="Logout"></ListItemText>
        </ListItem>
      </List>
    </div>
  );

  const atProducts = window.location.pathname == "/portal/products";
  const atDashboard = window.location.pathname == "/portal/dashboard";
  const atNews = window.location.pathname == "/portal/news";
  return (
    <Root style={{ position: "sticky", top: "0px", zIndex: 1000 }}>
      {headless ? null : (
        <div className={classes.root}>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <MobileBottomNavigation />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <AppBar
              style={{
                height: "80px",
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.background.default,
                boxShadow: "black 0 0 8px -5px",
                zIndex: 2000,
              }}
              position="relative"
            >
              <Grid container spacing={0} justifyContent="center">
                <Grid item xs={12} style={{ maxWidth: "calc(1200px + 48px)" }}>
                  <Toolbar>
                    <Link
                      to="/portal/products"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        style={{ marginLeft: "0px" }}
                        className={classes.logo}
                      />
                    </Link>

                    {/** Browser View */}
                    <Box
                      sx={{
                        display: {
                          md: "flex",
                          xs: "none",
                          alignItems: "center",
                        },
                      }}
                      style={{ width: "100%" }}
                    >
                      <Link
                        to="/portal/products"
                        style={{ textDecoration: "none" }}
                      >
                        <>
                          <Button
                            disableRipple
                            startIcon={<TrendingUpIcon />}
                            className={classes.button}
                            style={{
                              backgroundColor: "transparent",
                              fontWeight: 600,
                              width: "140px",
                              color: "black",
                            }}
                          >
                            Produkte
                          </Button>
                          {atProducts ? (
                            <div
                              style={{
                                margin: "auto",
                                width: "80%",
                                height: "2px",
                                backgroundColor: "black",
                              }}
                            />
                          ) : null}
                        </>
                      </Link>
                      <Link
                        to="/portal/dashboard"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          disableRipple
                          startIcon={<DonutLargeIcon />}
                          className={classes.button}
                          style={{
                            color: "black",
                            backgroundColor: "transparent",
                            fontWeight: 600,
                            width: "140px",
                          }}
                        >
                          Dashboard
                        </Button>
                        {atDashboard ? (
                          <div
                            style={{
                              margin: "auto",
                              width: "80%",
                              height: "2px",
                              backgroundColor: "black",
                            }}
                          />
                        ) : null}
                      </Link>

                      <div style={{ flexGrow: 1 }} />

                      <ReferraWidget style={{ marginRight: "20px" }} />
                      {/**<CustomerSupportWidget/>*/}
                      <SettingsMenu />
                    </Box>
                  </Toolbar>
                </Grid>
              </Grid>
            </AppBar>
          </Box>
        </div>
      )}
    </Root>
  );
}
