import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { InvestmentContextNew } from "../../../../pages/portal/investment/investment-process-new";
import { INVESTMENT_STATE } from "../../../../types/investment_types";
import AmountUtils from "../../../../utility/amount-utils";
import PrimaryButton from "../../../input-elements/buttons";

export default function InvestmentSuccess() {
  const history = useHistory();
  const investmentContext = useContext(InvestmentContextNew);
  const investment = investmentContext.investment;

  useEffect(() => {}, []);

  if (!investment) return null;

  return (
    <div style={{ maxWidth: "1200px", margin: "auto" }}>
      <Typography style={{ fontSize: "32px" }} variant="h1">
        Bestätigung Deiner Investition
      </Typography>
      <Typography
        variant="h3"
        style={{ fontWeight: 700, fontSize: "24px", marginTop: "30px" }}
      >
        {investment.campaignName}
      </Typography>
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 300,
          fontSize: "20px",
          lineHeight: "24px",
        }}
      >
        Tokenisierte Schuldverschreibung
      </Typography>
      <Grid container style={{ marginTop: "10px" }}>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                Investitionsbetrag
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                style={{ lineHeight: "20px", fontSize: "20px" }}
              >
                {(investment.amount * investment.tokenPrice).toLocaleString() +
                  " €"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {AmountUtils.toString(investment.disagio) != "0€" ? (
          <Grid item xs={12} md={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: "20px", fontWeight: 600 }}
                >
                  Gutschein
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  style={{ lineHeight: "20px", fontSize: "20px" }}
                >
                  {AmountUtils.toString(investment.disagio)}
                </Typography>
              </Grid>
            </Grid>{" "}
          </Grid>
        ) : null}

        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                Zu zahlender Betrag
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                style={{ lineHeight: "20px", fontSize: "20px" }}
              >
                {(
                  investment.amount * investment.tokenPrice -
                  Number(investment.disagio.amount) /
                    Math.pow(10, investment.disagio.decimals)
                ).toLocaleString() + " €"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="body1" style={{ marginTop: "30px" }}>
        Du hast erfolgreich Dein Investitionsangebot abgegeben.
      </Typography>
      <Typography variant="body1" style={{ marginTop: "5px", fontWeight: 700 }}>
        So geht es nun weiter:
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "20px" }}>
        Im nächsten Schritt überprüfen wir Deine Investition. Anschließend
        erhälst Du eine E-Mail mit einem Link zu den Zahlungsinformationen.
        Einen Überblick über Deine Investitionen erhälst Du auch im Dashboard.
      </Typography>
      <PrimaryButton href="/portal/dashboard">Zum Dashboard</PrimaryButton>
    </div>
  );
}
