import { Divider, Grid, TextField, Typography } from "@mui/material";
import IBAN from "iban";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { AppContext } from "../../../pages/portal/auth-router";
import { InvestmentContextAIF } from "../../../pages/portal/investment-aif/investment-process-aif";
import API_CLIENT from "../../../utility/api-client";
import HookFormTextField, {
  HookFormIBANField,
  HookFormUppercaseField,
} from "../../hook-form-inputs";
import LoadingScreen from "../../loading-screen";

import MultistepFormButtons from "../../vertical-multistep-form/multistep-form-buttons";

type BankAndTaxAIFProps = {
  back: () => void;
  next: () => void;
};

export default function BankAndTaxAIF(props: BankAndTaxAIFProps) {
  const investment_context = useContext(InvestmentContextAIF);
  const app_context = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<any>({ mode: "onChange" });

  function submit(data: any) {
    window.scrollTo(0, 0);
    setLoading(true);

    API_CLIENT.putBankAndTax(
      app_context.token,
      data,
      (user) => {
        app_context.updateUser(user);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  if (loading) {
    return <LoadingScreen className="w-full h-screen" />;
  }

  return (
    <div className="flex flex-col items-start justify-start h-screen">
      <form onSubmit={handleSubmit(submit)} className="grid gap-8 ">
        <div>
          <Typography variant="h2">Bankdaten und Steuern</Typography>
          <Typography variant="subtitle1" className="text-2xl text-gray-600">
            Um Deine Auszahlung durchzuführen benötigen wir hier dein
            gewünschtes Auszahlungskonto und deine Steuerdaten.
          </Typography>
        </div>
        <Divider />
        <div>
          <Typography variant="subtitle1" className="text-2xl">
            Auszahlungskonto
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body2">
                Aus rechtlichen Gründen darf der Kontoinhaber nicht vom Nutzer
                abweichen.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Kontoinhaber: {app_context.user?.natural_person.forename}{" "}
                {app_context.user?.natural_person.surname}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <HookFormUppercaseField
                defaultValue={app_context.user?.bank_account.bic}
                required
                control={control}
                name={"bic"}
                error={errors.bic != null}
                label={"BIC*"}
              />
            </Grid>
            <Grid item xs={12}>
              <HookFormIBANField
                label="IBAN"
                defaultValue={app_context.user?.bank_account.iban}
                control={control}
                name={"iban"}
                required
                error={errors.iban != null}
              ></HookFormIBANField>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div className="grid gap-3">
          <Typography variant="subtitle1" className="text-2xl">
            Steuernummer
          </Typography>
          <HookFormTextField
            label="Steueridentifikationsnummer"
            control={control}
            defaultValue={
              app_context.user?.taxInformation.tax_identification_number
            }
            name={"tin"}
            required
            error={errors.tin != null}
          ></HookFormTextField>
        </div>
        <MultistepFormButtons
          backFunction={() => investment_context.prevStep()}
          back={true}
          disabled={false}
        />
      </form>
    </div>
  );
}
