import { useAuth0, User } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";
import { InventureUser, SALUTATION } from "../types/inventure-user_types";
import { WalletType } from "../types/wallet_types";
import { Document, USER_STATE } from "../types/generic_types";
import API_CLIENT from "../utility/api-client";
import countries from "i18n-iso-countries";
import { AppContext } from "./portal/auth-router";
import LoadingScreen from "../components/loading-screen";
import { Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import PrimaryButton from "../components/input-elements/buttons";
import SetBankDialog from "../components/dialogs/set-bank-dialog";
import CopyText from "../components/data-display/wallet-chip";
import { DocumentBoxWideDirect } from "../components/data-display/document-box";
import { Box } from "@mui/material";
import { MoveToInbox } from "@mui/icons-material";
import { INVESTOR_TYPE } from "../types/onboarding_types";
import BenefitingPersonManager from "../components/data-display/benefiting-persons";
import theme from "../theme";
import SetAddressDialog from "../components/dialogs/set-adresse-dialog";
import SetTaxDialog from "../components/dialogs/set-tax-dialog";

const transition = { type: "spring", stiffness: 200, damping: 26 };

export default function ProfileNew() {
  const appContext = useContext(AppContext);
  const [user, setUser] = useState<InventureUser>();
  const [section, setsection] = useState(0);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [wallet, setWallet] = useState<WalletType | null>(null);
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently({});
      API_CLIENT.getUser(
        accessToken,
        (user: InventureUser) => {
          setUser(user);
          setLoading(false);
        },
        (error: string) => console.log(error)
      );
      API_CLIENT.getUserDocuments(
        accessToken,
        (documents: Document[]) => {
          var myDocuments: Document[] = [];
          documents = documents.filter(
            (document) => document.name != "phone_allowance"
          );
          documents.forEach((document) => {
            if (document.name === "effecta_customer_info") {
              document.name = "Effecta Kundeninformationen und AGB";
            }
            myDocuments.push(document);
          });
          setDocuments(myDocuments);
          setLoading(false);
        },
        (error: string) => console.log(error)
      );
      API_CLIENT.getWallet(
        accessToken,
        (wallet: WalletType) => {
          setWallet(wallet);
        },
        (error: string) => console.log(error)
      );
    };
    fetchData();
  }, [appContext]);

  if (user == undefined) {
    return <LoadingScreen style={{ height: "calc(100vh - 60px)" }} />;
  }

  const profile_position = 0;
  const bank_position = 1;
  const safety_position = 2;
  const documents_position = 3;
  const wallet_position = 4;
  const legal_person_position = 5;
  // -- Dinesh Kumar
  const tax_information_position = 6;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {/** Browser */}
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        style={{
          padding: "10px",
          maxWidth: "1080px",
          width: "100%",
          minHeight: "calc(100vh - 60px)",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography style={{ marginTop: "30px" }} variant="h5">
            Hallo {user.natural_person?.forename} {user.natural_person.surname}{" "}
            &#128075;,
          </Typography>
          <Typography variant="body1">
            Hier bekommst du Einsicht in deine Nutzerdaten, dein Wallet und
            deine Dokumente.
          </Typography>
        </div>
        <div
          id="profile-container"
          style={{ display: "flex", flexDirection: "row", marginTop: "30px" }}
        >
          <div id="profile-menu" style={{ width: "300px" }}>
            <ProfileMenuPoint
              icon={
                "https://ik.imagekit.io/inventure/App/Icons/user__1__ma2TxWrrj.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663153180795"
              }
              style={{ marginTop: "10px" }}
              onClick={() => setsection(profile_position)}
              active={section == profile_position}
              text="Stammdaten"
            />
            {user.legal_person != null ? (
              <ProfileMenuPoint
                icon={
                  "https://ik.imagekit.io/inventure/App/Icons/employee_9GEN7d1Gv.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646134520669"
                }
                onClick={() => setsection(legal_person_position)}
                active={section == legal_person_position}
                text="jur. Person"
                style={{ marginTop: "20px" }}
              />
            ) : null}
            <ProfileMenuPoint
              icon={
                "https://ik.imagekit.io/inventure/App/static/museum_9RnICcZbJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642415824458"
              }
              onClick={() => setsection(bank_position)}
              active={section == bank_position}
              text="Bankdaten"
              style={{ marginTop: "20px" }}
            />
            {/* -- Dinesh Kumar */}
            {/* <ProfileMenuPoint
              icon={
                "https://ik.imagekit.io/inventure/App/static/museum_9RnICcZbJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642415824458"
              }
              onClick={() => setsection(tax_information_position)}
              active={section == tax_information_position}
              text="Tax Information"
              style={{ marginTop: "20px" }}
            />*/}
            <ProfileMenuPoint
              icon={
                "https://ik.imagekit.io/inventure/App/static/shield_jCyEATbYP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642694714638"
              }
              onClick={() => setsection(2)}
              active={section == 2}
              text="Sicherheit"
              style={{ marginTop: "20px" }}
            />
            {documents.length > 0 ? (
              <ProfileMenuPoint
                icon={
                  "https://ik.imagekit.io/inventure/App/static/document_Z7rFSU0vMHF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642415577591"
                }
                onClick={() => setsection(documents_position)}
                active={section == documents_position}
                text="Dokumente"
                style={{ marginTop: "20px" }}
              />
            ) : null}
            {wallet ? (
              <ProfileMenuPoint
                icon={
                  "https://ik.imagekit.io/inventure/App/static/wallet_Np2-Yzw8Ddq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642415710336"
                }
                onClick={() => setsection(wallet_position)}
                active={section == wallet_position}
                text="Wallet"
                style={{ marginTop: "20px" }}
              />
            ) : null}
          </div>
          <div id="profile-content" style={{ width: "100%" }}>
            <motion.div
              style={{
                backgroundColor: theme.palette.background.paper,
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: theme.palette.background.paper,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
              }}
            >
              {section == profile_position ? (
                <UserDataSection user={user} />
              ) : section == bank_position ? (
                <BankSection user={user} />
              ) : section == tax_information_position ? (
                <TaxSection user={user} />
              ) : section == wallet_position ? (
                <WalletSection wallet={wallet} />
              ) : section == documents_position ? (
                <DocumentSection
                  documents={documents.map((document) => {
                    switch (document.name) {
                      case "privacy_policy":
                        document.name = document.name = "Datenschutzerklärung";
                        break;
                      case "terms_of_service":
                        document.name = document.name = "AGB";
                        break;
                    }
                    return document;
                  })}
                />
              ) : section == safety_position ? (
                <SafetySection />
              ) : section == legal_person_position ? (
                <LegalPersonSection />
              ) : null}
            </motion.div>
          </div>
        </div>
      </Box>
      {/** Mobile */}
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            minHeight: "100vh",
          }}
        >
          <Typography style={{ marginTop: "0px" }} variant="h5">
            Hallo, {user.natural_person?.forename} {user.natural_person.surname}{" "}
            &#128075;,
          </Typography>
          <Typography variant="body1">
            Hier bekommst du Einsicht in deine Nutzerdaten, dein Wallet und
            deine Dokumente
          </Typography>
          <motion.div>
            <motion.div
              transition={{ type: "spring", stiffness: 200, damping: 26 }}
              style={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: "5px",
                marginTop: "10px",
                boxShadow: "black 0 0 10px -5px",
                overflow: "hidden",
              }}
              animate={{
                height: section == profile_position ? "auto" : "40px",
              }}
            >
              <MobileMenuPoint
                text={"Stammdaten"}
                icon={
                  "https://ik.imagekit.io/inventure/App/Icons/user__1__ma2TxWrrj.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663153180795"
                }
                onClick={() =>
                  section == profile_position
                    ? setsection(-1)
                    : setsection(profile_position)
                }
              />
              <UserDataSection user={user} />
            </motion.div>
            <motion.div
              transition={{ type: "spring", stiffness: 200, damping: 26 }}
              style={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: "5px",
                marginTop: "10px",
                boxShadow: "black 0 0 10px -5px",
                overflow: "hidden",
              }}
              animate={{ height: section == bank_position ? "auto" : "40px" }}
            >
              <MobileMenuPoint
                text={"Bankdaten"}
                icon={
                  "https://ik.imagekit.io/inventure/App/static/museum_9RnICcZbJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642415824458"
                }
                onClick={() =>
                  section == bank_position
                    ? setsection(-1)
                    : setsection(bank_position)
                }
              />
              <BankSection headless user={user} />
            </motion.div>

            {/* 
             Dinesh Kumar
            <motion.div
              transition={{ type: "spring", stiffness: 200, damping: 26 }}
              style={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: "5px",
                marginTop: "10px",
                boxShadow: "black 0 0 10px -5px",
                overflow: "hidden",
              }}
              animate={{ height: section == tax_information_position ? "auto" : "40px" }}
            >
              <MobileMenuPoint
                text={"Taxdaten"}
                icon={
                  "https://ik.imagekit.io/inventure/App/static/museum_9RnICcZbJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642415824458"
                }
                onClick={() => (section == tax_information_position ? setsection(-1) : setsection(tax_information_position))}
              />
              <TaxSection headless user={user} />
            </motion.div> */}
            <motion.div
              transition={{ type: "spring", stiffness: 200, damping: 26 }}
              style={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: "5px",
                marginTop: "10px",
                boxShadow: "black 0 0 10px -5px",
                overflow: "hidden",
              }}
              animate={{ height: section == safety_position ? "auto" : "40px" }}
            >
              <MobileMenuPoint
                text={"Sicherheit"}
                icon={
                  "https://ik.imagekit.io/inventure/App/static/shield_jCyEATbYP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642694714638"
                }
                onClick={() =>
                  section == safety_position
                    ? setsection(-1)
                    : setsection(safety_position)
                }
              />
              <SafetySection />
            </motion.div>
            {documents.length > 0 ? (
              <motion.div
                transition={{ type: "spring", stiffness: 200, damping: 26 }}
                style={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "5px",
                  marginTop: "10px",
                  boxShadow: "black 0 0 10px -5px",
                  overflow: "hidden",
                }}
                animate={{
                  height: section == documents_position ? "auto" : "40px",
                }}
              >
                <MobileMenuPoint
                  text={"Dokumente"}
                  icon={
                    "https://ik.imagekit.io/inventure/App/static/document_Z7rFSU0vMHF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642415577591"
                  }
                  onClick={() =>
                    section == documents_position
                      ? setsection(-1)
                      : setsection(documents_position)
                  }
                />
                <DocumentSection
                  headless
                  documents={documents.map((document) => {
                    switch (document.name) {
                      case "privacy_policy":
                        document.name = document.name = "Datenschutzerklärung";
                        break;
                      case "terms_of_service":
                        document.name = document.name = "AGB";
                        break;
                    }
                    return document;
                  })}
                />
              </motion.div>
            ) : null}
            {wallet ? (
              <motion.div
                transition={{ type: "spring", stiffness: 200, damping: 26 }}
                style={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "5px",
                  marginTop: "10px",
                  boxShadow: "black 0 0 10px -5px",
                  overflow: "hidden",
                }}
                animate={{
                  height: section == wallet_position ? "auto" : "40px",
                }}
              >
                <MobileMenuPoint
                  text={"Wallet"}
                  icon={
                    "https://ik.imagekit.io/inventure/App/static/wallet_Np2-Yzw8Ddq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642415710336"
                  }
                  onClick={() =>
                    section == wallet_position
                      ? setsection(-1)
                      : setsection(wallet_position)
                  }
                />
                <WalletSection headless wallet={wallet} />
              </motion.div>
            ) : null}
          </motion.div>
        </div>
      </Box>
    </div>
  );
}

type ProfileMenuPointProps = {
  onClick?: () => void;
  style?: React.CSSProperties;
  active?: boolean;
  text: string;
  icon?: string;
};

function ProfileMenuPoint({
  onClick,
  icon,
  style,
  active,
  text,
}: ProfileMenuPointProps) {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      onClick={onClick}
      id="profile-menu-point1"
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "right",
        cursor: "pointer",
        ...style,
      }}
    >
      <Typography
        style={{ minWidth: "130px", fontWeight: active ? 700 : 500 }}
        variant="h6"
      >
        {text}
      </Typography>
      <img style={{ height: "25px", marginLeft: "10px" }} src={icon} />
      <div
        style={{
          height: "30px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "10px",
        }}
      >
        <motion.div
          layout
          style={{
            width: active ? "calc(100% - 30px)" : "0px",
            height: "1px",
            backgroundColor: "#7198A3",
          }}
        />
      </div>
    </motion.div>
  );
}

function MobileMenuPoint({
  icon,
  onClick,
  style,
  active,
  text,
}: ProfileMenuPointProps) {
  return (
    <div
      style={{
        height: "40px",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <img style={{ height: "25px", marginLeft: "10px" }} src={icon} />
      <Typography variant="h6" style={{ marginLeft: "10px", lineHeight: "" }}>
        {text}
      </Typography>
    </div>
  );
}

type UserDataSectionProps = {
  user: InventureUser;
  headless?: boolean;
};

function UserDataSection({ user, headless }: UserDataSectionProps) {
  // -- Dinesh Kumar [START]
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const appContext = useContext(AppContext);
  const [userState, setUserState] = useState(2);

  // -- Dinesh Kumar [END]
  return (
    <motion.div
      id="user-data-section"
      initial={{ height: "0px" }}
      animate={{ height: "auto" }}
      style={{ overflow: "hidden" }}
      transition={transition}
    >
      <div style={{ padding: "20px" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ marginTop: { xs: "10px", md: "0px" } }}
          >
            <Typography variant="h6">Über dich</Typography>
            <DoubleDeckerAttribute
              style={{ marginTop: "10px" }}
              attribute="E-Mail"
              value={user.communication.email}
            />
            <DoubleDeckerAttribute
              style={{ marginTop: "10px" }}
              attribute="Geburtsdatum"
              value={dateToString(new Date(user?.natural_person.birth_date))}
            />
            <DoubleDeckerAttribute
              style={{ marginTop: "10px" }}
              attribute="Geburtsort"
              value={user.natural_person.birth_place}
            />
            <DoubleDeckerAttribute
              attribute="Staatsbürgerschaft"
              style={{ marginTop: "10px" }}
              value={countries.getName(user?.natural_person.citizenship, "de")}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ marginTop: { xs: "30px", md: "0px" } }}
          >
            <div style={{ display: "flex" }}>
              <Typography variant="h6">Adresse</Typography>
              {appContext.userState != USER_STATE.NONE &&
              appContext.userState != USER_STATE.ZERO ? (
                <img
                  src="https://ik.imagekit.io/inventure/App/static/icons/edit_NrMKLfoCv.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655723786205"
                  onClick={() => setDialogOpen(true)}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                ></img>
              ) : null}
              <SetAddressDialog
                user={user}
                open={dialogOpen}
                handleClose={() => setDialogOpen(false)}
                submit={() => {
                  window.location.reload();
                }}
              />
            </div>
            <DoubleDeckerAttribute
              style={{ marginTop: "10px" }}
              attribute="Straße und Hausnummer"
              value={user.natural_person.street}
            />

            {user.natural_person.address_addition ? (
              <DoubleDeckerAttribute
                style={{ marginTop: "10px" }}
                attribute="Adresszusatz"
                value={user.natural_person.address_addition}
              />
            ) : null}

            <DoubleDeckerAttribute
              style={{ marginTop: "10px" }}
              attribute="Postleitzahl und Stadt"
              value={
                user.natural_person.zip && user.natural_person.city
                  ? `${user.natural_person.zip} ${user.natural_person.city}`
                  : null
              }
            />
            <DoubleDeckerAttribute
              style={{ marginTop: "10px" }}
              attribute="Land"
              value={countries.getName(user?.natural_person.country, "de")}
            />
          </Grid>
        </Grid>
      </div>
    </motion.div>
  );
}

function BankSection({ user, headless }: UserDataSectionProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  var iban = user?.bank_account?.iban;
  return (
    <motion.div
      initial={{ height: "0px" }}
      animate={{ height: "auto" }}
      style={{ padding: "0px", overflow: "hidden" }}
      transition={transition}
    >
      <div style={{ padding: "20px" }}>
        {!headless ? <Typography variant="h5">Bankdaten</Typography> : null}
        <Typography variant="body1">
          Hier kannst du deine Bankdaten hinterlegen. Diese werden{" "}
          <b>ausschließlich</b> für <b>Auszahlungen</b> verwendet.
        </Typography>
        {iban == null ? (
          <Typography variant="body1"></Typography>
        ) : (
          <>
            <DoubleDeckerAttribute
              style={{ marginTop: "30px" }}
              attribute="IBAN"
              value={user.bank_account.iban}
            />
            <DoubleDeckerAttribute
              style={{ marginTop: "10px" }}
              attribute="BIC"
              value={`${user.bank_account.bic}`}
            />
            <DoubleDeckerAttribute
              style={{ marginTop: "10px" }}
              attribute="Kontoinhaber"
              value={user.bank_account.account_holder}
            />
          </>
        )}
        <PrimaryButton
          onClick={() => setDialogOpen(true)}
          style={{ width: "200px", height: "50px", margin: "20px 0 0 0" }}
        >
          {iban ? "Kontodaten anpassen" : "Konto hinterlegen"}
        </PrimaryButton>
        <SetBankDialog
          user={user}
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          submit={() => {
            setDialogOpen(false);
            window.location.reload();
          }}
        />
      </div>
    </motion.div>
  );
}

// -- Dinesh Kumar
function TaxSection({ user, headless }: UserDataSectionProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  var flag = false;
  return (
    <motion.div
      initial={{ height: "0px" }}
      animate={{ height: "auto" }}
      style={{ padding: "0px", overflow: "hidden" }}
      transition={transition}
    >
      <div style={{ padding: "20px" }}>
        {!headless ? (
          <Typography variant="h5">Tax Information</Typography>
        ) : null}
        <Typography variant="body1">
          You haven't added your Tax Information yet!{" "}
        </Typography>
        {flag == false ? (
          <Typography variant="body1"></Typography>
        ) : (
          <>
            <DoubleDeckerAttribute
              style={{ marginTop: "30px" }}
              attribute="Tax Identification"
              value={user.taxInformation.tax_identification_number}
            />

            <DoubleDeckerAttribute
              style={{ marginTop: "10px" }}
              attribute="Kontoinhaber"
              value={user.bank_account.account_holder}
            />
          </>
        )}
        <PrimaryButton
          onClick={() => setDialogOpen(true)}
          style={{ width: "200px", height: "50px", margin: "20px 0 0 0" }}
        >
          {flag ? "Edit" : "Add"}
        </PrimaryButton>
        <SetTaxDialog
          user={user}
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          submit={() => {
            setDialogOpen(false);
            window.location.reload();
          }}
        />
      </div>
    </motion.div>
  );
}

function SafetySection() {
  const [enrolled, setEnrolled] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [loading, setLoading] = useState(true);
  const appContext = useContext(AppContext);

  useEffect(() => {
    API_CLIENT.hasMFA(
      appContext.token,
      (result) => {
        setIsActivated(result);
        setLoading(false);
      },
      (error) => console.log(error)
    );
  }, []);

  if (loading) {
    return <LoadingScreen style={{ height: "200px", marginTop: "100px" }} />;
  }

  return (
    <motion.div
      initial={{ height: "0px" }}
      animate={{ height: "auto" }}
      style={{ padding: "0px", overflow: "hidden" }}
      transition={transition}
    >
      <div style={{ padding: "20px" }}>
        <Typography variant="h5">Sicherheitseinstellungen</Typography>
        <Typography variant="body1" style={{ marginTop: "30px" }}>
          <b>Multi-Faktor Authentifizierung</b>
        </Typography>
        {isActivated ? (
          <>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <img
                src="https://ik.imagekit.io/inventure/App/Icons/checked_KG2vbnfSJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1643014956108"
                style={{ height: "25px", marginRight: "10px" }}
              />
              <Typography variant="body1">
                Du hast einen zweiten Faktor bereits eingerichtet.
              </Typography>
            </div>
            <Typography variant="body2" style={{ marginTop: "10px" }}>
              Melde dich gerne bei uns, falls du weiter Fragen hast.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body2" style={{ marginTop: "0px" }}>
              Um dein Konto vor Angriffen zu schützen, empfehlen wir die
              Aktivierung eines zweiten Faktors. Hier kannst du die Einrichtung
              eines zweiten Faktors anstoßen. Nach einem Klick auf den Button
              wirst du eine E-Mail bekommen, über welche der Faktor aktiviert
              werden kann.
            </Typography>
            {!enrolled ? (
              <PrimaryButton
                onClick={() => {
                  API_CLIENT.enrollMFA(
                    appContext.token,
                    () => setEnrolled(true),
                    (error) => console.log(error)
                  );
                }}
                style={{
                  padding: "0 30px",
                  margin: "10px 0 0 0",
                  height: "50px",
                }}
              >
                Aktivieren
              </PrimaryButton>
            ) : (
              <Typography
                variant="body2"
                style={{ marginTop: "10px", fontWeight: 700 }}
              >
                Dir wurde eine Aktivierungsmail zugesandt
              </Typography>
            )}
          </>
        )}
      </div>
    </motion.div>
  );
}

type WalletSectionProps = {
  wallet: WalletType | null;
  headless?: boolean;
};

function WalletSection({ wallet, headless }: WalletSectionProps) {
  if (wallet == null) return <LoadingScreen style={{ height: "200px" }} />;
  return (
    <motion.div
      initial={{ height: "0px" }}
      animate={{ height: "auto" }}
      style={{ padding: "0px", overflow: "hidden" }}
      transition={transition}
    >
      <div style={{ padding: "20px" }}>
        {!headless ? <Typography variant="h5">Wallet</Typography> : null}
        <Typography variant="body1">
          Hier findest du Informationen zu deinem Wallet, welches von Tangany
          verwaltet wird.
        </Typography>
        <Grid container style={{ marginTop: "30px" }}>
          <Grid item xs={6}>
            <DoubleDeckerAttribute
              attribute={"Blockchain"}
              value={"Polygon Mainnet"}
            />
          </Grid>
          <Grid item xs={6}>
            <DoubleDeckerAttribute attribute={"Custodian"} value={"Tangany"} />
          </Grid>
        </Grid>
        <CopyText
          text={wallet.ethereumAddress}
          avatar={
            "https://ik.imagekit.io/inventure/App/blockchains/polygon-logo_tgrwjlF4i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1625994741312"
          }
          style={{ marginTop: "30px" }}
        />
      </div>
    </motion.div>
  );
}

function LegalPersonSection({}) {
  var appContext = useContext(AppContext);
  const legalPerson = appContext.user?.legal_person;
  if (legalPerson == undefined) return null;
  return (
    <ProfileSection>
      <div style={{ padding: "20px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5">Juristische Person</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }} />
          <Grid item xs={4}>
            <Typography variant="body1">Name</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{legalPerson.company_name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">Register</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">
              {legalPerson.commercial_register}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">Nummer</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">
              {legalPerson.commercial_register_number}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }} />
          <Grid item xs={4}>
            <Typography variant="body1">Staße</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{legalPerson.street}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">Stadt</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">
              {legalPerson.zip} {legalPerson.city}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">Land</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">
              {countries.getName(legalPerson.country, "de")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ fontWeight: 700, marginTop: "30px" }}
            >
              Wirtschaftlich berechtigte Personen
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BenefitingPersonManager />
          </Grid>
        </Grid>
      </div>
    </ProfileSection>
  );
}

type DocumentSectionProps = {
  documents: Document[];
  headless?: boolean;
};

function DocumentSection({ documents, headless }: DocumentSectionProps) {
  if (documents.length == 0) {
    return <LoadingScreen style={{ height: "400px" }} />;
  }
  return (
    <motion.div
      initial={{ height: "0px" }}
      animate={{ height: "auto" }}
      style={{ padding: "0px", overflow: "hidden" }}
      transition={transition}
    >
      <div
        style={{
          padding: "20px",
        }}
      >
        {!headless ? (
          <Typography variant="h5">Nutzerdokumente</Typography>
        ) : null}
        <div style={{ marginTop: "20px" }}>
          <Grid container>
            {documents?.map((document: Document, i) => (
              <Grid item xs={12} md={6}>
                <DocumentBoxWideDirect
                  key={i}
                  location={document.location}
                  name={document.name}
                  type={document.type}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </motion.div>
  );
}

type DoubleDeckerAttributeProp = {
  style?: React.CSSProperties;
  attribute: string;
  value: string | null;
};

function DoubleDeckerAttribute({
  style,
  attribute,
  value,
}: DoubleDeckerAttributeProp) {
  return (
    <div style={{ ...style }}>
      {value == null || value === "" || value == undefined ? (
        <div
          style={{
            height: "15px",
            backgroundColor: "rgba(200,200,200,0.3)",
            borderRadius: "5px",
            width: "100px",
            marginBottom: "4px",
          }}
        />
      ) : (
        <Typography variant="body1">
          <b>{value}</b>
        </Typography>
      )}
      <Typography
        variant="body2"
        style={{ fontSize: "12px", marginTop: "-4px", color: "rgb(50,50,50)" }}
      >
        {attribute}
      </Typography>
    </div>
  );
}

const dateToString = (date: Date): string => {
  if (isNaN(date.getTime())) {
    return "";
  }
  return date.toLocaleDateString();
};

interface ProfileSectionProps {
  children: React.ReactNode;
}
function ProfileSection({ children }: ProfileSectionProps) {
  return (
    <motion.div
      initial={{ height: "0px" }}
      animate={{ height: "auto" }}
      style={{ padding: "0px", overflow: "hidden" }}
      transition={transition}
    >
      {children}
    </motion.div>
  );
}
