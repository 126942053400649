import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import countries from "i18n-iso-countries";
import React, { useContext, useState } from "react";
import { AppContext } from "../../pages/portal/auth-router";
import ResponsiveDialog, {
  ResponsiveDialogV2,
} from "../dialogs/responsive-dialog";
import PrimaryButton, {
  PrimaryButtonV2,
  SecondaryButtonV2,
} from "../input-elements/buttons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import HookFormTextField, {
  HookFormCountrySelect,
  HookFormCountrySelectV2,
  HookFormDatePicker,
  HookFormDatePickerV2,
  HookFormEducationPicker,
  HookFormEducationPickerV2,
  HookFormProfessionPicker,
  HookFormProfessionPickerV2,
  HookFormSalutationSelect,
  HookFormSalutationSelectV2,
  HookFormTextFieldV2,
  HookFormTitleSelect,
  HookFormTitleSelectV2,
} from "../hook-form-inputs";
import API_CLIENT from "../../utility/api-client";
import {
  BenefitingPerson,
  InventureUser,
  SALUTATION,
  TITLE,
} from "../../types/inventure-user_types";
import { Controller, useForm } from "react-hook-form";
import { EDUCATION, PROFESSION } from "../../types/investment_types";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import LoadingScreen from "../loading-screen";
import EnumConverter from "../../utility/enum-converter";
import theme from "../../theme";
import { AppContext as DirectAppContext } from "../../pages/direct/direct-router";

export default function BenefitingPersonManager() {
  const appContext = useContext(AppContext);
  const [user, setUser] = useState(appContext.user);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [expanded, setExpanded] = React.useState<number | false>(-1);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" style={{ color: theme.palette.grey[900] }}>
          Wirtschaftlich Berechtigter ist eine natürliche Person, die letztlich
          Eigentümer des Anlegers (der Gesellschaft) ist oder ihn kontrolliert,
          oder auf deren Anweisung eine Transaktion letztlich durchgeführt oder
          eine Geschäftsbeziehung letztlich begründet wird.
          <br />
          <br />
          Jede natürliche Person ist wirtschaftlicher Berechtigter des Anlegers,
          wenn:
          <ul style={{ paddingLeft: "20px" }}>
            <li>
              Sie/Er direkt oder indirekt mehr als 25% der Kapitalanteile des
              Anlegers hält
            </li>
            <li>
              Sie/Er mehr als 25% der Stimmrechte des Anlegers kontrolliert
            </li>
            <li>
              Kontrolle über den Anleger ausübt (Fiktiver Wirtschaftlich
              Berechtigter)
            </li>
          </ul>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div>
          {user?.benefiting_persons.length == 0 ? (
            <Typography variant="body2" style={{ marginBottom: "5px" }}>
              Es wurde noch keine wirtschaftlich berechtigte Person hinzugefügt.
            </Typography>
          ) : (
            <Typography variant="body2" style={{ marginBottom: "5px" }}>
              Bereits hinzugefügt:
            </Typography>
          )}

          {user?.benefiting_persons?.map((person, i) => (
            <Accordion
              expanded={expanded === i}
              onChange={handleChange(i)}
              style={{
                boxShadow: "none",
                backgroundColor: "#f5efe6",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: theme.palette.grey[400],
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "80%", flexShrink: 0, fontWeight: 700 }}
                >
                  {person.forename} {person.surname}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DisplayBenefitingPerson person={person} />
              </AccordionDetails>
            </Accordion>
          ))}
          {user?.benefiting_persons.length == 0 ? null : (
            <Typography
              variant="body2"
              style={{ marginBottom: "5px", marginTop: "10px" }}
            >
              Hier kannst du weitere Personen hinzufügen.{" "}
              <b>Eine Investition ist bereits möglich.</b>
            </Typography>
          )}
          <div style={{ height: "10px" }} />
          <PrimaryButton
            onClick={() => setDialogOpen(true)}
            style={{ marginTop: "0px", minWidth: "250px" }}
          >
            Person hinzufügen
          </PrimaryButton>
          <ResponsiveDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <div style={{ padding: "30px", backgroundColor: "#fefaf4" }}>
              <div style={{ display: "flex" }}>
                {" "}
                <Typography
                  variant="h5"
                  style={{ marginBottom: "60px", fontWeight: 600 }}
                >
                  Person hinzufügen
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <CloseIcon
                  style={{ width: "30px", height: "30px" }}
                  onClick={() => setDialogOpen(false)}
                ></CloseIcon>
              </div>

              <BenefitingPersonForm
                onSuccess={(user) => {
                  setUser(user);
                  appContext.updateUser(user);
                  setDialogOpen(false);
                }}
              />
            </div>
          </ResponsiveDialog>
        </div>
      </Grid>
    </Grid>
  );
}

export function BenefitingPersonManagerV2() {
  const appContext = useContext(DirectAppContext);
  const [user, setUser] = useState(appContext.user);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [expanded, setExpanded] = React.useState<number | false>(-1);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className="grid gap-8">
      <div className="text-base">
        <p className="text-sm">
          Wirtschaftlich Berechtigter ist eine natürliche Person, die letztlich
          Eigentümer des Anlegers (der Gesellschaft) ist oder ihn kontrolliert,
          oder auf deren Anweisung eine Transaktion letztlich durchgeführt oder
          eine Geschäftsbeziehung letztlich begründet wird.
          <br />
          <br />
          <div className="p-4 bg-orange-100 text-orange-800 rounded-xl">
            Jede natürliche Person ist wirtschaftlicher Berechtigter des
            Anlegers, wenn:
            <ul className="list-disc pl-8 mt-2">
              <li>
                Sie/Er direkt oder indirekt{" "}
                <b>mehr als 25% der Kapitalanteile</b> des Anlegers hält
              </li>
              <li>
                Sie/Er <b>mehr als 25% der Stimmrechte</b> des Anlegers
                kontrolliert
              </li>
              <li>
                Kontrolle über den Anleger ausübt (Fiktiver Wirtschaftlich
                Berechtigter)
              </li>
            </ul>
          </div>
        </p>
      </div>
      <div>
        <div>
          {user?.benefiting_persons.length == 0 ? (
            <p className="text-sm">
              Es wurde noch keine wirtschaftlich berechtigte Person hinzugefügt.
            </p>
          ) : (
            <Typography variant="body2" style={{ marginBottom: "5px" }}>
              Bereits hinzugefügt:
            </Typography>
          )}

          {user?.benefiting_persons?.map((person, i) => (
            <Accordion
              expanded={expanded === i}
              onChange={handleChange(i)}
              style={{
                boxShadow: "none",
                backgroundColor: "white",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: theme.palette.grey[400],
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "80%", flexShrink: 0, fontWeight: 700 }}
                >
                  {person.forename} {person.surname}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DisplayBenefitingPerson person={person} />
              </AccordionDetails>
            </Accordion>
          ))}
          {user?.benefiting_persons.length == 0 ? null : (
            <Typography
              variant="body2"
              style={{ marginBottom: "5px", marginTop: "10px" }}
            >
              Hier kannst du weitere Personen hinzufügen.{" "}
              <b>Eine Investition ist bereits möglich.</b>
            </Typography>
          )}
          <div style={{ height: "10px" }} />
          <div className="flex gap-4">
            <PrimaryButtonV2
              onClick={() => setDialogOpen(true)}
              style={{ marginTop: "0px", minWidth: "250px" }}
            >
              Person hinzufügen
            </PrimaryButtonV2>
            <SecondaryButtonV2
              onClick={() => user && appContext.updateUser(user)}
              style={{ marginTop: "0px", minWidth: "250px" }}
              disabled={
                user?.benefiting_persons
                  ? user?.benefiting_persons.length == 0
                  : false
              }
            >
              Weiter
            </SecondaryButtonV2>
          </div>
          <ResponsiveDialogV2
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <div style={{ padding: "30px" }}>
              <div style={{ display: "flex" }}>
                <p className="text-xl font-medium">Person hinzufügen</p>
                <div style={{ flexGrow: 1 }} />
                <div
                  onClick={() => setDialogOpen(false)}
                  style={{
                    width: "25px",
                    height: "25px",
                    backgroundColor: "rgb(230,230,230)",
                    borderRadius: "50%",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "70%",
                      height: "2px",
                      backgroundColor: "rgb(150,150,150)",
                      rotate: "45deg",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      width: "70%",
                      height: "2px",
                      backgroundColor: "rgb(150,150,150)",
                      rotate: "-45deg",
                    }}
                  />
                </div>
              </div>

              <BenefitingPersonFormV2
                onSuccess={(user) => {
                  setUser(user);
                  setDialogOpen(false);
                }}
              />
            </div>
          </ResponsiveDialogV2>
        </div>
      </div>
    </div>
  );
}

interface DisplayBenefitingPersonProps {
  person: BenefitingPerson;
}

function DisplayBenefitingPerson({ person }: DisplayBenefitingPersonProps) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body1">
          {EnumConverter.salutationToString(person.salutation)}{" "}
          {EnumConverter.titleToString(person.title)} {person.forename}{" "}
          {person.surname}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{person.street}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {person.zip} {person.city}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {countries.getName(person.country, "de")}
        </Typography>
      </Grid>
    </Grid>
  );
}

interface BenefitingPersonFormProps {
  onSuccess: (person: InventureUser) => void;
}

function BenefitingPersonForm({ onSuccess }: BenefitingPersonFormProps) {
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<any>();
  const [greater25, setGreater25] = useState(false);

  const onSubmit = (data: BenefitingPerson) => {
    setLoading(true);
    API_CLIENT.postBenefitingPerson(
      appContext.token,
      (user) => {
        onSuccess(user);
        setLoading(false);
      },
      data
    );
  };
  return (
    <>
      {!loading ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",

            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <HookFormSalutationSelect
                name="salutation"
                control={control}
                label="Anrede"
                defaultValue={SALUTATION.MS}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTitleSelect
                name="title"
                control={control}
                label="Titel"
                defaultValue={TITLE.NONE}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextField
                required
                name="forename"
                control={control}
                label="Vorname"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextField
                required
                name="surname"
                control={control}
                label="Nachname"
                defaultValue={""}
                error={false}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextField
                required
                name="street"
                control={control}
                label="Straße"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextField
                required
                name="zip"
                control={control}
                label="Postleitzahl"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextField
                required
                name="city"
                control={control}
                label="Stadt"
                defaultValue={""}
                error={errors.city != null}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormCountrySelect
                name="country"
                control={control}
                label="Land"
                defaultValue={"DEU"}
                error={errors.country != null}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextField
                name="address_addition"
                control={control}
                label="Adresserweiterung"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextField
                required
                name="phone"
                control={control}
                label="Telefonnummer"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormCountrySelect
                name="nationality"
                control={control}
                label="Nationalität"
                defaultValue={"DEU"}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormDatePicker
                name="birth_date"
                control={control}
                label="Geburtsdatum"
                defaultValue={null}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextField
                required
                name="birth_place"
                control={control}
                label="Geburtsort"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormCountrySelect
                required
                name="birth_country"
                control={control}
                label="Geburtsland"
                defaultValue={"DEU"}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormEducationPicker
                name="educational_qualification"
                control={control}
                label="Bildungsabschluss"
                defaultValue={EDUCATION.NO_ANSWER}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormProfessionPicker
                name="occupation"
                control={control}
                label="Beruf"
                defaultValue={PROFESSION.WITHOUT}
                error={false}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "20px 0 0 0" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      value={greater25}
                      onChange={(e) => setGreater25(e.target.checked)}
                    />
                  }
                  label={
                    <b>
                      Die Person hält mehr als 25% der Gesellschaftsanteile.
                    </b>
                  }
                />
              </FormGroup>
            </Grid>
            {greater25 ? (
              <Grid item xs={12}>
                <Controller
                  name="pep_status"
                  control={control}
                  defaultValue={false}
                  rules={{
                    required: greater25,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      style={{ marginTop: "20px", textAlign: "left" }}
                      control={
                        <Checkbox
                          style={{ marginRight: "30px" }}
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      }
                      label={
                        <Typography variant="body1">
                          Ich bestätige, dass es sich bei der Person um keine
                          politisch exponierte Person (PEP
                          <Tooltip
                            title={
                              <Typography variant="body2">
                                Eine politisch exponierte Person (PEP) ist eine
                                Person, die selbst ein hochrangiges wichtiges
                                öffentliches Amt (z.B. Politiker, Botschafter,
                                Richter oberster Gerichte) ausübt, ausgeübt hat
                                oder eine Person aus deren unmittelbaren
                                persönlichen Umfeld (z.B Familienmitglieder oder
                                nahestehende Personen).{" "}
                              </Typography>
                            }
                            style={{
                              fontSize: "18px",
                              verticalAlign: "middle",
                            }}
                          >
                            <InfoRoundedIcon />
                          </Tooltip>
                          ), kein unmittelbares Familienmitglied einer politisch
                          exponierten Person und keine einer politisch
                          exponierten Person bekanntermaßen nahestende Person
                          handelt.
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <PrimaryButton
                style={{ width: "calc(100% - 00px)" }}
                disabled={!isValid}
                type="submit"
              >
                Person hinzufügen
              </PrimaryButton>
            </Grid>
          </Grid>
        </form>
      ) : (
        <LoadingScreen
          style={{ width: "300px", height: "100px", marginTop: "100px" }}
        />
      )}
    </>
  );
}

function BenefitingPersonFormV2({ onSuccess }: BenefitingPersonFormProps) {
  const appContext = useContext(DirectAppContext);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<any>();
  const [greater25, setGreater25] = useState(false);

  const onSubmit = (data: BenefitingPerson) => {
    setLoading(true);
    API_CLIENT.postBenefitingPerson(
      appContext.token,
      (user) => {
        onSuccess(user);
        setLoading(false);
      },
      data
    );
  };
  return (
    <>
      {!loading ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",

            flexDirection: "column",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <HookFormSalutationSelectV2
                name="salutation"
                control={control}
                label="Anrede"
                defaultValue={SALUTATION.MS}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTitleSelectV2
                name="title"
                control={control}
                label="Titel"
                defaultValue={TITLE.NONE}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextFieldV2
                required
                name="forename"
                control={control}
                label="Vorname"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextFieldV2
                required
                name="surname"
                control={control}
                label="Nachname"
                defaultValue={""}
                error={false}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextFieldV2
                required
                name="street"
                control={control}
                label="Straße"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextFieldV2
                required
                name="zip"
                control={control}
                label="Postleitzahl"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextFieldV2
                required
                name="city"
                control={control}
                label="Stadt"
                defaultValue={""}
                error={errors.city != null}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormCountrySelectV2
                name="country"
                control={control}
                label="Land"
                defaultValue={"DEU"}
                error={errors.country != null}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextFieldV2
                name="address_addition"
                control={control}
                label="Adresserweiterung"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextFieldV2
                required
                name="phone"
                control={control}
                label="Telefonnummer"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormCountrySelectV2
                name="nationality"
                control={control}
                label="Nationalität"
                defaultValue={"DEU"}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormDatePickerV2
                name="birth_date"
                control={control}
                label="Geburtsdatum"
                defaultValue={null}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormTextFieldV2
                required
                name="birth_place"
                control={control}
                label="Geburtsort"
                defaultValue={""}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormCountrySelectV2
                required
                name="birth_country"
                control={control}
                label="Geburtsland"
                defaultValue={"DEU"}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormEducationPickerV2
                name="educational_qualification"
                control={control}
                label="Bildungsabschluss"
                defaultValue={EDUCATION.NO_ANSWER}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HookFormProfessionPickerV2
                name="occupation"
                control={control}
                label="Beruf"
                defaultValue={PROFESSION.WITHOUT}
                error={false}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "20px 0 0 0" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      value={greater25}
                      onChange={(e) => setGreater25(e.target.checked)}
                    />
                  }
                  label={
                    <b>
                      Die Person hält mehr als 25% der Gesellschaftsanteile.
                    </b>
                  }
                />
              </FormGroup>
            </Grid>
            {greater25 ? (
              <Grid item xs={12}>
                <Controller
                  name="pep_status"
                  control={control}
                  defaultValue={false}
                  rules={{
                    required: greater25,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      style={{ marginTop: "20px", textAlign: "left" }}
                      control={
                        <Checkbox
                          style={{ marginRight: "30px" }}
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      }
                      label={
                        <Typography variant="body1">
                          Ich bestätige, dass es sich bei der Person um keine
                          politisch exponierte Person (PEP
                          <Tooltip
                            title={
                              <Typography variant="body2">
                                Eine politisch exponierte Person (PEP) ist eine
                                Person, die selbst ein hochrangiges wichtiges
                                öffentliches Amt (z.B. Politiker, Botschafter,
                                Richter oberster Gerichte) ausübt, ausgeübt hat
                                oder eine Person aus deren unmittelbaren
                                persönlichen Umfeld (z.B Familienmitglieder oder
                                nahestehende Personen).{" "}
                              </Typography>
                            }
                            style={{
                              fontSize: "18px",
                              verticalAlign: "middle",
                            }}
                          >
                            <InfoRoundedIcon />
                          </Tooltip>
                          ), kein unmittelbares Familienmitglied einer politisch
                          exponierten Person und keine einer politisch
                          exponierten Person bekanntermaßen nahestende Person
                          handelt.
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <PrimaryButton
                style={{ width: "calc(100% - 00px)" }}
                disabled={!isValid}
                type="submit"
              >
                Person hinzufügen
              </PrimaryButton>
            </Grid>
          </Grid>
        </form>
      ) : (
        <LoadingScreen
          style={{ width: "300px", height: "100px", marginTop: "100px" }}
        />
      )}
    </>
  );
}
