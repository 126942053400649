import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import React from "react";
import theme from "../theme";

const PREFIX = 'SubHeader';

const classes = {
  header: `${PREFIX}-header`
};

const Root = styled('div')({
  [`&.${classes.header}`]: {
      boxShadow: "rgb(0 0 0 / 20%) 0px 0px 8px",
    
      width: "100%",
      padding: "5px 0 5px 0",
      backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.between('xs', 'md')]: {
      position: "relative",
      top: 0
    },
  }
});

type CardProps = {
  titleDesktop: string;
  titleMobile: string;
};

function SubHeader({ titleDesktop, titleMobile }: CardProps) {


  return (
    <Root
      className={classes.header}
    >
      <Typography
        variant="body1"
        style={{
          textAlign: "center",
          color: "white",
          fontWeight: 500,
          margin: "auto auto auto auto",
        }}
      >
        {" "}
        {titleMobile}
      </Typography>
    </Root>
  );
}

export default SubHeader;
