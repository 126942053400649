import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import theme from "../theme";
import { Box, Grid, Typography } from "@mui/material";
const PREFIX = "footer";

const classes = {
  footerWrapper: `${PREFIX}-footerWrapper`,
  button: `${PREFIX}-button`,
  existLogo: `${PREFIX}-existLogo`,
};

const StyledGrid = styled(Grid)({
  [`&.${classes.footerWrapper}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    padding: "20px 20px 130px 20px",
  },
  [`& .${classes.button}`]: {
    color: "white",
  },
  [`& .${classes.existLogo}`]: {
    height: "80px",
    width: "100px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    float: "left",
  },
});

export default function Footer() {
  const [riskOpened, setRiskOpened] = useState(false);

  return (
    <StyledGrid
      id="footer"
      container
      spacing={0}
      justifyContent="center"
      className={classes.footerWrapper}
      style={{ position: "relative" }}
    >
      <Grid item xs={12} style={{ maxWidth: "1080px" }}>
        <div>
          <Grid container spacing={0} justifyContent="center">
            <Grid
              item
              xs={12}
              style={{ marginTop: "30px" }}
              sx={{ display: { xs: "block", md: "block" } }}
            >
              <Grid container spacing={0}>
                <Grid item md={12} lg={5} style={{ textAlign: "left" }}>
                  {/** BROWSER */}
                  <Box sx={{ display: { xs: "block", lg: "block" } }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div
                          style={{
                            marginTop: "0px",
                            height: "50px",
                            width: "100%",
                            backgroundImage: `url(${process.env.REACT_APP_LOGO_DARK})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ marginTop: "5px" }}
                        >
                          Democratizing Venture Capital
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          inVenture Capital iVC GmbH
                        </Typography>
                        <Typography variant="body2">Wegedornstr. 32</Typography>
                        <Typography variant="body2">12524 Berlin</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {/** Mobile */}
                </Grid>
                <Grid
                  item
                  md={12}
                  lg={4}
                  style={{ textAlign: "left" }}
                  sx={{ display: { xs: "none", lg: "block" } }}
                >
                  <a
                    href="https://www.inventure.de"
                    target="blank"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Typography variant="body1">Home</Typography>
                  </a>
                  <a
                    href="https://www.inventure.de/funktionsweise"
                    target="blank"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Typography variant="body1" style={{ marginTop: "20px" }}>
                      Funktionsweise
                    </Typography>
                  </a>
                  <a
                    href="https://www.inventure.de/impressum"
                    target="blank"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Typography variant="body1" style={{ marginTop: "20px" }}>
                      Impressum
                    </Typography>
                  </a>
                  <a
                    href="https://www.inventure.de/risikohinweis"
                    target="blank"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Typography variant="body1" style={{ marginTop: "20px" }}>
                      Risikohinweise
                    </Typography>
                  </a>
                </Grid>
                <Grid
                  item
                  md={12}
                  lg={3}
                  style={{ textAlign: "left" }}
                  sx={{
                    marginTop: { xs: "20px", md: "0px" },
                    display: { xs: "none", lg: "block" },
                  }}
                >
                  <Typography variant="body1">Kontakt</Typography>
                  <Typography variant="body2" style={{ marginTop: "20px" }}>
                    Melde Dich gerne bei uns, falls Du Fragen rund um das
                    Investieren auf der Investmentplattform von inVenture haben
                    solltest:
                  </Typography>
                  <a
                    href="mailto:invest@inventure.de"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Typography variant="body1" style={{ marginTop: "10px" }}>
                      invest@inventure.de
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "20px" }}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Typography variant="body2" style={{ marginTop: "30px" }}>
                Der Erwerb von tokenisierten Schuldverschreibungen ist mit
                erheblichen Risiken verbunden und kann zum vollständigen Verlust
                des eingesetzten Vermögens führen. Die Informationen auf dieser
                Plattform richten sich ausschließlich an Personen, die ihren
                Wohnsitz bzw. gewöhnlichen Aufenthalt in der Bundesrepublik
                Deutschland haben. Die Informationen stellen weder ein Angebot
                zum Kauf oder zur Zeichnung der tokenisierten
                Schuldverschreibungen noch eine Aufforderung zur Abgabe eines
                Angebots zum Erwerb der tokenisierten Schuldverschreibungen über
                die inVenture Plattform dar. Die Informationen haben
                ausschließlich werblichen Charakter. Insbesondere stellen diese
                Informationen keine Finanz- oder sonstige Anlageberatung dar.
                Die auf der Plattform enthaltenen Informationen können eine auf
                die individuellen Verhältnisse des Anlegers abgestellte anleger-
                und anlagegerechte Beratung nicht ersetzen. Die Emittentin weist
                deutlich darauf hin, dass zur Beurteilung der tokenisierten
                Schuldverschreibungen ausschließlich die Angaben im
                Basisinformationsblatt und den Anleihebedingungen der Emittentin
                maßgeblich sind, die auf der Internetseite veröffentlicht werden
                und dort kostenlos heruntergeladen werden kann.
              </Typography>
            </Grid>
            <Box
              sx={{ display: { xs: "block", md: "none" } }}
              style={{
                color: theme.palette.primary.contrastText,
                height: riskOpened ? "auto" : "60px",
                overflow: "hidden",
              }}
            >
              <Typography
                variant="body1"
                onClick={() => setRiskOpened(!riskOpened)}
                style={{ textDecoration: "underline", marginTop: "20px" }}
              >
                Risikohinweise
              </Typography>

              <Typography variant="body2" style={{ marginTop: "30px" }}>
                Der Erwerb von tokenisierten Schuldverschreibungen ist mit
                erheblichen Risiken verbunden und kann zum vollständigen Verlust
                des eingesetzten Vermögens führen. Die Informationen auf dieser
                Plattform richten sich ausschließlich an Personen, die ihren
                Wohnsitz bzw. gewöhnlichen Aufenthalt in der Bundesrepublik
                Deutschland haben. Die Informationen stellen weder ein Angebot
                zum Kauf oder zur Zeichnung der tokenisierten
                Schuldverschreibungen noch eine Aufforderung zur Abgabe eines
                Angebots zum Erwerb der tokenisierten Schuldverschreibungen über
                die inVenture Plattform dar. Die Informationen haben
                ausschließlich werblichen Charakter. Insbesondere stellen diese
                Informationen keine Finanz- oder sonstige Anlageberatung dar.
                Die auf der Plattform enthaltenen Informationen können eine auf
                die individuellen Verhältnisse des Anlegers abgestellte anleger-
                und anlagegerechte Beratung nicht ersetzen. Die Emittentin weist
                deutlich darauf hin, dass zur Beurteilung der tokenisierten
                Schuldverschreibungen ausschließlich die Angaben im
                Basisinformationsblatt und den Anleihebedingungen der Emittentin
                maßgeblich sind, die auf der Internetseite veröffentlicht werden
                und dort kostenlos heruntergeladen werden kann.
              </Typography>
            </Box>
          </Grid>
        </div>
      </Grid>
    </StyledGrid>
  );
}
