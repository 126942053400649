import { Typography } from "@mui/material";
import React from "react";

interface HeaderProps {
    children?: React.ReactNode
    style?: React.CSSProperties
}

export default function Header2({children, style}: HeaderProps) {
  return (
    <Typography
      variant="h5"
      style={{ color: "#494949", fontWeight: 600, ...style }}
    >
       {children}
    </Typography>
  );
}
