import React, { useContext, useState } from "react";
import API_CLIENT from "../../utility/api-client";
import { InventureUser, Address } from "../../types/inventure-user_types";
import { AppContext } from "../../pages/portal/auth-router";
import PrimaryButton from "../input-elements/buttons";
import HookFormTextField, { HookFormCountrySelect } from "../hook-form-inputs";
import { useForm } from "react-hook-form";
import LoadingScreen from "../loading-screen";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";

type SetAddressDialogProps = {
  user: InventureUser;
  open: boolean;
  handleClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  submit: Function;
};

export default function SetAddressDialog({
  user,
  submit,
  open,
  handleClose,
}: SetAddressDialogProps) {
  const [error, setError] = useState("");
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<any>({ mode: "onChange" });

  const onSubmit = (address: Address) => {
    setLoading(true);
    setAddressInformation(appContext.token, address);
    //setLoading(false);
  };

  const setAddressInformation = (token: String, address: Address) => {
    API_CLIENT.putAddress(
      token.toString(),
      address,
      () => {
        submit();
      },
      () => setError("sending Data Error")
    );
  };

  return (
    <AppContext.Consumer>
      {(appContext) =>
        AppContext && (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="address-dialog">Neue Adresse</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <>
                    {!loading ? (
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{
                          display: "flex",
                          height: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12} style={{ marginTop: "10px" }}>
                            <HookFormCountrySelect
                              name="country"
                              control={control}
                              label="Land"
                              defaultValue={"DEU"}
                              error={false}
                              required
                            />
                          </Grid>

                          <Grid item xs={12} md={8}>
                            <HookFormTextField
                              name="street"
                              control={control}
                              label="Straße und Hausnummer"
                              defaultValue={""}
                              error={false}
                              required
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <HookFormTextField
                              name="address_addition"
                              control={control}
                              label="Adresszusatz"
                              defaultValue={""}
                              error={false}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <HookFormTextField
                              name="zip"
                              control={control}
                              label="Postleitzahl"
                              defaultValue={""}
                              error={false}
                              required
                            />
                          </Grid>

                          <Grid item xs={12} md={8}>
                            <HookFormTextField
                              name="city"
                              control={control}
                              label="Stadt"
                              defaultValue={""}
                              error={false}
                              required
                            />
                          </Grid>
                        </Grid>

                        <Grid>
                          <Grid>
                            <PrimaryButton
                              type="submit"
                              style={{
                                width: "100%",
                                height: "50px",
                                margin: "50px 0px 0px 0px",
                              }}
                            >
                              Adresse aktualisieren
                            </PrimaryButton>
                          </Grid>
                        </Grid>
                      </form>
                    ) : (
                      <LoadingScreen
                        style={{
                          width: "300px",
                          height: "100px",
                          marginTop: "100px",
                        }}
                      />
                    )}
                  </>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )
      }
    </AppContext.Consumer>
  );
}
