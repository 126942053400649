import { Box } from "@mui/material";
import React from "react";
import StepWrapper, { StepWrapperStatic } from "./step-wrapper";

type MultistepFormStepProps = {
  children?: React.ReactNode;
  activeStep: number;
  step: number;
  disableAnimation?: boolean;
  style?: React.CSSProperties;
};

export default function MultistepFormStep({
  step,
  activeStep,
  children,
  disableAnimation,
  style,
}: MultistepFormStepProps) {
  return (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "contents",
            width: "100%",
            padding: "10px",
          },
        }}
      >
        {true ? (
          <StepWrapperStatic style={style} activestep={activeStep} step={step}>
            {children}
          </StepWrapperStatic>
        ) : (
          <StepWrapper activestep={activeStep} step={step}>
            {children}
          </StepWrapper>
        )}
      </Box>
      <Box
        style={{ width: "100%" }}
        sx={{
          display: {
            xs: activeStep == step ? "block" : "none",
            md: "none",
          },
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function MultistepFormStepV2({
  step,
  activeStep,
  children,
  disableAnimation,
  style,
}: MultistepFormStepProps) {
  return (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "contents",
            width: "100%",
          },
        }}
      >
        {true ? (
          <StepWrapperStatic
            style={{ padding: "10px 40px 20px 40px", ...style }}
            activestep={activeStep}
            step={step}
          >
            {children}
          </StepWrapperStatic>
        ) : (
          <StepWrapper activestep={activeStep} step={step}>
            {children}
          </StepWrapper>
        )}
      </Box>
      <Box
        style={{ width: "100%" }}
        sx={{
          display: {
            xs: activeStep == step ? "block" : "none",
            md: "none",
          },
        }}
      >
        {children}
      </Box>
    </>
  );
}
