import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  Switch,
  styled,
  SwitchProps,
  Box,
} from "@mui/material";
import { sha256 } from "js-sha256";
import React, { useState, useContext, useEffect } from "react";
import TagManager from "react-gtm-module";
import { AppContext } from "../../../../pages/portal/auth-router";
import { InvestmentContextNew } from "../../../../pages/portal/investment/investment-process-new";
import { MatchingDTO } from "../../../../types/generic_types";
import {
  Survey,
  EXPERIENCE,
  KNOWLEDGE,
  SERVICE_KNOWLEDGE_OR_EXPERIENCE,
  INVESTMENT_COUNT,
  INVESTMENT_SIZE,
  WAIVER_TYPE,
  Investment,
} from "../../../../types/investment_types";
import API_CLIENT from "../../../../utility/api-client";
import LoadingScreen from "../../../loading-screen";
import AbortInvestment from "../abort-investment";
import ReactGA from "react-ga";
import theme from "../../../../theme";
import MultistepForm from "../../../vertical-multistep-form/multistep-form";
import MultistepFormButtons from "../../../vertical-multistep-form/multistep-form-buttons";
import PrimaryButton, {
  SecondaryButton,
} from "../../../input-elements/buttons";
import IvSwitch from "../../../input-elements/iv-switch";

type ExperienceKnowledgeStepNewProps = {
  back: () => void;
  next: () => void;
};

export function ExperienceKnowledgeStepNew({
  back,
  next,
}: ExperienceKnowledgeStepNewProps) {
  const noAnswerSurvey: Survey = {
    investmentexperience_investment_fund: EXPERIENCE.NO_ANSWER,
    investmentknowledge_investment_fund: KNOWLEDGE.NO_ANSWER,
    investmentexperience_money_market_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_money_market_funds: KNOWLEDGE.NO_ANSWER,

    investmentexperience_bond: EXPERIENCE.NO_ANSWER,
    investmentknowledge_bond: KNOWLEDGE.NO_ANSWER,
    investmentexperience_share: EXPERIENCE.NO_ANSWER,
    investmentknowledge_share: KNOWLEDGE.NO_ANSWER,

    investmentexperience_participation_right: EXPERIENCE.NO_ANSWER,
    investmentknowledge_participation_right: KNOWLEDGE.NO_ANSWER,
    investmentexperience_unsecured_junior_debt: EXPERIENCE.NO_ANSWER,
    investmentknowledge_unsecured_junior_debt: KNOWLEDGE.NO_ANSWER,
    investmentexperience_crypto_currency: EXPERIENCE.NO_ANSWER,
    investmentknowledge_crypto_currency: KNOWLEDGE.NO_ANSWER,
    investmentexperience_pension_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_pension_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_fund_of_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_fund_of_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_stock_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_stock_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_participation: EXPERIENCE.NO_ANSWER,
    investmentknowledge_participation: KNOWLEDGE.NO_ANSWER,

    serviceexperience_independent: SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_ANSWER,
    serviceexperience_investment_consultancy:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_ANSWER,
    serviceexperience_investment_management:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_ANSWER,
    serviceexperience_investment_brokerage:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE,

    avgyearlytransactions: INVESTMENT_COUNT.NO_ANSWER,
    avgtransactionvalue: INVESTMENT_SIZE.NO_ANSWER,

    scoreKnowledgeExperience: 0,
    scoreTransactions: 0,
    scoreService: 0,
  };

  const emptySurvey: Survey = {
    investmentexperience_investment_fund: EXPERIENCE.NO_ANSWER,
    investmentknowledge_investment_fund: KNOWLEDGE.NO_KNOWLEDGE,
    investmentexperience_money_market_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_money_market_funds: KNOWLEDGE.NO_KNOWLEDGE,

    investmentexperience_bond: EXPERIENCE.NO_ANSWER,
    investmentknowledge_bond: KNOWLEDGE.NO_KNOWLEDGE,
    investmentexperience_share: EXPERIENCE.NO_ANSWER,
    investmentknowledge_share: KNOWLEDGE.NO_KNOWLEDGE,

    investmentexperience_participation_right: EXPERIENCE.NO_ANSWER,
    investmentknowledge_participation_right: KNOWLEDGE.NO_KNOWLEDGE,
    investmentexperience_unsecured_junior_debt: EXPERIENCE.NO_ANSWER,
    investmentknowledge_unsecured_junior_debt: KNOWLEDGE.NO_KNOWLEDGE,
    investmentexperience_crypto_currency: EXPERIENCE.NO_ANSWER,
    investmentknowledge_crypto_currency: KNOWLEDGE.NO_KNOWLEDGE,
    investmentexperience_pension_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_pension_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_fund_of_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_fund_of_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_stock_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_stock_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_participation: EXPERIENCE.NO_ANSWER,
    investmentknowledge_participation: KNOWLEDGE.NO_ANSWER,

    serviceexperience_independent:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE,
    serviceexperience_investment_consultancy:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE,
    serviceexperience_investment_management:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE,
    serviceexperience_investment_brokerage:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE,

    avgyearlytransactions: INVESTMENT_COUNT.NO_TRANS,
    avgtransactionvalue: INVESTMENT_SIZE.LOWER_3000,

    scoreKnowledgeExperience: 0,
    scoreTransactions: 0,
    scoreService: 0,
  };

  const [unsuitabilityCheckbox, setUnsuitabilityCheckbox] = useState(false);
  const [noAnswer, setNoAnswer] = useState(false);
  const [surveyUpdate, setSurveyUpdate] = useState(false);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [usePrevious, setUsePrevious] = useState(false);
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const [lastSurveyDone, setLastSurveyDone] = useState(false);
  const [lastPaidInvestmentLoaded, setLastPaidInvestmentLoaded] =
    useState(false);
  /**
   * ActiveStep
   * 0 = Survey
   * 1 = Survey Waiver Unsuitablilty
   * 2 = Survey Waiver No Answer
   */
  const [activeStep, setActiveStep] = useState(0);

  //Context
  const appContext = useContext(AppContext);
  const investmentContext = useContext(InvestmentContextNew);
  const fund = investmentContext.fund;
  const campaign = investmentContext.campaign;

  useEffect(() => {
    const investment = investmentContext.investment;
    window.scrollTo(0, 0);
    const surveyInvestmentScore = investment?.survey?.scoreKnowledgeExperience;
    const surveyServiceScore = investment?.survey?.scoreService;
    const surveyTransactionScore = investment?.survey?.scoreTransactions;

    const campaignInvestmentScore = campaign?.experienceAndKnowledgeScore;
    const campaignServiceScore = campaign?.serviceScore;
    const campaignTransactionScore = campaign?.amountAndVolumeScore;

    var surveyValid = false;
    if (
      surveyInvestmentScore &&
      surveyServiceScore &&
      surveyTransactionScore &&
      campaignInvestmentScore &&
      campaignServiceScore &&
      campaignTransactionScore
    ) {
      surveyValid =
        surveyInvestmentScore >= campaignInvestmentScore &&
        surveyServiceScore >= campaignServiceScore &&
        surveyTransactionScore >= campaignTransactionScore;
    }
    if (investmentContext.investment?.survey == null) {
      setSurvey(emptySurvey);
    } else {
      setSurvey(investmentContext.investment?.survey);
    }
  }, []);

  const investment = investmentContext.investment;
  if (investment == null) return null;
  if (campaign == null) return null;

  //API Calls
  const track = () => {
    var uData = appContext.matchingData;
    var mData: MatchingDTO = {
      event: "Wallet",
      eventId: investment.id,
      sourceUrl: window.location.href,
      em: uData?.em,
      ph: uData?.ph,
      fn: uData?.fn,
      ln: uData?.ln,
      product: fund?.name,
      focus: fund?.fokus,
      type: fund?.type,
      value: investment.amount.toString(),
      currency: "EUR",
    };
    API_CLIENT.postMatching(appContext.token, mData);

    TagManager.dataLayer({
      dataLayer: {
        event: "view-investment-process-wallet",
        event_id: investment.id,
        first_name: appContext.user?.natural_person.forename,
        last_name: appContext.user?.natural_person.surname,
        phone: uData?.ph,
        email: sha256(appContext.userEmail),
        product: fund?.name,
        focus: fund?.fokus,
        type: fund?.type,
        value: investment.amount,
        currency: "EUR",
      },
    });
  };

  const sendSurvey = (noAnswer?: boolean) => {
    window.scrollTo(0, 0);
    ReactGA.event({
      category: "Investment",
      action: "investment - step2 send filled survey",
      value: investment.amount,
    });
    setLoadingNextStep(true);
    if (survey == null) return;
    const surveyToSend = noAnswer ? noAnswerSurvey : survey;
    API_CLIENT.putInvestmentSurvey(
      appContext.token,
      investment.id,
      surveyToSend,
      () => {
        //this weird "null" forces the investment-process to rerender...didnt find another solution
        investmentContext.setInvestment(null);
        investment.survey_waiver = null;
        investmentContext.setInvestment(investment);
        setActiveStep(2);
        setLoadingNextStep(false);
      },
      () => {
        investmentContext.setInvestment(null);
        investment.survey_waiver = null;
        investmentContext.setInvestment(investment);
        setActiveStep(1);
        setLoadingNextStep(false);
      },
      (investment) => {
        track();
        investmentContext.setInvestment(investment);
        next();
        setTimeout(() => setLoadingNextStep(false), 1000);
      },
      (error) => {
        setLoadingNextStep(false);
      }
    );
  };

  const sendNoAnswerWaiver = () => {
    window.scrollTo(0, 0);
    ReactGA.event({
      category: "Investment",
      action: "investment - step2 survey waiver [no answer] accepted",
      value: investment.amount,
    });
    setLoadingNextStep(true);
    API_CLIENT.putSurveyWaiver(
      appContext.token,
      investment.id,
      WAIVER_TYPE.NO_ANSWERS,
      (investment: Investment) => {
        track();
        investmentContext.setInvestment(investment);

        setTimeout(() => setLoadingNextStep(false), 500);
      },
      (error: string) => {}
    );
  };

  const UseLastInvestmentDialogue = () => {
    if (investmentContext.lastInvestment == null) return null;
    return (
      <Box>
        <Typography variant="h5">Kenntnisse und Erfahrungen</Typography>
        <Typography style={{ marginTop: "15px" }} variant="body1">
          Du hast bei deiner letzten Investition bereits Deine Kenntnisse und
          Erfahrungen angegeben. Möchtest Du diese übernehmen?
        </Typography>
        <Grid container style={{ maxWidth: "500px" }}>
          <Grid item xs={12} sm={6} style={{ marginTop: "30px" }}>
            <PrimaryButton
              style={{ width: "calc(100% - 20px)" }}
              onClick={() => {
                if (investmentContext.lastInvestment) {
                  setSurvey(investmentContext.lastInvestment.survey);
                }
                setLastSurveyDone(true);
              }}
            >
              Ja
            </PrimaryButton>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: "30px" }}>
            <SecondaryButton
              onClick={() => {
                setSurvey(emptySurvey);
                setLastSurveyDone(true);
              }}
              style={{ width: "calc(100% - 20px)", padding: "0" }}
            >
              Erneut ausfüllen
            </SecondaryButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const sendUnsuitabilityWaiver = () => {
    window.scrollTo(0, 0);
    ReactGA.event({
      category: "Investment",
      action: "investment - step2 survey waiver [unsuitability] accepted",
      value: investment.amount,
    });
    setLoadingNextStep(true);
    API_CLIENT.putSurveyWaiver(
      appContext.token,
      investment.id,
      WAIVER_TYPE.UNSUITABLE,
      (investment: Investment) => {
        investmentContext.setInvestment(investment);
        track();
        setTimeout(() => setLoadingNextStep(false), 1000);
      },
      (error: string) => {
        console.log(error);
      }
    );
  };

  //Components
  const NoAnswerWaiver = () => {
    ReactGA.event({
      category: "Investment",
      action: "investment - step2 survey waiver [no answer] shown",
      value: investment.amount,
    });

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendNoAnswerWaiver();
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{ marginBottom: "20px", textAlign: "left" }}
            >
              Kenntnisnahme
            </Typography>
            <Typography variant="body1" style={{ textAlign: "left" }}>
              Die Angemessenheit beurteilt sich danach, ob Du als Anleger/-in
              über die erforderlichen Kenntnisse und Erfahrungen verfügst, um
              die Risiken im Zusammenhang mit der Art der Finanzinstrumente oder
              Wertpapierdienstleistungen angemessen beurteilen zu können. In den
              von Dir zurückgesendeten Unterlagen hast Du unvollständige oder im
              Wesentlichen keine Angaben zu Deinen bisherigen Erfahrungen und
              Kenntnissen im Zusammenhang mit Wertpapiergeschäften gemacht.
              Dadurch sind wir nicht in der Lage, unserer Pflicht der Prüfung
              der Angemessenheit der Anlage nachzukommen.
              <br />
              <br />
              Wir weisen Dich daher eindringlich darauf hin, dass eine
              Angemessenheitsprüfung bei fehlenden/unvollständigen Angaben nicht
              möglich ist. Du musst daher selbst entscheiden, ob die Wertpapiere
              für Dich angemessen sind. Insbesondere solltest Du Dich mit den
              Risiken und Gegebenheiten der Wertpapiere vertraut machen.
              <br />
              <br />
              Eine ausführliche Darstellung der mit der Zeichnung verbundenen
              Risiken befindet sich im Abschnitt „Risikofaktoren“ des für das
              jeweilige Angebot maßgeblichen Basisinformationsblatts. Solltest
              Du dennoch von einer Zeichnung Abstand nehmen wollen, kannst Du
              Dein 14-tägiges Widerrufsrecht geltend machen.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <MultistepFormButtons
              nextButtonText="Bestätigen"
              disabled={false}
              back
              backFunction={() => setActiveStep(0)}
            />
          </Grid>
        </Grid>
      </form>
    );
  };

  const UnsuitabilityWaiver = () => {
    ReactGA.event({
      category: "Investment",
      action: "investment - step2 survey waiver [unsuitability] shown",
      value: investment.amount,
    });
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (unsuitabilityCheckbox) sendUnsuitabilityWaiver();
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{ marginBottom: "20px", textAlign: "left" }}
            >
              Kenntnisnahme
            </Typography>
            <Typography variant="body1" style={{ textAlign: "left" }}>
              Die Auswertung der von Dir gemachten Angaben zu bisherigen
              Kenntnisse und Erfahrungen bei Wertpapieren, Vermögensanlagen und
              Kapitalmarktprodukten ergab, dass das hier angebotene Produkt bzw.
              die Höhe Deiner Zeichnung nicht dem auf Basis Deiner Angaben
              erstellten Anlageprofil entspricht. Gemäß Deiner Angaben verfügst
              Du über zu wenig Kenntnisse und Erfahrungen, um die mit dem Erwerb
              des hier angebotenen Produkts verbundenen Risiken ausreichend
              beurteilen zu können, d.h. Deine Zeichnung entspricht nicht Deinem
              bisherigen Anlageprofil.
            </Typography>
            <div
              style={{
                borderColor: "#ff9900",
                borderStyle: "solid",
                borderWidth: "2px",
                borderRadius: "5px",
                padding: "10px",
                margin: "20px 0 20px 0",
              }}
            >
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ textAlign: "justify" }}>
                    Bitte informiere Dich noch einmal in dem
                    Basisinformationsblatt und den Risikohinweisen über die
                    Ausgestaltung und Risikostruktur des Produktes.
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <Typography variant="body1" style={{ textAlign: "justify" }}>
              Wenn Du Dich entscheidest, das Produkt dennoch zu zeichnen, bitten
              wir Dich, Folgendes zu bestätigen:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: {
                xs: "column-reverse",
                md: "row",
              },
              alignItems: {
                xs: "flex-start",
                md: "center",
              },
            }}
            style={{
              marginBottom: "50px",
              display: "flex",
            }}
          >
            <IvSwitch
              sx={{
                minWidth: {
                  xs: "200px",
                  md: "100px",
                },
                maxWidth: {
                  xs: "",
                  md: "100px",
                },
                marginTop: {
                  xs: "20px",
                  md: "0px",
                },
              }}
              style={{
                marginRight: "20px",
              }}
              defaultValue={unsuitabilityCheckbox}
              onChange={(checked) => {
                setUnsuitabilityCheckbox(checked);
              }}
            />
            <Typography variant="body1">
              Ich bin darüber informiert, dass aufgrund der von mir angegebenen
              Kenntnisse und bisherigen Erfahrungen bei Wertpapieren,
              Vermögensanlagen und sonstigen Kapitalmarktprodukten die Zeichnung
              der Kapitalanlage nicht meinem Anlageprofil entspricht.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "50px",
            }}
          >
            <MultistepFormButtons
              nextButtonText="Bestätigen"
              disabled={!unsuitabilityCheckbox}
              back
              backFunction={() => setActiveStep(0)}
            />
          </Grid>
        </Grid>
      </form>
    );
  };

  const Survey = () => {
    if (!survey) return null;
    return (
      <Grid container>
        {usePrevious ? (
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{ marginBottom: "50px", marginTop: "50px" }}
            >
              Einschätzung Deiner Erfahrungen als investierende Person​
            </Typography>
            <Typography variant="body1">
              Du hast bereits Angaben für dieses Investment gemacht. Möchtest Du
              diese beibehalten?
            </Typography>
            <Grid container spacing={0} style={{ margin: "0px 0 0px 0" }}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  onClick={() => {
                    //onSkip(investment);
                  }}
                  style={{
                    width: "calc(100% - 40px)",
                    marginTop: "10px",
                  }}
                >
                  Überspringen
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  onClick={() => {
                    setUsePrevious(false);
                  }}
                  style={{
                    width: "calc(100% - 40px)",
                    marginTop: "10px",
                  }}
                >
                  Angaben Überprüfen
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                style={{
                  fontSize: "32px",
                  fontWeight: 500,
                  marginBottom: "20px",
                }}
              >
                Kenntnisse und Erfahrungen​
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "left", fontSize: "16px" }}
              >
                Gemäß Wertpapierhandelsgesetz (§ 63 Abs. 5 WpHG) sind wir
                verpflichtet, Deine Kenntnisse und Erfahrungen bezüglich
                verschiedener Produktklassen wie Wertpapieren, Vermögensanlagen
                und sonstigen Kapitalmarktprodukten abzufragen.
                <br />
                <br />
                Mittels der Antworten können wir für Dich prüfen, ob die
                Zeichnung dieser Kapitalanlage Deinen Kenntnissen und
                Erfahrungen aus Deinem bisherigen Anlageverhalten entspricht.
                Die Beantwortung dieser Fragen ist jedoch freiwillig. Machst Du
                keine oder unvollständige Angaben, können wir die Angemessenheit
                des jeweiligen Kapitalmarktproduktes nicht für Dich prüfen.
                Daher solltest Du aus eigenem Interesse Angaben machen.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {noAnswer ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendSurvey(true);
                  }}
                >
                  <Grid container spacing={0} style={{ marginTop: "50px" }}>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          style={{
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                          control={
                            <Checkbox
                              checked={noAnswer}
                              style={{ marginRight: "10px" }}
                              onChange={(event) => {
                                window.scrollTo(0, 0);
                                setNoAnswer(event.target.checked);
                              }}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="Ich möchte keine Angaben machen."
                        />
                      </Grid>
                    </Grid>
                    <MultistepFormButtons
                      nextButtonText="Bestätigen"
                      disabled={false}
                      back={false}
                      backFunction={() => null}
                    />
                  </Grid>
                </form>
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    sendSurvey();
                  }}
                >
                  <Grid
                    container
                    spacing={0}
                    style={{ overflow: "hidden", marginTop: "20px" }}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={0}
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="h3"
                            style={{ fontSize: "24px", fontWeight: 300 }}
                          >
                            Erfahrungen mit Kapitalmarktprodukten
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop: "10px",
                            padding: "10px 0px",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {/**
                        <Grid container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "0px",
                          }}
                        >
                          <Grid item xs={false} md={5}></Grid>
                          <Grid item xs={6} md={3}>   <Typography
                            variant="body1"
                            style={{
                              width: "25%",
                              minWidth: "100px",
                              fontWeight: 500,
                              textAlign: "left",
                              marginLeft: "5px"
                            }}
                          >
                            Kenntnisse
                          </Typography></Grid>
                          <Grid item xs={6} md={4}> <Typography
                            variant="body1"
                            style={{
                              width: "60%",
                              fontWeight: 500,
                              textAlign: "left",
                              marginLeft: "5px",
                            }}
                          >
                            Erfahrungen
                          </Typography></Grid>
                         
                       
                         
                        </Grid>
                         */}
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_investment_fund
                            }
                            defaultValueExperience={
                              survey.investmentexperience_investment_fund
                            }
                            question="Investmentfonds"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_investment_fund =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_investment_fund =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: "10px 0px",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_bond
                            }
                            defaultValueExperience={
                              survey.investmentexperience_bond
                            }
                            question="Anleihen"
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_bond = newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_bond = newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: "10px 0px",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_share
                            }
                            defaultValueExperience={
                              survey.investmentexperience_share
                            }
                            question="Aktien"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_share = newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_share = newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: "10px 0px",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_money_market_funds
                            }
                            defaultValueExperience={
                              survey.investmentexperience_money_market_funds
                            }
                            question="Geldmarktfonds"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_money_market_funds =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_money_market_funds =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: "10px 0px",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_participation_right
                            }
                            defaultValueExperience={
                              survey.investmentexperience_participation_right
                            }
                            question="Genussrechte​"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_participation_right =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_participation_right =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: "10px 0px",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_unsecured_junior_debt
                            }
                            defaultValueExperience={
                              survey.investmentexperience_unsecured_junior_debt
                            }
                            question="Nachrang-/Part. Darlehen"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_unsecured_junior_debt =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_unsecured_junior_debt =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                          />{" "}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: "10px 0px",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_crypto_currency
                            }
                            defaultValueExperience={
                              survey.investmentexperience_crypto_currency
                            }
                            question="Virtuelle Währungen"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_crypto_currency =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_crypto_currency =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                          />{" "}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                      <Grid
                        container
                        spacing={0}
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <Grid item xs={12} style={{ marginTop: "30px" }}>
                          <Typography
                            variant="h3"
                            style={{ fontSize: "24px", fontWeight: 300 }}
                          >
                            Erfahrungen mit Wertpapierdienstleistungen
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop: "20px",
                            padding: "10px 0",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <ServiceKnowledgeAndExperienceSelect
                            defaultValue={
                              survey.serviceexperience_investment_consultancy
                            }
                            question="Wie viel Erfahrung hast Du mit der Investition in Wertpapiere über eine Anlageberatung?"
                            onChange={(newvalue) => {
                              survey.serviceexperience_investment_consultancy =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: "10px 0",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <ServiceKnowledgeAndExperienceSelect
                            defaultValue={
                              survey.serviceexperience_investment_management
                            }
                            question="Wie viel Erfahrung hast Du mit der Investition in Wertpapiere über eine Vermögensberatung?"
                            onChange={(newvalue) => {
                              survey.serviceexperience_investment_management =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: "10px 0",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <ServiceKnowledgeAndExperienceSelect
                            defaultValue={survey.serviceexperience_independent}
                            question="Wie viel Erfahrung hast Du mit der eigenständigen, beratungsfreien Investition in Wertpapiere?"
                            onChange={(newvalue) => {
                              survey.serviceexperience_independent = newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!surveyUpdate);
                            }}
                          />{" "}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "50px" }}>
                      <Grid
                        container
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="h2"
                            style={{ fontSize: "24px", fontWeight: 300 }}
                          >
                            Häufigkeit und Volumen Deiner Transaktionen
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop: "20px",
                            padding: "10px 0",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12} md={6}>
                              {" "}
                              <Typography variant="body1">
                                Wie viele Investitionen tätigst Du pro Jahr?
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {" "}
                              <Select
                                native
                                style={{
                                  height: "45px",
                                  backgroundColor: "rgba(249, 248, 248, 0.4)",
                                  maxWidth: "500px",
                                  width: "100%",
                                }}
                                defaultValue={survey.avgyearlytransactions}
                                onChange={(event) => {
                                  survey.avgyearlytransactions = event.target
                                    .value as INVESTMENT_COUNT;
                                  setSurvey(survey);
                                  setSurveyUpdate(!surveyUpdate);
                                }}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                              >
                                <option value={INVESTMENT_COUNT.NO_TRANS}>
                                  Keine Investitionen
                                </option>
                                <option value={INVESTMENT_COUNT.LOWER_5_TRANS}>
                                  Bis zu 5 Investitionen
                                </option>
                                <option value={INVESTMENT_COUNT.LOWER_10_TRANS}>
                                  Bis zu 10 Investitionen
                                </option>
                                <option
                                  value={INVESTMENT_COUNT.MORE_THAN_10_TRANS}
                                >
                                  Über 10 Investitionen
                                </option>
                              </Select>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: "10px 0",
                            justifyContent: "space-between",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12} md={6}>
                              <Typography variant="body1">
                                Wie hoch ist der durchschnittliche Gegenwert der
                                Transaktionen aus der vorherigen Frage?​
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {" "}
                              <Select
                                native
                                style={{
                                  height: "45px",
                                  backgroundColor: "rgba(249, 248, 248, 0.4)",
                                  maxWidth: "500px",
                                  width: "100%",
                                }}
                                defaultValue={survey.avgtransactionvalue}
                                onChange={(event) => {
                                  survey.avgtransactionvalue = event.target
                                    .value as INVESTMENT_SIZE;
                                  setSurvey(survey);
                                  setSurveyUpdate(!surveyUpdate);
                                }}
                                inputProps={{
                                  name: "age",
                                  id: "age-native-simple",
                                }}
                              >
                                <option value={INVESTMENT_SIZE.LOWER_3000}>
                                  Bis 3.000€
                                </option>
                                <option
                                  value={INVESTMENT_SIZE.BETWEEN_3000_AND_5000}
                                >
                                  Zwischen 3.000€ und 5.000€
                                </option>
                                <option
                                  value={INVESTMENT_SIZE.BETWEEN_5000_AND_10000}
                                >
                                  Zwischen 5.000€ und 10.000€
                                </option>
                                <option value={INVESTMENT_SIZE.OVER_10000}>
                                  Mehr als 10.000€
                                </option>
                              </Select>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          style={{
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                          control={
                            <Checkbox
                              checked={noAnswer}
                              style={{ marginRight: "10px" }}
                              onChange={(event) => {
                                window.scrollTo(0, 0);
                                setNoAnswer(event.target.checked);
                              }}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="Ich möchte keine Angaben machen."
                        />
                      </Grid>
                    </Grid>
                    <MultistepFormButtons
                      nextButtonText="Bestätigen"
                      disabled={false}
                      back={true}
                      backFunction={back}
                    />

                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "50px",
                      }}
                    >
                      <AbortInvestment investment={investment} />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return (
    <>
      {survey ? (
        <>
          {loadingNextStep ? (
            <LoadingScreen style={{ width: "100%", height: "60vh" }} />
          ) : investmentContext.lastInvestment != null &&
            investmentContext.investment?.survey == null &&
            !lastSurveyDone ? (
            <UseLastInvestmentDialogue />
          ) : activeStep == 0 ? (
            <Survey />
          ) : activeStep == 1 ? (
            <NoAnswerWaiver />
          ) : activeStep == 2 ? (
            <UnsuitabilityWaiver />
          ) : null}
        </>
      ) : null}
    </>
  );
}

function KnowledgeAndExperienceSelect({
  defaultValueKnowledge,
  defaultValueExperience,
  question,
  onChangeKnowledge,
  onChangeExperience,
}: KnowledgeAndExperienceSelectProps) {
  const defaultValueKnowledgeBool =
    defaultValueKnowledge === KNOWLEDGE.NO_ANSWER ||
    defaultValueKnowledge === KNOWLEDGE.NO_KNOWLEDGE
      ? false
      : true;
  const returnExperience = (checked: boolean) =>
    checked ? KNOWLEDGE.KNOWLEDGE : KNOWLEDGE.NO_KNOWLEDGE;
  const [experience, setExperience] = useState(EXPERIENCE.NO_ANSWER);

  useEffect(() => {
    setExperience(defaultValueExperience);
  }, []);
  return (
    <div style={{ marginBottom: "4px", textAlign: "left" }}>
      <Grid container style={{ display: "flex" }}>
        <Grid item xs={12} md={5}>
          <div style={{ width: "calc(100% - 10px)", paddingRight: "10px" }}>
            <Typography
              variant="body1"
              style={{
                fontWeight: 500,
                minWidth: "100%",
                maxWidth: "100%",
              }}
              sx={{
                marginTop: {
                  xs: "0px",
                  md: "25px",
                },
              }}
            >
              {question}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div
            style={{
              paddingRight: "10px",
              display: "flex",
              flexDirection: "column",
              width: "calc(100% - 10px)",
              minWidth: "100px",
            }}
          >
            <Typography
              variant="body2"
              style={{ marginLeft: "0px", color: "rgb(80,80,80)" }}
            >
              Kenntnisse
            </Typography>
            <IvSwitch
              defaultValue={defaultValueKnowledgeBool}
              onChange={(e) => {
                onChangeKnowledge(returnExperience(e));
              }}
            ></IvSwitch>
          </div>
        </Grid>
        <Grid item xs={6} md={4}>
          <div style={{ width: "calc(100% - 10px)", paddingRight: "10px" }}>
            <Typography variant="body2" style={{ color: "rgb(80,80,80)" }}>
              Erfahrungen
            </Typography>
            <Select
              native
              style={{
                borderWidth: 0.4,
                backgroundColor: "rgba(249, 248, 248, 0.4)",
                height: "41px",
                maxWidth: "300px",
                width: "100%",
              }}
              defaultValue={defaultValueExperience}
              onChange={(event) => {
                setExperience(event.target.value as EXPERIENCE);
                onChangeExperience(event.target.value as EXPERIENCE);
              }}
              inputProps={{
                name: "age",
                id: "age-native-simple",
              }}
            >
              <option value={EXPERIENCE.NO_ANSWER}>Keine</option>
              <option value={EXPERIENCE.ZERO_TO_THREE_YEARS}>
                {"< 3 Jahre"}
              </option>
              <option value={EXPERIENCE.THREE_TO_FIVE_YEARS}>
                {"3 - 5 Jahre"}
              </option>
              <option value={EXPERIENCE.GREATER_THAN_FIVE_YEARS}>
                {"> 5 Jahre"}
              </option>
            </Select>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function ServiceKnowledgeAndExperienceSelect({
  defaultValue,
  question,
  onChange,
}: ServiceKnowledgeAndExperienceSelectProps) {
  const [selected, setSelected] = useState(
    SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_ANSWER
  );

  useEffect(() => {
    setSelected(defaultValue);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant="body1">{question}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Select
          native
          style={{
            maxWidth: "500px",
            width: "100%",
            height: "45px",
            backgroundColor: "rgba(249, 248, 248, 0.4)",
          }}
          defaultValue={defaultValue}
          onChange={(event) => {
            setSelected(event.target.value as SERVICE_KNOWLEDGE_OR_EXPERIENCE);
            onChange(event.target.value as SERVICE_KNOWLEDGE_OR_EXPERIENCE);
          }}
          inputProps={{
            name: "age",
            id: "age-native-simple",
          }}
        >
          <option
            value={SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE}
          >
            Keine Erfahrung
          </option>
          <option value={SERVICE_KNOWLEDGE_OR_EXPERIENCE.ZERO_TO_THREE_YEARS}>
            Bis 3 Jahre Erfahrung
          </option>
          <option value={SERVICE_KNOWLEDGE_OR_EXPERIENCE.THREE_TO_FIVE_YEARS}>
            Zwischen 3 und 5 Jahre Erfahrung
          </option>
          <option
            value={SERVICE_KNOWLEDGE_OR_EXPERIENCE.GREATER_THAN_FIVE_YEARS}
          >
            Über 5 Jahre Erfahrung
          </option>
        </Select>
      </Grid>
    </Grid>
  );
}

type KnowledgeAndExperienceSelectProps = {
  defaultValueKnowledge: KNOWLEDGE;
  defaultValueExperience: EXPERIENCE;
  question: string;
  onChangeExperience: (value: EXPERIENCE) => void;
  onChangeKnowledge: (value: KNOWLEDGE) => void;
};

type ServiceKnowledgeAndExperienceSelectProps = {
  defaultValue: SERVICE_KNOWLEDGE_OR_EXPERIENCE;
  question: string;
  onChange: (value: SERVICE_KNOWLEDGE_OR_EXPERIENCE) => void;
};
