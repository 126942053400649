import React, { useContext } from "react";
import { useState } from "react";
import PostIdentButton from "../../../components/page-components/onboarding/postident-button";
import { AppContext } from "../direct-router";
import IVDrawer from "../../../components/template-components/iv-drawer";

export default function VideoIdent() {
  const app_context = useContext(AppContext);
  const [videoident_open, setVideoIdentOpen] = useState(false);
  const user = app_context.user;
  return (
    <div className=" bg-orange-100 text-orange-700 rounded-xl px-4 py-4 flex flex-col items-start gap-2">
      <div className="flex justify-between  w-full">
        <p className="text-xs font-normal ">
          Schließe hier deine <b>Legitimation</b> ab
        </p>
        <img
          src="https://ik.imagekit.io/inventure/App/Icons/icons8-video-conference-90__1__5Pmf02fbG.png?updatedAt=1689250215081"
          className="h-5 -mt-1"
        />
      </div>
      <div className="flex-grow" />
      <p
        onClick={() => setVideoIdentOpen(true)}
        className="text-sm px-4 py-2 rounded-lg border border-orange-700 cursor-pointer hover:bg-orange-700 hover:text-white"
      >
        Starten
      </p>
      <IVDrawer
        open={videoident_open}
        close={() => {
          setVideoIdentOpen(false);
        }}
      >
        <div className="p-4 text-black grid gap-1">
          <p className="text-xl font-semibold">Videoidentifikation</p>
          <div className="grid gap-8">
            <p className="text-gray-600 text-base">
              Damit wir deine Investition akzeptieren können, musst du das
              Legitimationsverfahren der Deutschen Post durchführen.
            </p>
            <ul className="list-disc pl-4 grid gap-2">
              <li>
                {" "}
                Der Vorgang dauert ca. <b>5-10 Minuten</b>. Nach erfolgreichem
                Abschluss erhältst du eine Mail.
              </li>
              <li>
                <p>Halte bitte deinen Ausweis oder Reisepass bereit.</p>
              </li>
              <li>
                Wir empfehlen das Verfahren über die App der Deutschen Post
                durchzuführen.
              </li>
            </ul>
            {user ? (
              <PostIdentButton
                firstName={user.natural_person.forename}
                lastName={user.natural_person.surname}
                email={user.communication.email}
                city={user.natural_person.city}
                country={user.natural_person.country}
                phone={user.natural_person.phone}
                street={user.natural_person.street}
                title={user.natural_person.title}
                uid={user.uid}
                zip={user.natural_person.zip}
              />
            ) : null}
          </div>
        </div>
      </IVDrawer>
    </div>
  );
}
