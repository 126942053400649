
import React from "react";

import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Avatar, Box, Chip, ClickAwayListener, Tooltip } from "@mui/material";

type WalletChip = {
  text: string;
  style?: React.CSSProperties;
  avatar: string;
};

export default function CopyText({ text, style, avatar }: WalletChip) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const copy = () => {
    navigator.clipboard.writeText(text);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Tooltip
            style={{ textOverflow: "ellipsis" }}
            placement="top"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}    
            title={open? "erfolgreich kopiert" : "kopieren"}
          >
            <Chip
             onClick={() => { copy();
                handleTooltipOpen();}}
              sx={{ display: { xs: "none", md: "flex" } }}
              style={{ ...style }}
              label={text}
              variant="outlined"
              deleteIcon={<FileCopyOutlinedIcon />}
              onDelete={() => {
                copy();
                handleTooltipOpen();
              }}
              avatar={<Avatar src={avatar} />}
            />
          </Tooltip>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <Tooltip
            style={{ textOverflow: "ellipsis" }}
            placement="top"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            title={open ? "erfolgreich kopiert" : "kopieren"}
          >
            <Chip
            onClick={() => { copy();
                handleTooltipOpen();}}
              sx={{ display: { xs: "flex", md: "none" } }}
              style={{ ...style }}
              label={text.slice(0, 19) + "..."}
              variant="outlined"
              deleteIcon={<FileCopyOutlinedIcon />}
              onDelete={() => {
                copy();
                handleTooltipOpen();
              }}
              avatar={<Avatar src={avatar} />}
            />
          </Tooltip>
        </Box>
      </div>
    </ClickAwayListener>
  );
}
