import { Box } from "@mui/material";
import React from "react";
import theme from "../../theme";
import PrimaryButton, {
  PrimaryButtonV2,
  SecondaryButton,
  SecondaryButtonV2,
} from "../input-elements/buttons";

interface MultistepFormButtonsProps {
  style?: React.CSSProperties;
  back: boolean;
  backFunction: () => void;
  disabled: boolean;
  nextButtonText?: string;
}

export default function MultistepFormButtons({
  style,
  back,
  disabled,
  nextButtonText,
  backFunction,
}: MultistepFormButtonsProps) {
  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        {back ? (
          <SecondaryButton
            style={{
              position: "sticky",
              width: "150px",
              margin: "40px 0px 0px 0px",
              marginRight: "10px",
            }}
            onClick={() => backFunction()}
          >
            Zurück
          </SecondaryButton>
        ) : null}
        <PrimaryButton
          disabled={disabled}
          style={{
            margin: "40px 0px 0px 0px",
            minWidth: "200px",
          }}
          type="submit"
        >
          {nextButtonText ? nextButtonText : "Weiter"}
        </PrimaryButton>
      </Box>
      <Box
        sx={{ display: { xs: "block", md: "none" } }}
        style={{
          position: "fixed",
          left: "0",
          bottom: "61px",
          width: "100vw",
          zIndex: 100,
          backgroundColor: theme.palette.background.paper,
          padding: "10px",
          ...style,
        }}
      >
        <PrimaryButton
          disabled={disabled}
          style={{
            borderRadius: "5px",
            width: "calc(100% - 20px)",
            height: "50px",
            margin: "0px",
            marginTop: "10px",
          }}
          type="submit"
        >
          {nextButtonText ? nextButtonText : "Weiter"}
        </PrimaryButton>
      </Box>
    </>
  );
}

export function MultistepFormButtonsV2({
  style,
  back,
  disabled,
  nextButtonText,
  backFunction,
}: MultistepFormButtonsProps) {
  return (
    <>
      <div className="hidden md:flex gap-4 mt-0">
        {back ? (
          <SecondaryButtonV2
            style={{
              position: "sticky",
              width: "150px",

              marginRight: "10px",
            }}
            onClick={() => backFunction()}
          >
            Zurück
          </SecondaryButtonV2>
        ) : null}
        <PrimaryButtonV2
          disabled={disabled}
          style={{
            minWidth: "200px",
          }}
          type="submit"
        >
          {nextButtonText ? nextButtonText : "Weiter"}
        </PrimaryButtonV2>
      </div>
      <div
        className="border-gray-300 block md:hidden"
        style={{
          position: "fixed",
          left: "0",
          bottom: "56px",
          width: "100vw",
          zIndex: 100,
          backgroundColor: "white",
          padding: "10px",
          borderTop: "solid",
          borderWidth: "0.5px",
          borderLeft: "0",
          borderRight: "0",
          borderBottom: "0",
          ...style,
        }}
      >
        <PrimaryButtonV2 disabled={disabled} type="submit">
          {nextButtonText ? nextButtonText : "Weiter"}
        </PrimaryButtonV2>
      </div>
    </>
  );
}
