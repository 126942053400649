import { Button, Checkbox, Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Investment } from "../../../../types/investment_types";
import AmountUtils from "../../../../utility/amount-utils";
import API_CLIENT from "../../../../utility/api-client";
import LoadingScreen from "../../../loading-screen";
import { Campaign } from "../../../../types/fund_types";
import { Document, MatchingDTO } from "../../../../types/generic_types";
import { AppContext } from "../../../../pages/portal/auth-router";
import CostRepresentation from "../cost-representation";
import PdfLinkOverlay from "../../../data-display/pdf-link-overlay";
import { Link } from "react-router-dom";
import AbortInvestment from "../abort-investment";
import { InvestmentContext } from "../../../../pages/portal/investment/investment-process";
import TagManager from "react-gtm-module";
import { sha256 } from "js-sha256";
import { InvestmentContextNew } from "../../../../pages/portal/investment/investment-process-new";
import IvSwitch from "../../../input-elements/iv-switch";
import MultistepFormButtons from "../../../vertical-multistep-form/multistep-form-buttons";
import ReactGA from "react-ga";

type ConfirmInvestmentStepProps = {
  back: () => void;
};

export default function SummaryStep({ back }: ConfirmInvestmentStepProps) {
  const [effectaChecked, setEffectaChecked] = useState(false);
  const appContext = useContext(AppContext);
  const investmentContext = useContext(InvestmentContextNew);
  const campaign = investmentContext.campaign;
  const investment = investmentContext.investment;
  const fund = investmentContext.fund;

  const [zuwendungenChecked, setZuwendungenChecked] = useState(false);
  const [
    customerInformationParticipationRightInvestmentAgreementChecked,
    setCustomerInformationParticipationRightChecked,
  ] = useState(false);
  const [basicInformatoinSheetChecked, setBasicInformationSheetChecked] =
    useState(false);

  const [loadingNextStep, setLoadingNextStep] = useState(false);

  var tanganyTerms: Document | undefined;
  var customerInformation: Document | undefined;
  var bondTerms: Document | undefined;
  var informationSheet: Document | undefined;
  var effectaAGB: Document | undefined;
  var effectaConsumerInformation: Document | undefined;
  var risikohinweise: Document | undefined;
  var cashlinkAGB: Document | undefined;

  const track = (investment: Investment) => {
    var uData = appContext.matchingData;
    var mData: MatchingDTO = {
      event: "Purchase",
      eventId: investment.id,
      sourceUrl: window.location.href,
      em: uData?.em,
      ph: uData?.ph,
      fn: uData?.fn,
      ln: uData?.ln,
      product: fund?.name,
      focus: fund?.fokus,
      type: fund?.type,
      value: investment.amount.toString(),
      currency: "EUR",
    };
    API_CLIENT.postMatching(appContext.token, mData);

    TagManager.dataLayer({
      dataLayer: {
        event: "purchase",
        event_id: investment.id,
        user_id: appContext.sub,
        first_name: appContext.user?.natural_person.forename,
        last_name: appContext.user?.natural_person.surname,
        phone: uData?.ph,
        email: sha256(appContext.userEmail),
        product: fund?.name,
        focus: fund?.fokus,
        type: fund?.type,
        value: investment.amount,
        currency: "EUR",
      },
    });
  };

  const submitInvestment = () => {
    window.scrollTo(0, 0);
    if (investment === null) return;
    API_CLIENT.makeBindingOffer(
      appContext.token,
      investment.id,
      (investment: Investment) => {
        track(investment);
        (window as any).dataLayer.push({
          event: "investment-accepted",
          user_id: appContext.sub,
          "investment-amount": investment.amount,
        });
        ReactGA.event({
          category: "Investment",
          action: "investment completed",
          value: investment.amount,
        });

        investmentContext.setInvestment(investment);
      },
      (error: string) => {
        console.log(error);
      }
    );
  };

  campaign?.documents.forEach((document) => {
    if (document.name === "Verbraucherinformationen") {
      customerInformation = document;
    } else if (document.name === "Anleihebedingungen") {
      bondTerms = document;
    } else if (document.name === "Risikohinweise") {
      risikohinweise = document;
    } else if (document.name === "Basisinformationsblatt") {
      informationSheet = document;
    } else if (document.name === "Effecta AGB") {
      effectaAGB = document;
    } else if (document.name === "Effecta Verbraucherinformationen") {
      effectaConsumerInformation = document;
    } else if (document.name === "Cashlink AGB") {
      cashlinkAGB = document;
    }
  });

  if (investment == null) return null;

  var documentMissing =
    effectaAGB == null ||
    informationSheet == null ||
    effectaConsumerInformation == null ||
    customerInformation == null ||
    bondTerms == null ||
    investment.costDocumentId == null ||
    (cashlinkAGB == null && campaign?.ewpg);
  var hasRiskDocument = risikohinweise != null;

  return (
    <>
      {loadingNextStep || campaign === undefined || campaign === null ? (
        <LoadingScreen
          style={{ width: "100%", height: "calc(100vh - 200px)" }}
        />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setLoadingNextStep(true);
            submitInvestment();
          }}
        >
          <Grid container spacing={4} style={{ textAlign: "left" }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "32px",
                  fontWeight: 500,
                  marginBottom: "20px",
                }}
                variant="h2"
              >
                Zusammenfassung
              </Typography>
              <Typography
                variant="h3"
                style={{ fontWeight: 700, fontSize: "24px", marginTop: "40px" }}
              >
                {investment.campaignName}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "24px",
                }}
              >
                Tokenisierte Schuldverschreibung
              </Typography>
            </Grid>
            {documentMissing ? (
              "Error: Documents missing"
            ) : (
              <>
                <Grid item xs={12} md={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: "20px", fontWeight: 600 }}
                      >
                        Investitionsbetrag
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        style={{ lineHeight: "20px", fontSize: "20px" }}
                      >
                        {(
                          investment.amount * investment.tokenPrice
                        ).toLocaleString("de-de", {
                          minimumFractionDigits: 0,
                        }) + "€"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {AmountUtils.toString(investment.disagio) != "" ? (
                  <Grid item xs={12} md={4}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontSize: "20px", fontWeight: 600 }}
                        >
                          Gutschein
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle1"
                          style={{ lineHeight: "20px", fontSize: "20px" }}
                        >
                          {AmountUtils.toString(investment.disagio)}
                        </Typography>
                      </Grid>
                    </Grid>{" "}
                  </Grid>
                ) : null}

                <Grid item xs={12} md={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: "20px", fontWeight: 600 }}
                      >
                        Zu zahlender Betrag
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        style={{ lineHeight: "20px", fontSize: "20px" }}
                      >
                        {(
                          investment.amount * investment.tokenPrice -
                          Number(investment.disagio.amount) /
                            Math.pow(10, investment.disagio.decimals)
                        ).toLocaleString("de-de", {
                          minimumFractionDigits: 2,
                        }) + "€"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <Typography
                        variant="h3"
                        style={{ fontSize: "24px", fontWeight: 600 }}
                      >
                        Empfangsbestätigung​
                      </Typography>
                      <Typography variant="subtitle1">
                        Hiermit bestätige ich, folgende Unterlagen rechtzeitig
                        vor Abgabe dieser Zeichnungserklärung entweder in
                        elektronischer Form zugesandt bekommen, heruntergeladen
                        oder ausgedruckt habe und diese gelesen und verstanden
                        habe:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container style={{ margin: "20px 0 20px 0" }}>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <IvSwitch
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              height: "35px",
                            }}
                            defaultValue={basicInformatoinSheetChecked}
                            onChange={(checked) =>
                              setBasicInformationSheetChecked(checked)
                            }
                          />

                          <Typography
                            style={{ marginLeft: "10px" }}
                            variant="body1"
                          >
                            Hiermit bestätige ich, das{" "}
                            <PdfLinkOverlay
                              title="Basisinformationsblatt"
                              pdf={informationSheet!!.location}
                            >
                              Basisinformationsblatt
                            </PdfLinkOverlay>{" "}
                            {hasRiskDocument ? (
                              <>
                                und die{" "}
                                <PdfLinkOverlay
                                  title="Risikohinweise"
                                  pdf={risikohinweise!!.location}
                                >
                                  Risikohinweise
                                </PdfLinkOverlay>{" "}
                              </>
                            ) : null}
                            erhalten zu haben.
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <IvSwitch
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              height: "35px",
                            }}
                            defaultValue={
                              customerInformationParticipationRightInvestmentAgreementChecked
                            }
                            onChange={(checked) =>
                              setCustomerInformationParticipationRightChecked(
                                checked
                              )
                            }
                          />

                          <Typography
                            style={{ marginLeft: "10px" }}
                            variant="body1"
                          >
                            Hiermit bestätige ich, die{" "}
                            <PdfLinkOverlay
                              title="Anleihebedingungen"
                              pdf={bondTerms!!.location}
                            >
                              Anleihebedingungen
                            </PdfLinkOverlay>
                            , sowie die{" "}
                            <PdfLinkOverlay
                              title="Verbraucherinformationen"
                              pdf={customerInformation!!.location}
                            >
                              Verbraucherinformationen inklusive Hinweise zum
                              Widerrufsrecht
                            </PdfLinkOverlay>{" "}
                            erhalten zu haben.
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <IvSwitch
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              height: "35px",
                            }}
                            defaultValue={zuwendungenChecked}
                            onChange={(checked) =>
                              setZuwendungenChecked(checked)
                            }
                          />

                          <Typography
                            style={{ marginLeft: "10px" }}
                            variant="body1"
                          >
                            Hiermit bestätige und akzeptiere ich die{" "}
                            <PdfLinkOverlay
                              title="Kosten und Zuwendungen"
                              pdf={investment.costDocumentId}
                            >
                              Kosten und Zuwendungen
                            </PdfLinkOverlay>
                            .
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <IvSwitch
                            style={{
                              minWidth: "100px",
                              maxWidth: "100px",
                              height: "35px",
                            }}
                            defaultValue={effectaChecked}
                            onChange={(checked) => setEffectaChecked(checked)}
                          />

                          <Typography
                            style={{ marginLeft: "10px" }}
                            variant="body1"
                          >
                            Hiermit nehme ich die{" "}
                            <PdfLinkOverlay
                              title="Effecta Verbraucherinformationen"
                              pdf={effectaConsumerInformation!!.location}
                            >
                              Verbraucherinformationen
                            </PdfLinkOverlay>{" "}
                            sowie die{" "}
                            <PdfLinkOverlay
                              title="Effecta AGB"
                              pdf={effectaAGB!!.location}
                            >
                              AGB
                            </PdfLinkOverlay>{" "}
                            der Effecta GmbH zur Kenntnis und stimme diesen zu.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <b>
                          Innerhalb der nächsten Tage erhältst Du eine E-Mail
                          mit einem Link zu den Zahlungsdetails von uns.
                        </b>
                      </Typography>
                    </Grid>

                    {/***
           <Grid item xs={12} style={{ display: "flex" }}>
              <Checkbox checked={riskChecked} onChange={(event) => setRiskChecked(event.target.checked)} name="checkedB" color="primary" />
              <Typography style={{ marginLeft: "10px" }} variant="body2">
                Hiermit bestätige ich, dass der Zeichnungsschein Risikohinweise, Zusicherungen und Garantien sowie Hinweise an mich enthält.
              </Typography>
            </Grid>
             */}

                    <Grid item xs={12}>
                      <MultistepFormButtons
                        nextButtonText="Verbindlich investieren"
                        back
                        backFunction={back}
                        disabled={
                          !effectaChecked ||
                          !zuwendungenChecked ||
                          !customerInformationParticipationRightInvestmentAgreementChecked ||
                          !basicInformatoinSheetChecked
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={12} style={{ marginTop: "50px" }}>
                      <AbortInvestment investment={investment} />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}{" "}
          </Grid>
        </form>
      )}
    </>
  );
}
