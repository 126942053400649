import {
  Button,
  ButtonGroup,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { palette } from "@mui/system";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import theme from "../../theme";

interface CostWidgetProps {
  pessimistic_szenario: number;
  medium_szenario: number;
  optimistic_szenario: number;
  management_fee: number;
  upfront_fee: number;
  carry_fee_pessimistic: number;
  carry_fee_medium: number;
  carry_fee_optimistic: number;
  years: number;
}

export default function CostWidget(props: CostWidgetProps) {
  var height = 100;
  const max_height = 200;
  const width = "50px";
  const static_costs = props.management_fee * props.years + props.upfront_fee;
  const overflow = height * props.medium_szenario > max_height;
  if (overflow) height = max_height / props.medium_szenario;

  const [investment_amount, set_investment_amount] = useState(10000);
  const [active_szenario, set_active_szenario] = useState(1);
  const [inputbar_height, set_inputbar_height] = useState(height);
  const [payoutbar_height, set_payoutbar_height] = useState(
    overflow ? max_height : height * props.medium_szenario
  );
  const [cost_bar_height, set_cost_bar_height] = useState(
    height * (props.carry_fee_medium + static_costs)
  );
  const [carry_cost_total, set_carry_cost_total] = useState(
    investment_amount * props.carry_fee_medium
  );
  const upfront_fee_total = investment_amount * props.upfront_fee;
  const management_fee_total =
    investment_amount * props.management_fee * props.years;
  const [netto_return, set_netto_return] = useState(
    investment_amount * props.medium_szenario
  );
  const [total_return, set_total_return] = useState(
    investment_amount * props.medium_szenario +
      carry_cost_total +
      upfront_fee_total +
      management_fee_total
  );

  useEffect(() => {
    var returns = upfront_fee_total + management_fee_total;
    var carry_cost_total = 0;
    var performance = props.medium_szenario;

    var payoutbar_height = 0;
    var inputbar_height = height;
    var carry = 0;
    var management_fee_bar_height = 0;

    switch (active_szenario) {
      case 0:
        performance = props.pessimistic_szenario;
        carry = props.carry_fee_pessimistic;
        set_netto_return(investment_amount * props.pessimistic_szenario);
        carry_cost_total = investment_amount * props.carry_fee_pessimistic;
        returns += investment_amount * props.pessimistic_szenario;
        break;
      case 1:
        performance = props.medium_szenario;
        carry = props.carry_fee_medium;
        set_netto_return(investment_amount * props.medium_szenario);
        carry_cost_total = investment_amount * props.carry_fee_medium;
        returns += investment_amount * props.medium_szenario;
        break;
      case 2:
        performance = props.optimistic_szenario;
        carry = props.carry_fee_optimistic;
        set_netto_return(investment_amount * props.optimistic_szenario);
        carry_cost_total = investment_amount * props.carry_fee_optimistic;
        returns += investment_amount * props.optimistic_szenario;
        break;
    }
    payoutbar_height = inputbar_height * performance;
    const overflow = payoutbar_height > max_height;

    if (overflow) {
      payoutbar_height = max_height;
      inputbar_height = max_height / performance;
    }
    set_cost_bar_height(inputbar_height * (static_costs + carry));
    set_payoutbar_height(payoutbar_height);
    set_inputbar_height(inputbar_height);
    returns += carry_cost_total;
    set_carry_cost_total(carry_cost_total);
    set_total_return(returns);
  }, [active_szenario, investment_amount]);

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "700px",
        margin: "auto",
        marginTop: "100px",
      }}
    >
      <Typography variant="h4">Kostenübersicht</Typography>
      <div
        style={{
          marginTop: "30px",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "rgb(254, 250, 244)",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">
            {(props.upfront_fee * 100).toLocaleString("de-de")}%
          </Typography>
          <Typography variant="body1">Initialkosten</Typography>
        </div>
        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "rgb(254, 250, 244)",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">
            {" "}
            {props.management_fee * 100}% p.a.
          </Typography>
          <Typography variant="body1">Managementgebühr</Typography>
        </div>
        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "rgb(254, 250, 244)",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            Carry
          </Typography>
          <Typography variant="body1" style={{ textAlign: "center" }}>
            Nur wenn du profitierst, profitieren wir.
          </Typography>
        </div>
      </div>

      <div style={{ marginTop: "40px" }}>
        <div
          style={{
            width: "100%",
            padding: "5px",
            borderStyle: "solid",
            borderWidth: "0.4px",
          }}
        >
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            Die dargestellten Renditen sind nicht garantiert und können geringer
            ausfallen, ferner kann es zum Verlust des eingesetzten Kapitals
            kommen.
          </Typography>
        </div>
        <Typography
          variant="body1"
          style={{ fontWeight: 600, marginTop: "10px" }}
        >
          Mit dem folgenden Rechner kannst Du die Kosten unter Annahme der
          verschiedenen Szenarien nachvollziehen.
        </Typography>
      </div>
      <div style={{ display: "flex", marginTop: "30px" }}>
        <div>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Anlagebetrag
          </Typography>

          <NumberFormat
            inputProps={{
              style: {
                height: "18px",
              },
            }}
            value={investment_amount || ""}
            customInput={TextField}
            suffix={" €"}
            type="text"
            decimalSeparator={","}
            thousandSeparator={"."}
            onValueChange={(value) => {
              set_investment_amount(Number(value.value));
            }}
          />
        </div>
        <div style={{ marginLeft: "30px", width: "calc(100% - 30px)" }}>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Wähle ein Szenario
          </Typography>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
            }}
          >
            <Button
              style={{
                height: "50px",
                textTransform: "none",
                color: active_szenario == 0 ? "white" : "black",
                backgroundColor:
                  active_szenario == 0
                    ? theme.palette.primary.light
                    : theme.palette.background.paper,
              }}
              onClick={() => set_active_szenario(0)}
            >
              <div>
                Negativ
                <br />
                {props.pessimistic_szenario}x
              </div>
            </Button>
            <Button
              style={{
                height: "50px",
                textTransform: "none",
                color: active_szenario == 1 ? "white" : "black",
                backgroundColor:
                  active_szenario == 1
                    ? theme.palette.primary.light
                    : theme.palette.background.paper,
              }}
              onClick={() => set_active_szenario(1)}
            >
              <div>
                Mittleres
                <br />
                {props.medium_szenario}x
              </div>
            </Button>
            <Button
              style={{
                height: "50px",
                textTransform: "none",
                color: active_szenario == 2 ? "white" : "black",
                backgroundColor:
                  active_szenario == 2
                    ? theme.palette.primary.light
                    : theme.palette.background.paper,
              }}
              onClick={() => set_active_szenario(2)}
            >
              <div>
                Optimistisches
                <br />
                {props.optimistic_szenario}x
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div style={{ height: "350px", display: "flex", alignItems: "flex-end" }}>
        <div>
          <motion.div
            layout
            style={{
              width: width,
              height: `${inputbar_height}px`,
              backgroundColor: theme.palette.primary.main,
              borderRadius: "5px",
            }}
          />
          <div style={{ height: "100px" }}>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              Anlagebetrag
            </Typography>
            <Typography variant="body1">
              {investment_amount.toLocaleString("de-de")} €
            </Typography>
          </div>
        </div>
        <div style={{ marginLeft: "50px" }}>
          {/** fee bar */}
          <motion.div
            layout
            style={{
              borderRadius: "5px 5px 0 0",
              width: width,
              height: `${cost_bar_height}px`,
              backgroundColor: theme.palette.primary.light,
            }}
          />
          {/** Return bar */}
          <motion.div
            layout
            style={{
              width: width,
              height: `${payoutbar_height}px`,
              backgroundColor: theme.palette.primary.main,
              borderRadius: "0 0 5px 5px",
            }}
          />
          <div style={{ height: "100px" }}>
            <Typography variant="body1" style={{ fontWeight: 600 }} noWrap>
              Bruttoergebnis
            </Typography>
            <Typography variant="body1">
              {total_return.toLocaleString("de-de")} €
            </Typography>
          </div>
        </div>
        <div
          style={{
            marginLeft: "100px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" style={{ marginBottom: "30px" }}>
            Die Ausschüttung setzt sich wie folgt zusammen:
          </Typography>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              textAlign: "right",
            }}
          >
            <Typography variant="body1" noWrap>
              Bruttoergebnis
            </Typography>
            <Typography variant="body1">
              {total_return.toLocaleString("de-de")} €
            </Typography>
            <Typography variant="body1">Initialkosten</Typography>
            <Typography variant="body1">
              {upfront_fee_total.toLocaleString("de-de")} €
            </Typography>
            <Typography variant="body1">Managementgebühr</Typography>
            <Typography variant="body1">
              {management_fee_total.toLocaleString("de-de")} €
            </Typography>
            <Typography variant="body1">Carry</Typography>
            <Typography variant="body1">
              {carry_cost_total.toLocaleString("de-de")} €
            </Typography>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "black",
                gridColumnStart: "1",
                gridColumnEnd: "3",
              }}
            />
            <Typography variant="body1" style={{ fontWeight: 700 }} noWrap>
              Ausschüttung
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              {netto_return.toLocaleString("de-de")} €
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
