import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Header from "../../template/header";
import InvestmentDetails from "./investment/investment-details";
import NotFound from "../not-found";
import API_CLIENT from "../../utility/api-client";
import { Vouchers } from "../../types/voucher_types";
import ForceEmailVerification from "../signup/force-email-verification";
import InvestmentProcess from "./investment/investment-process";
import FondDetails from "./funds/fund-details";
import Fonds from "./funds/funds";
import { Helmet } from "react-helmet";
import NewDashboard from "./dashboard/dashboard-new";
import { HubspotTrackingToken } from "../../types/hubspot_types";
import RiskAssessment from "../risk-assessment";
import ProfileNew from "../profile-new";
import TagManager from "react-gtm-module";
import { InventureUser } from "../../types/inventure-user_types";
import {
  MatchingDTO,
  UserMatchingDTO,
  USER_STATE,
} from "../../types/generic_types";
import Normalizer from "../../utility/normalizer";
import CompanyOnboarding from "./onboarding/onboarding-company";
import { setUser } from "@sentry/react";
import OnboardingNewLegal2 from "./onboarding/onboarding-new-legal-2";
import OnboardingSuccess from "./onboarding/onboarding-success";
import CalculatorWidget from "../../components/data-display/calculator-widget";
import InvestmentSuccess from "../../components/page-components/investment-process/steps-new/investment-success";
import InvestmentProcessNew from "./investment/investment-process-new";
import CostWidget from "../../components/data-display/cost-widget";
import HeaderV2 from "../../template/header-v2";
import FooterV2 from "../../template/footer-v2";
import NewsPage from "../news-page";
import { Fund } from "../../types/fund_types";
import { News } from "../../types/vc-platform/news_types";
import NEWS_CLIENT from "../../utility/news-client";
import OnboardingZero from "./onboarding/onboarding-zero";
import { Footer } from "@inventurefinancial/frontend-components";
import theme from "../../theme";
import LoadingScreen from "../../components/loading-screen";
import InvestmentProcessAIF from "./investment-aif/investment-process-aif";
import InvestmentProcessFull from "../direct/investment-process-full/investment-process-full";

declare const window: any;

interface AppContextProps {
  loading: boolean;
  authenticated: boolean;
  token: string;
  userEmail: string;
  sub: string;
  user: null | InventureUser;
  matchingData: null | UserMatchingDTO;
  userState: USER_STATE;
  setUserState: (state: USER_STATE) => void;
  funds: Fund[];
  news: News[];
  updateUser: (user: InventureUser) => void;
}

export const AppContext = React.createContext<AppContextProps>({
  updateUser: (user) => null,
  loading: true,
  authenticated: true,
  token: "",
  userEmail: "",
  sub: "",
  user: null,
  matchingData: null,
  userState: USER_STATE.NONE,
  setUserState: (state) => null,
  news: [],
  funds: [],
});

function AuthRouter() {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  const [accessToken, setAccessToken] = useState("");
  const [riskAssessment, setRiskAssessment] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [userStateLoading, setUserStateLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userState, setUserState] = useState(USER_STATE.NONE);
  const [funds, setFunds] = useState<Fund[]>([]);
  const [news, setNews] = useState<News[]>([]);

  const [userEmail, setUserEmail] = useState<string>("");
  const [sub, setSub] = useState<string>("");
  const [ivUser, setIvUser] = useState<InventureUser | null>(null);
  const [userEmailVerified, setUserEmailVerified] = useState<boolean>(false);

  const setReferralCode = (vouchers: Vouchers) => {
    localStorage.setItem("voucher_time", Date().toString());
    localStorage.setItem("vouchers", JSON.stringify(vouchers));
  };

  const activateHubspot = (email: string | undefined) => {
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
      "identify",
      {
        email: email,
      },
    ]);
    _hsq.push(["setPath", "/"]);
    _hsq.push(["trackPageView"]);
    // window.hsConversationsSettings = {
    //   identificationEmail: email,
    // };
    //window.HubSpotConversations.widget.load();
  };

  useEffect(() => {
    if (sub) {
      TagManager.dataLayer({
        dataLayer: {
          event: "login",
          user_id: sub,
        },
      });
    }
  }, [sub]);

  useEffect(() => {
    const el = document.querySelector(".loader-container");
    if (el) {
      el.remove();
    }
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({});
        var emailVerified = user?.email_verified;
        var email = user?.email;
        var sub = user?.sub;
        if (emailVerified != undefined && email && user && sub) {
          activateHubspot(email);
          // API_CLIENT.getHubspotTrackingToken(
          //   accessToken,
          //   (token) => activateHubspot(email, token),
          //   (error) => console.log(error)
          // );

          API_CLIENT.getUser(
            accessToken,
            (user) => {
              setRiskAssessment(!user.risk_confirmed);
              setIvUser(user);
              setUserLoading(false);
            },
            (error) => console.log(error)
          );
          API_CLIENT.getUserState(
            accessToken,
            (state: USER_STATE) => {
              setUserState(state);
              setUserStateLoading(false);
            },
            (error: string) => {
              console.log(error);
            }
          );
          API_CLIENT.getFunds(
            accessToken,
            (funds) => {
              setFunds(funds);
            },
            (error) => {
              console.log(error);
            }
          );

          setAccessToken(accessToken);
          setUserEmailVerified(emailVerified);
          setSub(sub);
          setUserEmail(email);

          setInterval(() => {
            API_CLIENT.getUserState(
              accessToken,
              (state: USER_STATE) => {
                setUserState(state);
              },
              (error: string) => {
                console.log(error);
              }
            );
          }, 20000);
        }
      } catch (e) {}
    };
    getUserMetadata();
  }, []);

  useEffect(() => {
    if (funds) {
      NEWS_CLIENT.getNewsForFunds(
        funds.map((fund) => fund.targetInvestmentId),
        (news) => {
          setNews(news);
        }
      );
    }
  }, [funds]);

  if (userLoading || userStateLoading) return <div></div>;
  var script =
    'var faOrderID = "' +
    sub +
    '"; var faProgramID = "3968"; var faCategory = "sale"';

  var phoneNumber;
  var phone = ivUser?.natural_person?.phone;
  if (phone) {
    phoneNumber = Normalizer.normalizePhone(phone);
  }
  var email = ivUser?.communication.email;
  if (email) {
    email = Normalizer.normalizeMail(email);
  }

  var matchingData: MatchingDTO = {
    event: "",
    eventId: undefined,
    sourceUrl: undefined,
    em: email,
    ph: phoneNumber,
    fn: ivUser?.natural_person?.forename,
    ln: ivUser?.natural_person?.surname,
  };

  const MainApp = () => (
    <>
      {process.env.REACT_APP_FINANCE_ADS === "false" ? (
        console.log("fads: " + process.env.REACT_APP_FINANCE_ADS)
      ) : (
        <Helmet>
          <script type="text/javascript">{script}</script>
          <script src="https://fat.financeads.net/fpc.js"></script>
        </Helmet>
      )}

      <>
        {false ? (
          <RiskAssessment />
        ) : (
          <div>
            <HeaderV2 />
            <Switch>
              <Route
                path="/portal/risk-assessment"
                component={RiskAssessment}
              />
              <Route path="/portal/dashboard" component={NewDashboard} />
              <Route path="/portal/dashboard-new" component={NewDashboard} />
              <Route path="/portal/news" component={NewsPage} />
              <Route
                path="/portal/investment-process"
                component={InvestmentProcessNew}
              />
              <Route
                path="/portal/investment-process-aif"
                component={InvestmentProcessAIF}
              />

              <Route
                path="/portal/investment-process-new"
                component={InvestmentProcessNew}
              />
              <Route
                path="/portal/investment-success"
                component={InvestmentSuccess}
              />
              <Route path="/portal/products" component={Fonds} />
              <Route path="/portal/investment" component={InvestmentDetails} />
              <Route path="/portal/profile" component={ProfileNew} />
              <Route path="/portal/profile-new" component={ProfileNew} />
              <Route path="/portal/product" component={FondDetails} />
              <Route
                path="/portal/onboarding"
                component={OnboardingNewLegal2}
              />
              <Route
                path="/portal/onboarding-success"
                component={OnboardingSuccess}
              />
              <Route
                path="/portal/company-onboarding"
                component={CompanyOnboarding}
              />
              <Route path="/portal/test/widget" component={CalculatorWidget} />

              <Route path="/**" component={NotFound} />
            </Switch>
            {/** Hide Footer for specific paths */}
            {window.location.pathname != "/portal/onboardingg" ? (
              <Footer
                theme={theme}
                logo_url={process.env.REACT_APP_LOGO_LIGHT}
                copyright={process.env.REACT_APP_COPYRIGHT}
                data_protection_url={process.env.REACT_APP_DATA_PROTECTION_URL}
                risk_information_url={
                  process.env.REACT_APP_RISK_INFROMATION_URL
                }
                imprint_url={process.env.REACT_APP_IMPRINT_URL}
                disclamer={process.env.REACT_APP_DISCLAIMER}
                license_shield_information={
                  process.env.REACT_APP_LICENSE_SHIELD_INFORMATION
                }
              />
            ) : null}
          </div>
        )}
      </>
    </>
  );

  if (!ivUser) {
    return <LoadingScreen />;
  }

  return (
    <AppContext.Provider
      value={{
        updateUser: (user) => setIvUser(user),
        token: accessToken,
        authenticated: isAuthenticated,
        loading: loading,
        userEmail: userEmail,
        sub: sub,
        user: ivUser,
        matchingData: matchingData,
        userState: userState,
        funds: funds,
        news: news,
        setUserState: (state) => {
          console.log(state);
          setUserState(state);
        },
      }}
    >
      {!userEmailVerified ? (
        <ForceEmailVerification />
      ) : userState == USER_STATE.ZERO ? (
        <OnboardingZero />
      ) : (
        <MainApp />
      )}
    </AppContext.Provider>
  );
}

export default withAuthenticationRequired(AuthRouter, {
  onRedirecting: () => <div />,
});
