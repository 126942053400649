import React from "react";

type LoadingScreenProps = {
  style?: React.CSSProperties;
  className?: string;
};

export default function LoadingScreen({
  style,
  className,
}: LoadingScreenProps) {
  return (
    <div
      className={className}
      style={{ ...style, display: "flex", alignItems: "center" }}
    >
      <img
        alt="loading"
        style={{
          width: "100px",
          marginLeft: "50%",
          transform: "translateX(-50%) translateY(-50%)",
        }}
        src="https://ik.imagekit.io/inventure/App/Icons/Spin-1.1s-211px_hjFATFuH7U.gif?updatedAt=1626880918590"
      />
    </div>
  );
}
