
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { ContentBlock } from "../../../types/fund_types";
import "./content-block.css";

type ContentBlocksProps = {
  blocks: ContentBlock[];
  style?: React.CSSProperties;
};
export default function ContentBlocks({ blocks, style }: ContentBlocksProps) {
  return (
    <Grid
      container
      spacing={3}
      style={{ padding: "0px", ...style }}
      className="content-block"
    >
      {blocks.map((block, iterator) => (
        <Grid item xs={12} key={iterator} style={{ marginTop: "0px" }}>
          {block.browserImg ? (
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <img
                alt={"generic"}
                src={block.browserImg.url}
                style={{ width: "100%" }}
              />
            </Box>
          ) : null}
          {block.mobileImg ? (
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <img
                alt={"generic-mobile"}
                src={block.mobileImg.url}
                style={{ width: "100%" }}
              />
            </Box>
          ) : null}
          {block.textBlock ? (
            <Typography variant="body2" style={{ textAlign: "left" }}>
              <div dangerouslySetInnerHTML={{ __html: block.textBlock.html }} />
            </Typography>
          ) : null}
        </Grid>
      ))}
    </Grid>
  );
}

type RichtTextBlockProps = {
  html: string;
};

export function ContentBlocksNew({ blocks, style }: ContentBlocksProps) {
  return (
    <Grid
      container
      spacing={3}
      style={{ padding: "0px", ...style }}
      className="content-block"
    >
      {blocks.map((block, iterator) => (
        <Grid item xs={12} key={iterator} style={{ marginTop: "0px" }}>
          {block.textBlock ? (
            <Typography variant="body2" style={{ textAlign: "left" }}>
              <div dangerouslySetInnerHTML={{ __html: block.textBlock.html }} />
            </Typography>
          ) : null}
          {block.browserImg ? (
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <img
                alt={"generic"}
                src={block.browserImg.url}
                style={{ width: "100%" }}
              />
            </Box>
          ) : null}
          {block.mobileImg ? (
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <img
                alt={"generic-mobile"}
                src={block.mobileImg.url}
                style={{ width: "100%" }}
              />
            </Box>
          ) : null}
        </Grid>
      ))}
    </Grid>
  );
}

export function RichTextBlock({ html }: RichtTextBlockProps) {
  return (
    <Typography className="content-block" variant="body2" style={{ textAlign: "left" }}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Typography>
  );
}
