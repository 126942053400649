import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import ReactGA from "react-ga";
import "./postident.css";

type PostIdentButton = {
  firstName: string,
  lastName: string,
  email: string,
  uid: string,
  phone: string,
  street: string,
  zip: string,
  city: string,
  title: string,
  country: string
}

export default function PostIdentButton({firstName, lastName, email, uid, street, zip, city, phone, title, country}: PostIdentButton) {
  const [update, setUpdate] = useState(false)

  const submit = () => {
    (window as any).dataLayer.push({
      event: 'onboarding-videoident',
    });
    ReactGA.event({
      category: "Onboarding",
      action: "user klicked button postident - kyc",
      label: uid,
    });
    setTimeout(() => {
      setUpdate(!update)
    }, 100);
   
  }

 
    var error = false
    if (!firstName || !lastName || !email || !uid || !street || !zip || !city || !country) { error = true }
    if (title === "NONE") { title = "" }
    return (
      <>
      {error ? 
      <Typography variant="body1" style={{color: "red"}}>Ein Fehler ist aufgetreten. Versuche es später erneut oder kontaktiere uns.</Typography>
      :
      <form action="https://multiconnectportal.de/pi/start/xCJ0nHP5DLm1n6ieIgQM" method="post" target="_blank" style={{width: "300px", display: "contents"}}>
        <input type="hidden" name="ident[method]" value="video"/>
        
        <input type="hidden" name="contactDataProvided[title]" value={title}/>
        <input type="hidden" name="contactDataProvided[firstName]" value={firstName}/>
        <input type="hidden" name="contactDataProvided[lastName]" value={lastName}/>
        <input type="hidden" name="contactDataProvided[email]" value={email}/>
        {phone ?
        <input type="hidden" name="contactDataProvided[mobilePhone]" value={phone}/>
        : null}
        <input type="hidden" name="contactDataProvided[address][streetAddress]" value={street}/>      
        <input type="hidden" name="contactDataProvided[address][postalCode]" value={zip}/>      
        <input type="hidden" name="contactDataProvided[address][city]" value={city}/>     
        <input type="hidden" name="contactDataProvided[address][country]" value={country}/>      
  
        <input type="hidden" name="externalReference" value={uid}/>
        <button
          onClick={() => submit()}
          type="submit"
          className="postident-button"
          style={{
            backgroundSize: "cover",
            width: "300px",
            height: "60px",
            cursor: "pointer",
            borderStyle: "none",
            backgroundColor: "rgba(0,0,0,0)"
          }}
        ></button>
      </form>}
      </>
    );
  

}
