import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Avatar, Tooltip, Typography } from "@mui/material";
import React from "react";

type CopyTextProps = {
  text?: string;
  style?: React.CSSProperties;
};

export default function CopyText({ text, style }: CopyTextProps) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const copy = () => {
    if (!text) return;
    navigator.clipboard.writeText(text);
  };

  return (
    <Tooltip
      style={{ textOverflow: "ellipsis" }}
      placement="top"
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      title={open ? "erfolgreich kopiert" : "kopieren"}
    >
      <div
        onClick={() => {
          handleTooltipOpen();
          copy();
        }}
        style={{
          ...style,
          cursor: "pointer",
          display: "flex",
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: "rgb(200,200,200)",
          margin: "0 10px 0 10px",
          padding: "4px",
          borderRadius: "5px",
        }}
      >
        <Typography
          variant="body2"
          style={{
            float: "right",
            marginLeft: "20px",
            marginTop: "9px",
            marginRight: "10px",
          }}
        >
          {text}
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <Avatar variant="rounded">
          <FileCopyOutlinedIcon />
        </Avatar>
      </div>
    </Tooltip>
  );
}

export function CopyTextV2({ text, style }: CopyTextProps) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const copy = () => {
    if (!text) return;
    navigator.clipboard.writeText(text);
  };

  return (
    <Tooltip
      style={{ textOverflow: "ellipsis" }}
      placement="top"
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      title={open ? "erfolgreich kopiert" : "kopieren"}
    >
      <div
        className="border rounded-lg py-1 px-2 flex items-center"
        onClick={() => {
          handleTooltipOpen();
          copy();
        }}
      >
        <p className="text-sm">{text}</p>
        <div style={{ flexGrow: 1 }} />

        <FileCopyOutlinedIcon className="h-4 w-4" />
      </div>
    </Tooltip>
  );
}
