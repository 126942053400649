import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import theme from "../../theme";

interface IvSwitchProps {
  defaultValue?: boolean;
  onChange: (checked: boolean) => void;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
  noText?: boolean;
}
export default function IvSwitch({
  style,
  defaultValue,
  onChange,
  sx,
  noText,
}: IvSwitchProps) {
  const [activated, setActivated] = useState(defaultValue);

  const newVal = (val: boolean) => {
    setActivated(val);
    onChange(val);
  };

  return (
    <Box
      sx={sx}
      style={{
        width: "100%",
        height: "40px",
        backgroundColor: "rgba(249, 248, 248, 0.4)",
        borderStyle: "solid",
        borderWidth: 0.4,
        borderColor: "#8C8C8C",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        cursor: "pointer",
        ...style,
      }}
    >
      <div
        onClick={() => {
          newVal(false);
        }}
        style={{
          width: "calc(50% - 4px)",
          height: "calc(100% - 4px)",
          backgroundColor: activated ? "" : theme.palette.primary.light,
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          style={{ color: activated ? theme.palette.primary.dark : "white" }}
        >
          {noText ? null : "Nein"}
        </Typography>
      </div>
      <div
        onClick={() => {
          newVal(true);
        }}
        style={{
          width: "calc(50% - 4px)",
          height: "calc(100% - 4px)",
          backgroundColor: activated ? theme.palette.primary.light : "",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          style={{ color: activated ? "white" : theme.palette.primary.dark }}
        >
          {noText ? null : "Ja"}
        </Typography>
      </div>
    </Box>
  );
}
export function IvSwitchV2({
  style,
  defaultValue,
  onChange,
  sx,
  noText,
}: IvSwitchProps) {
  const [activated, setActivated] = useState(defaultValue);

  const newVal = (val: boolean) => {
    setActivated(val);
    onChange(val);
  };

  return (
    <Box
      sx={sx}
      style={{
        width: "100%",
        height: "45px",
        backgroundColor: "#E2EAF2",
        borderStyle: "none",
        borderColor: "#8C8C8C",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        cursor: "pointer",
        ...style,
      }}
    >
      <div
        onClick={() => {
          newVal(false);
        }}
        style={{
          width: "calc(50% - 4px)",
          height: "calc(100% - 4px)",
          backgroundColor: activated ? "" : theme.palette.primary.main,
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          className="font-semibold"
          variant="body2"
          style={{ color: activated ? theme.palette.primary.dark : "white" }}
        >
          {noText ? null : "Nein"}
        </Typography>
      </div>
      <div
        onClick={() => {
          newVal(true);
        }}
        style={{
          width: "calc(50% - 4px)",
          height: "calc(100% - 4px)",
          backgroundColor: activated ? theme.palette.primary.main : "",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          className="font-semibold"
          style={{ color: activated ? "white" : theme.palette.primary.dark }}
        >
          {noText ? null : "Ja"}
        </Typography>
      </div>
    </Box>
  );
}
