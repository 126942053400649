import React, { useContext, useEffect, useState } from "react";
import MultistepForm from "../../../components/vertical-multistep-form/multistep-form";
import MultistepFormBody from "../../../components/vertical-multistep-form/multistep-form-body";
import MultistepFormButtons from "../../../components/vertical-multistep-form/multistep-form-buttons";
import MultistepFormNavigation from "../../../components/vertical-multistep-form/multistep-form-navigation";
import MultistepFormStep from "../../../components/vertical-multistep-form/multistep-form-step";
import VerticalStepper from "../../../components/vertical-multistep-form/vertical-stepper";
import { Campaign, CHARACTERISTIC, Fund } from "../../../types/fund_types";
import API_CLIENT from "../../../utility/api-client";
import { AppContext } from "../auth-router";
import queryString from "query-string";
import LoadingScreen from "../../../components/loading-screen";
import { Button, Grid } from "@mui/material";
import { InvestmentDataStepNew } from "../../../components/page-components/investment-process/steps/investment-data-step";
import {
  Investment,
  INVESTMENT_COUNT,
  INVESTMENT_SIZE,
  INVESTMENT_STATE,
} from "../../../types/investment_types";
import { InvestmentAmountStep } from "../../../components/page-components/investment-process/steps-new/investment-amount-step";
import { ExperienceKnowledgeStepNew } from "../../../components/page-components/investment-process/steps-new/experience-step-new";
import WalletStep from "../../../components/page-components/investment-process/steps/wallet-step";
import WalletStepNew from "../../../components/page-components/investment-process/steps-new/wallet-step-new";
import CostStepNew from "../../../components/page-components/investment-process/steps-new/costs-step-new";
import SummaryStep from "../../../components/page-components/investment-process/steps-new/summary-step";
import { Redirect, useHistory } from "react-router-dom";
import InvestmentSuccess from "../../../components/page-components/investment-process/steps-new/investment-success";
import { InventureUser } from "../../../types/inventure-user_types";
import { INVESTOR_TYPE } from "../../../types/onboarding_types";
import { USER_STATE } from "../../../types/generic_types";

interface InvestmentContextNewProps {
  fund: Fund | null;
  campaign: Campaign | null;
  investment: Investment | null;
  setInvestment: (investment: Investment | null) => void;
  lastInvestment: Investment | null | undefined;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

enum INVESTMENTPROCESS_STEP {
  ERROR = -1,
  AMOUNT = 0,
  SURVEY = 1,
  WALLET = 2,
  COSTS = 3,
  SUMMARY = 4,
  SUCCESS = 5,
}

export const InvestmentContextNew =
  React.createContext<InvestmentContextNewProps>({
    fund: null,
    campaign: null,
    investment: null,
    setInvestment: () => null,
    lastInvestment: null,
    loading: true,
    setLoading: () => null,
  });

export default function InvestmentProcessNew() {
  let history = useHistory();
  //Setup
  const appContext = useContext(AppContext);
  const queries = queryString.parse(window.location.search);
  const campaignId = queries.campaign ? queries.campaign.toString() : "";
  const fundId = queries.fund ? queries.fund.toString() : "";

  //State
  const [fund, setFund] = useState<Fund | null>(null);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [investment, setInvestment] = useState<Investment | null>(null);
  const [investmentLoaded, setInvestmentLoaded] = useState(false);
  const [lastInvestment, setLastInvestment] = useState<Investment | null>();
  const [loading, setLoading] = useState(true);
  const [commulatedInvestmentAmount, setCommulatedInvestmentAmount] =
    useState(0);
  const [blockTo, setBlockTo] = useState(0);

  //Vertical Stepper
  const [highestStep, setHighestStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const labels = [
    "Investitionsdaten",
    "Kenntnisse und Erfahrungen",
    "Wallet",
    "Kosten und Zuwendungen",
    "Zusammenfassung",
    "Bestätigung",
  ];

  useEffect(() => {
    if (appContext.user?.uid == null) return;
    setLoading(true);
    API_CLIENT.getFund(
      appContext.token,
      fundId,
      (fund) => {
        setFund(fund);
        var campaign = fund.cashlinkCampaigns.find(
          (campaign) => campaign.cashlinkId == campaignId
        );
        if (campaign) {
          setCampaign(campaign);
        }
      },
      (error) => console.log(error)
    );
    API_CLIENT.getLastIncomingInvestment(
      appContext.token,
      appContext.user.uid,
      (investment) => {
        setLastInvestment(investment);
      },
      () => {
        setLastInvestment(null);
        console.error("Error: Fetching investment failed");
      }
    );
    API_CLIENT.getInvestmentsByCampaign(
      appContext.token,
      campaignId,
      (investments: Investment[]) => {
        var activeInvestment = null;
        var commulatedInvestmentSize = 0;
        investments.forEach((investment) => {
          if (investment.state === INVESTMENT_STATE.PENDING) {
            activeInvestment = investment;
          }
          if (
            investment.state === INVESTMENT_STATE.ACCEPTED ||
            investment.state === INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE ||
            investment.state === INVESTMENT_STATE.PAID ||
            investment.state === INVESTMENT_STATE.DELIVERED ||
            investment.state === INVESTMENT_STATE.KYC_PENDING ||
            investment.state === INVESTMENT_STATE.KYC_INVALID ||
            investment.state === INVESTMENT_STATE.KYC_INSUFFICIENT
          ) {
            commulatedInvestmentSize += investment.amount;
          }
        });
        setCommulatedInvestmentAmount(commulatedInvestmentSize);
        setInvestment(activeInvestment);
        setInvestmentLoaded(true);
      },
      () => {
        setInvestment(null);
      },
      (error) => console.log(error)
    );
    const checkUserState = () => {
      const state = appContext.userState;
      const user = appContext.user;
      if (
        state !=
        USER_STATE.ONBOARDED_2 /** 2 is ONBOARDED_2 => see auth router */
      ) {
        window.location.replace("/portal/onboarding");
      } else if (
        user?.investor_type == INVESTOR_TYPE.LEGAL_PERSON &&
        (user?.legal_person == null || user?.benefiting_persons.length == 0)
      ) {
        window.location.replace("/portal/company-onboarding");
      }
    };

    checkUserState();
  }, []);

  useEffect(() => {
    if (!investmentLoaded || !campaign || lastInvestment === undefined) {
      return;
    }

    chooseStep();
    setLoading(false);
  }, [investmentLoaded, campaign, investment, lastInvestment]);

  const setStep = (step: INVESTMENTPROCESS_STEP) => {
    setActiveStep(step);
    setHighestStep(step);
  };

  const translateTransactionValue = (size: INVESTMENT_SIZE): number => {
    switch (size) {
      case INVESTMENT_SIZE.NO_ANSWER:
        return 0;
      case INVESTMENT_SIZE.LOWER_3000:
        return 3000;
      case INVESTMENT_SIZE.BETWEEN_3000_AND_5000:
        return 5000;
      case INVESTMENT_SIZE.BETWEEN_5000_AND_10000:
        return 10000;
      case INVESTMENT_SIZE.OVER_10000:
        return 100000;
    }
  };

  const translateYearlyTransaction = (
    transactions: INVESTMENT_COUNT
  ): number => {
    switch (transactions) {
      case INVESTMENT_COUNT.NO_ANSWER:
        return 0;
      case INVESTMENT_COUNT.NO_TRANS:
        return 0;
      case INVESTMENT_COUNT.LOWER_5_TRANS:
        return 5;
      case INVESTMENT_COUNT.LOWER_10_TRANS:
        return 10;
      case INVESTMENT_COUNT.MORE_THAN_10_TRANS:
        return 100;
    }
  };

  const chooseStep = () => {
    const survey = investment?.survey;
    if (investment == null || campaign == null) return;
    const surveyInvestmentScore = survey?.scoreKnowledgeExperience;
    const surveyServiceScore = survey?.scoreService;
    const surveyTransactionValue = survey
      ? translateTransactionValue(survey.avgtransactionvalue)
      : 0;
    const surveyYearlyTransactions = survey
      ? translateYearlyTransaction(survey.avgyearlytransactions)
      : 0;
    const surveyTransactionScore =
      (surveyTransactionValue * surveyYearlyTransactions) / 2;
    const campaignInvestmentScore = campaign?.experienceAndKnowledgeScore;
    const campaignServiceScore = campaign?.serviceScore;

    var surveyValid = false;
    if (
      surveyInvestmentScore &&
      surveyServiceScore &&
      campaignInvestmentScore &&
      campaignServiceScore
    ) {
      surveyValid =
        surveyInvestmentScore >= campaignInvestmentScore &&
        surveyServiceScore >= campaignServiceScore &&
        surveyTransactionScore >= investment.amount;
    }

    //if no investment has been created yet, start at the beginning
    if (investment == null) {
      setStep(INVESTMENTPROCESS_STEP.AMOUNT);
      return;
    }

    const one_million_exception = campaign.characteristic?.includes(
      CHARACTERISTIC.ONE_MILLION_EXCEPTION
    );

    const investmentAmountStepDone =
      (one_million_exception && investment.amount > 0) ||
      (investment.amount > 0 &&
        (investment.self_disclosure_monthly_income !== false ||
          investment.self_disclosure_net_worth_100k !== false));

    const surveyStepDone =
      investmentAmountStepDone &&
      (surveyValid || investment.survey_waiver != null);
    const walletStepDone = surveyStepDone && investment.tangany_wallet_accepted;
    const CostStepDone = walletStepDone && investment.cost_accepted;
    const confirmationStepDone =
      investment.state === INVESTMENT_STATE.ACCEPTED ||
      investment.state === INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE ||
      investment.state === INVESTMENT_STATE.KYC_PENDING;

    if (confirmationStepDone) {
      setBlockTo(5);
      setStep(INVESTMENTPROCESS_STEP.SUCCESS);
    } else if (CostStepDone) {
      setStep(INVESTMENTPROCESS_STEP.SUMMARY);
    } else if (walletStepDone) {
      setStep(INVESTMENTPROCESS_STEP.COSTS);
    } else if (surveyStepDone) {
      setStep(INVESTMENTPROCESS_STEP.WALLET);
    } else if (investmentAmountStepDone) {
      setStep(INVESTMENTPROCESS_STEP.SURVEY);
    } else {
      setStep(INVESTMENTPROCESS_STEP.AMOUNT);
    }
  };

  if (loading || fund == null || campaign == null) {
    return (
      <div style={{ height: "100vh", backgroundColor: "#f5efe6" }}>
        <LoadingScreen style={{ height: "100vh" }} />
      </div>
    );
  }

  return (
    <InvestmentContextNew.Provider
      value={{
        fund: fund,
        campaign: campaign,
        investment: investment,
        setInvestment: (newInvestment: Investment | null) => {
          setInvestment(newInvestment);
        },
        lastInvestment: lastInvestment,
        loading: loading,
        setLoading: (loading: boolean) => setLoading(loading),
      }}
    >
      <MultistepForm
        sx={{
          height: {
            xs: "100%",
            md: "100%",
          },
        }}
      >
        <MultistepFormNavigation>
          <VerticalStepper
            blockTo={blockTo}
            alignTop
            totalSteps={6}
            highestStep={highestStep}
            onStepClick={(step) => {
              if (step <= highestStep) setActiveStep(step);
            }}
            style={{ width: "100%", minHeight: "100%" }}
            activeStep={activeStep}
            returnOnFirstStep={false}
            labels={labels}
          />
        </MultistepFormNavigation>
        {}
        <MultistepFormBody alignTop>
          <MultistepFormStep
            style={{ width: "90%" }}
            disableAnimation
            step={INVESTMENTPROCESS_STEP.AMOUNT}
            activeStep={activeStep}
          >
            <InvestmentAmountStep
              commulatedInvestmentAmount={commulatedInvestmentAmount}
              stepFinished={() => {
                setLoading(false);
              }}
            />
          </MultistepFormStep>
          <MultistepFormStep
            style={{ width: "90%" }}
            disableAnimation
            step={INVESTMENTPROCESS_STEP.SURVEY}
            activeStep={activeStep}
          >
            <ExperienceKnowledgeStepNew
              back={() => {
                setActiveStep(INVESTMENTPROCESS_STEP.AMOUNT);
              }}
              next={() => null}
            />
          </MultistepFormStep>
          <MultistepFormStep
            style={{ width: "90%" }}
            disableAnimation
            step={INVESTMENTPROCESS_STEP.WALLET}
            activeStep={activeStep}
          >
            <WalletStepNew
              back={() => setActiveStep(INVESTMENTPROCESS_STEP.SURVEY)}
              next={() => null}
            />
          </MultistepFormStep>
          <MultistepFormStep
            style={{ width: "90%" }}
            disableAnimation
            step={INVESTMENTPROCESS_STEP.COSTS}
            activeStep={activeStep}
          >
            <CostStepNew
              back={() => setActiveStep(INVESTMENTPROCESS_STEP.WALLET)}
              next={() => null}
            />
          </MultistepFormStep>
          <MultistepFormStep
            style={{ width: "90%" }}
            disableAnimation
            step={INVESTMENTPROCESS_STEP.SUMMARY}
            activeStep={activeStep}
          >
            <SummaryStep
              back={() => setActiveStep(INVESTMENTPROCESS_STEP.COSTS)}
            />
          </MultistepFormStep>
          <MultistepFormStep
            style={{ width: "90%" }}
            disableAnimation
            step={INVESTMENTPROCESS_STEP.SUCCESS}
            activeStep={activeStep}
          >
            <InvestmentSuccess />
          </MultistepFormStep>
        </MultistepFormBody>
      </MultistepForm>
    </InvestmentContextNew.Provider>
  );
}
