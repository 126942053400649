import React, { useState, useContext } from "react";
import { AppContext } from "../../pages/direct/direct-router";
import API_CLIENT from "../../utility/api-client";
import { PrimaryButtonV2, SecondaryButtonV2 } from "../input-elements/buttons";
import { uuid4 } from "@sentry/utils";

interface FileUploadPopupProps {
  investmentId: string;
  onDocumentUploaded: () => void;
}

const FileUploadPopup: React.FC<FileUploadPopupProps> = ({
  investmentId,
  onDocumentUploaded,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [documentDescription, setDocumentDescription] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const app_context = useContext(AppContext);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!file || !documentName) return;

    try {
      API_CLIENT.postInvestmentDoc(
        app_context.token,
        investmentId,
        file,
        uuid4() + ".pdf",
        documentName,
        () => onDocumentUploaded()
      );
      setShowForm(false);
      setDocumentName("");
      setFile(null);
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  return (
    <div>
      <div className="flex">
        <SecondaryButtonV2 onClick={() => setShowForm(!showForm)}>
          Dokument beifügen
        </SecondaryButtonV2>
      </div>
      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            onClick={() => setShowForm(false)}
            className="fixed inset-0 bg-black opacity-50"
          ></div>
          <div className="bg-white p-8 rounded-lg shadow-lg z-10 grid gap-8">
            <h2>Dokument beifügen</h2>
            <form onSubmit={handleSubmit} className="grid gap-4">
              <div className="mb-4">
                <label className="text-primary text-sm mb-2">
                  Dokumentname
                </label>
                <input
                  placeholder="Anlage A"
                  type="text"
                  value={documentName}
                  onChange={(e) => setDocumentName(e.target.value)}
                  className="px-4 w-full transition-all rounded-lg outline-none border-[1.5px] focus:border-[1.5px] hover:border-[1.5px] border-primary_light border-opacity-0 hover:border-opacity-100 focus:border-opacity-100 bg-[#E2EAF2] h-12"
                  required
                />
              </div>
              {/* <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Name der Datei
                </label>
                <input
                  placeholder="anlage_a.pdf"
                  type="text"
                  value={documentDescription}
                  onChange={(e) => setDocumentDescription(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div> */}

              <div className="mb-4">
                <label className="text-primary text-sm mb-2">
                  Datei auswählen
                </label>
                <div>
                  <p className="opacity-50 text-sm">
                    Der Dateityp muss PDF sein.
                  </p>
                  <input
                    type="file"
                    className="w-full"
                    accept="application/pdf"
                    size={0}
                    onChange={handleFileChange}
                    required
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <PrimaryButtonV2 type="submit">Hochladen</PrimaryButtonV2>
                <SecondaryButtonV2
                  type="button"
                  onClick={() => setShowForm(false)}
                >
                  Abbrechen
                </SecondaryButtonV2>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadPopup;
