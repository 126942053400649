import { Grid, Typography } from "@mui/material";
import React from "react";
import theme from "../../theme";
import API_CLIENT from "../../utility/api-client";
import PdfOverlay from "./pdf-overlay";

type DocumentBoxProps = {
  location: string;
  name: string;
  type: string;
  style?: React.CSSProperties;
};

type DocumentBoxPrivateProps = {
  fund: string;
  name: string;
  token: string;
};

const borderColor = theme.palette.primary.light;
const hoveredColor = theme.palette.background.dark;
const defaultColor = "rgba(0,0,0,0)";

export default function DocumentBox({
  location,
  name,
  type,
}: DocumentBoxProps) {
  const [isHovered, setIsHovered] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        style={{
          textDecoration: "none",
          float: "left",
          margin: "5px",
          cursor: "pointer",
        }}
      >
        <Grid
          container
          style={{
            width: "200px",
            textAlign: "center",
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: !isHovered ? defaultColor : hoveredColor,
          }}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          <Grid item xs={12}>
            <img
              alt="pdf"
              src="https://ik.imagekit.io/inventure/App/Icons/pdf-icon_v1n4-WvDhW.png"
              style={{ height: "50px", margin: "auto auto" }}
            ></img>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              style={{
                margin: "10px 0 0 0",
                color: "black",
                fontWeight: 700,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <PdfOverlay
        title={name}
        pdf={location}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
}

export function DocumentBoxWide({ location, name, type }: DocumentBoxProps) {
  const [isHovered, setIsHovered] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <div
        onClick={() => setOpen(true)}
        className="no-underline float-left m-1 cursor-pointer"
      >
        <div
          className={`flex flex-row w-full border text-center items-center rounded-full bg-primary gap-2 px-4 py-2 ${
            isHovered
              ? "bg-opacity-[.03] border-opacity-0 "
              : "bg-opacity-[.0] border-primary border-opacity-20"
          }`}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          <div>
            <img
              alt="pdf"
              src="https://ik.imagekit.io/inventure/App/static/icons/icons8-pdf-96_kHg3vFVtI.png?updatedAt=1719325890652"
              className="m-auto h-6 w-6"
            ></img>
          </div>
          <div style={{ overflow: "hidden" }}>
            <p className="text-sm font-medium">{name}</p>
          </div>
        </div>
      </div>
      <PdfOverlay
        title={name}
        pdf={location}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </div>
  );
}

export function DocumentBoxPrivate({
  fund,
  name,
  token,
}: DocumentBoxPrivateProps) {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <a
      onClick={() => {
        API_CLIENT.getDocument(token, fund, name);
      }}
    >
      <div
        style={{
          textDecoration: "none",
          float: "left",
          margin: "5px",
          cursor: "pointer",
        }}
      >
        <Grid
          container
          style={{
            width: "200px",
            textAlign: "center",
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: !isHovered ? defaultColor : hoveredColor,
          }}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          <Grid item xs={12}>
            <img
              alt="pdf"
              src="https://ik.imagekit.io/inventure/App/Icons/pdf-icon_v1n4-WvDhW.png"
              style={{ height: "50px", margin: "auto auto" }}
            ></img>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              style={{
                margin: "10px 0 0 0",
                color: "black",
                fontWeight: 700,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              Fondspräsentation
            </Typography>
          </Grid>
        </Grid>
      </div>
    </a>
  );
}

export function DocumentBoxPrivateWide({
  fund,
  name,
  token,
}: DocumentBoxPrivateProps) {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <a
      style={{ width: "100%" }}
      onClick={() => {
        API_CLIENT.getDocument(token, fund, name);
      }}
    >
      <div
        style={{
          textDecoration: "none",
          float: "left",
          margin: "5px",
          cursor: "pointer",
          width: "calc(100% - 30px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            textAlign: "center",
            padding: "10px",
            borderRadius: "10px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: borderColor,
            backgroundColor: !isHovered ? defaultColor : hoveredColor,
          }}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          <div>
            <img
              alt="pdf"
              src="https://ik.imagekit.io/inventure/App/Icons/pdf-icon_v1n4-WvDhW.png"
              style={{ height: "30px", margin: "auto auto" }}
            ></img>
          </div>
          <div style={{ overflow: "hidden" }}>
            <Typography
              variant="body2"
              style={{
                margin: "7px 0px 0 10px",
                color: "black",
                fontWeight: 700,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              Fondspräsentation
            </Typography>
          </div>
        </div>
      </div>
    </a>
  );
}

export function DocumentBoxDirect({ location, name, type }: DocumentBoxProps) {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <a href={location} target="blank">
      <div
        style={{
          textDecoration: "none",
          float: "left",
          margin: "5px",
          cursor: "pointer",
        }}
      >
        <Grid
          container
          style={{
            width: "200px",
            textAlign: "center",
            padding: "20px",
            borderRadius: "10px",
            backgroundColor: !isHovered ? defaultColor : hoveredColor,
          }}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          <Grid item xs={12}>
            <img
              alt="pdf"
              src="https://ik.imagekit.io/inventure/App/Icons/pdf-icon_v1n4-WvDhW.png"
              style={{ height: "50px", margin: "auto auto" }}
            ></img>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              style={{
                margin: "10px 0 0 0",
                color: "black",
                fontWeight: 700,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </a>
  );
}

export function DocumentBoxWideDirect({
  location,
  name,
  type,
  style,
}: DocumentBoxProps) {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <a href={location} target="blank">
      <div
        style={{
          textDecoration: "none",
          float: "left",
          margin: "5px",
          cursor: "pointer",
          width: "calc(100% - 10px)",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "calc(100% - 20px)",
            textAlign: "center",
            padding: "10px",
            borderRadius: "10px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: borderColor,
            backgroundColor: !isHovered ? defaultColor : hoveredColor,
          }}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          <div>
            <img
              alt="pdf"
              src="https://ik.imagekit.io/inventure/App/Icons/pdf-icon_v1n4-WvDhW.png"
              style={{ height: "30px", margin: "auto auto" }}
            ></img>
          </div>

          <div
            style={{
              marginLeft: "10px",
              textAlign: "left",
              whiteSpace: "nowrap",
              width: "calc(100% - 40px)",
            }}
          >
            <Typography
              variant="body2"
              style={{
                margin: "7px 0 0 0",
                color: "black",
                fontWeight: 700,
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {name}
            </Typography>
          </div>
        </div>
      </div>
    </a>
  );
}
