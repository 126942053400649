import { Typography, TextField } from "@mui/material";
import { sha256 } from "js-sha256";
import React, { useContext, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import NumberFormat from "react-number-format";
import { AppContext } from "../../../pages/portal/auth-router";
import { InvestmentContextAIF } from "../../../pages/portal/investment-aif/investment-process-aif";
import { MatchingDTO } from "../../../types/generic_types";
import {
  CreateInvestmentDTO,
  SelfDisclosure,
} from "../../../types/investment_types";
import API_CLIENT from "../../../utility/api-client";
import PrimaryButton, { SecondaryButton } from "../../input-elements/buttons";
import LoadingScreen from "../../loading-screen";
import MultistepFormButtons from "../../vertical-multistep-form/multistep-form-buttons";

export default function InvestmentAmountAIF() {
  const investment_context = useContext(InvestmentContextAIF);
  const app_context = useContext(AppContext);
  const investment = investment_context.investment;
  const campaign = investment_context.campaign;
  const fund = investment_context.fund;

  //consts
  const MIN_AMOUNT = 5000;
  const MAX_AMOUNT = 100000;

  const [amount, setAmount] = useState(10000);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (investment?.amount == null) {
      setAmount(MIN_AMOUNT);
    } else {
      setAmount(investment?.amount * investment?.tokenPrice);
    }
  }, []);

  const setAmountFunction = (amount: number) => {
    const isValidAmount =
      amount % 100 === 0 && amount >= MIN_AMOUNT && amount <= MAX_AMOUNT;
    setError(!isValidAmount);
    setAmount(amount);
  };

  if (campaign == null) return null;

  const sendInvestment = (amount: number | undefined) => {
    setLoading(true);
    window.scrollTo(0, 0);
    if (amount === undefined) {
      return;
    }

    // var sd: SelfDisclosure = {
    //   self_disclosure_monthly_income: selfDisclosureMonthlyIncome,
    //   self_disclosure_net_worth_100k: selfDisclosure100k,
    // };

    var sd: SelfDisclosure = {
      self_disclosure_monthly_income: true,
      self_disclosure_net_worth_100k: true,
    };
    var createInvestmentDTO: CreateInvestmentDTO = {
      selfDisclosure: sd,
      couponCode: null,
      investmentKey: null,
    };
    setLoading(true);
    if (investment === null) {
      API_CLIENT.postInvestmentAmount(
        createInvestmentDTO,
        app_context.token,
        amount,
        campaign.cashlinkId,
        (investment) => {
          var uData = app_context.matchingData;

          var mData: MatchingDTO = {
            event: "CustomizeProduct",
            eventId: investment.id,
            sourceUrl: window.location.href,
            em: uData?.em,
            ph: uData?.ph,
            fn: uData?.fn,
            ln: uData?.ln,
            product: fund?.name,
            focus: fund?.fokus,
            type: fund?.type,
            value: investment.amount.toString(),
            currency: "EUR",
          };

          API_CLIENT.postMatching(app_context.token, mData);

          TagManager.dataLayer({
            dataLayer: {
              event: "customize-product",
              event_id: investment.id,
              first_name: app_context.user?.natural_person.forename,
              last_name: app_context.user?.natural_person.surname,
              phone: uData?.ph,
              email: sha256(app_context.userEmail),
              product: fund?.name,
              focus: fund?.fokus,
              type: fund?.type,
              value: investment.amount,
              currency: "EUR",
            },
          });
          (window as any).dataLayer.push({
            event: "investment-created",
            "investment-amount": investment.amount,
          });

          investment_context.setInvestment(investment);
          setTimeout(() => setLoading(false), 500);
        },
        (error) => console.log(error),
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    } else {
      API_CLIENT.putInvestmentAmount(
        createInvestmentDTO,
        app_context.token,
        amount,
        investment.id,
        (investment) => {
          investment_context.setInvestment(investment);
        },
        (error) => console.log(error),
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  if (loading) {
    return <LoadingScreen className="w-full h-screen" />;
  }

  return (
    <form
      className="h-screen"
      onSubmit={(e) => {
        e.preventDefault();
        sendInvestment(amount);
      }}
    >
      <Typography variant="h2">Anlagebetrag</Typography>
      <Typography variant="subtitle1" className="text-2xl text-gray-600">
        Gebe hier den Betrag ein, den Du investieren möchtest
      </Typography>
      <NumberFormat
        className="mt-9 mb-9 w-full"
        value={amount || ""}
        label="Investitionsbetrag"
        inputProps={{
          style: {
            fontSize: "24px",
            fontWeight: 300,
            padding: "10px 15px",
          },
        }}
        variant="outlined"
        customInput={TextField}
        suffix={" €"}
        type="text"
        decimalSeparator={","}
        thousandSeparator={"."}
        onValueChange={(value: any) => {
          setAmountFunction(Number(value.value));
        }}
      />
      <Typography variant="subtitle1" className="text-1xl text-gray-600">
        Oder wähle eine der folgenden Investitionsgrößen
      </Typography>
      <div className="grid grid-cols-4 gap-2">
        <SecondaryButton onClick={() => setAmountFunction(MIN_AMOUNT)}>
          {MIN_AMOUNT.toLocaleString("de-de")} €
        </SecondaryButton>
        <SecondaryButton onClick={() => setAmountFunction(MIN_AMOUNT * 2)}>
          {(MIN_AMOUNT * 2).toLocaleString("de-de")} €
        </SecondaryButton>
        <SecondaryButton onClick={() => setAmountFunction(MIN_AMOUNT * 10)}>
          {(MIN_AMOUNT * 10).toLocaleString("de-de")} €
        </SecondaryButton>
        <SecondaryButton onClick={() => setAmountFunction(MIN_AMOUNT * 20)}>
          {(MIN_AMOUNT * 20).toLocaleString("de-de")} €
        </SecondaryButton>
      </div>
      <div className="grid grid-cols-4 gap-2">
        <MultistepFormButtons
          disabled={false}
          back={false}
          backFunction={() => null}
        />
      </div>
    </form>
  );
}
