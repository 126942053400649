import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';

export default function NotFound() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])
    return(
        <Grid container justifyContent="center" style={{backgroundColor: "rgba(74,120,134,1)"}}> 
            <Grid item xs={12} style={{minHeight: "calc(100vh - 200px)", maxWidth: "1080px", textAlign: "center", paddingTop: "calc(40vh - 200px)", paddingLeft: "10px", paddingRight: "10px", color: "white"}}>
                <Typography variant="h1" style={{fontSize: "120px"}}>404</Typography>
                <Typography variant="h5">Wir konnten die Seite nicht finden. Versuche es später erneut und kontaktiere uns gerne, falls das Problem bestehen bleibt.</Typography>
                <Link to="/portal/dashboard" style={{textDecoration: "none"}}>
                <Button variant="outlined" style={{fontWeight: 700, marginTop: "30px", backgroundColor: "white", color: "rgba(74,120,134,1)"}}>Zurück zum Dashboard</Button>
                </Link>
            </Grid>
        </Grid>
    )
}