import { Box, Hidden } from "@mui/material";
import React from "react";

type MultistepFormBodyProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  alignTop?: boolean;
};

export default function MultistepFormBody({
  children,
  style,
  alignTop,
}: MultistepFormBodyProps) {
  return (
    <Box
      className="content"
      style={{
        paddingRight: "10px",
        display: "flex",
        alignItems: alignTop ? "flex-start" : "center",
        overflow: "hidden",
        ...style,
      }}
      sx={{
        paddingTop: { xs: "20px", md: alignTop ? "100px" : "" },
        paddingLeft: { xs: "10px", md: "100px" },

        width: { xs: "calc(100% -20px)", md: "70%" },
      }}
    >
      {children}
    </Box>
  );
}

export function MultistepFormBodyV2({
  children,
  style,
  alignTop,
}: MultistepFormBodyProps) {
  return (
    <Box
      className="content"
      style={{
        width: "100%",
        paddingRight: "0px",
        display: "flex",
        alignItems: alignTop ? "flex-start" : "center",
        overflow: "hidden",
        ...style,
      }}
      sx={{
        paddingTop: { xs: "00px", md: alignTop ? "00px" : "" },
        paddingLeft: { xs: "00px", md: "00px" },
      }}
    >
      {children}
    </Box>
  );
}
