import React from "react";
import queryString from "query-string";

export default function InvestmentProcessLegalPerson() {
  //queries
  const queries = queryString.parse(window.location.search);
  const campaign_id = queries.campaign ? queries.campaign.toString() : "";
  const fund_id = queries.fund ? queries.fund.toString() : "";

  return (
    <div className="w-full max-h-80 text-black p-8">
      <h1>Zeichnung über eine juristische Person</h1>
      <p>
        Hier kannst du deine Investition über eine juristische Persons starten.{" "}
      </p>
    </div>
  );
}
