import { useAuth0 } from "@auth0/auth0-react";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StandardCard from "../../components/data-display/standard-card/standard-card";
import PrimaryButton, {
  PrimaryButtonV2,
  SecondaryButton,
  SecondaryButtonV2,
} from "../../components/input-elements/buttons";
import MultistepForm from "../../components/vertical-multistep-form/multistep-form";
import MultistepFormBody from "../../components/vertical-multistep-form/multistep-form-body";
import MultistepFormNavigation from "../../components/vertical-multistep-form/multistep-form-navigation";
import MultistepFormStep from "../../components/vertical-multistep-form/multistep-form-step";
import Footer from "../../template/footer";
import API_CLIENT from "../../utility/api-client";
import { AppContext } from "../portal/auth-router";
import TagManager from "react-gtm-module";
import { sha256 } from "js-sha256";
import Normalizer from "../../utility/normalizer";

export default function ForceEmailVerification() {
  const { getAccessTokenSilently } = useAuth0();
  var context = useContext(AppContext);
  const [token, setToken] = useState("");
  const { logout } = useAuth0();
  const [mailSent, setMailSent] = useState(false);

  useEffect(() => {
    const getToken = async () => {
      const accessToken = await getAccessTokenSilently({});
      setToken(accessToken);
    };
    getToken();
  }, []);

  return (
    <MultistepForm style={{ height: "100vh" }}>
      <MultistepFormNavigation>
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          style={{ width: "100%", justifyContent: "center" }}
        >
          <a href="https://www.inventure.de/">
            <img
              src={process.env.REACT_APP_LOGO_LIGHT}
              style={{ height: "60px", margin: "40px auto" }}
            />
          </a>
        </Box>
      </MultistepFormNavigation>
      <MultistepFormBody>
        <div style={{ overflow: "hidden", width: "800px" }}>
          <div
            style={{
              textAlign: "left",
              padding: "0 10px 0 10px",
            }}
          >
            <Typography
              variant="h4"
              style={{
                fontWeight: 600,
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Herzlich Willkommen
            </Typography>

            <Typography variant="body1" style={{ marginTop: "0px" }}>
              Dein Account wurde erfolgreich erstellt. Wir haben eine Nachricht
              an deine E-Mail Adresse gesendet.{" "}
              <b>
                Bitte überprüfe deine E-Mails, um deine Registrierung
                abzuschließen.
              </b>{" "}
              Überprüfe bitte auch deinen Spam-Ordner, falls du keine E-Mail
              finden solltest. Wir freuen uns dich im Anschluss auf unserer
              Investmentplattform willkommen zu heißen.
            </Typography>
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <PrimaryButton
                disabled={mailSent}
                onClick={() => {
                  API_CLIENT.resendVerificationMail(
                    token,
                    () => {
                      setMailSent(true);
                    },
                    () => console.log("error")
                  );
                }}
                style={{ minWidth: "250px", marginRight: "50px" }}
              >
                E-Mail erneut versenden
              </PrimaryButton>
              <Typography
                sx={{
                  marginTop: { xs: "20px", md: "0" },
                  width: { xs: "100%", md: "250px" },
                  marginLeft: { xs: "0px", md: "50px" },
                }}
                variant="body1"
                onClick={() => logout()}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Logout
              </Typography>
            </div>
            {mailSent ? (
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                Verifizierungsmail erfolgreich versandt.
              </Typography>
            ) : null}
          </div>
        </div>
      </MultistepFormBody>
    </MultistepForm>
  );

  return (
    <>
      <div
        style={{
          padding: "40px 10px 60px 10px",
          backgroundImage:
            "url(https://ik.imagekit.io/inventure/signup_4xE2xHa2vjMYh.svg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionX: "center",
          backgroundPositionY: "bottom",
          minHeight: "100vh",
        }}
      >
        <Grid
          container
          justifyContent="center"
          style={{ display: "grid", padding: "0" }}
          spacing={0}
        >
          <Grid item xs={12}>
            <div
              id="signup-mobile-header"
              style={{ width: "100%", height: "100px", textAlign: "center" }}
            >
              <Link
                to="/portal/dashboard"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  style={{
                    width: "20vh",
                    height: "3.4vh",
                    backgroundImage:
                      "url(https://ik.imagekit.io/inventure/Brand/logo-dark_PdXJv72exG.svg)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    margin: "2vh auto 0 auto",
                  }}
                />
                <Typography variant="body1">
                  Venture Capital for Everyone.
                </Typography>
              </Link>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={12}
                style={{ maxWidth: "800px", paddingTop: "10px" }}
              >
                <div style={{ height: "100vh", overflow: "hidden" }}>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "20px 30px 60px 30px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: 600,
                        marginTop: "50px",
                        marginBottom: "50px",
                      }}
                    >
                      Herzlich Willkommen
                    </Typography>

                    <Typography variant="body1" style={{ marginTop: "20px" }}>
                      Dein Account wurde erfolgreich erstellt. Wir haben eine
                      Nachricht an Deine E-Mail Adresse gesendet.{" "}
                      <b>
                        Bitte überprüfe Deine E-Mails, um Deine Registrierung
                        abzuschließen.
                      </b>{" "}
                      Überprüfe bitte auch Deinen Spam-Ordner, falls Du keine
                      E-Mail finden solltest. Wir freuen uns Dich im Anschluss
                      auf unserer Investmentplattform willkommen zu heißen.
                    </Typography>
                    <div style={{ marginTop: "50px" }}>
                      <Button
                        variant="contained"
                        disabled={mailSent}
                        onClick={() => {
                          API_CLIENT.resendVerificationMail(
                            token,
                            () => {
                              setMailSent(true);
                            },
                            () => console.log("error")
                          );
                        }}
                        style={{ margin: "5px", minWidth: "250px" }}
                      >
                        E-Mail erneut versenden
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => logout()}
                        style={{ margin: "5px", minWidth: "250px" }}
                      >
                        Logout
                      </Button>
                    </div>
                    {mailSent ? (
                      <Typography variant="body1" style={{ fontWeight: 700 }}>
                        Verifizierungsmail erfolgreich versandt.
                      </Typography>
                    ) : null}
                  </div>{" "}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
}

export function ForceEmailVerificationV2() {
  const { getAccessTokenSilently } = useAuth0();
  var context = useContext(AppContext);
  const [token, setToken] = useState("");
  const { logout } = useAuth0();
  const [mailSent, setMailSent] = useState(false);

  useEffect(() => {
    const getToken = async () => {
      const accessToken = await getAccessTokenSilently({});
      setToken(accessToken);
    };
    getToken();
  }, []);

  var signed_up = localStorage.getItem("signed-up");
  var e_mail = context.user?.communication.email;
  if (e_mail == null || e_mail == undefined) e_mail = "";

  //send data to gtm for first signup
  if (signed_up != "true") {
    TagManager.dataLayer({
      dataLayer: {
        event: "signup",
        event_id: sha256(e_mail),
        email: sha256(Normalizer.normalizeMail(e_mail)),
      },
    });
    localStorage.setItem("signed-up", "true");
  }

  return (
    <div className="w-full pt-32 flex justify-center items-center text-primary">
      <div className="max-w-3xl grid">
        <h1 className="text-3xl font-headline">Herzlich Willkommen</h1>
        <div className="bg-[#E2EAF2] p-8 rounded-xl mt-8 grid gap-4">
          <h2 className="text-xl font-body font-semibold">
            Bitte bestätige uns noch deine E-Mail Adresse
          </h2>
          <p>
            Dein Account wurde erfolgreich erstellt. Wir haben eine Nachricht an
            deine E-Mail Adresse gesendet.{" "}
            <b>
              Bitte überprüfe deine E-Mails, um deine Registrierung
              abzuschließen.
            </b>{" "}
            Überprüfe bitte auch deinen Spam-Ordner, falls du keine E-Mail
            finden solltest. Wir freuen uns dich im Anschluss auf unserer
            Investmentplattform willkommen zu heißen.
          </p>
          <div className="flex gap-4">
            <PrimaryButtonV2
              disabled={mailSent}
              onClick={() => {
                API_CLIENT.resendVerificationMail(
                  token,
                  () => {
                    setMailSent(true);
                  },
                  () => console.log("error")
                );
              }}
            >
              E-Mail erneut versenden
            </PrimaryButtonV2>
            <SecondaryButtonV2
              onClick={() => logout()}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Logout
            </SecondaryButtonV2>
          </div>
          {mailSent ? (
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              Verifizierungsmail erfolgreich versandt.
            </Typography>
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div
        style={{
          padding: "40px 10px 60px 10px",
          backgroundImage:
            "url(https://ik.imagekit.io/inventure/signup_4xE2xHa2vjMYh.svg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionX: "center",
          backgroundPositionY: "bottom",
          minHeight: "100vh",
        }}
      >
        <Grid
          container
          justifyContent="center"
          style={{ display: "grid", padding: "0" }}
          spacing={0}
        >
          <Grid item xs={12}>
            <div
              id="signup-mobile-header"
              style={{ width: "100%", height: "100px", textAlign: "center" }}
            >
              <Link
                to="/portal/dashboard"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  style={{
                    width: "20vh",
                    height: "3.4vh",
                    backgroundImage:
                      "url(https://ik.imagekit.io/inventure/Brand/logo-dark_PdXJv72exG.svg)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    margin: "2vh auto 0 auto",
                  }}
                />
                <Typography variant="body1">
                  Venture Capital for Everyone.
                </Typography>
              </Link>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid
                item
                xs={12}
                style={{ maxWidth: "800px", paddingTop: "10px" }}
              >
                <div style={{ height: "100vh", overflow: "hidden" }}>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "20px 30px 60px 30px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: 600,
                        marginTop: "50px",
                        marginBottom: "50px",
                      }}
                    >
                      Herzlich Willkommen
                    </Typography>

                    <Typography variant="body1" style={{ marginTop: "20px" }}>
                      Dein Account wurde erfolgreich erstellt. Wir haben eine
                      Nachricht an Deine E-Mail Adresse gesendet.{" "}
                      <b>
                        Bitte überprüfe Deine E-Mails, um Deine Registrierung
                        abzuschließen.
                      </b>{" "}
                      Überprüfe bitte auch Deinen Spam-Ordner, falls Du keine
                      E-Mail finden solltest. Wir freuen uns Dich im Anschluss
                      auf unserer Investmentplattform willkommen zu heißen.
                    </Typography>
                    <div style={{ marginTop: "50px" }}>
                      <Button
                        variant="contained"
                        disabled={mailSent}
                        onClick={() => {
                          API_CLIENT.resendVerificationMail(
                            token,
                            () => {
                              setMailSent(true);
                            },
                            () => console.log("error")
                          );
                        }}
                        style={{ margin: "5px", minWidth: "250px" }}
                      >
                        E-Mail erneut versenden
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => logout()}
                        style={{ margin: "5px", minWidth: "250px" }}
                      >
                        Logout
                      </Button>
                    </div>
                    {mailSent ? (
                      <Typography variant="body1" style={{ fontWeight: 700 }}>
                        Verifizierungsmail erfolgreich versandt.
                      </Typography>
                    ) : null}
                  </div>{" "}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
}

function getAccessTokenSilently(arg0: {}) {
  throw new Error("Function not implemented.");
}
