import { Grid, Typography } from "@mui/material";
import React from "react";
import { reasonSectionType } from "../../../../types/fund_types";
import Headline from "../headline";

type ReasonSectionProps = {
  reasonSection: reasonSectionType;
};

export default function ReasonSection({ reasonSection }: ReasonSectionProps) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: "#494949", marginBottom: "10px", fontWeight: 600, marginTop: "70px" }}>
          {reasonSection.headline}
        </Typography>
      </Grid>
      {reasonSection.reasons.map((reason, i) => {
        return i % 2 == 0 ? (
          <>
            <Grid item xs={12} md={12} style={{ marginTop: "50px" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="h4" style={{ color: "#7CA7B3", marginBottom: "10px" }}>
                  {i + 1}
                </Typography>
                <Typography variant="h6" style={{ color: "black", marginBottom: "10px", marginLeft: "10px", fontWeight: 600 }}>
                  {reason.key}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body1" style={{ color: "black", marginBottom: "10px", marginLeft: "10px" }}>
                {reason.value}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={12} />
            <Grid item xs={12} md={12} style={{ marginTop: "50px" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="h4" style={{ color: "#7CA7B3", marginBottom: "10px" }}>
                  {i + 1}
                </Typography>
                <Typography variant="h6" style={{ color: "black", marginBottom: "10px", marginLeft: "10px", fontWeight: 600 }}>
                 {reason.key}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ color: "black", marginBottom: "10px", marginLeft: "10px" }}>
                {reason.value}
              </Typography>
            </Grid>
          </>
        );
      })}
    </Grid>
  );
}
