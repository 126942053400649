import { Typography } from "@mui/material";
import Chart from "react-apexcharts";
import React, { useContext, useEffect, useState } from "react";
import { DashboardType } from "../../../types/dashboard_types";
import API_CLIENT from "../../../utility/api-client";
import { useAuth0 } from "@auth0/auth0-react";
import { Investment, INVESTMENT_STATE } from "../../../types/investment_types";
import LoadingScreen from "../../../components/loading-screen";
import queryString from "query-string";
import { Fund, FUND_STAGE } from "../../../types/fund_types";
import { Fund as VCPlatformFund } from "../../../types/vc-platform/news_types";
import { News } from "../../../types/vc-platform/news_types";
import NEWS_CLIENT from "../../../utility/news-client";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { motion } from "framer-motion";
import WalletPosition, {
  WalletPositionV2,
} from "../../../components/page-components/dashboard/walletPosition";
import { Link } from "react-router-dom";
import InvestmentStatusChip from "../../../components/data-display/investment-status-chip";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Divider from "@mui/material/Divider";
import { Box, Button, Grid } from "@mui/material";
import PrimaryButton from "../../../components/input-elements/buttons";
import TagManager from "react-gtm-module";
import { AppContext } from "../auth-router";
import { MatchingDTO } from "../../../types/generic_types";
import {
  Transaction as NewTransaction,
  TransactionList,
} from "@inventurefinancial/frontend-components";
import { v4 } from "uuid";
import "./dashboard.css";

import NewsCard, {
  NewsCardWide,
} from "../../../components/data-display/news-card";
import theme from "../../../theme";
import Transaction, {
  INVESTMENT_TYPE,
  Transaction as TransactionType,
} from "./transaction";
import NewsSwiper from "../../../components/page-components/news/news-swiper";
import { DonutChart } from "@inventurefinancial/frontend-components";
import { AppContext as AppContextFull } from "../../direct/direct-router";

export default function NewDashboard() {
  const [dashboard, setDashboard] = useState<DashboardType>();
  const [funds, setFunds] = useState<Fund[]>();
  const [vcplatform_funds, setVcPlatform_funds] = useState<VCPlatformFund[]>();
  const [successfullInvestments, setSuccessfullInvestments] =
    useState<Investment[]>();
  const [transactions, setTransactions] = useState<TransactionType[]>();
  const [wallet, setWallet] = useState<Wallet>();
  const appContext = useContext(AppContext);
  const [news, setNews] = useState<News[]>();
  const { getAccessTokenSilently } = useAuth0();
  let callId = v4();

  const queries = queryString.parse(window.location.search);
  const allNews = queries.allnews ? true : false;

  SwiperCore.use([Autoplay, Pagination]);

  type Position = {
    name: string;
    amount: string;
  };

  useEffect(() => {
    const uData = appContext.matchingData;
    TagManager.dataLayer({
      dataLayer: {
        event: "view-dashboard",
        event_id: callId,
        first_name: appContext.user?.natural_person?.forename,
        last_name: appContext.user?.natural_person?.surname,
        phone: uData?.ph,
        email: appContext.userEmail,
      },
    });
    window.scrollTo(0, 0);
    const getDashboard = async () => {
      const uData = appContext.matchingData;
      if (uData == null) return;
      const accessToken = await getAccessTokenSilently({});
      var mData: MatchingDTO = {
        event: "ViewContent",
        eventId: callId,
        sourceUrl: window.location.href,
        em: uData?.em,
        ph: uData?.ph,
        fn: uData?.fn,
        ln: uData?.ln,
      };
      API_CLIENT.postMatching(accessToken, mData);
      API_CLIENT.getDashboard(
        accessToken,
        (dashboard: DashboardType) => {
          setDashboard(dashboard);
          setSuccessfullInvestments(
            dashboard?.investments
              ?.filter(
                (investment) =>
                  investment.state == INVESTMENT_STATE.ACCEPTED ||
                  investment.state == INVESTMENT_STATE.DELIVERED ||
                  investment.state == INVESTMENT_STATE.KYC_INVALID ||
                  investment.state == INVESTMENT_STATE.KYC_PENDING ||
                  investment.state == INVESTMENT_STATE.PAID ||
                  investment.state == INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE
              )
              .sort(
                (a, b) =>
                  new Date(b.signedAt).getTime() -
                  new Date(a.signedAt).getTime()
              )
          );
        },
        (error: string) => {
          setDashboard({
            coInvestments: [],
            investments: [],
            tokens: [],
          });
        }
      );
      API_CLIENT.getFunds(
        accessToken,
        (funds: Fund[]) => {
          setFunds(funds);
        },
        () => {}
      );
    };
    getDashboard();
  }, []);

  useEffect(() => {
    if (dashboard == undefined || dashboard == null) return;
    const successfullInvestments = dashboard.investments
      ?.filter(
        (investment) =>
          investment.state == INVESTMENT_STATE.ACCEPTED ||
          investment.state == INVESTMENT_STATE.DELIVERED ||
          investment.state == INVESTMENT_STATE.KYC_INVALID ||
          investment.state == INVESTMENT_STATE.KYC_PENDING ||
          investment.state == INVESTMENT_STATE.PAID ||
          investment.state == INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE
      )
      .map((investment) => {
        const t: TransactionType = {
          type: INVESTMENT_TYPE.PLATFORM,
          id: investment.id,
          fundId: investment.fundId,
          fundName: investment.fundName,
          campaignId: investment.campaignId,
          state: investment.state,
          signedAt: new Date(investment.signedAt),
          offerCreatedAt: new Date(investment.offerCreatedAt),
          amount: investment.amount,
        };
        return t;
      });

    const coInvestments = dashboard.coInvestments?.map((coinv) => {
      const t: TransactionType = {
        type: INVESTMENT_TYPE.COINVEST,
        id: coinv.id,
        fundId: coinv.fundId,
        fundName: coinv.fundName,
        campaignId: coinv.campaignId,
        signedAt: new Date(coinv.investedOn),
        offerCreatedAt: new Date(),
        amount: coinv.amount,
        documents: coinv.documents,
      };
      return t;
    });
    const allTransactions = successfullInvestments
      .concat(coInvestments)
      .sort((a, b) => {
        if (b.signedAt && a.signedAt) {
          return b.signedAt.getTime() - a.signedAt.getTime();
        }
        return -1;
      });
    setTransactions(allTransactions);
    /**.sort(
          (a, b) =>
            new Date(b.signedAt).getTime() -
            new Date(a.signedAt).getTime()
        ) */
  }, [dashboard]);

  useEffect(() => {
    if (transactions && funds) {
      setWallet(new Wallet(transactions, funds));
    }
  }, [transactions, funds]);

  useEffect(() => {
    var fundIds = wallet?.funds.map((fund) => fund.targetInvestmentId);
    if (!fundIds) fundIds = [];

    //for debugging new news, this switch allows to show all news by adding ?allnews=true to the query
    if (allNews) {
      NEWS_CLIENT.getNews((news) => {
        if (news) {
          setNews(news.slice(0, 10));
        }
      });
    } else {
      NEWS_CLIENT.getNewsForFunds(fundIds, (news) => {
        if (news) {
          setNews(news.slice(0, 10));
        }
      });
    }

    NEWS_CLIENT.getFunds(fundIds, (funds: VCPlatformFund[]) => {
      if (funds) {
        setVcPlatform_funds(funds);
      }
    });
  }, [wallet]);
  if (!dashboard || !funds)
    return <LoadingScreen style={{ height: "100vh" }} />;
  //creating investment lists
  const actionRequiredInvestments = dashboard?.investments?.filter(
    (investment) => investment.state == INVESTMENT_STATE.ACCEPTED
  );

  if (!transactions || transactions.length == 0) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "1200px",
            minHeight: "calc(100vh - 80px)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            display: "flex",
            justifyContent: "center",
            backgroundPosition: "center",
          }}
        >
          <div
            style={{
              maxWidth: "500px",
              width: "90%",
              minWidth: "300px",
              height: "200px",
              textAlign: "center",
              marginTop: "200px",
              marginBottom: "200px",
              textShadow: "0 0 5px white",
            }}
          >
            <Typography variant="h5">
              <b style={{}}>Dashboard</b>
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: "10px", marginBottom: "20px" }}
            >
              <b>
                Hier wirst du, sobald du eine Investition bei uns getätigt hast,
                alle relevanten Informationen finden. Schaue gerne bei unseren
                Produkten vorbei.
              </b>
            </Typography>
            <Link to="/portal/products" style={{ textDecoration: "none" }}>
              <PrimaryButton
                style={{
                  height: "50px",
                  margin: "auto auto",
                  padding: "0 30px",
                }}
              >
                Zu den Produkten
              </PrimaryButton>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  type ChartPosition = "top" | "right" | "bottom" | "left" | undefined;
  type LegendPosition = "right" | "center" | "left" | undefined;
  const colors = ["red", "black", "green"];

  const leftChartOptions = {
    floating: true,
    legend: {
      width: 130,
      show: true,
      offsetX: 10,
      position: "left" as ChartPosition,
      horizontalAlign: "left" as LegendPosition,
      fontSize: "12px",
      fontFamily: "Nunito Sans",
      fontWeight: 800,
      markers: {
        offsetY: 2,
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: theme.palette.primary.main,
        shadeTo: "light" as "light" | "dark" | undefined,
        shadeIntensity: 0.4,
      },
    },

    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    chart: {
      offsetX: -10,
      sparkline: {
        enabled: true,
      },
    },
    labels: wallet?.positions.map((position) => position.name),
  };
  const rightChartOptions = {
    legend: {
      width: 130,
      offsetX: -10,
      show: true,
      position: "right" as ChartPosition,
      fontSize: "12px",
      fontFamily: "Nunito Sans",
      fontWeight: 800,
      markers: {
        offsetY: 2,
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#C0A77D",
        shadeTo: "light" as "light" | "dark" | undefined,
        shadeIntensity: 0.4,
      },
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    chart: {
      sparkline: {
        offsetX: 10,
        enabled: true,
      },
    },
    labels: wallet?.positionsByStage.map((position) => position.stage),
  };

  const portfolioSeries = {
    series: wallet?.positions?.map((positions) => positions.amount),
  };
  const stageSeries = {
    series: wallet?.positionsByStage?.map((position) => position.amount),
  };

  return (
    <>
      {/** Aktion erforderlich secton---------------------------------------------------------------------------------------- */}

      {actionRequiredInvestments.length > 0 ? (
        <div
          style={{
            backgroundColor: theme.palette.background.dark,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: "1200px",
              width: "100%",
              textAlign: "left",
              padding: "10px 10px",
              overflowX: "auto",
            }}
          >
            <Typography variant="body2" style={{ color: "rgb(80,80,80)" }}>
              <b>Aktion erforderlich</b>
            </Typography>
            <div style={{ display: "flex" }}>
              {actionRequiredInvestments.map((investment) => (
                <Link
                  to={"/portal/investment?id=" + investment.id}
                  style={{ textDecoration: "none" }}
                >
                  <motion.div
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                      marginRight: "5px",
                      padding: "5px 20px",
                      borderRadius: "5px",
                    }}
                    whileHover={{
                      backgroundColor: theme.palette.primary.light,
                    }}
                  >
                    <Typography variant="body1">
                      <b>
                        {" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          Bezahlung: {investment.campaignName}
                        </span>
                      </b>
                    </Typography>
                  </motion.div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      ) : null}
      {/** Pie Chart section--------------------------------------------------------------------------------------- */}
      {wallet ? (
        <div
          style={{
            width: "calc(100% - 0px)",
            marginBottom: "200px",

            padding: "0 0 0 0",
          }}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{ maxWidth: "1200px", margin: "0 20px", width: "100%" }}
              >
                <Typography
                  variant="h3"
                  style={{
                    marginTop: "20px",
                    marginLeft: "10px",
                    fontWeight: 500,
                  }}
                >
                  Dashboard
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  <Grid container>
                    {/* <Grid item xs={12} md={6}>
                      <div
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{ marginBottom: "30px" }}
                        >
                          <b>Portfolio</b>
                        </Typography>
                        <div
                          style={{
                            width: "80%",
                            padding: "0px",
                            margin: "auto",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <Chart
                              colors={colors}
                              options={leftChartOptions}
                              series={portfolioSeries.series}
                              type="donut"
                              width="100%"
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ marginTop: { xs: "50px", md: "0px" } }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{ marginBottom: "30px" }}
                        >
                          <b>Stages</b>
                        </Typography>
                        <div
                          style={{
                            width: "80%",
                            padding: "0px",
                            margin: "auto",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <Chart
                              colors={colors}
                              options={rightChartOptions}
                              series={stageSeries.series}
                              type="donut"
                              width="100%"
                            />
                          </div>
                        </div>
                      </div>
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <div style={{ padding: "10px" }}>
                        <DonutChart
                          style={{ textAlign: "left" }}
                          headline="Portfolio"
                          series={
                            portfolioSeries.series ? portfolioSeries.series : []
                          }
                          labels={wallet?.positions.map(
                            (position) => position.name
                          )}
                          theme={theme}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div style={{ padding: "10px" }}>
                        <DonutChart
                          style={{ textAlign: "left" }}
                          headline="Stages"
                          series={stageSeries.series ? stageSeries.series : []}
                          labels={wallet?.positionsByStage.map(
                            (position) => position.stage
                          )}
                          theme={theme}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>{" "}
            </div>
            {/** News section---------------------------------------------------------------------------------------- */}

            {/** Wallet section---------------------------------------------------------------------------------------- */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{ maxWidth: "1200px", margin: "0 20px", width: "100%" }}
              >
                <div style={{ marginTop: "80px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    {" "}
                    <b>Übersicht</b>
                  </Typography>

                  <Grid
                    container
                    justifyContent="flex-start"
                    style={{ padding: "10px" }}
                  >
                    <Grid item xs={4} md={3}>
                      <Typography variant="h6">Produkt</Typography>
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Typography variant="h6">Zielinvestment</Typography>
                    </Grid>

                    <Grid item xs={4} md={3}>
                      <Typography
                        sx={{ display: { xs: "none", md: "block" } }}
                        variant="h6"
                        style={{ textAlign: "right" }}
                      >
                        Gesamtvolumen
                      </Typography>
                      <Typography
                        sx={{ display: { xs: "block", md: "none" } }}
                        variant="h6"
                        style={{ textAlign: "right" }}
                      >
                        <b></b>
                      </Typography>
                    </Grid>
                  </Grid>
                  {vcplatform_funds
                    ? wallet?.positions.map((position) => (
                        <WalletPosition
                          position={position}
                          fund={vcplatform_funds.find(
                            (fund) =>
                              fund.fundId == position.fund?.targetInvestmentId
                          )}
                          style={{ marginBottom: "20px" }}
                        />
                      ))
                    : null}
                  {/**dashboard.wallet?.address ?
                  <Grid container style={{ padding: "20px" }}>

                  <Grid item xs={12}>
                  <Typography variant="body2" style={{ fontWeight: 800 }}>
                    Polygon Adresse
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div>
                   
                    <WalletChip text={dashboard.wallet?.address} avatar="https://ik.imagekit.io/inventure/App/blockchains/polygon-logo_tgrwjlF4i.png" />
                
                  </div>
              </Grid></Grid>: null **/}
                </div>
                <div style={{ marginTop: "50px" }}>
                  {/** Transaction section---------------------------------------------------------------------------------------- */}

                  <Typography variant="h6" style={{ marginBottom: "10px" }}>
                    <b>Transaktionen</b>
                  </Typography>
                  <TransactionList
                    transactions={transactions.map((transaction) => {
                      const isCoInvest =
                        transaction.type == INVESTMENT_TYPE.COINVEST;
                      return {
                        transaction_url: isCoInvest
                          ? undefined
                          : `/portal/investment?id=${transaction.id}`,
                        theme: theme,
                        target: transaction.fundName,
                        type: isCoInvest ? 1 : 0,
                        date: transaction.signedAt
                          ? transaction.signedAt.toDateString()
                          : "",
                        amount: transaction.amount,
                        documents: transaction.documents?.map((doc) => {
                          console.log(doc);
                          return {
                            name: doc.fileName,
                            url: doc.url,
                          };
                        }),
                      };
                    })}
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

type Stage = {};

export type WalletPositionType = {
  productId: string;
  name: string;
  amount: number;
  stage: string;
  fund: Fund | undefined;
  investments: TransactionType[];
};

type StagePosition = {
  stage: string;
  amount: number;
};

class Wallet {
  stageMap = new Map();

  positions: WalletPositionType[] = [];
  positionsByStage: StagePosition[] = [];
  funds: Fund[] = [];

  constructor(transaction: TransactionType[], funds: Fund[]) {
    this.funds = funds.filter((fund) =>
      transaction.find((transaction) => transaction.fundId == fund.cashlinkId)
    );

    //create mapping for stages
    funds.forEach((fund) => {
      var stage = fund.investmentStage;
      this.stageMap.set(
        fund.cashlinkId,
        FUND_STAGE[stage as string as keyof typeof FUND_STAGE]
      );
    });

    //seed with investments
    transaction.forEach((transaction) => {
      this.putInvestment(transaction);
      this.putInvestmentStage(transaction);
    });

    this.positions.sort((a, b) => b.amount - a.amount);
    this.positionsByStage.sort((a, b) => b.amount - a.amount);
  }

  getFund(fundId: string): Fund | undefined {
    return this.funds.find((fund) => fund.cashlinkId == fundId);
  }

  putInvestment(investment: TransactionType) {
    for (var i = 0; i < this.positions.length; i++) {
      if (this.positions[i].productId == investment.fundId) {
        this.positions[i].name = investment.fundName;
        this.positions[i].investments.push(investment);
        this.positions[i].amount += investment.amount;
        this.positions[i].name = investment.fundName;
        return;
      }
    }

    this.positions.push({
      productId: investment.fundId,
      name: investment.fundName,
      amount: investment.amount,
      investments: [investment],
      stage: this.stageMap.get(investment.fundId),
      fund: this.getFund(investment.fundId),
    });
  }

  putInvestmentStage(investment: TransactionType) {
    var investmentStage = this.stageMap.get(investment.fundId);

    //update the entry for the specific stage
    for (var i = 0; i < this.positionsByStage.length; i++) {
      if (this.positionsByStage[i].stage == investmentStage) {
        this.positionsByStage[i].amount += investment.amount;
        this.positionsByStage[i].stage = investmentStage;
        return;
      }
    }
    this.positionsByStage.push({
      stage: investmentStage,
      amount: investment.amount,
    });
  }
}
