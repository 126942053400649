import { Divider } from "@mui/material";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import {
  HookFormCountrySelect,
  HookFormCountrySelectV2,
  HookFormDatePickerV2,
  HookFormTextFieldV2,
} from "../../../components/hook-form-inputs";
import LoadingScreen from "../../../components/loading-screen";
import PostIdentButton from "../../../components/page-components/onboarding/postident-button";
import { USER_STATE } from "../../../types/generic_types";
import CountryUtils from "../../../utility/country-utils";
import { AppContext } from "../direct-router";

export default function Profile() {
  const {
    register: register,
    control: control,
    formState: formState,
    formState: { errors: errors, isValid: isValid },
    handleSubmit: handleSubmit,
    setValue: setValue,
  } = useForm<any>({ mode: "onChange" });
  const app_context = useContext(AppContext);
  const user = app_context.user?.natural_person;
  if (!user) {
    return <LoadingScreen className="w-full h-screen" />;
  }

  const is_onboarded_two = app_context.userState == USER_STATE.ONBOARDED_2;
  const is_onboarded_one =
    app_context.userState == USER_STATE.ONBOARDED_1 || is_onboarded_two;
  const is_onboarded_zero =
    app_context.userState == USER_STATE.ZERO || is_onboarded_one;
  const is_onboarded_none =
    app_context.userState == USER_STATE.NONE || is_onboarded_zero;

  return (
    <div className="w-full flex flex-col items-center text-primary">
      <div className="flex bg-cover justify-center items-center w-full h-32">
        <div className="max-w-6xl w-full px-6 md:px-12">
          <h1>Dein Profil</h1>
        </div>
      </div>
      <div className="h-[1px] w-full bg-gray-200" />
      <div className="w-full max-w-6xl pb-32 mt-16">
        <div className="-translate-y-14 md:-translate-y-6 mx-6 md:mx-12">
          <div className="flex items-start md:items-center gap-2 md:gap-8 flex-col md:flex-row">
            <div className="w-28 h-28 bg-primary text-white flex justify-center items-center rounded-full border-white border-2 shadow-lg">
              <div className="flex justify-center ">
                <p className="text-6xl">
                  {app_context.user?.natural_person.forename.substring(0, 1)}
                </p>
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-medium mt-4">
                {user?.forename + " " + user?.surname}
              </h1>
              <p className="text-1xl text-primary_light">
                {app_context.user?.communication.email}
              </p>
            </div>
          </div>
          <Divider className="my-8 mx-0" />
          <div className="grid gap-8">
            {is_onboarded_zero ? (
              <ProfileSection>
                <ProfileSectionLeftSide label="Stammdaten"></ProfileSectionLeftSide>
                <div className="grid grid-cols-2 gap-4">
                  <HookFormTextFieldV2
                    name="forename"
                    control={control}
                    defaultValue={user?.forename}
                    error={false}
                    label={"Vorname"}
                    required={true}
                    disabled
                  />
                  <HookFormTextFieldV2
                    name="surname"
                    control={control}
                    defaultValue={user?.surname}
                    error={false}
                    label={"Nachname"}
                    required={true}
                    disabled
                  />

                  <HookFormDatePickerV2
                    name="birth_date"
                    control={control}
                    defaultValue={user?.birth_date}
                    error={false}
                    label={"Geburtstag"}
                    required={true}
                    disabled
                  />
                  <HookFormTextFieldV2
                    name="birthplace"
                    control={control}
                    defaultValue={user?.birth_place}
                    error={false}
                    label={"Geburtsort"}
                    required={true}
                    disabled
                  />
                  <HookFormCountrySelectV2
                    name="birthcountry"
                    control={control}
                    defaultValue={user.birth_country}
                    error={false}
                    label={"Geburtsland"}
                    required={true}
                    disabled
                  />
                </div>
              </ProfileSection>
            ) : null}
            {is_onboarded_zero ? <Divider /> : null}
            {is_onboarded_zero ? (
              <ProfileSection>
                <ProfileSectionLeftSide label="Adressdaten"></ProfileSectionLeftSide>
                <div className="grid grid-cols-2 gap-4">
                  <HookFormTextFieldV2
                    name="street"
                    control={control}
                    defaultValue={user?.street}
                    error={false}
                    label={"Straße und Hausnummer"}
                    required={true}
                    disabled
                  />
                  <HookFormTextFieldV2
                    name="address_addition"
                    control={control}
                    defaultValue={user?.address_addition}
                    error={false}
                    label={"Addresszusatz"}
                    required={true}
                    disabled
                  />
                  <HookFormTextFieldV2
                    name="zip"
                    control={control}
                    defaultValue={user?.zip}
                    error={false}
                    label={"Postleitzahl"}
                    required={true}
                    disabled
                  />
                  <HookFormCountrySelectV2
                    name="country"
                    control={control}
                    defaultValue={user?.country}
                    error={false}
                    label={"Land"}
                    required={true}
                    disabled
                  />
                </div>
              </ProfileSection>
            ) : null}
            {is_onboarded_one && !is_onboarded_two ? (
              <>
                <Divider></Divider>
                <ProfileSection>
                  <ProfileSectionLeftSide label="Identifikation"></ProfileSectionLeftSide>
                  {app_context.user ? (
                    <div className="grid gap-4">
                      <p className="text-sm">
                        Du hast dich bisher noch nicht legitimiert. Das kannst
                        die Legitimation über das VideoIdent Verfahren der
                        Deutschen Post per App oder Webbrowser durchführen.
                        Bitte halte hierfür deinen Ausweis oder Reisepass bereit
                        und starte den Prozess über den folgenden Button.
                      </p>
                      <PostIdentButton
                        firstName={user.forename}
                        lastName={user.surname}
                        email={app_context.user.communication.email}
                        uid={app_context.user.uid}
                        street={user.street}
                        zip={user.zip}
                        city={user.city}
                        phone={user.phone}
                        title={user.title}
                        country={user.country}
                      />
                    </div>
                  ) : null}
                </ProfileSection>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

interface ProfileSectionProps {
  children?: React.ReactNode;
}

export function ProfileSection({ children }: ProfileSectionProps) {
  return (
    <div className="grid gap-8">
      <div className="grid md:grid-cols-2 gap-4">{children}</div>
    </div>
  );
}

interface ProfileSectionLeftSide {
  label?: string;
  children?: React.ReactNode;
}
export function ProfileSectionLeftSide({
  label,
  children,
}: ProfileSectionLeftSide) {
  return (
    <div className="flex flex-col gap-4">
      {label ? (
        <h2 className="text-2xl font-medium font-serif h-6">{label}</h2>
      ) : null}
      <div className="w-full max-w-sm flex flex-col gap-4">{children}</div>
    </div>
  );
}
