import React from "react";
import { useState } from "react";
import PdfOverlay from './pdf-overlay';

type PdfLinkOverlayProps = {
    title: string;
    pdf: string;
    children: React.ReactNode
  };

export default function PdfLinkOverlay({title, pdf, children}: PdfLinkOverlayProps) {
    const [open, setOpen] = useState(false)
     
    return(
       <>
            <div onClick={() => setOpen(true)} style={{cursor: "pointer", textDecoration: "underline", display: "inline"}}>{children}</div>
            <PdfOverlay title={title} pdf={pdf} open={open} handleClose={() => setOpen(false)}/>
            </>
    )
}