
import { Grid, Typography } from "@mui/material";
import React from "react";
import { GenericSectionType } from "../../../../types/fund_types";
import OverlayText from "../../../data-display/overlay-text";

interface IntroSectionProps {
    data: GenericSectionType
}

export default function IntroSection({data}: IntroSectionProps) {
    return(
        <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ color: "#494949", marginBottom: "10px", fontWeight: 600, marginTop: "100px"}}>
            {data.header}
          </Typography>
          
          </Grid>
          <Grid item xs={12}  sx={{display: {xs: "none", sm: "block"}}}>
          <img src={data.contentBlocks[0].browserImg.url} style={{float: "right", width: "100%", maxWidth: "400px", margin: "20px 0px 10px 10px"}} />
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: data.contentBlocks[0]?.textBlock.html }}></Typography>
          

          </Grid>
          <Grid item xs={12}  sx={{display: {xs: "block", sm: "none"}}}>
          <img src={data.contentBlocks[0].browserImg.url} style={{ width: "100%", margin: "0px 0px 0px 0px"}} />
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: data.contentBlocks[0]?.textBlock.html }}></Typography>
          

          </Grid>
          </Grid>
    )
}