import { Checkbox, Grid, Typography } from "@mui/material";
import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PdfLinkOverlay from "../../../components/data-display/pdf-link-overlay";
import HookFormTextField, {
  HookFormSalutationSelect,
  HookFormSalutationSelectV2,
  HookFormTextFieldV2,
  HookFormTitleSelect,
  HookFormTitleSelectV2,
} from "../../../components/hook-form-inputs";
import PrimaryButton from "../../../components/input-elements/buttons";
import LoadingScreen from "../../../components/loading-screen";
import MultistepFormButtons from "../../../components/vertical-multistep-form/multistep-form-buttons";
import theme from "../../../theme";
import { USER_STATE } from "../../../types/generic_types";
import { OnboardingZero as OnboardingZeroType } from "../../../types/onboarding_types";
import API_CLIENT from "../../../utility/api-client";
import { AppContext } from "../auth-router";
import { AppContext as DirectContext } from "../../direct/direct-router";

export default function OnboardingZero() {
  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<any>({ mode: "onChange" });
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  function submit(data: OnboardingZeroType) {
    var affiliate_program = "";
    var cookie = Cookies.get("iv-affiliate");
    if (cookie != undefined) {
      affiliate_program = cookie;
    }
    data.affiliate_program = affiliate_program;
    setLoading(true);
    API_CLIENT.putOnboardingZero(
      appContext.token,
      data,
      (user) => {
        appContext.updateUser(user);
        appContext.setUserState(USER_STATE.NONE);
      },
      () => {
        setLoading(false);
        console.log("error");
      }
    );
  }

  if (loading) {
    return <LoadingScreen style={{ width: "100vw", height: "100vh" }} />;
  }

  return (
    <Grid
      container
      style={{
        width: "100%",
        minHeight: "100vh",
      }}
    >
      <Grid
        item
        md={6}
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
        style={{
          minHeight: "100vh",
          width: "50%",
          backgroundImage:
            "url(https://images.unsplash.com/photo-1589966922656-b33b711d9e4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cm9hZCUyMHN1bnJpc2V8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          justifyContent: "center",
          alignItems: "flex-end",
          color: theme.palette.primary.dark,
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          minHeight: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px 0px",
        }}
      >
        <div style={{ maxWidth: "400px", margin: "0 20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              style={{ width: "100px" }}
              src="https://uploads-ssl.webflow.com/603e16cc348981d0e613c257/6294be96dc50f49d9d250c8d_inVenture-Capital-VC-d.png"
            />
            <Typography
              style={{ fontWeight: 600, marginTop: "3rem", lineHeight: "1" }}
              variant="h4"
            >
              Willkommen
            </Typography>
            <Typography
              variant="body1"
              style={{
                marginTop: "0.5rem",
                color: theme.palette.grey[700],
                fontWeight: 600,
              }}
            >
              Bevor du loslegen kannst brauchen wir noch folgende Informationen
              von dir.
            </Typography>
          </div>
          <div style={{ marginTop: "3rem" }}>
            <form onSubmit={handleSubmit(submit)}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <HookFormSalutationSelect
                    control={control}
                    name={"salutation"}
                    error={false}
                    label={"Anrede*"}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <HookFormTitleSelect
                    control={control}
                    name={"title"}
                    error={false}
                    label={"Titel"}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <HookFormTextField
                    required
                    control={control}
                    name={"firstName"}
                    error={errors.firstname != null}
                    label={"Vorname*"}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <HookFormTextField
                    required
                    control={control}
                    name={"lastName"}
                    error={errors.surname != null}
                    label={"Nachname*"}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <HookFormTextField
                    control={control}
                    defaultValue={""}
                    name={"phoneNumber"}
                    error={false}
                    label={"Telefonnummer"}
                  />
                </Grid>
                {/* <Grid item xs={12} md={12}>
                  <Controller
                    name="termsAccepted"
                    control={control}
                    defaultValue={false}
                    rules={{
                      required:
                        "Bitte bestätige die AGB und die Kundeninformation der Effecta GmbH.",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />

                        <Typography
                          variant="body2"
                          style={{ fontSize: "12px" }}
                        >
                          Die inVenture Capital iVC GmbH wird bei der
                          Vermittlung von Finanzinstrumenten ausschließlich im
                          Namen, auf Rechnung und unter der Haftung des
                          Wertpapierinstituts Effecta GmbH tätig. Ich habe die{" "}
                          <PdfLinkOverlay
                            title="Effecta AGB"
                            pdf="https://inventurestorage.blob.core.windows.net/platform/effecta_terms_of_service.pdf"
                          >
                            AGB
                          </PdfLinkOverlay>{" "}
                          und die{" "}
                          <PdfLinkOverlay
                            title="Effecta Kundeninformation"
                            pdf="https://inventurestorage.blob.core.windows.net/platform/kundeninformationen_effecta.pdf"
                          >
                            Kundeninformationen
                          </PdfLinkOverlay>{" "}
                          der Effecta GmbH gelesen und nehme diese zur
                          Kenntnis.*
                        </Typography>
                      </div>
                    )} // props contains: onChange, onBlur and value
                  />
                </Grid> */}

                <Grid item xs={12} md={6} />
                <Grid item xs={12} style={{ marginTop: "1rem" }}>
                  <PrimaryButton
                    disabled={!formState.isValid}
                    style={{ width: "100%" }}
                    type="submit"
                  >
                    Zu den Produkten
                  </PrimaryButton>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export function OnboardingZeroV2() {
  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<any>({ mode: "onChange" });
  const appContext = useContext(DirectContext);
  const [loading, setLoading] = useState(false);

  function submit(data: OnboardingZeroType) {
    var affiliate_program = "";
    var cookie = Cookies.get("iv-affiliate");
    if (cookie != undefined) {
      affiliate_program = cookie;
    }
    data.affiliate_program = affiliate_program;
    setLoading(true);
    API_CLIENT.putOnboardingZero(
      appContext.token,
      data,
      (user) => {
        appContext.updateUser(user);
        appContext.setUserState(USER_STATE.NONE);
      },
      () => {
        setLoading(false);
        console.log("error");
      }
    );
  }

  if (loading) {
    return <LoadingScreen style={{ width: "100vw", height: "100vh" }} />;
  }

  return (
    <div className="flex justify-center py-16">
      <div className="max-w-lg p-8 rounded-xl shadow-xl">
        <div className="grid gap-8">
          <div>
            <h1 className="text-3xl font-bold">Willkommen</h1>
            <p>
              Bevor du loslegen kannst brauchen wir noch folgende Informationen
              von dir.
            </p>
          </div>
          <div>
            <form onSubmit={handleSubmit(submit)}>
              <div className="grid gap-4">
                <div>
                  <HookFormSalutationSelectV2
                    control={control}
                    name={"salutation"}
                    error={false}
                    label={"Anrede*"}
                  />
                </div>
                <div>
                  <HookFormTitleSelectV2
                    control={control}
                    name={"title"}
                    error={false}
                    label={"Titel"}
                  />
                </div>
                <div>
                  <HookFormTextFieldV2
                    required
                    control={control}
                    name={"firstName"}
                    error={errors.firstname != null}
                    label={"Vorname*"}
                  />
                </div>
                <div>
                  <HookFormTextFieldV2
                    required
                    control={control}
                    name={"lastName"}
                    error={errors.surname != null}
                    label={"Nachname*"}
                  />
                </div>

                <div>
                  <HookFormTextFieldV2
                    control={control}
                    defaultValue={""}
                    name={"phoneNumber"}
                    error={false}
                    label={"Telefonnummer"}
                  />
                </div>
                {/* <div>
                  <Controller
                    name="termsAccepted"
                    control={control}
                    defaultValue={false}
                    rules={{
                      required:
                        "Bitte bestätige die AGB und die Kundeninformation der Effecta GmbH.",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />

                        <Typography
                          variant="body2"
                          style={{ fontSize: "12px" }}
                        >
                          Die inVenture Capital iVC GmbH wird bei der
                          Vermittlung von Finanzinstrumenten ausschließlich im
                          Namen, auf Rechnung und unter der Haftung des
                          Wertpapierinstituts Effecta GmbH tätig. Ich habe die{" "}
                          <PdfLinkOverlay
                            title="Effecta AGB"
                            pdf="https://inventurestorage.blob.core.windows.net/platform/effecta_terms_of_service.pdf"
                          >
                            AGB
                          </PdfLinkOverlay>{" "}
                          und die{" "}
                          <PdfLinkOverlay
                            title="Effecta Kundeninformation"
                            pdf="https://inventurestorage.blob.core.windows.net/platform/kundeninformationen_effecta.pdf"
                          >
                            Kundeninformationen
                          </PdfLinkOverlay>{" "}
                          der Effecta GmbH gelesen und nehme diese zur
                          Kenntnis.*
                        </Typography>
                      </div>
                    )} // props contains: onChange, onBlur and value
                  />
                </div> */}

                <div />
                <div>
                  <PrimaryButton
                    disabled={!formState.isValid}
                    style={{ width: "100%" }}
                    type="submit"
                  >
                    Zu den Produkten
                  </PrimaryButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
