import { Grid, Typography } from "@mui/material";
import React from "react";
import { CostData } from "../../../types/fund_types";

type CostRepresentationprops = {
  amount: number;
  costData: CostData;
};

export default function CostRepresentation({ amount, costData }: CostRepresentationprops) {
  type CostPointProps = {
    bold?: boolean;
    percent: number;
  };
  const CostPoint = ({ bold, percent }: CostPointProps) => (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="body1" style={{ float: "left", fontWeight: bold ? 700 : 400 }}>
          {(percent * amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}€{" "}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" style={{ float: "left", fontWeight: bold ? 700 : 400 }}>
          {(percent * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
        </Typography>
      </Grid>
    </Grid>
  );

  const widthFirstColumn = 4;
  const doubleColumnWidth = 4;
  const singleColumnWidth = 8;

  const descriptionTextVariant = "body2";

  return (
    <Grid container spacing={1} style={{ textAlign: "left" }}>
      <Grid item xs={widthFirstColumn}>
        <Typography variant={descriptionTextVariant}>Nominalkapitalbetrag</Typography>
      </Grid>
      <Grid item xs={singleColumnWidth}>
        <Typography variant="body1">{amount.toLocaleString()}€</Typography>
      </Grid>
      <Grid item xs={widthFirstColumn}>
        <Typography variant={descriptionTextVariant}>Monetäre Zuwendungen</Typography>
      </Grid>
      <Grid item xs={singleColumnWidth}>
        <Typography variant="body1">
          {(costData.monetaereZuwendungenAufAusgabebetrag * amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}€ (
          {(costData.monetaereZuwendungenAufAusgabebetrag * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% in Bezug
          auf den Ausgabebetrag)
        </Typography>
      </Grid>
      <Grid item xs={widthFirstColumn}>
        <Typography variant={descriptionTextVariant}>Davon weitergeleitet an vertraglich gebundenen Vermittler</Typography>
      </Grid>
      <Grid item xs={singleColumnWidth}>
        <Typography variant="body1">
          {(costData.monetaereZuwendungenAufAusgabebetragAnVermittler * amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          € (
          {(costData.monetaereZuwendungenAufAusgabebetragAnVermittler * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
          in Bezug auf den Ausgabebetrag)
        </Typography>
      </Grid>

      {/** Art der Gebühren */}
      <Grid item xs={12} spacing={1} style={{ backgroundColor: "rgb(230,230,230)", padding: "0 0px 0px 5", borderRadius: "4px", marginTop: "10px" }}>
        <Grid container>
          <Grid item xs={widthFirstColumn}>
            <Typography variant={descriptionTextVariant}>Art der Gebühren und Kosten</Typography>
          </Grid>
          <Grid item xs={doubleColumnWidth}>
            <Typography variant={descriptionTextVariant}>Einmalkosten</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body2">Nom. Angabe</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">%-Angabe</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={doubleColumnWidth}>
            <Typography variant={descriptionTextVariant}>Laufende Kosten pro Jahr</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body2">Nom. Angabe</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">%-Angabe</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={widthFirstColumn}>
        <Typography variant={descriptionTextVariant}>Kosten der Vermittlung</Typography>
      </Grid>
      <Grid item xs={doubleColumnWidth}>
        <CostPoint percent={costData.kostenVermittlungEinmal} />{" "}
      </Grid>
      <Grid item xs={doubleColumnWidth}>
        <CostPoint percent={costData.kostenVermittlungLaufend} />{" "}
      </Grid>
      <Grid item xs={widthFirstColumn}>
        <Typography variant={descriptionTextVariant}>Davon Zuwendungen</Typography>
      </Grid>
      <Grid item xs={doubleColumnWidth}>
        <CostPoint percent={costData.zuwendungenVonKostenVermittlungEinmal} />
      </Grid>
      <Grid item xs={doubleColumnWidth}>
        <CostPoint percent={costData.zuwendungenVonKostenVermittlungLaufend} />{" "}
      </Grid>
      <Grid item xs={widthFirstColumn}>
        <Typography variant={descriptionTextVariant}>
          Kosten des Finanzinstrumentes <br />
          (Transaktionskosten / Depotkosten)
        </Typography>
      </Grid>
      <Grid item xs={doubleColumnWidth}>
        <CostPoint percent={costData.kostenFinanzInstrumentEinmal} />{" "}
      </Grid>
      <Grid item xs={doubleColumnWidth}>
        <CostPoint percent={costData.kostenFinanzInstrumentLaufend} />{" "}
      </Grid>
      <Grid item xs={widthFirstColumn}>
        <Typography variant={descriptionTextVariant}>Gesamtkosten des Finanzinstrumentes</Typography>
      </Grid>
      <Grid item xs={doubleColumnWidth}>
        <CostPoint percent={costData.gesamtkostenFinanzInstrumentEinmal} />{" "}
      </Grid>
      <Grid item xs={doubleColumnWidth}>
        <CostPoint percent={costData.gesamtkostenFinanzInstrumentLaufend} />{" "}
      </Grid>

      {/** Gesamtkosten */}
      <Grid item xs={12} spacing={1} style={{ backgroundColor: "rgb(230,230,230)", padding: "0 0px 0px 5", borderRadius: "4px", marginTop: "10px" }}>
        <Grid container spacing={1}>
          <Grid item xs={widthFirstColumn}>
            <Typography variant="body2">Art der Gebühren und Kosten</Typography>
          </Grid>
          <Grid item xs={doubleColumnWidth}>
            <Typography variant={descriptionTextVariant}>Komplett</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body2">Nom. Angabe</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">%-Angabe</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={doubleColumnWidth}>
            <Typography variant={descriptionTextVariant}>p.a.</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body2">Nom. Angabe</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">%-Angabe</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={widthFirstColumn}>
        <Typography variant={descriptionTextVariant} style={{ fontWeight: 700 }}>
          Gesamtkosten
        </Typography>
      </Grid>
      <Grid item xs={doubleColumnWidth}>
        <CostPoint percent={costData.gesamtkostenKomplett} bold />{" "}
      </Grid>
      <Grid item xs={doubleColumnWidth}>
        <CostPoint percent={costData.gesamtkostenKomplettPA} bold />{" "}
      </Grid>
      <Grid item xs={widthFirstColumn}>
        <Typography variant={descriptionTextVariant}>Laufzeit</Typography>
      </Grid>
      <Grid item xs={singleColumnWidth}>
        {costData.laufzeit} Jahre
      </Grid>
      <Grid item xs={4}>
        <Typography variant={descriptionTextVariant}>Kosten reduzieren die Rendite im ersten Jahr</Typography>
      </Grid>
      <Grid item xs={4}>
        <CostPoint percent={costData.kostenReduzierenRenditeErstenJahr} />{" "}
      </Grid>
      <Grid item xs={4}/>
      <Grid item xs={4}>
        <Typography variant={descriptionTextVariant}>Kosten reduzieren die Rendite in den folgenden Jahren</Typography>
      </Grid>
      <Grid item xs={4}>
        <CostPoint percent={costData.kostenReduzierenRenditeFolgejahre} />{" "}
      </Grid>
      <Grid item xs={4}/>
    </Grid>
  );
}
