
import { Divider } from "@mui/material";
import React from "react";
import { OverviewSectionType } from "../../../../types/fund_types";
import OverlayText from "../../../data-display/overlay-text";
import StandardCard from "../../../data-display/standard-card/standard-card";
import ContentBlocks from "../content-blocks";
export type PlanetAOverviewSectionProps = {
  data: OverviewSectionType;
};

export default function OverviewSection({ data }: PlanetAOverviewSectionProps) {
  return (
    <div>
      <OverlayText header="Fondsüberblick" contentBlocks={data.contentBlocks} />
      <Divider />
      {/**<StandardCard header="Fondsüberblick">
      <ContentBlocks blocks={data.contentBlocks}/>
  </StandardCard>*/}
    </div>
  );
}
