import React, { useState } from "react";
import IBAN from "iban";
import API_CLIENT from "../../utility/api-client";
import {
  BankAccount,
  InventureUser,
  LEGAL_ENTITY_TYPE,
} from "../../types/inventure-user_types";
import { AppContext } from "../../pages/portal/auth-router";
import PrimaryButton from "../input-elements/buttons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  TextField,
  DialogActions,
} from "@mui/material";
import { INVESTOR_TYPE } from "../../types/onboarding_types";

type SetBankDialogProps = {
  user: InventureUser;
  open: boolean;
  handleClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  submit: Function;
};

export default function SetBankDialog({
  user,
  submit,
  open,
  handleClose,
}: SetBankDialogProps) {
  var ownr = user.natural_person.forename + " " + user.natural_person.surname;
  if (user.investor_type == INVESTOR_TYPE.LEGAL_PERSON && user.legal_person) {
    var type = "GmbH";
    if (user.legal_person.legal_entity_type == LEGAL_ENTITY_TYPE.UG)
      type = "UG";
    ownr = user.legal_person.company_name + " " + type;
  }
  const [owner] = useState(ownr);
  const [iban, setIban] = useState("");
  const [error, setError] = useState("");
  const [bic, setBank] = useState("");

  const setBankAccount = (token: String) => {
    if (owner.length === 0) {
      setError("Kontoinhaber nicht angegeben");
      return;
    }
    setError("");
    if (!IBAN.isValid(iban)) {
      setError("IBAN ungültig");
      return;
    }
    if (bic.length != 11 && bic.length != 8) {
      setError("BIC ungültig");
      return;
    }
    var bankAccount: BankAccount = {
      iban: iban,
      account_holder: owner,
      bank: "",
      bic: bic,
      currency: "EUR",
      country: "",
    };
    API_CLIENT.putBankAccount(
      token.toString(),
      bankAccount,
      () => submit(),
      () => setError("sending Data Error")
    );
  };

  return (
    <AppContext.Consumer>
      {(appContext) =>
        AppContext && (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="bank-dialog">{"Deine Bankdaten"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Aus rechtlichen Gründen darf der Kontoinhaber nicht vom
                        Nutzer abweichen.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Kontoinhaber: {owner}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={bic}
                        label="BIC"
                        style={{ width: "100%" }}
                        onChange={(event) =>
                          setBank(event.target.value.toUpperCase())
                        }
                      ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={iban}
                        label="IBAN"
                        style={{ width: "100%" }}
                        onChange={(event) =>
                          setIban(
                            IBAN.printFormat(event.target.value.toUpperCase())
                          )
                        }
                      ></TextField>
                    </Grid>
                    {error !== "" ? (
                      <Grid item xs={12}>
                        <Typography variant="body2" style={{ color: "red" }}>
                          {error}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <PrimaryButton
                style={{ width: "200px", height: "50px", margin: "15px" }}
                onClick={() => setBankAccount(appContext.token)}
              >
                Speichern
              </PrimaryButton>
            </DialogActions>
          </Dialog>
        )
      }
    </AppContext.Consumer>
  );
}
