import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

type MultistepFormProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
};

export default function MultistepForm({
  children,
  style,
  sx,
}: MultistepFormProps) {
  return (
    <Box
      sx={{
        display: { xs: "flex", md: "flex" },
        height: { xs: "100%", md: "100vh" },
        minHeight: { xs: "700px", md: "700px" },
        flexDirection: { xs: "column", md: "row" },
        ...sx,
      }}
      style={{
        width: "100%",

        position: "relative",
        ...style,
      }}
    >
      {children}
    </Box>
  );
}
