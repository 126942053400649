import React from "react";

interface IVDrawerProps {
  open: boolean;
  close: () => void;
  children: React.ReactNode;
  large?: boolean;
}
export default function IVDrawer({
  open,
  close,
  children,
  large,
}: IVDrawerProps) {
  var max_w = "max-w-md";
  if (large) max_w = "max-w-3xl";

  return (
    <div
      className={` transition-all duration-300 w-full top-0 flex justify-start ${
        open ? "right-0" : "-right-full"
      } h-screen bg-black bg-opacity-20 fixed flex-shrink-0 z-50`}
    >
      <div className="w-full h-screen" onClick={() => close()} />
      <div
        className={`relative flex-shrink-0 w-full ${max_w}
      z-100 min-h-screen pb-16 bg-white shadow-lg overflow-y-scroll overflow-x-hidden scrollbar-hide`}
      >
        {children}
      </div>
    </div>
  );
}
