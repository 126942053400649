import { Divider, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { WalletPositionType } from "../../../pages/portal/dashboard/dashboard-new";
import theme from "../../../theme";
import { TARGET_INVESTMENT_TYPE } from "../../../types/fund_types";
import { Fund } from "../../../types/vc-platform/news_types";

type WalletPositionProps = {
  style: React.CSSProperties;
  fund?: Fund;
  position: WalletPositionType;
};

export default function WalletPosition({
  style,
  fund,
  position,
}: WalletPositionProps) {
  const currentYear = 0.5;
  const maxYear = 10;
  const percent = (currentYear / maxYear) * 100 + "%";
  const variantsWrapper = {
    open: {
      height: "auto",
      backgroundColor: theme.palette.background.paper,
      boxShadow: "black 0 0 10px -5px",
    },
    closed: {
      height: "60px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: "none",
    },
  };

  const variantsProgressIllustration = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: -10 },
  };

  const textVariants = {
    open: { opacity: 1 },
    closed: { opacity: 2 },
  };

  const [open, setOpen] = useState(false);

  const startups = fund?.deals?.map((deal) => deal?.startup).flat();
  const reportings = position.fund?.reportings;
  return (
    <motion.div
      animate={open ? "open" : "closed"}
      variants={variantsWrapper}
      whileHover={{ scale: 1.0 }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
      style={{
        width: "calc(100% - 10px)",
        backgroundColor: "#EAD7BB",
        borderRadius: "5px",
        overflow: "hidden",
        color: "rgb(80,80,80)",
        padding: "5px",
        ...style,
      }}
    >
      <Grid
        container
        onClick={() => setOpen(!open)}
        style={{ height: "60px", padding: "10px", cursor: "pointer" }}
        justifyContent="flex-start"
        alignContent="center"
        alignItems="center"
      >
        <Grid item xs={4} md={3}>
          <Typography variant="body1" style={{ display: "flex" }}>
            <img
              src={position.fund?.tokenLogo}
              style={{ maxWidth: "70%", maxHeight: "35px" }}
            />
          </Typography>
        </Grid>
        <Grid item xs={4} md={3}>
          <Typography variant="body1" style={{ display: "flex" }}>
            {position.fund?.targetInvestmentType ==
            TARGET_INVESTMENT_TYPE.STARTUP ? (
              <img
                src={position.fund.logoUrl}
                style={{ maxWidth: "70%", maxHeight: "35px" }}
              />
            ) : (
              <img
                src={fund?.logo?.url}
                style={{ maxWidth: "70%", maxHeight: "35px" }}
              />
            )}
          </Typography>
        </Grid>
        <Grid item xs={4} md={3}>
          <Typography variant="h5" style={{ textAlign: "right" }}>
            <b>{position.amount.toLocaleString("de-de")}€</b>
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "5px" }} />
      <div>
        <Grid container justifyContent="left" style={{ margin: "30px 10px" }}>
          <Grid item xs={6} md={3}>
            <motion.div
              transition={{ duration: 0.1, delay: 0.3 }}
              animate={open ? "open" : "closed"}
              variants={variantsProgressIllustration}
            >
              <Typography
                variant="h5"
                style={{ color: "rgb(30,30,30)", marginBottom: "-5px" }}
              >
                <b>{position.stage}</b>
              </Typography>
              <Typography variant="body1">
                <b>Stage</b>
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={6} md={3}>
            <motion.div
              transition={{ duration: 0.1, delay: 0.3 }}
              animate={open ? "open" : "closed"}
              variants={variantsProgressIllustration}
            >
              <Typography
                variant="h5"
                style={{ color: "rgb(30,30,30)", marginBottom: "-5px" }}
              >
                <b>{position.fund?.fokus}</b>
              </Typography>
              <Typography variant="body1">
                <b>Fokus</b>
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
      </div>

      <motion.div
        transition={{ duration: 0.1, delay: 0.9 }}
        animate={open ? "open" : "closed"}
        variants={variantsProgressIllustration}
      >
        {reportings && reportings.length > 0 ? (
          <div style={{ margin: "10px", width: "calc(100% - 20px)" }}>
            <Typography variant="body1">
              <b>Reportings</b>
            </Typography>
            <div className="flex flex-wrap gap-4">
              {reportings.map((reporting) => (
                <a
                  target="_blank"
                  href={reporting.document.url}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {" "}
                  <div
                    style={{
                      minWidth: "200px",
                      padding: "10px",
                      height: "60px",

                      backgroundColor: "rgba(0,0,0,0.05)",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      className="w-auto h-full"
                      alt="pdf"
                      src="https://ik.imagekit.io/inventure/App/Icons/pdf-icon_v1n4-WvDhW.png"
                      style={{ height: "30px" }}
                    ></img>
                    <Typography variant="body2" className="ml-4 font-semibold">
                      {reporting.name}
                    </Typography>
                  </div>
                </a>
              ))}
            </div>
          </div>
        ) : null}
      </motion.div>
    </motion.div>
  );
}

export function WalletPositionV2({
  style,
  fund,
  position,
}: WalletPositionProps) {
  const [open, setOpen] = useState(false);

  const startups = fund?.deals?.map((deal) => deal?.startup).flat();
  const reportings = position.fund?.reportings;
  return (
    <li
      className={` odd:bg-slate-200 overflow-hidden px-2  ${
        !open ? "h-12  hover:bg-slate-300 " : "bg-slate-100 h-74"
      } transition-all ease-in-ou duration-300`}
    >
      <div
        onClick={() => setOpen(!open)}
        className="h-12 cursor-pointer grid grid-cols-3 items-center"
      >
        <div>
          <p className="text-sm">{position.fund?.name}</p>
        </div>
        <div>
          <p className="text-sm hidden md:block">{position.fund?.fundName}</p>
        </div>
        <div>
          <p className="text-sm text-right font-medium">
            {position.amount.toLocaleString("de-de")}€
          </p>
        </div>
      </div>
      <Divider />
      <div className="p-4 ">
        <div className="grid grid-cols-3">
          <div>
            <div>
              <p className="text-xl font-medium ">{position.stage}</p>
              <p className="text-xs">Stage</p>
            </div>
          </div>
          <div>
            <div>
              <p className="text-xl font-medium ">{position.fund?.fokus}</p>
              <p className="text-xs">Fokus</p>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4">
        {reportings && reportings.length > 0 ? (
          <div style={{ width: "calc(100% - 20px)" }}>
            <p className="mb-2 text-sm">Reportings</p>

            <div className="flex flex-wrap gap-4">
              {reportings.map((reporting) => (
                <a
                  target="_blank"
                  href={reporting.document.url}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {" "}
                  <div
                    className="bg-gray-200 hover:bg-gray-300"
                    style={{
                      width: "80px",
                      height: "80px",

                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      alt="pdf"
                      src="https://ik.imagekit.io/inventure/App/Icons/pdf-icon_v1n4-WvDhW.png"
                      style={{ height: "30px", margin: "10px 0" }}
                    ></img>
                    <p className="text-sm font-medium">{reporting.name}</p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </li>
  );
}
