
import Typography from "@mui/material/Typography";
import React from "react";
import { IntroductionSectionType } from "../../../../types/fund_types";
import OverlayText from "../../../data-display/overlay-text";
import StandardCard from "../../../data-display/standard-card/standard-card";
import ContentBlocks from "../content-blocks";


export type IntroductionSectionProps = {
  data: IntroductionSectionType
}

export default function IntroductionSection({ data }: IntroductionSectionProps) {
  return (
    <div style={{marginTop: "70px"}}>
       <Typography variant="h3" style={{ color: "#494949", fontWeight: 700, marginBottom: "10px", fontSize: "24px" }}>
        Wie ist die Struktur des Investments?
      </Typography>
      <ContentBlocks blocks={data.contentBlocks}/>
    
     {/**<StandardCard header="Einleitung">
       
     <ContentBlocks blocks={data.contentBlocks}/>
    </StandardCard>*/}
    </div>
  );
}
