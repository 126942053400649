import React, { useState } from "react";

type ReferralProgrammProps = {
  referralcode: string | null | undefined;
};

export default function ReferralProgramm({
  referralcode,
}: ReferralProgrammProps) {
  const text =
    "Erhalte Zugang zu über 200 Start-ups - mit nur einer Investition. Hier gelangst du zum Venture Capital Dachfonds von inVenture Capital: https://www.inventure.capital/inventure-capital-ivc-venture-innovation-fund. Nutze bei der Investition in Schritt 6 meinen persönlichen Code und erhalte eine Rabattierung in Höhe von 3%: " +
    referralcode;

  const [is_open, setIsOpen] = useState(false);
  if (!referralcode) return null;
  return (
    <div
      onClick={() => {
        setIsOpen(true);
      }}
      className="col-span-1 md:col-span-2 gap-4 rounded-xl bg-primary  bg-gradient-to-r from-primary to-[#70B0F0]"
    >
      <div className="cursor-pointer p-4">
        <h2 className="text-white text-xs">Connect & Earn</h2>
        <p className="text-white">Empfehle uns und erhalte mindestens 300€</p>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        className={`w-full h-full flex justify-center items-center overflow-hidden bg-black bg-opacity-50 ${
          is_open ? "fixed top-0 left-0 z-[100]" : "hidden"
        }`}
      >
        <div
          className="w-full min-h-full sm:min-h-fit sm:w-3/4 sm:rounded-xl bg-slate-50 flex overflow-hidden max-w-4xl max-h-fit"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex-1 flex items-center justify-center min-w-[30px] sm:min-w-[100px]">
            <img
              className="h-full w-auto object-cover"
              alt="referral-bild"
              src="https://images.unsplash.com/photo-1729731322066-183911deb95b?q=80&w=2667&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
          </div>
          <div className="h-full p-8 flex justify-between flex-col gap-8 w-full">
            <div className="flex justify-between">
              <h2 className="text-primary_light text-xl">Connect & Earn</h2>
              <div
                className="w-8 h-8 relative sm:hidden cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <div className="top-4 left-0 h-[1px] w-full bg-primary absolute rotate-45" />
                <div className="top-4 h-[1px] w-full bg-primary absolute -rotate-45" />
              </div>
            </div>
            <div className="flex flex-col justify-evenly h-2/3 text-xl sm:text-2xl text-primary gap-4">
              <div className="flex gap-4 flex-shrink-0">
                <b className="w-6">1.</b>
                <div>
                  <p>Teile deinen Code mit Bekannten</p>
                  <p className="text-sm text-primary_light">
                    Kopiere deinen persönlichen 7-stelligen Referral Code und
                    teile diesen mit Freunden und Bekannten.
                  </p>
                </div>
              </div>
              <div className="flex gap-4">
                <b className="w-6">2.</b>{" "}
                <div>
                  <p>
                    Mindestens <b>300€</b> Agioerlass für die geworbene Person
                  </p>
                  <p className="text-sm text-primary_light">
                    Bei der Investition erhält der Geworbene 3% Rabatt.
                  </p>
                </div>
              </div>
              <div className="flex gap-4">
                <b className="w-6">3.</b>
                <div>
                  <p>
                    Mindestens <b>300€</b> Cashback für dich
                  </p>
                  <p className="text-sm text-primary_light">
                    Du erhältst 3% der Investitionshöhe der Investition als
                    Cashback.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex gap-8 w-full items-end flex-wrap">
              <div
                className="border rounded-md p-2 bg-[#F7A963] text-white"
                onClick={() => navigator.clipboard.writeText(referralcode)}
              >
                {referralcode}
              </div>
              <a
                style={{ margin: "0 5px" }}
                href={"https://api.whatsapp.com/send?text=" + text}
                target="_blank"
              >
                <div
                  style={{
                    height: "45px",
                    width: "45px",
                    color: "white",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={
                      "https://ik.imagekit.io/inventure/App/Icons/icons8-whatsapp-96_QR0h6_xMO.png?updatedAt=1730286006255"
                    }
                    style={{
                      color: "white",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </div>
              </a>
              <a
                style={{ margin: "0 5px" }}
                href={`mailto:?subject=Dein Code für inVenture&body=${text}`}
              >
                <div
                  style={{
                    height: "45px",
                    width: "45px",
                    color: "white",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={
                      "https://ik.imagekit.io/inventure/App/Icons/icons8-mail-96_bPs3KMxn7.png?updatedAt=1730285246603"
                    }
                    style={{
                      color: "white",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </div>
              </a>
              <div className="flex-grow" />

              <a
                className="text-xs underline text-gray-700"
                href="https://inventurestorage.blob.core.windows.net/platform/referral/Nutzungsbedingungen Connect and Earn.pdf"
                target="_blank"
              >
                Teilnahmebedingungen
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
