import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import API_CLIENT from "../../../../utility/api-client";
import queryString from "query-string";
import {
  Investment,
  CreateInvestmentDTO,
  SelfDisclosure,
  Voucher,
} from "../../../../types/investment_types";
import LoadingScreen from "../../../loading-screen";
import {
  Campaign,
  CAMPAIGN_TYPE,
  CHARACTERISTIC,
  Fund,
} from "../../../../types/fund_types";
import { AppContext } from "../../../../pages/portal/auth-router";
import { useAuth0 } from "@auth0/auth0-react";
import ReactGA from "react-ga";
import NumberFormat from "react-number-format";
import { Helmet } from "react-helmet";
import { InvestmentContext } from "../../../../pages/portal/investment/investment-process";
import { sha256 } from "js-sha256";
import TagManager from "react-gtm-module";
import { MatchingDTO } from "../../../../types/generic_types";
import { v4 } from "uuid";
import { InvestmentContextNew } from "../../../../pages/portal/investment/investment-process-new";
import MultistepFormButtons from "../../../vertical-multistep-form/multistep-form-buttons";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

type InvestmentAmountStepProps = {
  commulatedInvestmentAmount: number;
  stepFinished: () => void;
};
export function InvestmentAmountStep({
  commulatedInvestmentAmount,
  stepFinished,
}: InvestmentAmountStepProps) {
  const appContext = useContext(AppContext);
  const investmentContext = useContext(InvestmentContextNew);
  const investment = investmentContext.investment;
  const campaign = investmentContext.campaign;
  const fund = investmentContext.fund;
  const setLoading = investmentContext.setLoading;

  const [voucherOpen, setVoucherOpen] = useState(false);
  const [voucher, setVoucher] = useState<Voucher>();
  const [couponCode, setCouponCode] = useState("");
  const [couponState, setCouponState] = useState(0); // no coupon:0, invalid: 1, valid: 2

  const [amount, setAmount] = useState<number>(0);
  const [selfDisclosure100k, setSelfDisclosure100k] = useState(false);
  const [selfDisclosureMonthlyIncome, setSelfDisclosureMonthlyIncome] =
    useState(false);
  const [loadingNextStep, setLoadingNextStep] = useState(false);
  const history = useHistory();

  const callId = v4();

  //PRIVATE PLACEMENT
  const queries = queryString.parse(window.location.search);
  var investmentKey = queries.key ? queries.key.toString() : null;
  var special = queries.special ? queries.special.toString() : null;
  var mitarbeitergeschaeft = false;
  special == "mitarbeitergeschaeft"
    ? (mitarbeitergeschaeft = true)
    : (mitarbeitergeschaeft = false);

  const [error, setError] = useState(false);
  var MIN_INVESTMENT_PUBLIC = investmentContext.campaign
    ? investmentContext.campaign.minInvestmentAmount
    : 1000;
  if (mitarbeitergeschaeft) {
    MIN_INVESTMENT_PUBLIC = 1000;
  }

  if (investment != null) {
    investmentKey = investment.investmentKey;
  }
  const [keyNotFoundError, setKeyNotFoundError] = useState(false);
  const [keyAlreadyUsedError, setKeyAlreadyUsedError] = useState(false);
  const isPrivate = campaign?.type === CAMPAIGN_TYPE.PRIVATE;
  const user = appContext.user;

  useEffect(() => {
    if (investment == null) {
      var uData = appContext.matchingData;
      var mData: MatchingDTO = {
        event: "InitiateCheckout",
        eventId: callId,
        sourceUrl: window.location.href,
        em: uData?.em,
        ph: uData?.ph,
        fn: uData?.fn,
        ln: uData?.ln,
        product: fund?.name,
        focus: fund?.fokus,
        type: fund?.type,
      };

      API_CLIENT.postMatching(appContext.token, mData);

      TagManager.dataLayer({
        dataLayer: {
          event: "initiate-checkout",
          event_id: callId,
          first_name: appContext.user?.natural_person.forename,
          last_name: appContext.user?.natural_person.surname,
          phone: uData?.ph,
          email: sha256(appContext.userEmail),
          product: fund?.name,
          focus: fund?.fokus,
          type: fund?.type,
        },
      });
    }
    if (investment?.amount == null) {
      setAmount(
        investmentContext.campaign?.type === CAMPAIGN_TYPE.PRIVATE
          ? maxAmount
          : MIN_INVESTMENT_PUBLIC
      );
    } else {
      setAmount(investment?.amount * investment?.tokenPrice);
    }
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (campaign == undefined) return null;
  if (user == undefined) return null;
  const uid = user.uid;

  const setAmountFunction = (amount: number) => {
    if (
      amount % 100 !== 0 ||
      amount < MIN_INVESTMENT_PUBLIC ||
      amount > maxAmount
    ) {
      setError(true);
    } else {
      setError(false);
    }
    setSelfDisclosure100k(false);
    setSelfDisclosureMonthlyIncome(false);
    setAmount(amount);
  };

  const setNewVoucher = (voucher: Voucher) => {
    if (!voucher.valid) {
      setCouponState(1);
      setVoucher(undefined);
    } else {
      setCouponState(2);
      setCouponCode(voucher.code);
      setVoucher(voucher);
    }
  };

  const sendInvestment = (amount: number | undefined) => {
    window.scrollTo(0, 0);
    if (amount === undefined) {
      return;
    }
    if (
      !isPrivate &&
      (amount % 100 !== 0 ||
        amount < MIN_INVESTMENT_PUBLIC ||
        amount > maxAmount)
    ) {
      setError(true);
      return;
    }
    if (isPrivate && (amount % 100 !== 0 || amount < 25000)) {
      setError(true);
      return;
    }
    var sd: SelfDisclosure = {
      self_disclosure_monthly_income: selfDisclosureMonthlyIncome,
      self_disclosure_net_worth_100k: selfDisclosure100k,
    };
    var createInvestmentDTO: CreateInvestmentDTO = {
      selfDisclosure: sd,
      couponCode: couponCode === "" ? null : couponCode,
      investmentKey: investmentKey,
    };
    setLoading(true);
    if (investment === null) {
      API_CLIENT.postInvestmentAmount(
        createInvestmentDTO,
        appContext.token,
        amount,
        campaign.cashlinkId,
        (investment) => {
          var uData = appContext.matchingData;

          var mData: MatchingDTO = {
            event: "CustomizeProduct",
            eventId: investment.id,
            sourceUrl: window.location.href,
            em: uData?.em,
            ph: uData?.ph,
            fn: uData?.fn,
            ln: uData?.ln,
            product: fund?.name,
            focus: fund?.fokus,
            type: fund?.type,
            value: investment.amount.toString(),
            currency: "EUR",
          };

          API_CLIENT.postMatching(appContext.token, mData);

          TagManager.dataLayer({
            dataLayer: {
              event: "customize-product",
              event_id: investment.id,
              first_name: appContext.user?.natural_person.forename,
              last_name: appContext.user?.natural_person.surname,
              phone: uData?.ph,
              email: sha256(appContext.userEmail),
              product: fund?.name,
              focus: fund?.fokus,
              type: fund?.type,
              value: investment.amount,
              currency: "EUR",
            },
          });
          (window as any).dataLayer.push({
            event: "investment-created",
            "investment-amount": investment.amount,
          });
          ReactGA.event({
            category: "Investment",
            action: "investment - step1 finished",
            value: amount,
          });
          investmentContext.setInvestment(investment);
          setTimeout(() => setLoading(false), 500);
        },
        (error) => console.log(error),
        () => {
          setKeyNotFoundError(true);
          setLoading(false);
        },
        () => {
          setKeyAlreadyUsedError(true);
          setLoading(false);
        }
      );
    } else {
      API_CLIENT.putInvestmentAmount(
        createInvestmentDTO,
        appContext.token,
        amount,
        investment.id,
        (investment) => {
          ReactGA.event({
            category: "Investment",
            action: "investment - step1 finished",
            value: amount,
          });
          investmentContext.setInvestment(investment);
          stepFinished();
        },
        (error) => console.log(error),
        () => {
          setKeyNotFoundError(true);
          setLoading(false);
        },
        () => {
          setKeyAlreadyUsedError(true);
          setLoading(false);
        }
      );
    }
  };

  const discount = voucher?.discount;

  const amountRecommendation1 = MIN_INVESTMENT_PUBLIC;
  const amountRecommendation2 = 5000;
  const amountRecommendation3 = 10000;
  const amountRecommendation4 = 20000;

  const one_million_exception = campaign.characteristic?.includes(
    CHARACTERISTIC.ONE_MILLION_EXCEPTION
  );

  const maxAmount = campaign.maxInvestmentAmount;
  const commulatedAmountError = isPrivate
    ? false
    : commulatedInvestmentAmount + amount > maxAmount;
  const commulatedAmount = commulatedInvestmentAmount + amount;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        sendInvestment(amount);
      }}
    >
      {loadingNextStep ? (
        <>
          <LoadingScreen className="h-screen w-full" />
        </>
      ) : (
        <Grid container style={{ padding: "0px 0px 100px 0px" }}>
          {/** PUBLIC PLACEMENT */}
          <Grid item xs={12}>
            <Grid
              container
              alignItems="flex-start"
              alignContent="center"
              spacing={0}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  style={{ fontSize: "32px", fontWeight: 500 }}
                >
                  Investitionsdaten
                </Typography>
                <img
                  src={fund?.tokenLogo}
                  style={{ height: "60px", margin: "30px 0" }}
                />
              </Grid>
              <Grid item xs={12}>
                {investmentKey == null && isPrivate ? (
                  <Typography variant="body1" style={{ color: "red" }}>
                    Es ist ein Fehler aufgetreten, sodass Du mit der Investition
                    nicht beginnen kannst. Bitte melde Dich telefonisch (wollen
                    wir?) unter +49 30 209346616 oder schreibe eine E-Mail an
                    invest@inventure.de. Wir kümmern uns dann schnellstmöglich
                    um das Problem.
                  </Typography>
                ) : null}
                {isPrivate && keyNotFoundError ? (
                  <Typography variant="body1" style={{ color: "red" }}>
                    Dein Investitionscode wurde nicht gefunden....​
                  </Typography>
                ) : null}
                {isPrivate && keyAlreadyUsedError ? (
                  <Typography variant="body1" style={{ color: "red" }}>
                    Dein Investitionscode wurde bereits verwendet...​
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ marginBottom: "20px" }} />
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: "24px", fontWeight: 400 }}
                >
                  Wähle den Betrag aus, den Du investieren möchtest.​
                </Typography>
                {isPrivate ? (
                  <Typography variant="body1" style={{ margin: "20px 0 20px" }}>
                    Der Investitionsbetrag muss mindestens{" "}
                    {Number(MIN_INVESTMENT_PUBLIC).toLocaleString()}€ betragen
                  </Typography>
                ) : (
                  <Typography variant="body1" style={{ margin: "20px 0 20px" }}>
                    Der Investitionsbetrag muss zwischen{" "}
                    {Number(MIN_INVESTMENT_PUBLIC).toLocaleString()}€ und{" "}
                    {Number(maxAmount).toLocaleString()}€ liegen und durch 100
                    teilbar sein.
                  </Typography>
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    color: "white",
                  }}
                >
                  {MIN_INVESTMENT_PUBLIC <= 5000 ? (
                    <Grid container spacing={0}>
                      <Grid item xs={6} md={3} style={{ display: "grid" }}>
                        <Button
                          variant="outlined"
                          style={{
                            justifyContent: "",
                            width: "90%",
                            margin: "5px 0",
                            height: "50px",
                          }}
                          onClick={() =>
                            setAmountFunction(amountRecommendation1)
                          }
                        >
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 500, fontSize: "20px" }}
                          >
                            {Number(amountRecommendation1).toLocaleString("de")}
                            €
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={3} style={{ display: "grid" }}>
                        <Button
                          variant="outlined"
                          style={{
                            justifyContent: "",
                            width: "90%",
                            margin: "5px 0",
                            height: "50px",
                          }}
                          onClick={() =>
                            setAmountFunction(amountRecommendation2)
                          }
                        >
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 500, fontSize: "20px" }}
                          >
                            {Number(amountRecommendation2).toLocaleString("de")}
                            €
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={3} style={{ display: "grid" }}>
                        <Button
                          variant="outlined"
                          style={{
                            justifyContent: "",
                            width: "90%",
                            margin: "5px 0",
                            height: "50px",
                          }}
                          onClick={() =>
                            setAmountFunction(amountRecommendation3)
                          }
                        >
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 500, fontSize: "20px" }}
                          >
                            {Number(amountRecommendation3).toLocaleString("de")}
                            €
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={3} style={{ display: "grid" }}>
                        <Button
                          variant="outlined"
                          style={{
                            justifyContent: "",
                            width: "90%",
                            margin: "5px 0",
                            height: "50px",
                          }}
                          onClick={() =>
                            setAmountFunction(amountRecommendation4)
                          }
                        >
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 500, fontSize: "20px" }}
                          >
                            {Number(amountRecommendation4).toLocaleString("de")}
                            €
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                </div>

                <NumberFormat
                  style={{
                    marginTop: "40px",
                    marginBottom: "20px",
                    width: "calc(50% - 20px)",
                  }}
                  value={amount || ""}
                  label="Investitionsbetrag"
                  inputProps={{
                    style: {
                      fontSize: "24px",
                      fontWeight: 300,
                      padding: "10px 15px",
                    },
                  }}
                  variant="outlined"
                  customInput={TextField}
                  suffix={" €"}
                  type="text"
                  decimalSeparator={","}
                  thousandSeparator={"."}
                  onValueChange={(value) => {
                    setAmountFunction(Number(value.value));
                  }}
                />

                {commulatedAmountError ? (
                  <Typography variant="body2" style={{ color: "red" }}>
                    Mit dem von Dir ausgewählten Betrag kommst Du über das
                    Maximalinvestment von {Number(maxAmount).toLocaleString()}€.
                  </Typography>
                ) : null}
                {isPrivate ? (
                  <div>
                    {amount < maxAmount ? (
                      <Typography variant="body2" style={{ color: "red" }}>
                        Bitte wähle einen Investitionsbetrag, der größer als{" "}
                        {Number(maxAmount).toLocaleString()}€ ist.
                      </Typography>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    {error ? (
                      <Typography variant="body2" style={{ color: "red" }}>
                        Bitte wähle einen Investitionsbetrag, der zwischen{" "}
                        {Number(MIN_INVESTMENT_PUBLIC).toLocaleString()}€ und{" "}
                        {Number(maxAmount).toLocaleString()}€ liegt und durch
                        100 teilbar ist.
                      </Typography>
                    ) : null}
                  </div>
                )}

                <Divider style={{ marginTop: "30px" }} />
                {isPrivate ? null : (
                  <>
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "left",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => setVoucherOpen(!voucherOpen)}
                    >
                      Gutschein einlösen
                    </Typography>
                    <Box
                      sx={{ display: { xs: voucherOpen ? "block" : "none" } }}
                    >
                      <ButtonGroup style={{ height: "55px" }}>
                        <TextField
                          className="voucher-textfield"
                          type="text"
                          style={{
                            borderRadius: "0px !important",
                            width: "90%",

                            maxWidth: "200px",
                            backgroundColor:
                              couponState === 0
                                ? "#f5efe6"
                                : couponState === 1
                                ? "#fa9696"
                                : "#b4cca9",
                            margin: "0px",
                          }}
                          onChange={(event) => {
                            setCouponCode(event.target.value);
                          }}
                          variant="outlined"
                        />

                        <Button
                          style={{ borderRadius: "4px", marginLeft: "10px" }}
                          onClick={(event) => {
                            API_CLIENT.validateVoucher(
                              uid,
                              amount,
                              couponCode,
                              (voucher: Voucher) => {
                                setNewVoucher(voucher);
                              },
                              (error) => console.log(error)
                            );
                          }}
                        >
                          Überprüfen
                        </Button>
                      </ButtonGroup>
                      <Typography variant="body1" style={{ marginTop: "20px" }}>
                        {couponState === 1 ? "Gutscheincode ungültig" : null}
                        {discount
                          ? discount.type === "AMOUNT"
                            ? "Dein Gutschein im Wert von " +
                              (
                                Number(voucher?.discount?.amount_off) / 100
                              ).toLocaleString("de-de", {
                                minimumFractionDigits: 2,
                              }) +
                              " € wird bei der Investition berücksichtigt"
                            : discount.type === "PERCENT"
                            ? "Dein Gutschein im Wert von " +
                              (
                                (Number(discount.percent_off) * amount) /
                                100
                              ).toLocaleString("de-de", {
                                minimumFractionDigits: 2,
                              }) +
                              "€ wird bei der Investition berücksichtigt"
                            : null
                          : null}
                      </Typography>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/** SELF DISCLOSURE SECTION ####################################################################################################################################################### */}
          {commulatedAmountError || one_million_exception ? null : (
            <Grid item xs={12} style={{ marginTop: "30px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{ textAlign: "left", fontWeight: 700 }}
                  >
                    Selbstauskunft
                  </Typography>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Bei dieser Kapitalanlage ist die Höhe der Investitionssumme
                    beschränkt und an bestimmte Voraussetzungen gebunden. Das
                    Wertpapierprospektgesetz schreibt in diesem Fall vor, Deine
                    Selbstauskunft einzuholen.
                  </Typography>
                  {commulatedAmount <= 10000 ? (
                    <Typography variant="body1" style={{ textAlign: "left" }}>
                      Eine Investition über {commulatedAmount.toLocaleString()}{" "}
                      Euro ist nur möglich, wenn Du zum Zeitpunkt der Zeichnung
                      mindestens eine der beiden folgenden Aussagen bestätigen
                      kannst:
                    </Typography>
                  ) : (
                    <Typography variant="body1" style={{ textAlign: "left" }}>
                      Eine Investition über {commulatedAmount.toLocaleString()}{" "}
                      Euro ist nur möglich, wenn Du zum Zeitpunkt der Zeichnung
                      die folgende Aussage bestätigen kannst:
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    style={{
                      textAlign: "left",
                      marginTop: "20px",
                      width: "100%",
                    }}
                    control={
                      <Checkbox
                        checked={selfDisclosureMonthlyIncome}
                        style={{ marginRight: "10px" }}
                        onChange={(event) => {
                          setSelfDisclosureMonthlyIncome(event.target.checked);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body1">
                        Ich bestätige, dass der Investitionsbetrag von{" "}
                        {commulatedAmount?.toLocaleString()}€ nicht das
                        Zweifache meines durchschnittlichen monatlichen
                        Netto-Einkommens übersteigt.
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {commulatedAmount <= 10000 ? (
                    <FormControlLabel
                      style={{ marginTop: "20px", width: "100%" }}
                      control={
                        <Checkbox
                          checked={selfDisclosure100k}
                          style={{ marginRight: "10px" }}
                          onChange={(event) => {
                            setSelfDisclosure100k(event.target.checked);
                          }}
                          name="selfDis100k"
                          color="primary"
                        />
                      }
                      label={
                        <Typography
                          variant="body1"
                          style={{ textAlign: "left" }}
                        >
                          Ich bestätige, dass ich über ein frei verfügbares
                          Vermögen in Form von Bankguthaben und
                          Finanzinstrumenten von mindestens{" "}
                          {Number(100000).toLocaleString()}€ verfüge.
                        </Typography>
                      }
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          )}
          <MultistepFormButtons
            disabled={
              (!selfDisclosure100k &&
                !selfDisclosureMonthlyIncome &&
                !one_million_exception) ||
              commulatedAmountError
            }
            back={false}
            backFunction={() => null}
          />
          {/**
          <Grid item xs={12}>
            <Button
              disabled={((!selfDisclosure100k && !selfDisclosureMonthlyIncome) && !one_million_exception) || commulatedAmountError}
              onClick={() => validateAndSend(amount)}
              style={{ width: "100%", marginTop: "30px" }}
              color="primary"
              variant="contained"
            >
              Weiter
            </Button>
          </Grid>
           */}
        </Grid>
      )}
    </form>
  );
}
