
import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { TeamMember, TeamSectionType } from "../../../../types/fund_types";
import theme from "../../../../theme";
import OverlayText from "../../../data-display/overlay-text";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "./swiper.css";

import "swiper/swiper-bundle.css";
import { Manager } from "../../../../types/vc-platform/news_types";
import { Box, Grid, Typography } from "@mui/material";
const PREFIX = 'team-section';

const classes = {
  root: `${PREFIX}-root`,
  member: `${PREFIX}-member`,
  imgWrapper: `${PREFIX}-imgWrapper`,
  img: `${PREFIX}-img`,
  text: `${PREFIX}-text`
};

const StyledBox = styled(Box)({
  [`& .${classes.root}`]: {
    padding: "0px",
  },
  [`& .${classes.member}`]: {
    [theme.breakpoints.between("xs", "md")]: {
      display: "inline-grid",
      textAlign: "left",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },

    height: "calc(100% - 40px)",
    margin: "10px",
    padding: "10px",
    borderRadius: "5px",
  },
  [`& .${classes.imgWrapper}`]: {
    [theme.breakpoints.up("md")]: {
      width: "150px",
    },
  },
  [`& .${classes.img}`]: {
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("md")]: {},
    marginTop: "0px",
    display: "inline-block",
    verticalAlign: "middle",
    borderRadius: "10px",
    borderStyle: "solid",
    float: "left",

    borderWidth: "3px",
    width: "100px",
    height: "100px",
  },
  [`& .${classes.text}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: "0px",
      paddingLeft: "0px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "0px",
    },
  },
});

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

interface PlanetATeamProps {
  data: TeamSectionType;
}

interface TeamSectionNewProps {
  team?: Manager[];
}

export function TeamSectionNew({ team }: TeamSectionNewProps) {
 


  if(team == undefined) return null
  if(team.length == 0) return null
  return (
    <StyledBox sx={{ display: { xs: "block", md: "block" } }}>
      <Typography
        variant="h5"
        style={{
          fontWeight: 600,
          marginBottom: "10px",
          marginTop: "70px",
        }}
      >
        Wer verantwortet das Portfolio?
      </Typography>
      {team ?
      <Grid container justifyContent="center">
     
        <Grid item xs={12} style={{ margin: "10px 0" }}>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
          >
            {team.map((teamMember, i) => (
              <SwiperSlide style={{ paddingBottom: "40px" }} key={i}>
                <Box
                  style={{
                    marginTop: "10px",
                    color: "black",
                    marginLeft: "5%",
                    backgroundColor: "#fefaf4",
                    borderRadius: "5px",
                    margin: "auto",
                    overflow: "hidden",
                    boxShadow: "black 0px 0px 30px -25px",
                    display: "flex"
                  }}
                  sx={{
                    width: {
                      xs: "90%",
                      md: "100%",
                    },
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                    minHeight: {
                      xs: "250px",
                      sm: "0px",
                    },
                    maxHeight: {
                      xs: "1000px",
                      sm: "300px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: {
                        xs: "250px",
                        sm: "300px",
                      },
                      backgroundPosition: {
                        xs: "center",
                        sm: "center",
                      },
                    }}
                    style={{
                      minWidth: "200px",
                      backgroundImage: "url(" + teamMember?.image?.url + ")",
                      backgroundSize: "cover",
                    }}
                  />
                  <Box
                    sx={{ minHeight: { sm: "300px", xs: "180px" } }}
                    style={{ padding: "10px 10px 10px 10px", color: "black" }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className={classes.text}>
                        <Typography variant="h6">
                          <b>{teamMember.name}</b>
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ fontSize: "12px", fontWeight: 400 }}
                        >
                          <b>{teamMember.position}</b>
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ marginTop: "20px" }}
                        >
                          {teamMember.description}
                        </Typography>
                      </div>
                    </div>
                    <Box
                      sx={{
                        display: { xs: "none", sm: "flex" },
                        overflowX: { xs: "scroll", sm: "hidden" },
                      }}
                      style={{
                        paddingLeft: "25px",
                        paddingBottom: "7px",
                        marginTop: "30px",
                      }}
                    >
                     
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
    
      </Grid> : null }
    </StyledBox>
  );
}

export default function TeamSection({ data }: PlanetATeamProps) {

  const [activeCard, setActiveCard] = useState(0);
  var teamMembers: TeamMember[] = [];
  data.teamMemberGroups.forEach((group) => {
    teamMembers = teamMembers.concat(group.teamMembers);
  });
  return (
    <Box sx={{ display: { xs: "block", md: "block" } }}>
      <Typography
        variant="h5"
        style={{
          color: "#494949",
          fontWeight: 600,
          marginBottom: "10px",
          marginTop: "70px",
        }}
      >
        Wer verantwortet das Portfolio?
      </Typography>
      <Grid container justifyContent="center">
        {data.introContentBlocks.length > 0 ? (
          <Grid item xs={12}>
            <OverlayText contentBlocks={data.introContentBlocks} />
          </Grid>
        ) : null}
        <Grid item xs={12} style={{ margin: "10px 0" }}>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
          >
            {teamMembers.map((teamMember, i) => (
              <SwiperSlide style={{ paddingBottom: "40px" }} key={i}>
                <Box
                  style={{
                    marginTop: "10px",
                    color: "black",
                    marginLeft: "5%",
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "5px",
                    margin: "auto",
                    overflow: "hidden",
                    boxShadow: "black 0px 0px 30px -25px",
                    display: "flex",
                  }}
                  sx={{
                    width: {
                      xs: "90%",
                      md: "100%",
                    },
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                    minHeight: {
                      xs: "250px",
                      sm: "0px",
                    },
                    maxHeight: {
                      xs: "1000px",
                      sm: "300px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: {
                        xs: "250px",
                        sm: "300px",
                      },
                      backgroundPosition: {
                        xs: "center",
                        sm: "center",
                      },
                    }}
                    style={{
                      minWidth: "200px",
                      backgroundImage: "url(" + teamMember.img.url + ")",
                      backgroundSize: "cover",
                    }}
                  />
                  <Box
                    sx={{ minHeight: { sm: "300px", xs: "180px" } }}
                    style={{ padding: "10px 10px 10px 10px", color: "black" }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className={classes.text}>
                        <Typography variant="h6">
                          <b>{teamMember.name}</b>
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ fontSize: "12px", fontWeight: 400 }}
                        >
                          <b>{teamMember.subHeadline}</b>
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ marginTop: "20px" }}
                        >
                          {teamMember.description}
                        </Typography>
                      </div>
                    </div>
                    <Box
                      sx={{
                        display: { xs: "none", sm: "flex" },
                        overflowX: { xs: "scroll", sm: "hidden" },
                      }}
                      style={{
                        paddingLeft: "25px",
                        paddingBottom: "7px",
                        marginTop: "30px",
                      }}
                    >
                      {teamMember.logos.map((logo, i) => (
                        <img
                          key={i}
                          src={logo.url}
                          style={{
                            width: "auto",
                            marginLeft: "5px",
                            maxHeight: "30px",
                            marginRight: "20px",
                          }}
                        />
                      ))}
                    </Box>{" "}
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        {data.outroContentBlocks.length > 0 ? (
          <Grid item xs={12}>
            <OverlayText contentBlocks={data.outroContentBlocks} />
          </Grid>
        ) : null}
        {data.trackRecord.length > 0 ? (
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <Typography variant="h6">Team Track Record</Typography>
              </Grid>
              {data.trackRecord.map((record) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={3}
                  style={{ textAlign: "center", marginBottom: "50px" }}
                >
                  <Typography variant="h3">{record.value}</Typography>
                  <Typography variant="body2">{record.key}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}
