import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../pages/portal/auth-router";
import { TrackRecordSection as TrackRecordType } from "../../../types/fund_types";
import { USER_STATE } from "../../../types/generic_types";
import { BulletPoint } from "../../../types/vc-platform/news_types";
import NEWS_CLIENT from "../../../utility/news-client";
import IvBox from "../../data-display/standard-card/iv-box";
import StandardCard from "../../data-display/standard-card/standard-card";
import PrimaryButton from "../../input-elements/buttons";
import Header2 from "../../template-components/texts/headers";
import { ContentBlocksNew, RichTextBlock } from "./content-blocks";

interface TrackRecordSectionProps {
  fundId: string;
}

export default function TrackRecordSection({
  fundId,
}: TrackRecordSectionProps) {
  const [trackRecord, setTrackrecord] = useState<BulletPoint[]>();
  const [trackRecordDate, setTrackRecordDate] = useState<string>();

  useEffect(() => {
    NEWS_CLIENT.getFund(fundId, (vc) => {
      const ivcompany = vc?.investmentCompany;
      var trackRecord = ivcompany?.trackRecord;
      var trackRecordDate = ivcompany?.trackRecordDate;
      setTrackRecordDate(trackRecordDate);
      if (trackRecord?.length > 0) {
        setTrackrecord(trackRecord);
      }
    });
  }, []);
  return (
    <div>
      {trackRecord && trackRecordDate ? (
        <>
          <Header2 style={{ margin: "50px 0 20px 0" }}>
            Performance & Trackrecord{" "}
          </Header2>

          <Grid
            container
            sx={{
              padding: {
                xs: "",
              },
            }}
          >
            {trackRecord.map((record, i) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                spacing={0}
                sx={{
                  padding: {
                    xs: "10px",
                    sm: "10px",
                    md:
                      i % 3 == 0
                        ? "10px 10px 10px 0"
                        : i % 3 == 1
                        ? "10px 5px 10px 5px"
                        : "10px 0 10px 10px",
                  },
                }}
              >
                <IvBox
                  sx={{
                    width: {
                      md: "calc(100% - 40px)",
                      xs: "calc(100% - 50px)",
                    },
                  }}
                  style={{
                    height: "calc(100px - 30px)",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: {
                        xs: "24px",
                        sm: "30px",
                      },
                    }}
                    style={{ fontWeight: 500 }}
                  >
                    {record.content}
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "12px" }}>
                    {record.headline}
                  </Typography>
                </IvBox>
              </Grid>
            ))}
          </Grid>

          <div style={{ marginTop: "10px", marginLeft: "4px" }}>
            <Typography variant="body2">
              Stand der Angaben:{" "}
              {new Date(trackRecordDate).toLocaleDateString("de-DE")}; Frühere
              Performance ist kein zuverlässiger Indikator für künftige
              Wertentwicklungen.
            </Typography>
          </div>
        </>
      ) : null}
    </div>
  );
}

interface TrackRecordNewSectionProps {
  section: TrackRecordType;
}

export function TrackRecordSectionNew({ section }: TrackRecordNewSectionProps) {
  const appContext = useContext(AppContext);
  return (
    <div style={{ marginTop: "40px" }}>
      <Typography
        variant="h3"
        style={{ fontSize: "24px", fontWeight: 700, color: "#4B4B4B" }}
      >
        {section.header}
      </Typography>
      <div style={{ width: "100%", position: "relative", marginTop: "20px" }}>
        {appContext.userState != USER_STATE.ONBOARDED_2 ? (
          <>
            <Typography variant="body1" style={{ marginBottom: "20px" }}>
              Bitte schließe Deine Identifikation ab, um den Track Record sehen
              zu können.
            </Typography>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <img
                src={section?.browserImageBlurred?.url}
                style={{
                  borderRadius: "5px",
                  position: "relative",
                  width: "100%",
                  filter: "blur(10px)",
                }}
              />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <img
                src={section?.mobileImageBlurred?.url}
                style={{
                  borderRadius: "5px",
                  position: "relative",
                  width: "100%",
                  filter: "blur(10px)",
                }}
              />
            </Box>
            <Link to="/portal/onboarding">
              <PrimaryButton
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "calc(50% - 100px)",
                  width: "200px",
                }}
              >
                Zum Onboarding
              </PrimaryButton>
            </Link>
          </>
        ) : (
          <>
            <RichTextBlock html={section.introductionText.html} />
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <img
                src={section?.browserImage?.url}
                style={{
                  borderRadius: "5px",
                  position: "relative",
                  width: "100%",
                }}
              />
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <img
                src={section?.mobileImage?.url}
                style={{
                  borderRadius: "5px",
                  position: "relative",
                  width: "100%",
                }}
              />
            </Box>
          </>
        )}
      </div>
    </div>
  );
}
