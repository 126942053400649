import { EDUCATION, PROFESSION } from "./investment_types";
import { INVESTOR_TYPE } from "./onboarding_types";
export type InventureUser = {
  uid: string;
  cashlink_id: string;
  communication: Communication;
  risk_confirmed: Boolean;
  legal_person: LegalPerson;
  natural_person: NaturalPerson;
  identification: Identification;
  taxInformation: TaxInformation;
  bank_account: BankAccount;
  tangany_wallet: string;
  investor_type: INVESTOR_TYPE;
  benefiting_persons: BenefitingPerson[];
  signup_at: string;
  generic_1: string;
};
export type Communication = {
  email: string;
  email_confirmed: boolean;
};
export type LegalPerson = {
  company_name: string;
  legal_entity_type: LEGAL_ENTITY_TYPE;
  company_type: string;
  street: string;
  city: string;
  zip: string;
  country: COUNTRY;
  phone: string;
  commercial_register_number: string;
  commercial_register: string;
  company_identity_proof_id: string;
  company_structure_proof: string;
};
export type NaturalPerson = {
  salutation: SALUTATION;
  title: TITLE;
  forename: string;
  surname: string;
  birth_date: string;
  birth_place: string;
  birth_country: COUNTRY;
  citizenship: string;
  street: string;
  city: string;
  zip: string;
  country: COUNTRY;
  phone: string;
  address_addition: string;
  occupation: PROFESSION;
  educational_qualification: EDUCATION;
};

export interface BenefitingPerson {
  salutation: SALUTATION;
  title: TITLE;
  forename: string;
  surname: string;
  birth_date: string;
  birth_place: string;
  birth_country: COUNTRY;
  nationality: string;
  street: string;
  city: string;
  zip: string;
  country: COUNTRY;
  phone: string;
  address_addition: string;
  occupation: PROFESSION;
  educational_qualification: EDUCATION;
}

export type Identification = {
  document_type: string;
  document_id: string;
  document_issuer: string;
  document_valid_from: string;
  document_valid_to: string;
  identity_provider: string;
  legitimation_process_id: string;
  legitimation_protocol_blob: string;
  verified_at: string;
};

export type BankAccount = {
  account_holder: string;
  iban: string;
  bic: string;
  bank: string;
  country: string;
  currency: string;
};

export type BankAndTax = {
  bank?: string;
  iban?: string;
  bic?: string;
  tin?: string;
  tax_number?: string;
  tax_office?: string;
  tax_germany_only?: boolean;
  fatca_additional_tax_countries?: string;
};

export type AdditionalTaxCountry = {
  tin: string;
  country: string;
};

export enum COUNTRY {
  DEU = "DEU",
  AUT = "AUT",
  CHE = "CHE",
}

export enum SALUTATION {
  MS = "MS",
  MR = "MR",
  D = "D",
}

export enum TITLE {
  NONE = "NONE",
  DR = "DR",
}

export enum LEGAL_ENTITY_TYPE {
  GMBH = "GMBH",
  UG = "UG",
}

// -- Dinesh Kumar

export type TaxInformation = {
  tax_identification_number: string;
  non_assessment_certificate: string;
  tax_number?: string;
  tax_office?: string;
  tax_germany_only?: boolean;
  fatca_additional_tax_countries?: string;
};

export type Address = {
  city: string;
  country: string;
  street: string;
  zip: string;
  address_addition: string;
};
