import { Asset, Avatar, Document, RichText } from "./generic_types";
import ContentBlocks from "../components/page-components/fund-details/content-blocks";

export type Fund = {
  id: string;
  cashlinkCampaigns: Campaign[];
  structure: STRUCTURE;
  type: FUND_TYPE;
  name: string;
  fundName: string;
  targetInvestmentId: string;
  targetInvestmentType: TARGET_INVESTMENT_TYPE;
  description: string;
  tokenLogo: string;
  tokenLogoInverted: string;
  backgroundImgUrl: string;
  headerText: string;
  founderImgUrl: string;
  numberStartups: number;
  fundingThershold: number;
  region: String;
  emissionGoal: number;
  openingDate: string;
  closingDate: string;
  expectedFirstReturnDate: string;
  investmentStage: FUND_STAGE;
  fundStatus: FUND_STATUS;
  documents: Document[];
  tags: string[];
  logoUrl: string;
  logoInvertedUrl: string;
  roi: string;
  duration: string;
  fokus: string;
  nettoMoneyMultiplier: string;
  cashlinkId: string;
  fundTab1: FundTab1Section | null;
  hidden: boolean;
  videoUrl: string;
  NoFundPresentation: boolean;
  reportings: Reporting[];
  invertedThumbnailLogo: boolean;
};

export type StringPair = {
  key: string;
  value: string;
};

export type ContentBlock = {
  textBlock: RichText;
  browserImage: string;
  browserImg: Asset;
  mobileImage: string;
  mobileImg: Asset;
};

export type Campaign = {
  cashlinkId: string;
  maxInvestmentAmount: number;
  minInvestmentAmount: number;
  ewpg: boolean;
  characteristic: CHARACTERISTIC[];
  id: string;
  name: string;
  type: CAMPAIGN_TYPE;
  emissionSize: string;
  tokenLogo: string;
  tokenLogoInverted: string;
  drawnSize: string;
  tokenPrics: number;
  hidden: string;
  documents: Document[];
  serviceScore: number;
  agio: number;
  experienceAndKnowledgeScore: number;
  amountAndVolumeScore: number;
  investmentProcessImg: string;
  investmentProcessLogo: string;
  costData: CostData;
  provider: PROVIDER;
  bankAccountHolder: string;
  bankAccountIban: string;
  bankAccountBic: string;
  bankAccountBank: string;
};

export type CostData = {
  monetaereZuwendungenAufAusgabebetrag: number;
  monetaereZuwendungenAufAusgabebetragAnVermittler: number;

  kostenVermittlungEinmal: number;
  kostenVermittlungLaufend: number;
  zuwendungenVonKostenVermittlungEinmal: number;
  zuwendungenVonKostenVermittlungLaufend: number;
  kostenFinanzInstrumentEinmal: number;
  kostenFinanzInstrumentLaufend: number;

  gesamtkostenFinanzInstrumentEinmal: number;
  gesamtkostenFinanzInstrumentLaufend: number;
  gesamtkostenKomplett: number;
  gesamtkostenKomplettPA: number;

  laufzeit: number;
  kostenReduzierenRenditeErstenJahr: number;
  kostenReduzierenRenditeFolgejahre: number;
};

export type Reporting = {
  name: string;
  document: Asset;
  date: Date;
};

export type FundTab1Section = {
  introSection: GenericSectionType;
  keyFactSection: KeyFactSection;
  fundOverviewSection: OverviewSectionType;
  investmentStrategySection: InvestmentStrategySectionType;
  teamMemberSection: TeamSectionType;
  introductionSection: IntroductionSectionType;
  companySection: CompanySectionType;
  conditionSection: ConditionSectionType;
  trackRecordSection: TrackRecordSection;
  genericSections: GenericSectionType[];
  whatToKnowSection: WhatToKnowSectionType;
  reasonSection: reasonSectionType;
};

export type GenericSectionType = {
  header: string;
  contentBlocks: ContentBlock[];
};

export type TrackRecordSection = {
  header: string;
  introductionText: RichText;
  browserImage: Asset;
  browserImageBlurred: Asset;
  mobileImage: Asset;
  mobileImageBlurred: Asset;
};

export type SimpleCard = {
  headline: string;
  content: string;
  icon: Asset;
};

export type reasonSectionType = {
  headline: string;
  reasons: StringPair[];
};

export type WhatToKnowSectionType = {
  headline: string;
  simpleCards: SimpleCard[];
  mainCard: SimpleCard;
};

export type KeyFactSection = {
  headline1: string;
  headline2: string;
  headline3: string;
  subheadline1: string;
  subheadline2: string;
  subheadline3: string;
  description1: RichText;
  description2: RichText;
  description3: RichText;
  img1: string;
  img2: string;
  img3: string;
};

export type Company = {
  logoUrl: string;
  logo: Asset;
  name: string;
  description: RichText;
  investors: Avatar[];
  website: string;
  punchline: string;
  founders: string[];
  investmentDecisionLink: string;
  yearOfFoundation: string;
};

export type TeamMemberGroup = {
  introContent: RichText;
  teamMembers: TeamMember[];
  outroContent: RichText;
};

export type CompanySectionType = {
  introContent: RichText;
  companies: Company[];
};

export type OverviewSectionType = {
  contentBlocks: ContentBlock[];
};

export type InvestmentCriteria = {
  header: string;
  criterias: string[];
};

export type InvestmentStrategySectionType = {
  contentBlocks: ContentBlock[];
  investmentCriterias: InvestmentCriteria[];
};

export type TeamSectionType = {
  introContentBlocks: ContentBlock[];
  teamMemberGroups: TeamMemberGroup[];
  outroContentBlocks: ContentBlock[];
  trackRecord: StringPair[];
};

export type IntroductionSectionType = {
  contentBlocks: ContentBlock[];
};

export type TeamMember = {
  imgUrl: string;
  img: Asset;
  description: string;
  name: string;
  color: GraphCMSColor;
  subHeadline: string;
  bulletPoints: string[];
  logoUrls: string[];
  logos: Asset[];
};

export type ReportingEntity = {
  name: string;
  location: string;
  type: string;
};

export type GraphCMSColor = {
  hex: string;
};

export type FundConditions = {
  name: string;
  companyType: string;
  managementFee: string;
  carry: string;
  hurdleRate: string;
};

export type InVentureConditions = {
  spv: string;
  investmentForm: string;
  managementFee: string;
  initialFee: string;
};

export type ConditionSectionType = {
  introContentBlocks: ContentBlock[];
  conditionsInVenture: InVentureConditions;
  conditionsFund: FundConditions;
  outroContentBlocks: ContentBlock[];
  inVentureConditions: StringPair[];
  fundConditions: StringPair[];
};

export enum FUND_STAGE {
  SEED = "Early",
  GROWTH = "Growth",
  EARLY_AND_GROWTH = "Early & Growth",
}

export enum FUND_TYPE {
  VC = "VC",
  GROWTH = "GROWTH",
  STARTUP = "STARTUP",
}

export enum CAMPAIGN_TYPE {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum FUND_STATUS {
  OPEN = "OPEN",
  COMING_SOON = "COMING_SOON",
  CLOSED = "CLOSED",
  SPECIAL1 = "SPECIAL1",
}

export enum CHARACTERISTIC {
  ONE_MILLION_EXCEPTION = "ONE_MILLION_EXCEPTION",
}

export enum TARGET_INVESTMENT_TYPE {
  FUND = "FUND",
  STARTUP = "STARTUP",
}

export enum STRUCTURE {
  TOKEN = "TOKEN",
  AIF = "AIF",
}

export enum PROVIDER {
  CASHLINK = "CASHLINK",
  KVG = "KVG",
}
