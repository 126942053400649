import { Typography } from "@mui/material";
import React from "react";
import PrimaryButton from "../components/input-elements/buttons";
import IVButton from "../components/input-elements/iv-button";
import MultistepForm from "../components/vertical-multistep-form/multistep-form";
import MultistepFormBody from "../components/vertical-multistep-form/multistep-form-body";
import MultistepFormNavigation from "../components/vertical-multistep-form/multistep-form-navigation";

export default function GenericErrorPage() {
  return (
    <div>
      <MultistepForm style={{ height: "100vh" }}>
        <MultistepFormNavigation />
        <MultistepFormBody>
          <div style={{ display: "flex", flexDirection: "column", maxWidth: "600px" }}>
            <Typography variant="h3">Fehler</Typography>
            <Typography variant="body1">
              Es ist wohl ein Fehler aufgetreten. Über folgenden Link gelangst Du zurück zur Investmentplattform.
            </Typography>
            <a href="/portal/products" style={{textDecoration: "none", marginTop: "30px"}}>   <PrimaryButton style={{width: "200px"}}>Zur Investmentplattform</PrimaryButton>
         
                </a>
          </div>
        </MultistepFormBody>
      </MultistepForm>
    </div>
  );
}
