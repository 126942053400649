import React, { useContext } from "react";
import { ProfileSection, ProfileSectionLeftSide } from "../profile/profile";
import { AppContext } from "../direct-router";
import { HookFormTextFieldV2 } from "../../../components/hook-form-inputs";
import { useForm } from "react-hook-form";

export default function LegalPerson() {
  const app_context = useContext(AppContext);
  const user = app_context.user;

  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm<any>({ mode: "onChange" });

  return (
    <div className="w-full flex flex-col items-center text-primary">
      <div className="flex bg-cover justify-center w-full bg-center ">
        <div className="max-w-6xl w-full px-6 md:px-12">
          <h1 className="mt-12 mb-8 text-primary font-headline">
            Dein Investmentvehikel
          </h1>
        </div>
      </div>
      <div className="h-[1px] w-full bg-gray-200" />
      <div className="w-full max-w-6xl pb-32 mt-16">
        <div className="-translate-y-14 md:-translate-y-6 mx-6 md:mx-12 grid gap-16">
          <ProfileSection>
            <ProfileSectionLeftSide label="Daten zum Unternehmen">
              <p className="text-sm text-gray-600 w-full">
                Wirtschaftlich Berechtigter ist eine natürliche Person, die
                letztlich Eigentümer des Anlegers (der Gesellschaft) ist oder
                ihn kontrolliert, oder auf deren Anweisung eine Transaktion
                letztlich durchgeführt oder eine Geschäftsbeziehung letztlich
                begründet wird.
              </p>
            </ProfileSectionLeftSide>
            <form className="grid gap-4">
              <HookFormTextFieldV2
                name="company_name"
                control={control}
                error={false}
                label="Name des Unternehmens"
                required={true}
                defaultValue={user?.legal_person?.company_name}
              />
              <HookFormTextFieldV2
                name="company_type"
                control={control}
                error={false}
                label="Art des Unternehmens"
                required={true}
                defaultValue={user?.legal_person?.legal_entity_type}
              />
              <HookFormTextFieldV2
                name="company_name"
                control={control}
                error={false}
                label="Name des Unternehmens"
                required={true}
                defaultValue={user?.legal_person?.company_name}
              />
            </form>
          </ProfileSection>
          <ProfileSection>
            <ProfileSectionLeftSide label="Wirtschaftlich berechtige Personen">
              <p className="text-sm text-gray-600 w-full">
                Wirtschaftlich Berechtigter ist eine natürliche Person, die
                letztlich Eigentümer des Anlegers (der Gesellschaft) ist oder
                ihn kontrolliert, oder auf deren Anweisung eine Transaktion
                letztlich durchgeführt oder eine Geschäftsbeziehung letztlich
                begründet wird.
              </p>
            </ProfileSectionLeftSide>
            <div className="grid gap-8">
              <div>
                <p>
                  Wirtschaftlich Berechtigter ist eine natürliche Person, die
                  letztlich Eigentümer des Anlegers (der Gesellschaft) ist oder
                  ihn kontrolliert, oder auf deren Anweisung eine Transaktion
                  letztlich durchgeführt oder eine Geschäftsbeziehung letztlich
                  begründet wird.
                </p>
              </div>
              <div>
                <span className="font-medium">
                  Jede natürliche Person ist wirtschaftlicher Berechtigter des
                  Anlegers, wenn:
                </span>
                <ul className="list-disc text-sm">
                  <li>
                    Sie/Er direkt oder indirekt mehr als 25% der Kapitalanteile
                    des Anlegers hält
                  </li>
                  <li className="list-disk">
                    Sie/Er mehr als 25% der Stimmrechte des Anlegers
                    kontrolliert Kontrolle über den Anleger ausübt (Fiktiver
                    Wirtschaftlich Berechtigter)
                  </li>
                </ul>
              </div>
              <div className="flex flex-col">
                <p className="font-medium text-sm">Bereits hinzugefügt:</p>
                {user?.benefiting_persons.map((person, i) => (
                  <div className="w-full py-2 px-4 bg-primary bg-opacity-5 rounded-md">
                    {person.forename + " " + person.surname}
                  </div>
                ))}
              </div>
            </div>
          </ProfileSection>
        </div>
      </div>
    </div>
  );
}
