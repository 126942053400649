
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { News } from "../../../../types/vc-platform/news_types";
import NEWS_CLIENT from "../../../../utility/news-client";
import NewsCard, { NewsCardWide } from "../../../data-display/news-card";

type NewsSectionProps = {
  fundId: String;
};

export default function NewsSection({ fundId }: NewsSectionProps) {
  const [news, setNews] = useState<News[]>();

  useEffect(() => {
    NEWS_CLIENT.getNewsForFund(fundId, (news) => {
      setNews(news);
    });
  }, []);
  if (!news) return null
  if (news?.length == 0) return null;

  return (
    <div>
      <Typography
        variant="h5"
        style={{
          color: "#494949",
          fontWeight: 600,
          marginBottom: "10px",
          marginTop: "100px",
        }}
      >
        Was sind die jüngsten Highlights im Portfolio?
      </Typography>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        {news?.length > 0 ? (
          <Grid container style={{ width: "100%", marginTop: "0px" }}>
            <Grid item xs={12} md={8}>
              <div style={{ padding: "10px 10px 10px 10px" }}>
                <NewsCardWide news={news[0]} />
              </div>
            </Grid>
            {news.length > 1 &&
              news.slice(1).map((news) => (
                <Grid item xs={12} md={4}>
                  <div style={{ padding: "10px 10px 10px 10px" }}>
                    <NewsCard news={news} />
                  </div>
                </Grid>
              ))}
          </Grid>
        ) : null}
      </Box>
      <Box sx={{ display: { xs: "block", md: "none", marginTop: "20px" } }}>
        <Swiper
          style={{
            width: "calc(100vw - 20px)",
            paddingBottom: "40px",
            maxWidth: "800px",
          }}
          spaceBetween={30}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
        >
          {news.map((news, i) => (
            <SwiperSlide>
              <NewsCardWide
                news={news}
                style={{ paddingBottom: "0px" }}
                key={i}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </div>
  );
}
